import React, { useState } from "react";
// Bootstrap
import { Container, Row, Col } from "react-bootstrap";
// Material UI
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { Button, TextField, makeStyles } from "@material-ui/core";
import { CheckRounded, CloseRounded } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
// Alerts and toasts
import swal from "sweetalert";
import { toast } from "react-hot-toast";
// Tanstack Query
import { useQueryClient } from "@tanstack/react-query";
// Shared
import { createEditDeleteTag } from "../../../../shared/fetchers/tags";

const EditarTags = ({ tags }) => {
  const [tag, setTag] = useState("");
  const [tagTranslation, setTagTranslation] = useState("");
  const [tagDescription, setTagDescription] = useState("");

  const queryClient = useQueryClient();

  const classes = useStyles();

  const clearForm = () => {
    setTag("");
    setTagTranslation("");
    setTagDescription("");
  };

  const editTag = async (e) => {
    e.preventDefault();
    console.log("Editando Tag");

    try {
      const response = await createEditDeleteTag(
        tag.tag,
        "editar",
        tagTranslation,
        tagDescription
      );

      if (!response.status) throw new Error("Error al editar tag");

      queryClient.invalidateQueries({
        queryKey: ["tags"],
      });

      clearForm();

      toast.success("Tag editado");
    } catch (error) {
      toast.error("Error al editar tag");
    }
  };

  const deleteTag = async (e) => {
    e.preventDefault();
    console.log("Eliminar");

    const verify = await swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este tag",
      icon: "warning",
      buttons: ["Cancelar", "Eliminar"],
    });

    if (!verify) return;

    try {
      const response = await createEditDeleteTag(tag.tag, "eliminar");

      if (!response.status) throw new Error("Error al eliminar tag");

      queryClient.invalidateQueries({
        queryKey: ["tags"],
      });

      clearForm();

      toast.success("Tag eliminado");
    } catch (error) {
      toast.error("Error al eliminar tag");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="d-flex align-items-center">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Editar o Eliminar Tag
            </div>
          </div>
          <hr />
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Autocomplete
              options={tags.filter((tag) => tag?.tag.length > 0)}
              getOptionLabel={(option) =>
                option
                  ? `${option.tag} - ${option.tagEng ?? "No traducido"}`
                  : ""
              }
              value={tag}
              placeholder="Selecciona un tag"
              onChange={(e, value) => {
                setTag(value);
                setTagTranslation(value?.tagEng ?? "");
                setTagDescription(value?.description ?? "");
              }}
              noOptionsText="Sin resultados"
              disabled={!tags.length}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />
            <TextField
              name="autor"
              value={tagTranslation}
              onChange={(e) => setTagTranslation(e.target.value)}
              variant="outlined"
              placeholder="Traducción"
              disabled={!tag}
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />

            <TextField
              name="tagDescription"
              value={tagDescription}
              onChange={(e) => setTagDescription(e.target.value)}
              variant="outlined"
              placeholder="Descripción"
              multiline
              maxRows={4}
              disabled={!tag}
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />

            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!tagTranslation}
                startIcon={<CheckRounded />}
                onClick={editTag}
                classes={{ root: classes.agregar }}
              >
                Editar
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!tag}
                startIcon={<CheckRounded />}
                onClick={deleteTag}
                classes={{ root: classes.eliminar }}
              >
                Eliminar
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditarTags;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
