import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { GENERAL_URL_API } from "../../../../shared/urls";
import swal from "sweetalert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";

const AgregarNuevaSedeForm = ({ sede, clearSede }) => {
  const [loading, setLoading] = useState(false);
  const [institutos, setInstitutos] = useState([]);

  const [formData, setFormData] = useState({
    instituto: { value: null, error: false },
    nombre: { value: "", error: false },
    correo: { value: "", error: false },
    telefono: { value: "", error: false },
    direccion: { value: "", error: false },
    ciudad: { value: "", error: false },
  });

  useEffect(() => {
    fetchInstitutes();
  }, []);

  useEffect(() => {
    if (sede)
      setFormData({
        correo: { value: sede?.correo, error: false },
        telefono: { value: sede?.telefono, error: false },
        nombre: { value: sede?.nombre, error: false },
        instituto: { value: sede?.instituto || null, error: false },
        direccion: { value: sede?.direccionSede, error: false },
        ciudad: { value: sede?.ciudadSede, error: false },
      });
  }, [sede]);

  const fetchInstitutes = async () => {
    const fd = new FormData();

    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const data = await res.json();
    setInstitutos(data.data);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const name = n || e.target.name;
    const value = v || e.target.value;
    setFormData({ ...formData, [name]: { value, error: false } });
  };

  const updateInstituto = (v) => {
    const temp = { ...formData };
    temp.instituto.value = v;
    setFormData(temp);
  };

  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { value: "", error: false };
    }
    setFormData(temp);
  };

  const clearErrors = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], error: false };
    }
  };

  const validate = () => {
    let isValid = true;

    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };

    for (const formElement in temp) {
      if (temp[formElement].value === "") {
        temp[formElement].error = true;
        isValid = false;
      }
    }

    setFormData(temp);
    if (isValid) clearErrors();
    return isValid;
  };

  const addSede = async () => {
    setLoading(true);
    const fd = new FormData();

    fd.append("correo", formData.correo.value);
    fd.append("telefono", formData.telefono.value);
    fd.append("pais", formData.instituto.value.pais);
    fd.append("direccionSede", formData.direccion.value);
    fd.append("ciudadSede", formData.ciudad.value);
    if (sede) {
      fd.append("sede", formData.nombre.value); //nombre de la sede
      fd.append("idSede", sede.idInstitutoPlanLector);
    } else {
      fd.append(
        "instituto",
        `${formData.instituto.value.nombre} | ${formData.nombre.value}`, //nombre de la sede
      );
      fd.append("superInstituto", formData.instituto.value.nombre); //nombre del instituto
    }

    const url = `${GENERAL_URL_API}/sedes/${
      !sede ? "agregar" : "editar"
    }Sede.php`;
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const data = await res.json();
    if (data.status) {
      await swal({
        title: "Éxito",
        text: data.info,
        icon: "success",
      });
      if (sede) await clearSede();
    } else {
      await swal({
        title: "Error",
        text: data.info,
        icon: "error",
      });
    }

    setLoading(false);
  };

  const SubmitForm = async () => {
    setLoading(true);

    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };

    if (validate()) {
      for (const formElement in temp) {
        console.log(temp[formElement].value);
      }
      addSede();
    }

    setLoading(false);
  };

  return (
    <form>
      <label className="admin-label-text mt-3 d-block">Instituto*</label>
      <Autocomplete
        fullWidth
        options={institutos}
        getOptionLabel={(option) => option.nombre}
        value={formData.instituto.value}
        disabled={Boolean(sede)}
        onChange={(_, value) => updateInstituto(value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              variant="outlined"
              size="small"
              fullWidth
              style={{
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={formData.instituto.error}
              helperText={formData.instituto.error ? "Campo no válido" : null}
            />
          </>
        )}
      />

      <label className="admin-label-text mt-3 d-block">Nombre de la sede</label>
      <TextField
        name="nombre"
        disabled={Boolean(sede)}
        value={formData.nombre.value}
        onChange={updateForm}
        error={formData.nombre.error}
        helperText={
          formData.nombre.error ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <label className="admin-label-text mt-3 d-block">Correo</label>
      <TextField
        name="correo"
        value={formData.correo.value}
        onChange={updateForm}
        error={formData.correo.error}
        helperText={
          formData.correo.error ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <label className="admin-label-text mt-3 d-block">País</label>
      <TextField
        name="correo"
        value={formData.instituto.value ? formData.instituto.value.pais : ""}
        disabled={true}
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <label className="admin-label-text mt-3 d-block">Teléfono</label>
      <TextField
        name="telefono"
        value={formData.telefono.value}
        onChange={(e) => {
          updateForm(e);
        }}
        error={formData.telefono.error}
        helperText={
          formData.telefono.error ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <label className="admin-label-text mt-3 d-block">Dirección</label>
      <TextField
        name="direccion"
        value={formData.direccion.value}
        onChange={updateForm}
        error={formData.direccion.error}
        helperText={
          formData.direccion.error ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <label className="admin-label-text mt-3 d-block">Ciudad</label>
      <TextField
        name="ciudad"
        value={formData.ciudad.value}
        onChange={updateForm}
        error={formData.ciudad.error}
        helperText={
          formData.ciudad.error ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <div className="d-flex justify-content-end mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={SubmitForm}
          startIcon={<CheckRounded />}
          disabled={loading}
          style={{ background: "#81c868" }}
        >
          {!sede ? "Agregar" : "Editar"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={!sede ? clearForm : clearSede}
          className="ml-2"
          startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
          disabled={loading}
          style={{ background: "#f05050" }}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default AgregarNuevaSedeForm;
