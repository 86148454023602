import { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { DropzoneArea } from "material-ui-dropzone";
import swal from "sweetalert";
// icons
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import { Button } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import { CloseRounded } from "@material-ui/icons";
// data
import { GENERAL_URL_API } from "../../../shared/urls";
// css
import TinyEditorComponent from "../tinyMCEEditor";
const CrearEntradaBlog = () => {
  const [loading, setLoading] = useState(false);

  //Definimos los estaos para almacenar los datos del formulario y sus errores
  const [formData, setFormData] = useState({
    titulo: { value: "", error: false },
    autor: { value: "", error: false },
    descripcion: { value: "", error: false },
    tag1: { value: "", error: false },
    tag2: { value: "", error: false },
    tag3: { value: "", error: false },
    tag4: { value: "", error: false },
    tag5: { value: "", error: false },
    libro1: { value: "", error: false },
    libro2: { value: "", error: false },
  });

  //Estado para guardar lo que hay en el editor de texto
  const [editorState, setEditorState] = useState("");

  //Estado para guardar todo lo relacionado con las imagenes
  const [imageState, setImageState] = useState({
    thumb: {
      images: [],
      error: false,
    },
    cover: {
      images: [],
      error: false,
    },
  });

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };
  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { value: "", error: false };
    }
    setFormData(temp);
    setImageState({
      ...imageState,
      error: false,
    });
  };

  const handleDropFileChange = (images, type) => {
    setImageState({
      ...imageState,
      [type]: {
        ...imageState[type],
        images,
      },
    });
  };

  const validateForm = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      //No todos los tags son obligatorios
      if (
        formElement !== "tag2" &&
        formElement !== "tag3" &&
        formElement !== "tag4" &&
        formElement !== "tag5"
      ) {
        if (temp[formElement].value === "") {
          temp[formElement].error = true;
          isValid = false;
        }
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(temp);

    //Verificamos que efectivamente haya dos imagenes
    if (!imageState.cover.images.length) {
      setImageState({
        ...imageState,
        cover: {
          ...imageState.cover,
          error: true,
        },
      });
      isValid = false;
    }

    //Verificamos que efectivamente haya dos imagenes
    if (!imageState.thumb.images.length) {
      setImageState({
        ...imageState,
        thumb: {
          ...imageState.thumb,
          error: true,
        },
      });
      isValid = false;
    }

    return isValid;
  };

  //Funcion para validar el formulario y subirlo
  const SubmitForm = async () => {
    setLoading(true);
    if (validateForm()) {
      try {
        await addBlog();
      } catch (e) {
        console.log(e);
        await swal({
          title: "Error creando entrada.",
          text: e.message,
          icon: "error",
        });
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const addBlog = async () => {
    const fd = new FormData();
     
    fd.append("contenidoBlog", editorState);
    fd.append("tituloBlog", formData.titulo.value);
    fd.append("autorBlog", formData.autor.value);
    fd.append("descBlog", formData.descripcion.value);
    fd.append("tag1", formData.tag1.value);
    fd.append("tag2", formData.tag2.value);
    fd.append("tag3", formData.tag3.value);
    fd.append("tag4", formData.tag4.value);
    fd.append("tag5", formData.tag5.value);
    fd.append("recom1", formData.libro1.value);
    fd.append("recom2", formData.libro2.value);
    if (imageState.thumb.images.length)
      fd.append("thumbBlog", imageState.thumb.images[0]);
    if (imageState.cover.images.length)
      fd.append("coverBlog", imageState.cover.images[0]);

    console.log(editorState);

    const res = await fetch(`${GENERAL_URL_API}/blog/crearEntrada.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error creando entrada.",
        text: data.info,
        icon: "error",
      });
    else
      await swal({
        title: "La entrada se ha creado exitosamente.",
        text: data.info,
        icon: "success",
      });
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <ForumOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Crear una nueva entrada en el blog
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <form>
              <TinyEditorComponent
                value={editorState}
                onChange={(nv) => {
                  setEditorState(nv);
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Título del post*
              </label>
              <TextField
                name="titulo"
                value={formData.titulo.value}
                onChange={updateForm}
                error={formData.titulo.error}
                helperText={
                  formData.titulo.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Título"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Autor*</label>
              <TextField
                name="autor"
                value={formData.autor.value}
                onChange={updateForm}
                error={formData.autor.error}
                helperText={
                  formData.autor.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Autor"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Descripción corta*
              </label>
              <TextField
                name="descripcion"
                value={formData.descripcion.value}
                onChange={updateForm}
                error={formData.descripcion.error}
                helperText={
                  formData.descripcion.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Descripción corta"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Tag 1* (solo 1 es requerido)
              </label>
              <TextField
                name="tag1"
                value={formData.tag1.value}
                onChange={updateForm}
                error={formData.tag1.error}
                helperText={
                  formData.tag1.error ? "Este campo no puede estar vacío" : null
                }
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Tag 2</label>
              <TextField
                name="tag2"
                value={formData.tag2.value}
                onChange={updateForm}
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Tag 3</label>
              <TextField
                name="tag3"
                value={formData.tag3.value}
                onChange={updateForm}
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Tag 4</label>
              <TextField
                name="tag4"
                value={formData.tag4.value}
                onChange={updateForm}
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Tag 5</label>
              <TextField
                name="tag5"
                value={formData.tag5.value}
                onChange={updateForm}
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Libro recomendado 1*
              </label>
              <TextField
                name="libro1"
                value={formData.libro1.value}
                onChange={updateForm}
                error={formData.libro1.error}
                helperText={
                  formData.libro1.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="MM0001"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Libro recomendado 2*
              </label>
              <TextField
                name="libro2"
                value={formData.libro2.value}
                onChange={updateForm}
                error={formData.libro2.error}
                helperText={
                  formData.libro2.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="MM0002"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Archivos de arte*
              </label>
              <div
                className="admin-muted-text-small"
                style={imageState.thumb.error ? { color: "red" } : null}
              >
                Thumb
              </div>
              <DropzoneArea
                id="dropzone"
                dropzoneText="Arrastra un archivo o haz click aquí!"
                showFileNames={true}
                // maxFileSize={} //n in bytes
                showAlerts={true}
                filesLimit={1}
                acceptedFiles={["image/png"]}
                onChange={(files) => handleDropFileChange(files, "thumb")}
              />

              <div
                className="admin-muted-text-small"
                style={imageState.cover.error ? { color: "red" } : null}
              >
                Cover
              </div>
              <DropzoneArea
                id="dropzone"
                dropzoneText="Arrastra un archivo o haz click aquí!"
                showFileNames={true}
                // maxFileSize={} //n in bytes
                showAlerts={true}
                filesLimit={1}
                acceptedFiles={["image/png"]}
                onChange={(files) => handleDropFileChange(files, "cover")}
              />

              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitForm}
                  startIcon={<CheckRounded />}
                  disabled={loading}
                  style={{ background: "#81c868" }}
                >
                  Finalizar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2"
                  onClick={clearForm}
                  startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                  disabled={loading}
                  style={{ background: "#f05050" }}
                >
                  Cancelar
                </Button>
              </div>
            </form>
            )
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CrearEntradaBlog;
