import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Creates a new institute with provided information.
 *
 * @async
 * @param {Object} evento - The information of the event.
 * @param {string} evento.fecha - The date of the event.
 * @param {string} evento.hora - The time of the event.
 * @param {string} evento.nombreEvento - The name of the event.
 * @param {string} evento.nombreEng - The name of the event in English.
 * @param {string} evento.invitados - The guests of the event.
 * @param {"Público"|"Privado"|string} evento.tipoEvento - The type of the event.
 * @param {string} evento.descripcion - The description of the event.
 * @param {string} evento.descripcionEng - The description of the event in English.
 * @param {File} evento.imagen - The image of the event.
 * @param {string} evento.link - The link of the event.
  * @param {"Ver"|"Inscríbete"|string} evento.textoBoton - The button text of the event.
 * @param {string} evento.territorio - The territory of the event.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
export const editarEvento = async (evento) => {
  const fd = new FormData();

  Object.entries(evento).forEach(([key, value]) => {
    if (value) {
      fd.append(key, value);
    }
  });

  const { data } = await axios.post(
    `${GENERAL_URL_API}/eventos/editarEvento`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};
