import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import AgregarNuevaSedeForm from "../../adicionar-elementos/agregar-sede-pl/agregar-nueva-sede-form";
import { GENERAL_URL_API } from "../../../../shared/urls";
import { TextField } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";

const EditarSedePL = () => {
  const [sedes, setSedes] = useState([]);
  const [sede, setSede] = useState("");

  useEffect(() => {
    fetchSedes();
  }, []);

  const fetchSedes = async () => {
    const fd = new FormData();
     
    fd.append("show", "all");
    const url = `${GENERAL_URL_API}/sedes/verSedes.php`;
    const response = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await response.json();
    setSedes(data.data);
  };

  const clearSede = async () => {
    await fetchSedes();
    setSede("");
  };

  return (
    <>
      <div>
        <div className="mb-4 d-flex align-items-center">
          <div className="admin-page-tilte">(</div>
          <CreateOutlinedIcon />
          <div className="admin-page-tilte mr-2">)</div>
          <div className="admin-page-tilte">Editar una sede</div>
        </div>
        <Container fluid>
          <Row>
            <Col xs={12} className="content-white-box px-4 py-4">
              <div className="d-flex align-items-center">
                <PersonIcon style={{ fontSize: "20px" }} />
                <span className="admin-main-title-dark-gray ml-2 mt-1">
                  BUSCAR Y EDITAR UNA SEDE EXISTENTE
                </span>
              </div>
              {!sedes.length ? (
                <>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </>
              ) : !sede ? (
                <>
                  <label className="admin-label-text mt-3 d-block">
                    Selecciona un sede para editar:
                  </label>
                  <Autocomplete
                    options={sedes}
                    getOptionLabel={(option) =>
                      option ? `${option.instituto}` : ""
                    }
                    value={sede}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSede({
                          ...newValue,
                          nombre: newValue?.instituto,
                          instituto: {
                            nombre: newValue?.superInstituto,
                            pais: newValue?.pais,
                          },
                        });
                      }
                    }}
                    noOptionsText="Sin resultados"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        style={{
                          width: "100%",
                          fontFamily: "'Noto Sans JP', sans-serif",
                        }}
                      />
                    )}
                  />
                </>
              ) : (
                <AgregarNuevaSedeForm sede={sede} clearSede={clearSede} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EditarSedePL;
