import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

/**
 * @module routers/agregarLibrosCursoMasivo
 * @desc Este módulo se encarga de agregar libros a un curso de forma masiva
 * @param {File} file - Archivo con los datos de los libros a agregar
 * @returns {{
 * data: Array<unknown>,
 * info: string,
 * status: 0 | 1
 * }} - Objeto con la respuesta de la petición
 *
 */
export const agregarLibrosCursoPlanLectorMasivo = async (file) => {
  const fd = new FormData();

  fd.append("books", file);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/paquetes/agregarLibrosCursoMasivo`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};
