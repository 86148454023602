import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";

import TvIcon from "@material-ui/icons/Tv";
import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import exportFromJSON from "export-from-json";

import { columnasUsuariosAcademia } from "../../../shared/data/columnas/formacion/listaUsuarios";
import { useQuery } from "@tanstack/react-query";
import { obtenerReporteUsuariosAcademia } from "../../../shared/fetchers/academia/reporteUsuarios";
import { columnasAccesosAcademia } from "../../../shared/data/columnas/formacion/accesosClientes";
import { columnasProgresosCursosAcademia } from "../../../shared/data/columnas/formacion/progresoCursos";

const ReporteUsuariosAcademia = () => {
  const {
    data: reporte,
    isLoading,
    isError,
  } = useQuery(["reporteUsuariosAcademia"], obtenerReporteUsuariosAcademia);

  const [columnsAccesos, setColumnsAccesos] = useState([]);
  const [columnsAvance, setColumnsAvance] = useState([]);

  useEffect(() => {
    let columnasAccesos = columnasAccesosAcademia;
    let columnasAvance = columnasProgresosCursosAcademia;

    columnasAccesos.map((columna) => (columna.isVisible = true));
    columnasAvance.map((columna) => (columna.isVisible = true));

    setColumnsAccesos(columnasAccesos);
    setColumnsAvance(columnasAvance);
  }, []);

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columnsAccesos];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumnsAccesos(temp);
  };

  if (isLoading)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isError) return <div>Ha ocurrido un error</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Reportes Usuarios Academia</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de accesos de usuarios Academia
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Lista de accesos.
            </div>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columnsAccesos}
              changeColumnStatus={changeColumnStatus}
            />
            <br />
            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                onClick={() => {
                  const dat = [...reporte.AccesosUsuarios];
                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>

              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columnsAccesos}
                  rows={reporte.AccesosUsuarios}
                  filename={"reporte-avance-usuarios-academia"}
                />
              </CustomGreenButton>

              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: reporte.AccesosUsuarios,
                    fileName: "reporte-avance-usuarios-academia",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
            </ButtonGroup>
            <MDBDataTableV5
              small
              responsive
              striped
              bordered
              searchTop
              searchBottom={false}
              barReverse
              pagingTop
              noBottomColumns
              order={["name", "asc"]}
              entriesOptions={[5, 10, 20, 50]}
              entriesLabel="Numero de filas"
              searchLabel="Buscar"
              paginationLabel={["Anterior", "Siguiente"]}
              infoLabel={["Mostrando filas", "a", "de", "filas"]}
              fullPagination
              data={{
                columns: columnsAccesos.filter((obj) => obj.isVisible),
                rows: reporte.AccesosUsuarios,
              }}
            />
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de avance de cursos Academia
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Lista de avances.
            </div>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columnsAvance}
              changeColumnStatus={changeColumnStatus}
            />
            <br />
            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                onClick={() => {
                  const dat = [...reporte.ProgresoCursos];
                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>

              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columnsAvance}
                  rows={reporte.ProgresoCursos}
                  filename={"reporte-progreso-usuarios-academia"}
                />
              </CustomGreenButton>

              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: reporte.ProgresoCursos,
                    fileName: "reporte-progreso-usuarios-academia",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
            </ButtonGroup>
            <MDBDataTableV5
              small
              responsive
              striped
              bordered
              searchTop
              searchBottom={false}
              barReverse
              pagingTop
              noBottomColumns
              order={["name", "asc"]}
              entriesOptions={[5, 10, 20, 50]}
              entriesLabel="Numero de filas"
              searchLabel="Buscar"
              paginationLabel={["Anterior", "Siguiente"]}
              infoLabel={["Mostrando filas", "a", "de", "filas"]}
              fullPagination
              data={{
                columns: columnsAvance.filter((obj) => obj.isVisible),
                rows: reporte.ProgresoCursos.map((row) => ({
                  ...row,
                  porcentajeTotal: parseFloat(row.porcentajeTotal).toFixed(3),
                })),
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReporteUsuariosAcademia;
