import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { DropzoneArea } from "material-ui-dropzone";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import { GENERAL_URL_API } from "../../../../shared/urls";
import swal from "sweetalert";

const EditarArteEditorialForm = (props) => {
  //Definimos los estados para almacenar los datos del formulario y sus errores
  //La informacion por defecto es la del blog seleccionado
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);

  const [formData, setFormData] = useState({
    nombre: {
      label: "Nombre de la editorial",
      value: props.selectedOption.nombre,
      error: false,
      required: false,
    },
    description: {
      label: "Description",
      placeholder: "Description",
      multiline: true,
      value: props.selectedOption.descripcion,
      error: false,
      required: false,
    },
    premios: {
      label: "Premios",
      value: props.selectedOption.premios,
      multiline: true,
      error: false,
      required: false,
    },
    linkEditorial: {
      label: "Link de la editorial",
      value: props.selectedOption.linkEditorial,
      error: false,
      required: false,
    },
  });

  const [colorData, setColorData] = useState({
    colorLetras: {
      value: props.selectedOption.colorLetra,
      error: false,
    },
    color1: {
      value: props.selectedOption.color1,
      error: false,
    },
    color2: {
      value: props.selectedOption.color2,
      error: false,
    },
  });

  const updateForm = (e) => {
    const { value, name } = e.target;
    setColorData({ ...colorData, [name]: { value: value, error: false } });
    console.log(colorData);
  };

  const clearForm = () => {
    setFormData(fData);
  };

  const handleChange = (
    e,
    name = e.target.name,
    value = e.target.value,
    condition = true
  ) => {
    if (condition === true || condition(value)) {
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value,
        },
      });
    }
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
      style: {
        opacity: "0.3",
        margin: "auto",
      },
    };
    if (type.startsWith("application/pdf"))
      return <PictureAsPdfOutlinedIcon {...iconProps} />;
  };

  // solo funciona para textfields, si hay autocomplete components no
  const textFields = Object.keys(formData).map((key, index) => {
    const formElement = formData[key];
    let onChangeCondition = true;
    if (formElement.condition) {
      onChangeCondition = formElement.condition;
    }
    return (
      <React.Fragment key={index}>
        <label className="admin-label-text mt-3 d-block">
          {formElement.label}
        </label>
        <TextField
          fullWidth
          multiline={formElement.multiline ? formElement.multiline : false}
          rows={formElement.multiline ? 4 : 1}
          maxrows={formElement.multiline ? 4 : 1}
          variant="outlined"
          size="small"
          type="text"
          value={formElement.value}
          name={key}
          placeholder={formElement.placeholder && formElement.placeholder}
          onChange={(e) => {
            const name = e.target.name;
            let value = e.target.value;
            if (formElement.uppercase) value = e.target.value.toUpperCase();
            handleChange(e, name, value, onChangeCondition);
          }}
          error={formElement.error ? formElement.error : false}
          helperText={formElement.error ? "Campo no válido" : null}
        />
      </React.Fragment>
    );
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { ...formData };
    let isValid = true;

    //si alguno de los campos requeridos está vacío
    for (const formElement in data) {
      if (data[formElement].required && !data[formElement].value) {
        data[formElement].error = true;
        isValid = false;
      } else {
        data[formElement].error = false;
      }
    }

    //Actualizamos el estado con todos los cambios
    setFormData(data);
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      clearForm();
      document.querySelector(".MuiDropzoneArea-root").classList.remove("error");
      //handle data here
      const data = {};
      data.file = file;
      Object.keys(formData).forEach((key) => {
        data[key] = formData[key].value;
      });
      const fd = new FormData();
       
       
      fd.append("editorial", props.selectedOption.nombre);
      fd.append("nuevoNombre", formData.nombre.value);
      fd.append("color1", colorData.color1.value);
      fd.append("color2", colorData.color2.value);
      fd.append("colorLetra", colorData.colorLetras.value);
      fd.append("premios", formData.premios.value);
      fd.append("descripcion", formData.description.value);
      fd.append("linkEditorial", formData.linkEditorial.value);

      if (file !== undefined) {
        fd.append("logoEditorial", file[0]);
      }

      try {
        const res = await fetch(`${GENERAL_URL_API}/arte/editoriales.php`, {
          method: "POST", credentials: 'include',
          credentials: "include",
          body: fd,
        });
        const data = await res.json();
        if (data.status === 1) {
          await swal({
            title: "Editorial editada.",
            text: "La editorial se edito satisfactoriamente!",
            icon: "success",
          });
        } else {
          await swal({
            title: "Sorry.",
            text: "No se pudo editar la editorial, comunicate con soporte.",
            icon: "error",
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className="admin-muted-text-small mt-3">
        Por favor modifique la información que desee cambiar (Si el usuario no
        existe, debe crearse primero):
      </div>
      <CustomGreenButton
        className="mt-3"
        size="small"
        onClick={props.clearSelection}
      >
        Regresar
      </CustomGreenButton>
      <form>
        {textFields}
        <label className="admin-label-text mt-3 d-block">
          Color segmento 1*
        </label>
        <TextField
          name="color1"
          value={colorData.color1.value}
          onChange={updateForm}
          error={colorData.color1.error}
          helperText={
            colorData.color1.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">
          Color segmento 2*
        </label>
        <TextField
          name="color2"
          value={colorData.color2.value}
          onChange={updateForm}
          error={colorData.color2.error}
          helperText={
            colorData.color2.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">Color letras*</label>
        <TextField
          name="colorLetras"
          value={colorData.colorLetras.value}
          onChange={updateForm}
          error={colorData.colorLetras.error}
          helperText={
            colorData.colorLetras.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <div className="mt-4 w-100">
          <label className="admin-label-text mt-3 d-block">
            Logo de editorial* <br />
            <span className="admin-muted-text">
              1 archivo requerido con extensión png ["logo_editorial.png"]
            </span>
          </label>
          <DropzoneArea
            id="dropzone"
            dropzoneText="Arrastra un archivo o haz click aquí!"
            showFileNames={true}
            maxFileSize={30000000} //n in bytes
            showAlerts={true}
            filesLimit={1}
            acceptedFiles={["image/png"]}
            getPreviewIcon={handlePreviewIcon}
            onChange={(file) => {
              setFile(file);
            }}
          />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <CustomGreenButton
            variant="contained"
            className="mr-2"
            style={{ background: "#5d9cec" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            Finalizar
          </CustomGreenButton>
          <CustomGreenButton variant="contained">Cancelar</CustomGreenButton>
        </div>
      </form>
    </>
  );
};

export default EditarArteEditorialForm;

const fData = {
  nombre: {
    label: "Nombre de la editorial",
    value: "",
    error: false,
    required: false,
  },
  description: {
    label: "Description",
    placeholder: "Description",
    multiline: true,
    value: "",
    error: false,
    required: false,
  },
  premios: {
    label: "Premios",
    value: "",
    multiline: true,
    error: false,
    required: false,
  },
  linkEditorial: {
    label: "Link de la editorial",
    value: "",
    error: false,
    required: false,
  },
};
