import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { fetchInstitutos } from "../../../../shared/fetchers/classics/institutos/verInstitutos";
import { fetchSedes } from "../../../../shared/fetchers/classics/institutos/verSedes";
import { TextField } from "@material-ui/core";
import CrearSedeClassicsIndividual from "../../adicionar-elementos/crear-sede/crear-sede-individual";

const EditarSedeClassics = () => {
  const [instituto, setInstituto] = useState(null);
  const [sede, setSede] = useState(null);
  const [editDone, setEditDone] = useState(false);

  const {
    data: institutos,
    isLoading,
    isError,
  } = useQuery(["institutosClassics"], fetchInstitutos);

  const { data: sedes } = useQuery(
    ["sedesClassics", instituto?.nombre],
    () => fetchSedes(instituto?.nombre ?? ""),
    {
      enabled: Boolean(instituto),
    }
  );

  useEffect(() => {
    if (editDone) {
      setInstituto(null);
      setSede(null);
      setEditDone(false);
    }
  }, [editDone]);

  if (isLoading)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isError)
    return <div>Ha ocurrido un error, prueba recargando la página</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Editar una sede</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <label className="admin-label-text mt-3 d-block">
              Selecciona un instituto para ver sus sedes:
            </label>
            <Autocomplete
              options={institutos}
              value={instituto}
              disabled={Boolean(sede)}
              getOptionLabel={(option) => option.nombre}
              onChange={(_, newValue) => {
                setInstituto(newValue);
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <label className="admin-label-text mt-3 d-block">
              Selecciona una sede para editarla:
            </label>
            <Autocomplete
              options={sedes}
              value={sede}
              disabled={!instituto || !sedes?.length || Boolean(sede)}
              getOptionLabel={(option) => option.nombreSede}
              onChange={(_, newValue) => {
                setSede(newValue);
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />
          </Col>

          {sede && (
            <CrearSedeClassicsIndividual
              sede={{ ...sede, instituto }}
              clearEdit={() => setEditDone(true)}
            />
          )}
        </Row>
      </Container>
    </>
  );
};

export default EditarSedeClassics;
