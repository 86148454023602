import React, { useState } from "react";

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import TvIcon from "@material-ui/icons/Tv";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import BrushOutlinedIcon from "@material-ui/icons/BrushOutlined";
import FormatPaintOutlinedIcon from "@material-ui/icons/FormatPaintOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";

import NavigationCollapse from "./navigation-collapse";
import { useClient } from "../../../shared/contex/client/client-context";
import { Lock } from "@material-ui/icons";
import RoutesFormularios from "../../../routes/classics/formularios";
import RoutesUsuariosAcademia from "../../../routes/formacion/usuarios";

//En este componente eso donde estan las categorias y sus elementos correpondienes de la navegacion
//Cada categoria es un collapes el cual contiene botones donde cada boton corresponde a una pagina

const NavigationElements = () => {
  const { getClient } = useClient();
  const client = getClient();
  const access = client?.access;
  // console.log(access);
  //En este estado guardamos todas las categorias existentes
  const [currentCategorie, setCurrentCategorie] = useState({
    Dashboard: false,
    Consultas_Verdatos: false,
    Consultas_Verestadisticas: false,
    RegistraryEditar_AdicionarElementos: false,
    RegistraryEditar_EditarElementos: false,
    RegistraryEditar_BorrarElementos: false,
    HomeYOtros_CambiosVisuales: false,
    HomeYOtros_GeneradorContrasenas: false,
    HomeYOtros_Eventos: false,
    HomeYOtros_Recursos: false,
    HomeYOtros_Notibuzon: false,
    HomeYOtros_Notibanner: false,
    HomeYOtros_Formularios: false,
    Blog_ManejoBlog: false,
    PlanLector_VerDatos: false,
    PlanLector_VerEstadisticas: false,
    PlanLector_AdicionarElementos: false,
    PlanLector_EditarElementos: false,
    PlanLector_BorrarElementos: false,
    Formacion_VerDatos: false,
    Formacion_AdicionarElementos: false,
    Formacion_EditarElementos: false,
    Formacion_BorrarElementos: false,
  });

  function isDefined(arr1) {
    for (var i = 0; i < arr1.length; i++) {
      if (typeof arr1[i] !== "undefined") {
        return true;
      }
    }
    return false;
  }

  const ver = {
    consultas: {
      ver_datos: isDefined([
        access?.ver_libros,
        access?.ver_clientes,
        access?.ver_actividades,
        access?.ver_idp_saml,
        access?.ver_lectura_libros,
        access?.ver_acceso_clientes,
        access?.ver_solicitudes_demo,
        access?.ver_codigos_regalo,
        // TODO: agregar access de eventos cuando sea creado
        // TODO: agregar access de recursos cuando sea creado
        // TODO: agregar access de NOTIBUZON cuando sea creado
        // TODO: agregar access de NOTIBANNER cuando sea creado
      ]),
      ver_estadisticas: isDefined([
        access?.ver_acceso_institutos,
        access.ver_acceso_personal,
        access?.ver_estadisticas_globales,
      ]),
    },
    registrar: {
      adicionar_elementos: isDefined([
        access?.agregar_libro_paquete,
        access?.autorizar_paquete_cliente,
        access?.agregar_libro_coleccion,
        access?.habilitar_paquete_cliente,
        access?.autorizar_paquete_cliente,
        access?.crear_libro_contenido,
        access?.crear_actividad,
        access?.crear_codigo,
        access?.agregar_cliente_SAML,
        client?.access?.CRUD_usuario_dios,
        client?.access?.CRUD_usuario_editorial,
        client?.access?.CRUD_usuario_vendedor,
        access?.crear_cliente_individual,
        access?.crear_cliente_demo,
        access?.crear_cliente_instituto,
        access?.filas_home,
        // TODO: agregar access de eventos cuando sea creado
        // TODO: agregar access de recursos cuando sea creado
        // TODO: agregar access de NOTIBUZON cuando sea creado
        // TODO: agregar access de NOTIBANNER cuando sea creado
      ]),
      editar_elementos: isDefined([
        access?.editar_cliente_individual,
        access?.editar_cliente_instituto,
        client?.access?.editar_libro_contenido,
        client?.access?.editar_libro_metadatos,
        client?.access?.editar_cliente_demo,
        access?.editar_actividad,
        access?.editar_coleccion_arte,
        access?.editar_arte_editorial,
        access?.editar_instituto,
        access?.editar_cliente_SAML,
        // TODO: agregar access de eventos cuando sea creado
        // TODO: agregar access de recursos cuando sea creado
        // TODO: agregar access de NOTIBUZON cuando sea creado
        // TODO: agregar access de NOTIBANNER cuando sea creado
      ]),
      borrar_elementos: isDefined([
        access?.eliminar_cliente_individual,
        access?.eliminar_cliente_instituto,
        access?.eliminar_libro_contenido,
        access?.eliminar_actividad,
        access?.eliminar_coleccion,
        access?.eliminar_SAML,
        access?.eliminar_paquete_libros,
        // TODO: agregar access de eventos cuando sea creado
        // TODO: agregar access de recursos cuando sea creado
        // TODO: agregar access de NOTIBUZON cuando sea creado
        // TODO: agregar access de NOTIBANNER cuando sea creado
      ]),
    },
    home: {
      cambiar_banners: isDefined([access?.editar_banners]),
    },
    blog: {
      manejo_blog: isDefined([
        access?.crear_entrada_blog,
        access?.editar_entrada_blog,
        access?.eliminar_entrada_blog,
      ]),
    },
    plan_lector: {
      ver_datos: isDefined([
        client?.access?.ver_institutos_PL,
        client?.access?.ver_sedes,
        client?.access?.ver_cursos,
        client?.access?.ver_profesores,
        client?.access?.ver_estudiantes,
        client?.access?.reporte_lecturas,
        client?.access?.reporte_acceso_estudiantes,
      ]),
      ver_estadisticas: isDefined([
        client?.access?.reporte_acceso_estudiantes,
        client?.access?.reporte_lecturas,
      ]),
      adicionar_elementos: isDefined([
        client?.access?.agregar_instituto_PL,
        client?.access?.agregar_sede,
        client?.access?.agregar_curso,
        client?.access?.agregar_estudiante,
        client?.access?.agregar_profesor,
        client?.access?.habilitar_paquete_PL,
        client?.access?.autorizar_paquete_PL,
        client?.access?.escoger_libros_PL,
      ]),
      editar_elementos: isDefined([
        client?.access?.editar_instituto_PL,
        client?.access?.editar_estudiante,
        client?.access?.editar_sede,
        client?.access?.editar_curso,
        client?.access?.editar_profesor,
      ]),
      borrar_elementos: isDefined([
        client?.access?.eliminar_estudiante,
        client?.access?.eliminar_profesor,
        client?.access?.eliminar_curso,
        client?.access?.eliminar_instituto_PL,
        client?.access?.eliminar_sede,
      ]),
    },
    formacion: {
      ver_datos: isDefined([access?.ver_datos_academia]),
      adicionar_elementos: isDefined([
        access?.asisgnar_curso_academia,
        access?.crear_usuario_academia,
      ]),
      borrar_elementos: isDefined([""]),
    },
    regalias: {
      ver_datos: isDefined([access?.ver_regalias]),
      adicionar_elementos: isDefined([access?.crear_regalias]),
    },
  };
  ver.consultas.value =
    ver.consultas.ver_datos || ver.consultas.ver_estadisticas;
  ver.registrar.value =
    ver.registrar.adicionar_elementos ||
    ver.registrar.editar_elementos ||
    ver.registrar.borrar_elementos;
  ver.home.value = ver.home.cambiar_banners;
  ver.blog.value = ver.blog.manejo_blog;
  ver.plan_lector.value =
    ver.plan_lector.ver_datos ||
    ver.plan_lector.ver_estadisticas ||
    ver.plan_lector.adicionar_elementos ||
    ver.plan_lector.editar_elementos ||
    ver.plan_lector.borrar_elementos;
  ver.formacion.value =
    ver.formacion.ver_datos ||
    ver.formacion.adicionar_elementos ||
    ver.formacion.borrar_elementos;
  ver.regalias.value =
    ver.regalias.ver_datos || ver.regalias.adicionar_elementos;

  //Funcion para seleccionar una categoria
  const changeCategorie = (categorieID) => {
    const temp = { ...currentCategorie };
    if (temp[categorieID] === true) {
      temp[categorieID] = false;
      setCurrentCategorie(temp);
    } else {
      Object.keys(temp).map((key) => {
        temp[key] = false;
        return null;
      });
      temp[categorieID] = true;
      setCurrentCategorie(temp);
    }
  };

  const toCategorieItems = (routes) =>
    routes.map((route) => ({
      ...route,
      url: route.path,
      hide: access[route.access] ? false : true,
    }));

  //Retornamos cada categoria con sus correspontienes elementos (Items)
  /*
    Cada elemento (Item) debe tener su nombre y la url de la pagina a la que corresponde
    Esta url debe ser unica y la pagina tiene que estar en el componente admin-dashboard.jsx dentro del switch de react-router-dom
    de la carpeta main dentro de componentes
  */
  return (
    <>
      <NavigationCollapse
        categorieID={"Dashboard"}
        categorieLabel={"Dashboard"}
        currentCategorie={currentCategorie.Dashboard}
        categorieItems={[{ name: "Home", url: "/" }]}
        changeCategorie={changeCategorie}
      >
        <HomeOutlinedIcon style={{ marginRight: "15px" }} />
      </NavigationCollapse>

      {ver.consultas.value ? (
        <div className="admin-muted-text py-2 pl-2">Consultas</div>
      ) : (
        <div />
      )}
      {ver.consultas.ver_datos ? (
        <div>
          <NavigationCollapse
            categorieID={"Consultas_Verdatos"}
            categorieLabel={"Ver datos"}
            currentCategorie={currentCategorie.Consultas_Verdatos}
            categorieItems={[
              {
                name: "Lista de libros",
                url: "/consultas/lista-libros",
                hide: access?.ver_libros ? false : true,
              },
              // { name: "Lista de actividades", url: "/consultas/lista-actividades" },
              {
                name: "Lista de clientes",
                url: "/consultas/lista-clientes",
                hide: access?.ver_clientes ? false : true,
              },
              {
                name: "Lista de actividades",
                url: "/consultas/lista-actividades",
                hide: access?.ver_actividades ? false : true,
              },
              {
                name: "Lista de IdPs SAML",
                url: "/consultas/lista-idps-saml",
                hide: access?.ver_idp_saml ? false : true,
              },
              {
                name: "Reporte de lecturas de libros",
                url: "/consultas/reporte-lecturas",
                hide: access?.ver_lectura_libros ? false : true,
              },
              {
                name: "Reporte de acceso de clientes",
                url: "/consultas/reporte-acceso-clientes",
                hide: access?.ver_acceso_clientes ? false : true,
              },
              {
                name: "Reporte de solicitudes demo",
                url: "/consultas/reporte-solicitudes-demo",
                hide: access?.ver_solicitudes_demo ? false : true,
              },
              {
                name: "Códigos de regalo",
                url: "/consultas/lista-codigos-regalo",
                hide: access?.ver_codigos_regalo ? false : true,
              },
              {
                name: "Lista de institutos",
                url: "/consultas/lista-institutos",
                hide: access?.ver_codigos_regalo ? false : true,
              },
              {
                name: "Lista de sedes",
                url: "/consultas/lista-sedes",
                hide: access?.ver_codigos_regalo ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.consultas.ver_estadisticas ? (
        <div>
          <NavigationCollapse
            categorieID={"Consultas_Verestadisticas"}
            categorieLabel={"Ver estadísticas"}
            currentCategorie={currentCategorie.Consultas_Verestadisticas}
            categorieItems={[
              {
                name: "Estadísticas de acceso por instituto",
                url: "/consultas/estadisticas-acceso-instituto",
                hide: access?.ver_acceso_institutos ? false : true,
              },
              {
                name: "Estadísticas de acceso personales",
                url: "/consultas/estadisticas-acceso-personales",
                hide: access.ver_acceso_personal ? false : true,
              },
              {
                name: "Estadísticas globales de lectura y acceso",
                url: "/consultas/estadisticas-globales-lectura-acceso",
                hide: access?.ver_estadisticas_globales ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TimelineOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.registrar.value ? (
        <div className="admin-muted-text py-2 pl-2">Registrar y editar</div>
      ) : (
        <div />
      )}
      {ver.registrar.adicionar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"RegistraryEditar_AdicionarElementos"}
            categorieLabel={"Adicionar Elementos"}
            currentCategorie={
              currentCategorie.RegistraryEditar_AdicionarElementos
            }
            categorieItems={[
              {
                name: "Agregar o eliminar libros de un paquete",
                url: "/registrar-y-editar/agregar-eliminar-libros-paquete",
                hide: access?.agregar_libro_paquete ? false : true,
              },
              {
                name: "Paquetes Relacionados a un libro",
                url: "/registrar-y-editar/paquetes-libro",
                hide: access?.ver_libros ? false : true,
              },
              {
                name: "Agregar o eliminar un paquete a un cliente",
                url: "/registrar-y-editar/agregar-eliminar-paquete-cliente",
                hide: access?.autorizar_paquete_cliente ? false : true,
              },
              {
                name: "Agregar o eliminar libros de una coleccion",
                url: "/registrar-y-editar/agregar-eliminar-libros-coleccion",
                hide: access?.agregar_libro_coleccion ? false : true,
              },
              // {
              //   name: " Agregar o eliminar un paquete a un cliente",
              //   url: "/registrar-y-editar/agregar-eliminar-paquete-cliente",
              // },
              {
                name: " Habilitar paquete personalizado para cliente",
                url: "/registrar-y-editar/habilitar-paquete-cliente",
                hide: access?.habilitar_paquete_cliente ? false : true,
              },
              {
                name: " Autorizar paquete personalizado para cliente",
                url: "/registrar-y-editar/autorizar-paquete-cliente",
                hide: access?.autorizar_paquete_cliente ? false : true,
              },
              {
                name: " Agregar un nuevo libro",
                url: "/registrar-y-editar/agregar-nuevo-libro",
                hide: access?.crear_libro_contenido ? false : true,
              },
              {
                name: " Agregar una nueva actividad",
                url: "/registrar-y-editar/agregar-nueva-actividad",
                hide: access?.crear_actividad ? false : true,
              },
              {
                name: " Asignar código",
                url: "/registrar-y-editar/asignar-codigo",
                hide: access?.crear_codigo ? false : true,
              },
              {
                name: " Agregar un nuevo cliente SAML",
                url: "/registrar-y-editar/agregar-nuevo-cliente-saml",
                hide: access?.agregar_cliente_SAML ? false : true,
              },
              {
                name: "Crear Conexión OAuth",
                url: "/consultas/agregar-conexion-oauth",
                hide: access?.ver_idp_saml ? false : true,
              },
              {
                name: " Agregar un usuario administrativo",
                url: "/registrar-y-editar/agregar-cliente-administrativo",
                hide:
                  client?.access?.CRUD_usuario_dios ||
                    client?.access?.CRUD_usuario_editorial ||
                    client?.access?.CRUD_usuario_vendedor
                    ? false
                    : true, //asignar un permiso, lo muestra para todos
              },
              {
                name: " Agregar un nuevo cliente individual",
                url: "/registrar-y-editar/agregar-cliente-individual",
                hide:
                  access?.crear_cliente_individual || access?.crear_cliente_demo
                    ? false
                    : true,
              },
              {
                name: " Agregar un nuevo cliente institucional",
                url: "/registrar-y-editar/agregar-cliente-institucional",
                hide: access?.crear_cliente_instituto ? false : true,
              },
              {
                name: "Filas Home",
                url: "/registrar-y-editar/filas-home",
                hide: access?.crear_cliente_instituto ? false : true,
              },
              {
                name: "Crear Instituto",
                url: "/registrar-y-editar/crear-instituto",
                hide: access?.crear_cliente_instituto ? false : true,
              },
              {
                name: "Crear Sede",
                url: "/registrar-y-editar/crear-sede",
                hide: access?.crear_cliente_instituto ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <CreateOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.registrar.editar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"RegistraryEditar_EditarElementos"}
            categorieLabel={"Editar Elementos"}
            currentCategorie={currentCategorie.RegistraryEditar_EditarElementos}
            categorieItems={[
              {
                name: "Editar un cliente individual existente",
                url: "/registrar-y-editar/editar-cliente-individual-existente",
                hide:
                  access?.editar_cliente_individual ||
                    client?.access?.editar_cliente_demo
                    ? false
                    : true,
              },
              {
                name: "Editar un cliente institucional existente",
                url: "/registrar-y-editar/editar-cliente-institucional-existente",
                hide: access?.editar_cliente_instituto ? false : true,
              },
              {
                name: "Editar un libro",
                url: "/registrar-y-editar/editar-libro",
                hide:
                  client?.access?.editar_libro_contenido &&
                    client?.access?.editar_libro_metadatos
                    ? false
                    : true,
              },
              {
                name: "Editar una actividad",
                url: "/registrar-y-editar/editar-actividad",
                hide: access?.editar_actividad ? false : true,
              },
              {
                name: "Editar el arte de una coleccion",
                url: "/registrar-y-editar/editar-arte-coleccion",
                hide: access?.editar_coleccion_arte ? false : true,
              },
              {
                name: "Editar el arte de una editorial",
                url: "/registrar-y-editar/editar-arte-editorial",
                hide: access?.editar_arte_editorial ? false : true,
              },
              //{
              //name: "Editar el arte de un instituto",
              //url: "/registrar-y-editar/editar-arte-instituto",
              //hide: access?.editar_instituto ? false : true,
              //},
              {
                name: "Editar cliente SAML",
                url: "/registrar-y-editar/editar-cliente-saml",
                hide: access?.editar_cliente_SAML ? false : true,
              },
              {
                name: "Editar usuario administrativo",
                url: "/registrar-y-editar/editar-usuario-admin",
                hide:
                  access?.CRUD_usuario_dios ||
                    access?.CRUD_usuario_editorial ||
                    access?.CRUD_usuario_vendedor
                    ? false
                    : true,
              },
              {
                name: "Agregar / Editar Tags",
                url: "/registrar-y-editar/agregar-editar-tags",
                hide:
                  access?.CRUD_usuario_dios ||
                    access?.CRUD_usuario_editorial ||
                    access?.CRUD_usuario_vendedor
                    ? false
                    : true,
              },
              {
                name: "Editar Instituto",
                url: "/registrar-y-editar/editar-instituto",
              },
              {
                name: "Editar Sede",
                url: "/registrar-y-editar/editar-sede",
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <BrushOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.registrar.borrar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"RegistraryEditar_BorrarElementos"}
            categorieLabel={"Borrar Elementos"}
            currentCategorie={currentCategorie.RegistraryEditar_BorrarElementos}
            categorieItems={[
              {
                name: "Eliminar un cliente individual",
                url: "/registrar-y-editar/eliminar-cliente-individual",
                hide: access?.eliminar_cliente_individual ? false : true,
              },
              {
                name: "Eliminar un cliente institucional",
                url: "/registrar-y-editar/eliminar-cliente-institucional",
                hide: access?.eliminar_cliente_instituto ? false : true,
              },
              {
                name: "Eliminar un libro",
                url: "/registrar-y-editar/eliminar-libro",
                hide: access?.eliminar_libro_contenido ? false : true,
              },
              {
                name: "Eliminar una actividad",
                url: "/registrar-y-editar/eliminar-actividad",
                hide: access?.eliminar_actividad ? false : true,
              },
              {
                name: "Eliminar una colección",
                url: "/registrar-y-editar/eliminar-coleccion",
                hide: access?.eliminar_coleccion ? false : true,
              },
              {
                name: "Eliminar un cliente SAML",
                url: "/registrar-y-editar/eliminar-saml",
                hide: access?.eliminar_SAML ? false : true,
              },
              {
                name: "Eliminar un paquete de libros",
                url: "/registrar-y-editar/eliminar-paquete-libros",
                hide: access?.eliminar_paquete_libros ? false : true,
              },
              {
                name: "Eliminar usuario administrativo",
                url: "/registrar-y-editar/eliminar-usuario-admin",
                hide:
                  access?.CRUD_usuario_dios ||
                    access?.CRUD_usuario_editorial ||
                    access?.CRUD_usuario_vendedor
                    ? false
                    : true,
              },
              {
                name: "Eliminar un instituto",
                url: "/registrar-y-editar/eliminar-instituto",
                hide: access?.eliminar_cliente_instituto ? false : true,
              },
              {
                name: "Eliminar una sede",
                url: "/registrar-y-editar/eliminar-sede",
                hide: access?.eliminar_cliente_instituto ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <DeleteOutlineIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.home.value ? (
        <div className="admin-muted-text py-2 pl-2">Home y otros</div>
      ) : (
        <div />
      )}

      {ver.home.cambiar_banners ? (
        <div>
          <NavigationCollapse
            categorieID={"HomeYOtros_CambiosVisuales"}
            categorieLabel={"Cambios visuales"}
            currentCategorie={currentCategorie.HomeYOtros_CambiosVisuales}
            categorieItems={[
              {
                name: "Cambiar los banners",
                url: "/home-y-otros/cambiar-los-banners",
                hide: access?.editar_banners ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <FormatPaintOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>

          <NavigationCollapse
            categorieID={"HomeYOtros_Eventos"}
            categorieLabel={"Eventos"}
            currentCategorie={currentCategorie.HomeYOtros_Eventos}
            categorieItems={[
              {
                name: "Lista de Eventos",
                url: "/consultas/lista-eventos",
                hide: access?.ver_codigos_regalo ? false : true,
                // TODO: cambiar por el access de eventos cuando sea creado
              },
              {
                name: "Crear Evento",
                url: "/registrar-y-editar/crear-evento",
                hide: access?.crear_cliente_instituto ? false : true,
                // TODO: cambiar por el access de eventos cuando sea creado
              },
              {
                name: "Editar Evento",
                url: "/registrar-y-editar/editar-evento",
                // TODO: Poner el hide cuando se creen los permisos de acceso para eventos
              },
              {
                name: "Eliminar un evento",
                url: "/registrar-y-editar/eliminar-evento",
                hide: access?.eliminar_cliente_instituto ? false : true,
                // TODO: cambiar por el access de eventos cuando sea creado
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>

          <NavigationCollapse
            categorieID={"HomeYOtros_Recursos"}
            categorieLabel={"Recursos"}
            currentCategorie={currentCategorie.HomeYOtros_Recursos}
            categorieItems={[
              {
                name: "Lista de Recursos",
                url: "/consultas/lista-recursos",
                hide: access?.ver_codigos_regalo ? false : true,
                // TODO: cambiar por el access de eventos cuando sea creado
              },
              {
                name: "Crear Recurso",
                url: "/registrar-y-editar/crear-recurso",
                hide: access?.crear_cliente_instituto ? false : true,
                // TODO: cambiar por el access de eventos cuando sea creado
              },
              {
                name: "Editar Recurso",
                url: "/registrar-y-editar/editar-recurso",
                // TODO: Poner el hide cuando se creen los permisos de acceso para eventos
              },
              {
                name: "Eliminar un recurso",
                url: "/registrar-y-editar/eliminar-recurso",
                hide: access?.eliminar_cliente_instituto ? false : true,
                // TODO: cambiar por el access de eventos cuando sea creado
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>

          <NavigationCollapse
            categorieID={"HomeYOtros_Notibuzon"}
            categorieLabel={"Notibuzon"}
            currentCategorie={currentCategorie.HomeYOtros_Notibuzon}
            categorieItems={[
              {
                name: "Lista de Notibuzon",
                url: "/consultas/lista-notibuzon",
                hide: access?.ver_codigos_regalo ? false : true,
                // TODO: cambiar por el access de NOTIBUZON cuando sea creado
              },
              {
                name: "Crear Notibuzon",
                url: "/registrar-y-editar/crear-notibuzon",
                hide: access?.crear_cliente_instituto ? false : true,
                // TODO: cambiar por el access de NOTIBUZON cuando sea creado
              },
              {
                name: "Editar Notibuzón",
                url: "/registrar-y-editar/editar-notibuzon",
                // TODO: cambiar por el access de NOTIBUZON cuando sea creado
              },
              {
                name: "Eliminar un notibuzon",
                url: "/registrar-y-editar/eliminar-notibuzon",
                hide: access?.eliminar_cliente_instituto ? false : true,
                // TODO: cambiar por el access de NOTIBUZON cuando sea creado
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>

          <NavigationCollapse
            categorieID={"HomeYOtros_Notibanner"}
            categorieLabel={"Notibanner"}
            currentCategorie={currentCategorie.HomeYOtros_Notibanner}
            categorieItems={[
              {
                name: "Lista de Notibanner",
                url: "/consultas/lista-notibanner",
                hide: access?.ver_codigos_regalo ? false : true,
                // TODO: cambiar por el access de NOTIBANNER cuando sea creado
              },
              {
                name: "Crear Notibanner",
                url: "/registrar-y-editar/crear-notibanner",
                hide: access?.crear_cliente_instituto ? false : true,
                // TODO: cambiar por el access de NOTIBANNER cuando sea creado
              },
              {
                name: "Editar Notibanner",
                url: "/registrar-y-editar/editar-notibanner",
                // TODO: cambiar por el access de NOTIBANNER cuando sea creado
              },
              {
                name: "Eliminar un Notibanner",
                url: "/registrar-y-editar/eliminar-notibanner",
                hide: access?.eliminar_cliente_instituto ? false : true,
                // TODO: cambiar por el access de NOTIBUZON cuando sea creado
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>

          <NavigationCollapse
            categorieID={"HomeYOtros_Formularios"}
            categorieLabel={"Formularios"}
            currentCategorie={currentCategorie.HomeYOtros_Formularios}
            categorieItems={toCategorieItems(RoutesFormularios)}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.blog.value ? (
        <div className="admin-muted-text py-2 pl-2">Blog</div>
      ) : (
        <div />
      )}
      {ver.blog.manejo_blog ? (
        <div>
          <NavigationCollapse
            categorieID={"Blog_ManejoBlog"}
            categorieLabel={"Manejo del blog"}
            currentCategorie={currentCategorie.Blog_ManejoBlog}
            categorieItems={[
              {
                name: "Crear nueva entrada del blog",
                url: "/blog/crear-entrada-blog",
                hide: access?.crear_entrada_blog ? false : true,
              },
              {
                name: "Editar nueva entrada del blog",
                url: "/blog/editar-entrada-blog",
                hide: access?.editar_entrada_blog ? false : true,
              },
              {
                name: "ELiminar entrada del blog",
                url: "/blog/eliminar-entrada-blog",
                hide: access?.eliminar_entrada_blog ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <ForumOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.plan_lector.value ? (
        <div className="admin-muted-text py-2 pl-2">Plan Lector</div>
      ) : (
        <div />
      )}
      {ver.plan_lector.ver_datos ? (
        <div>
          <NavigationCollapse
            categorieID={"PlanLector_VerDatos"}
            categorieLabel={"Ver datos"}
            currentCategorie={currentCategorie.PlanLector_VerDatos}
            categorieItems={[
              {
                name: "Lista de Institutos plan lector",
                url: "/plan-lector/lista-institutos",
                hide: access?.ver_institutos_PL ? false : true,
              },
              {
                name: "Lista de Sedes plan lector",
                url: "/plan-lector/lista-sedes",
                hide: access?.ver_sedes ? false : true,
              },
              {
                name: "Lista de Cursos plan lector",
                url: "/plan-lector/lista-cursos",
                hide: access?.ver_cursos ? false : true,
              },
              {
                name: "Lista de profesores plan lector",
                url: "/plan-lector/lista-profesores",
                hide: access?.ver_profesores ? false : true,
              },
              {
                name: "Lista de estudiantes plan lector",
                url: "/plan-lector/lista-estudiantes",
                hide: access?.ver_estudiantes ? false : true,
              },
              {
                name: "Reporte Lecturas",
                url: "/plan-lector/reporte-lecturas-pl",
                hide: access?.reporte_lecturas ? false : true,
              },
              {
                name: "Reporte accesos estudiantes",
                url: "/plan-lector/reporte-acceso-estudiantes",
                hide: access?.reporte_acceso_estudiantes ? false : true,
              },
              // {
              //   name: "Reporte accesos profesores",
              //   url: "/plan-lector/reporte-acceso-profesores",
              // },
            ]}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.plan_lector.ver_estadisticas ? (
        <div>
          <NavigationCollapse
            categorieID={"PlanLector_VerEstadisticas"}
            categorieLabel={"Ver estadísticas"}
            currentCategorie={currentCategorie.PlanLector_VerEstadisticas}
            categorieItems={[
              {
                name: "Estadísticas globales de lectura y acceso",
                url: "/plan-lector/estadisticas-generales",
                hide: access?.reporte_lecturas ? false : true,
              },
              {
                name: "Estadísticas de acceso especificas",
                url: "/plan-lector/estadisticas-acceso",
                hide: access?.reporte_acceso_estudiantes ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TimelineOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.plan_lector.adicionar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"PlanLector_AdicionarElementos"}
            categorieLabel={"Adicionar Elementos"}
            currentCategorie={currentCategorie.PlanLector_AdicionarElementos}
            categorieItems={[
              {
                name: "Agregar un nuevo instituto plan lector",
                url: "/plan-lector/agregar-nuevo-instituto",
                hide: access?.agregar_instituto_PL ? false : true,
              },
              {
                name: "Agregar una nueva sede plan lector",
                url: "/plan-lector/agregar-nueva-sede",
                hide: access?.agregar_sede ? false : true,
              },
              {
                name: "Agregar un nuevo curso plan lector",
                url: "/plan-lector/agregar-nuevo-curso",
                hide: access?.agregar_curso ? false : true,
              },
              {
                name: "Agregar un nuevo estudiante plan lector",
                url: "/plan-lector/agregar-nuevo-estudiante",
                hide: access?.agregar_estudiante ? false : true,
              },
              {
                name: "Agregar un nuevo profesor",
                url: "/plan-lector/agregar-nuevo-profesor",
                hide: access?.agregar_profesor ? false : true,
              },
              {
                name: "Asignar cursos a profesores plan lector",
                url: "/plan-lector/asignar-cursos",
                hide: access?.agregar_curso ? false : true,
              },
              {
                name: "Agregar o eliminar libros de un paquete",
                url: "/registrar-y-editar/agregar-eliminar-libros-paquete",
                hide: access?.agregar_libro_paquete ? false : true,
              },
              // Se agrega por la otra seccion
              // {
              //   name: "Agregar o eliminar libros de un paquete",
              //   url: "/plan-lector/agregar-eliminar-libros-paquete-pl",
              // },
              // solo se agrega paquete a instituto-sede
              // {
              //   name: "Agregar o eliminar un paquete a un curso",
              //   url: "/plan-lector/agregar-eliminar-paquete-curso-pl",
              // },
              {
                name: "Habilitar paquete personalizado",
                url: "/plan-lector/habilitar-paquete-pl",
                hide: access?.habilitar_paquete_PL ? false : true,
              },
              {
                name: "Autorizar paquete personalizado para instituto",
                url: "/plan-lector/autorizar-paquete-pl",
                hide: access?.autorizar_paquete_PL ? false : true,
              },
              {
                name: "Agregar o eliminar un paquete a un instituto",
                url: "/plan-lector/agregar-eliminar-paquete-instituto-pl",
                hide: access?.agregar_instituto_PL ? false : true,
              },
              {
                name: "Agregar o eliminar un paquete a una sede",
                url: "/plan-lector/agregar-eliminar-paquete-sede-pl",
                hide: access?.agregar_sede ? false : true,
              },
              {
                name: "Agregar o eliminar libros a un curso",
                url: "/plan-lector/agregar-eliminar-libros-curso",
                hide: access?.agregar_curso ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <CreateOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.plan_lector.editar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"PlanLector_EditarElementos"}
            categorieLabel={"Editar Elementos"}
            currentCategorie={currentCategorie.PlanLector_EditarElementos}
            categorieItems={[
              {
                name: "Editar un instituto plan lector",
                url: "/plan-lector/editar-un-instituto",
                hide: access?.editar_instituto_PL ? false : true,
              },
              {
                name: "Editar una sede plan lector",
                url: "/plan-lector/editar-una-sede",
                hide: access?.editar_sede ? false : true,
              },
              {
                name: "Editar un curso plan lector",
                url: "/plan-lector/editar-un-curso",
                hide: access?.editar_curso ? false : true,
              },
              {
                name: "Editar un estudiante plan lector",
                url: "/plan-lector/editar-un-estudiante",
                hide: access?.editar_estudiante ? false : true,
              },
              {
                name: "Editar un profesor plan lector",
                url: "/plan-lector/editar-un-profesor",
                hide: access?.editar_profesor ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <BrushOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.plan_lector.borrar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"PlanLector_BorrarElementos"}
            categorieLabel={"Borrar Elementos"}
            currentCategorie={currentCategorie.PlanLector_BorrarElementos}
            categorieItems={[
              {
                name: "Eliminar un estudiante",
                url: "/plan-lector/eliminar-un-estudiante",
                hide: access?.eliminar_estudiante ? false : true,
              },
              {
                name: "Eliminar un profesor",
                url: "/plan-lector/eliminar-un-profesor",
                hide: access?.eliminar_profesor ? false : true,
              },
              {
                name: "Eliminar un curso",
                url: "/plan-lector/eliminar-un-curso",
                hide: access?.eliminar_curso ? false : true,
              },
              {
                name: "Eliminar un instituto",
                url: "/plan-lector/eliminar-un-instituto",
                hide: access?.eliminar_instituto_PL ? false : true,
              },
              {
                name: "Eliminar una sede",
                url: "/plan-lector/eliminar-una-sede",
                hide: access?.eliminar_sede ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <DeleteOutlineIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.formacion.value ? (
        <div className="admin-muted-text py-2 pl-2">Formacion</div>
      ) : (
        <div />
      )}
      {ver.formacion.ver_datos ? (
        <div>
          <NavigationCollapse
            categorieID={"Formacion_VerDatos"}
            categorieLabel={"Ver Datos"}
            currentCategorie={currentCategorie.Formacion_VerDatos}
            categorieItems={[
              {
                name: "Lista de usuarios Academia",
                url: "/formacion/lista-usuarios-academia",
              },
              {
                name: "Lista de Cursos Academia",
                url: "/formacion/lista-cursos-academia",
              },
              {
                name: "Reporte Usuarios Academia",
                url: "/formacion/reporte-usuarios-academia",
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.formacion.adicionar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"Formacion_AdicionarElementos"}
            categorieLabel={"Adicionar Elementos"}
            currentCategorie={currentCategorie.Formacion_AdicionarElementos}
            categorieItems={[
              {
                name: "Crear un usuario Academia",
                url: "/formacion/crear-usuario-academia",
              },
              {
                name: "Asignar un curso a un usuario",
                url: "/formacion/asignar-curso-usuario",
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <CreateOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.formacion.adicionar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"Formacion_EditarElementos"}
            categorieLabel={"Editar Elementos"}
            currentCategorie={currentCategorie.Formacion_EditarElementos}
            categorieItems={[...toCategorieItems(RoutesUsuariosAcademia)]}
            changeCategorie={changeCategorie}
          >
            <CreateOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.formacion.borrar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"Formacion_BorrarElementos"}
            categorieLabel={"Borrar Elementos"}
            currentCategorie={currentCategorie.Formacion_BorrarElementos}
            categorieItems={[
              {
                name: "Borrar un usuario Academia",
                url: "/formacion/borrar-usuario-academia",
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <DeleteOutlineIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.regalias.value ? (
        <div className="admin-muted-text py-2 pl-2">Nuevas Regalías</div>
      ) : (
        <div />
      )}
      {ver.regalias.ver_datos ? (
        <div>
          <NavigationCollapse
            categorieID={"NuevasRegalias_VerDatos"}
            categorieLabel={"Ver Datos"}
            currentCategorie={currentCategorie.NuevasRegalias_VerDatos}
            categorieItems={[
              {
                name: "Lista de cortes de regalía",
                url: "/nuevas-regalias/lista-cortes-regalia",
                hide: access?.ver_regalias ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <TvIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}

      {ver.regalias.adicionar_elementos ? (
        <div>
          <NavigationCollapse
            categorieID={"NuevasRegalias_AdicionarElementos"}
            categorieLabel={"Adicionar Elementos"}
            currentCategorie={
              currentCategorie.NuevasRegalias_AdicionarElementos
            }
            categorieItems={[
              {
                name: "Crear un nuevo corte regalías por cliente",
                url: "/nuevas-regalias/crear-nuevo-corte",
                hide: access?.crear_regalias ? false : true,
              },
            ]}
            changeCategorie={changeCategorie}
          >
            <CreateOutlinedIcon style={{ marginRight: "15px" }} />
          </NavigationCollapse>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

export default NavigationElements;
