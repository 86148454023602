/**
 * Validate password, password must contain at least one number, one letter, one special character and 6 characters long
 * @param {string} password
 * @returns {boolean}
 *
 */
export function validatePassword(password) {
  const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])(?=.{6,})/;
  return passwordRegex.test(password);
}
