export const columnasEventos = [
  {
    label: "Id",
    field: "idEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Nombre",
    field: "nombreEsp",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Nombre En Inglés",
    field: "nombreEng",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha y hora CO",
    field: "fechaCo",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha y hora BS",
    field: "fechaBs",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha y hora CDMX",
    field: "fechaCdmx",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha y hora CL",
    field: "fechaCl",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha y hora USA",
    field: "fechaUsa",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Invitados",
    field: "invitados",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Evento",
    field: "tipoEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Descripción",
    field: "descripcionEsp",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Descripción En Inglés",
    field: "descripcionEng",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Texto Botón",
    field: "estadoBoton",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Enlace del Evento",
    field: "link",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Territorio",
    field: "territorio",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Imagen URL",
    field: "imagenUrl",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
