import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * @returns {Promise<Array<Notibanner>>}
 */
export async function fetchNotibanner() {
  const { data } = await axios.get(`${GENERAL_URL_API}/notificaciones/notibanner/verNotibanners`, {
    withCredentials: true,
  });

  return data.data;
}
