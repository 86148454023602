import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import IOSSwitch from "../../../components/shared/ios-switch";
import CustomGreenButton from "../../../components/shared/custom-green-button";

import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PeopleIcon from "@material-ui/icons/People";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TableChartIcon from "@material-ui/icons/TableChart";

import exportFromJSON from "export-from-json";
import swal from "sweetalert";

import { Bar } from "react-chartjs-2";
import { GENERAL_URL_API } from "../../../shared/urls";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";

const getChartData = (
  labels = [],
  label = "",
  data = [],
  backgroundColor = []
) => ({
  labels,
  datasets: [
    {
      label,
      data,
      backgroundColor,
    },
  ],
});

const EstadisticasAccesoInstitutoPL = () => {
  //Definimos un estado para guardar la informacion del autocomplete
  const [formData, setFormData] = useState({
    instituto: { value: null, error: false },
    sede: { value: null, error: false },
    curso: { value: null, error: false },
  });
  const [institutos, setInstitutos] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [switchesState, setSwitchesState] = useState({
    sw1: true,
    sw2: false,
    sw3: false,
  });
  const [dates, setDates] = useState({ from: "", to: "" });
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    num: getChartData(),
    duration: getChartData(),
    avg: getChartData(),
  });
  const [rows, setRows] = useState([]);

  const columns = [
    {
      label: "Fecha",
      field: "date",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
    {
      label: "Número de sesiones",
      field: "numSessions",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
    {
      label: "Duración de las sesiones (en minutos)",
      field: "durationSessions",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
    {
      label: "Duración promedio de las sesiones (en minutos)",
      field: "avgSessions",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
  ];

  useEffect(() => {
    fetchInstitutes();
  }, []);

  const fetchInstitutes = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setInstitutos(data.data);
  };

  const fetchSedes = async (instituto) => {
    const fd = new FormData();
     
    fd.append("instituto", instituto);
    const res = await fetch(`${GENERAL_URL_API}/sedes/verSedes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setSedes(data.data);
  };

  const fetchCursos = async (sede) => {
    const fd = new FormData();
     
    fd.append("sede", sede);
    const res = await fetch(`${GENERAL_URL_API}/Cursos/verCursos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setCursos(data.data);
  };

  const updateCharts = (data) => {
    // las labels son las mismas para las 3 charts
    const labels = data.agregados_duracion_total?.map((el) => {
      if (getPeriodoName() === "day") return el.fechaFiltrar;
      return `${el.fechaFiltrar}-${el.yearT}`;
    });
    // Obtiene los arreglos correspondientes a c/chart
    const numData = data.agregados_fecha?.map((el) => el.total_sessions);
    const durationData = data.agregados_duracion_total.map(
      (el) => el.total_time
    );
    const avgData = data.agregados_duracion_promedio?.map(
      (el) => el.total_time
    );

    const tableRows = [];
    for (let i = 0; i < labels.length; i++) {
      // fill tableRows
      tableRows.push({
        date: labels[i],
        numSessions: parseFloat(numData[i]),
        durationSessions: parseFloat(durationData[i]),
        avgSessions: parseFloat(avgData[i]),
      });
    }

    setChartData({
      num: getChartData(labels, "# de sesiones", numData, ["#75c97b"]),
      duration: getChartData(labels, "Duración de las sesiones", durationData, [
        "#66b7fd",
      ]),
      avg: getChartData(labels, "Duración promedio de las sesiones", avgData, [
        "#fdc166",
      ]),
    });
    setRows(tableRows);
  };

  const setSwitch1 = () => {
    setSwitchesState({ sw1: !switchesState.sw1, sw2: false, sw3: false });
  };
  const setSwitch2 = () => {
    setSwitchesState({ sw1: false, sw2: !switchesState.sw2, sw3: false });
  };
  const setSwitch3 = () => {
    setSwitchesState({ sw1: false, sw2: false, sw3: !switchesState.sw3 });
  };

  const getPeriodoName = () => {
    if (switchesState.sw1) return "day";
    if (switchesState.sw2) return "week";
    return "month";
  };

  const fetchEstadisticas = async () => {
    setLoading(true);
    let search = "institutosPL";
    const fd = new FormData();
     
    fd.append("instituto", formData.instituto.value.nombre);

    if (formData.sede.value != null) {
      fd.append("sede", formData.sede.value.instituto);
      search = "sedesPL";
    }

    if (formData.curso.value != null) {
      fd.append("curso", formData.curso.value.idCursoPlanLector);
      search = "cursosPL";
    }

    fd.append("periodo", getPeriodoName());
    fd.append("fechaInicio", dates.from);
    fd.append("fechaFin", dates.to);
    let res = {};
    try {
      res = await fetch(
        `${GENERAL_URL_API}/verEstadisticas/` + search + `.php`,
        {
          method: "POST", credentials: 'include',
          credentials: "include",
          body: fd,
        }
      );
    } catch (error) {
      console.log(error);
    }
    const data = await res.json();
    if (data.status === 1) updateCharts(data.data);
    else
      await swal({
        title: "Error",
        text: data.info,
        icon: "error",
      });
    setLoading(false);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const value = v || e.target.value;
    const name = n || e.target.name;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };

  //Funcion para limpiar todo el formulario
  // const clearForm = () => {
  //   let temp = { ...formData };
  //   for (const formElement in temp) {
  //     if (formElement !== "colorHeader" && formElement !== "colorLetras") {
  //       temp[formElement] = { value: "", error: false };
  //     } else {
  //       temp[formElement] = { value: "#000000", error: false };
  //     }
  //   }
  //   setFormData(temp);
  // };

  const clearErrors = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], error: false };
    }
  };

  const validate = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      if (temp[formElement].value === "") {
        temp[formElement].error = true;
        isValid = false;
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(temp);
    if (isValid) clearErrors();
    return isValid;
  };

  const submitText = () => {
    setLoading(true);
    //Creamos una variable temporal para actualizar el estado
    if (validate()) {
      fetchEstadisticas();
    }
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TimelineOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Estadísticas de acceso</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <AccountBalanceIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Selecciona un instituto
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Instituto:
              </label>
              <Autocomplete
                fullWidth
                options={institutos}
                getOptionLabel={(option) => option.nombre}
                value={formData.instituto.value}
                onChange={(e, value) => {
                  updateForm(e, "instituto", value);
                  fetchSedes(value.nombre);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Selecciona un instituto"
                      size="small"
                      fullWidth
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                      error={formData.instituto.error}
                      helperText={
                        formData.instituto.error ? "Campo no válido" : null
                      }
                    />
                  </>
                )}
              />

              <label className="admin-label-text mt-3 d-block">Sede</label>
              <Autocomplete
                fullWidth
                options={sedes}
                getOptionLabel={(option) => option.instituto}
                value={formData.sede.value}
                onChange={(e, value) => {
                  updateForm(e, "sede", value);
                  fetchCursos(value.instituto);
                }}
                noOptionsText="Sin resultados"
                disabled={!formData.instituto.value}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      placeholder="Selecciona una sede"
                      variant="outlined"
                      size="small"
                      fullWidth
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                      error={formData.sede.error}
                      helperText={
                        formData.sede.error ? "Campo no válido" : null
                      }
                    />
                  </>
                )}
              />

              <label className="admin-label-text mt-3 d-block">Curso</label>
              <Autocomplete
                fullWidth
                options={cursos}
                getOptionLabel={(option) => option.curso}
                value={formData.curso.value}
                onChange={(e, value) => {
                  updateForm(e, "curso", value);
                }}
                noOptionsText="Sin resultados"
                disabled={!formData.sede.value}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      placeholder="Selecciona un curso"
                      variant="outlined"
                      size="small"
                      fullWidth
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                      error={formData.curso.error}
                      helperText={
                        formData.curso.error ? "Campo no válido" : null
                      }
                    />
                  </>
                )}
              />

              <div className="mt-3"></div>
              <label
                className="admin-label-text mt-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por día:
              </label>
              <IOSSwitch checked={switchesState.sw1} onClick={setSwitch1} />
              <label
                className="admin-label-text mt-2 ml-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por semana:
              </label>
              <IOSSwitch checked={switchesState.sw2} onClick={setSwitch2} />
              <label
                className="admin-label-text mt-2 ml-md-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por mes:
              </label>
              <IOSSwitch checked={switchesState.sw3} onClick={setSwitch3} />

              <label className="admin-label-text mt-3 d-block">
                Entre las fechas:
              </label>
              <div className="d-flex">
                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                  value={dates.from}
                  onChange={(e) => setDates({ ...dates, from: e.target.value })}
                />
                <CustomGreenButton
                  variant="contained"
                  style={{
                    height: "40px",
                    pointerEvents: "none",
                  }}
                  className="d-block d-md-inline"
                  disableTouchRipple
                  disableRipple
                  disableElevation
                  disableFocusRipple
                >
                  Hasta
                </CustomGreenButton>
                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                  value={dates.to}
                  onChange={(e) => setDates({ ...dates, to: e.target.value })}
                />
              </div>
              <br />
              <CustomGreenButton
                variant="contained"
                className="mt-4"
                onClick={submitText}
                disabled={
                  loading ||
                  (!switchesState.sw1 &&
                    !switchesState.sw2 &&
                    !switchesState.sw3)
                }
              >
                Consultar
              </CustomGreenButton>
            </form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <PeopleIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                NÚMERO DE SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.num} />
          </Col>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <AccessTimeIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                DURACIÓN DE LAS SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.duration} />
          </Col>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <AccessTimeIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                DURACIÓN PROMEDIO DE LAS SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.avg} />
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <TableChartIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                TABLA DE DATOS
              </span>
            </div>
            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <>
                <ButtonGroup size="small" variant="contained" className="my-3">
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "estadisticas-instituto",
                        exportType: exportFromJSON.types.csv,
                      });
                    }}
                  >
                    CSV
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "estadisticas-instituto",
                        exportType: exportFromJSON.types.json,
                      });
                    }}
                  >
                    JSON
                  </CustomGreenButton>

                  {/* <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "estadisticas-instituto",
                        exportType: exportFromJSON.types.xls,
                      });
                    }}
                  >
                    EXCEL
                  </CustomGreenButton> */}

                  <CustomGreenButton>
                    <ExcelWorkBook
                      columns={columns}
                      rows={rows}
                      filename={"estadisticas-instituto"}
                    />
                  </CustomGreenButton>
                </ButtonGroup>
                <MDBDataTableV5
                  className="mt-3"
                  small
                  responsive
                  striped
                  searchTop
                  searchBottom={false}
                  barReverse
                  pagingTop
                  bordered
                  data={{
                    columns: columns.filter((obj) => obj.isVisible),
                    rows: rows,
                  }}
                  noBottomColumns
                  order={["name", "asc"]}
                  entriesOptions={[5, 10, 20, 50]}
                  entriesLabel="Numero de filas"
                  searchLabel="Buscar"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={["Mostrando filas", "a", "de", "filas"]}
                  fullPagination
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EstadisticasAccesoInstitutoPL;
