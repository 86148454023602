import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import IOSSwitch from "../../../components/shared/ios-switch";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";

import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import PeopleIcon from "@material-ui/icons/People";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TableChartIcon from "@material-ui/icons/TableChart";
// import BookIcon from "@material-ui/icons/Book";
// import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
// import TvIcon from "@material-ui/icons/Tv";
// import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

import exportFromJSON from "export-from-json";
import swal from "sweetalert";

// import AnimatedNumber from "animated-number-react";

import { Bar } from "react-chartjs-2";
import { GENERAL_URL_API } from "../../../shared/urls";
import {
  columnasEstadisticasGlobalesAcceso,
  columnasEstadisticasGlobalesLectura,
} from "../../../shared/data/columnas";
import { MenuBook } from "@material-ui/icons";

const getChartData = (
  labels = [],
  label = "",
  data = [],
  backgroundColor = []
) => ({
  labels,
  datasets: [
    {
      label,
      data,
      backgroundColor,
    },
  ],
});

const EstadisticasGlobalesAccesoPL = () => {
  // Estado para guardar los valores de la sección superior
  // const [usage, setUsage] = useState({
  //   books: undefined,
  //   hours: undefined,
  //   sessions: undefined,
  //   booksPerSession: undefined,
  // });
  //Definimos un estado para guardar la informacion del autocomplete
  const [switchesState, setSwitchesState] = useState({
    sw1: true,
    sw2: false,
    sw3: false,
  });
  const [dates, setDates] = useState({ from: "", to: "" });

  const [chartData, setChartData] = useState({
    num: getChartData(),
    duration: getChartData(),
    avg: getChartData(),
  });
  const [rows, setRows] = useState([]);
  const [rowsLectura, setRowsLectura] = useState([]);
  const [loading, setLoading] = useState(false);
  // const formatValue = (value) => value.toFixed(2);

  const updateCharts = (dataAccesos, dataLecturas) => {
    const usageTemp = {
      books: undefined,
      hours: undefined,
      sessions: undefined,
      booksPerSession: undefined,
    };

    usageTemp.books = dataLecturas.length;
    // las labels son las mismas para las 3 charts
    const labels = dataAccesos.agregados_duracion_total?.map((el) => {
      if (getPeriodoName() === "day") return el.fechaFiltrar;
      return `${el.fechaFiltrar}-${el.yearT}`;
    });
    // Obtiene los arreglos correspondientes a c/chart
    const numData = dataAccesos.agregados_fecha?.map((el) => {
      if (usageTemp.sessions !== undefined) {
        usageTemp.sessions = usageTemp.sessions + parseFloat(el.total_sessions);
      } else {
        usageTemp.sessions = parseFloat(el.total_sessions);
      }

      return el.total_sessions;
    });
    const durationData = dataAccesos.agregados_duracion_total.map((el) => {
      if (usageTemp.hours !== undefined) {
        usageTemp.hours = usageTemp.hours + parseFloat(el.total_time);
      } else {
        usageTemp.hours = parseFloat(el.total_time);
      }
      return el.total_time;
    });

    usageTemp.hours = usageTemp.hours / 60;
    usageTemp.booksPerSession = usageTemp.books / usageTemp.sessions;
    const avgData = dataAccesos.agregados_duracion_promedio?.map(
      (el) => el.total_time
    );
    // setUsage(usageTemp);
    setChartData({
      num: getChartData(labels, "# de sesiones", numData, ["#75c97b"]),
      duration: getChartData(labels, "Duración de las sesiones", durationData, [
        "#66b7fd",
      ]),
      avg: getChartData(labels, "Duración promedio de las sesiones", avgData, [
        "#fdc166",
      ]),
    });
    /* ACCESOS */
    const tableRows = [];
    for (let i = 0; i < labels.length; i++) {
      // fill tableRows
      tableRows.push({
        date: labels[i],
        numSessions: parseFloat(numData[i]),
        durationSessions: parseFloat(durationData[i]),
        avgSessions: parseFloat(avgData[i]),
      });
    }
    setRows(tableRows);
    /* lecturas */
    setRowsLectura(dataLecturas);
  };

  const setSwitch1 = () => {
    setSwitchesState({ sw1: !switchesState.sw1, sw2: false, sw3: false });
  };
  const setSwitch2 = () => {
    setSwitchesState({ sw1: false, sw2: !switchesState.sw2, sw3: false });
  };
  const setSwitch3 = () => {
    setSwitchesState({ sw1: false, sw2: false, sw3: !switchesState.sw3 });
  };

  const getPeriodoName = () => {
    if (switchesState.sw1) return "day";
    if (switchesState.sw2) return "week";
    return "month";
  };
  /* acceso */
  const fetchEstadisticas = async () => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("periodo", getPeriodoName());
    fd.append("fechaInicio", dates.from);
    fd.append("fechaFin", dates.to);
    let res = {};
    try {
      res = await fetch(`${GENERAL_URL_API}/verEstadisticas/globalesPL.php`, {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      });
    } catch (error) {
      console.log(error);
    }
    const data = await res.json();
    setLoading(false);
    if (data.status === 1) return data.data;
    else
      await swal({
        title: "Error",
        text: data.info,
        icon: "error",
      });
  };

  const fetchEstadisticasLectura = async () => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("fechaInicio", dates.from);
    fd.append("fechaFin", dates.to);
    let res = {};
    try {
      res = await fetch(`${GENERAL_URL_API}/verEstadisticas/lecturasPL.php`, {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      });
    } catch (error) {
      console.log(error);
    }
    const data = await res.json();
    setLoading(false);
    if (data.status === 1) return data.data;
    else
      await swal({
        title: "Error",
        text: data.info,
        icon: "error",
      });
  };

  const submitText = async () => {
    const dataAccesos = await fetchEstadisticas();
    const dataLecturas = await fetchEstadisticasLectura();
    updateCharts(dataAccesos, dataLecturas);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TimelineOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Estadísticas globales de lectura y acceso plan lector
        </div>
      </div>

      {/* <Container fluid>
        <Row className="mb-lg-4">
          <Col
            xs={12}
            md={6}
            lg={3}
            className="p-0 d-flex justify-content-between pr-md-2 mb-3 mb-lg-0"
          >
            <div className="icon-with-text-left pl-4">
              {usage.books !== undefined ? (
                <AnimatedNumber
                  value={usage.books}
                  formatValue={formatValue}
                  className="icon-with-text-font"
                />
              ) : (
                <div className="d-flex">
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                </div>
              )}
              <div className="admin-muted-text">Libros leídos en total</div>
            </div>
            <div className="icon-with-text-right">
              <BookIcon style={{ fontSize: "55px", color: "#5d9cec" }} />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="p-0 d-flex justify-content-between pl-md-2 pr-lg-2 mb-3 mb-lg-0"
          >
            <div className="icon-with-text-left pl-4">
              {usage.hours !== undefined ? (
                <AnimatedNumber
                  value={usage.hours}
                  formatValue={formatValue}
                  className="icon-with-text-font"
                />
              ) : (
                <div className="d-flex">
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                </div>
              )}
              <div className="admin-muted-text">Horas de lectura</div>
            </div>
            <div className="icon-with-text-right">
              <QueryBuilderIcon
                style={{ fontSize: "55px", color: "#fb876d" }}
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="p-0 d-flex justify-content-between pl-lg-2 pr-md-2 mb-3 mb-lg-0"
          >
            <div className="icon-with-text-left pl-4">
              {usage.sessions !== undefined ? (
                <AnimatedNumber
                  value={usage.sessions}
                  formatValue={formatValue}
                  className="icon-with-text-font"
                />
              ) : (
                <div className="d-flex">
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                  <Skeleton
                    className="mr-2"
                    animation="circle"
                    width={20}
                    height={30}
                  />
                </div>
              )}
              <div className="admin-muted-text">Sesiones totales</div>
            </div>
            <div className="icon-with-text-right">
              <TvIcon style={{ fontSize: "55px", color: "#71cd6b" }} />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="p-0 d-flex justify-content-between pl-md-2 mb-3 mb-lg-0"
          >
            <div className="icon-with-text-left pl-4">
              <div className="icon-with-text-font" style={{ fontSize: "20px" }}>
                {usage.booksPerSession !== undefined ? (
                  <AnimatedNumber
                    value={usage.booksPerSession}
                    formatValue={formatValue}
                    className="icon-with-text-font"
                  />
                ) : (
                  <div className="d-flex">
                    <Skeleton
                      className="mr-2"
                      animation="circle"
                      width={20}
                      height={30}
                    />
                    <Skeleton
                      className="mr-2"
                      animation="circle"
                      width={20}
                      height={30}
                    />
                    <Skeleton
                      className="mr-2"
                      animation="circle"
                      width={20}
                      height={30}
                    />
                  </div>
                )}
              </div>
              <div className="admin-muted-text">Libros leídos por sesión</div>
            </div>
            <div className="icon-with-text-right">
              <AssignmentIndIcon
                style={{ fontSize: "55px", color: "#b372bf" }}
              />
            </div>
          </Col>
        </Row>
      </Container> */}

      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBook />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                SELECCIONA LAS FECHAS DE CONSULTA
              </div>
            </div>

            <form>
              <div className="mt-3"></div>
              <label
                className="admin-label-text mt-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por día:
              </label>
              <IOSSwitch checked={switchesState.sw1} onClick={setSwitch1} />
              <label
                className="admin-label-text mt-2 ml-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por semana:
              </label>
              <IOSSwitch checked={switchesState.sw2} onClick={setSwitch2} />
              <label
                className="admin-label-text mt-2 ml-md-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por mes:
              </label>
              <IOSSwitch checked={switchesState.sw3} onClick={setSwitch3} />

              <label className="admin-label-text mt-3 d-block">
                Entre las fechas:
              </label>
              <div className="d-flex">
                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                  value={dates.from}
                  onChange={(e) => setDates({ ...dates, from: e.target.value })}
                />
                <CustomGreenButton
                  variant="contained"
                  style={{
                    height: "40px",
                    pointerEvents: "none",
                  }}
                  className="d-block d-md-inline"
                  disableTouchRipple
                  disableRipple
                  disableElevation
                  disableFocusRipple
                >
                  Hasta
                </CustomGreenButton>
                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                  value={dates.to}
                  onChange={(e) => setDates({ ...dates, to: e.target.value })}
                />
              </div>
              <br />
              <CustomGreenButton
                variant="contained"
                className="mt-4"
                onClick={submitText}
                disabled={
                  loading ||
                  (!switchesState.sw1 &&
                    !switchesState.sw2 &&
                    !switchesState.sw3) ||
                  !dates.from ||
                  !dates.to
                }
              >
                Consultar
              </CustomGreenButton>
            </form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <PeopleIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                NÚMERO DE SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.num} />
          </Col>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <AccessTimeIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                DURACIÓN DE LAS SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.duration} />
          </Col>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <AccessTimeIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                DURACIÓN PROMEDIO DE LAS SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.avg} />
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <TableChartIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                TABLA DE ACCESOS
              </span>
            </div>
            {rows.length <= 0 ||
            columnasEstadisticasGlobalesAcceso.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <>
                <ButtonGroup size="small" variant="contained" className="my-3">
                  <CustomGreenButton
                    onClick={() => {
                      const dat = [...rows];
                      navigator.clipboard
                        .writeText(JSON.stringify(dat))
                        .then(async () => {
                          await swal({
                            title: "Se ha copiado la tabla!",
                            icon: "success",
                          });
                        });
                    }}
                  >
                    Copy
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "estadisticas-globales-tabla-accesos",
                        exportType: exportFromJSON.types.csv,
                      });
                    }}
                  >
                    CSV
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "estadisticas-globales-tabla-accesos",
                        exportType: exportFromJSON.types.json,
                      });
                    }}
                  >
                    JSON
                  </CustomGreenButton>
                  {/* <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "estadisticas-globales-tabla-accesos",
                        exportType: exportFromJSON.types.xls,
                      });
                    }}
                  >
                    EXCEL
                  </CustomGreenButton> */}

                  <CustomGreenButton>
                    <ExcelWorkBook
                      columns={columnasEstadisticasGlobalesAcceso.filter(
                        (obj) => obj.isVisible
                      )}
                      rows={rows}
                      filename={"estadisticas-globales-tabla-accesos"}
                    />
                  </CustomGreenButton>
                </ButtonGroup>
                <MDBDataTableV5
                  className="mt-3"
                  small
                  searchTop
                  searchBottom={false}
                  barReverse
                  pagingTop
                  responsive
                  striped
                  bordered
                  data={{
                    columns: columnasEstadisticasGlobalesAcceso.filter(
                      (obj) => obj.isVisible
                    ),
                    rows: rows,
                  }}
                  noBottomColumns
                  order={["name", "asc"]}
                  entriesOptions={[5, 10, 20, 50]}
                  entriesLabel="Numero de filas"
                  searchLabel="Buscar"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={["Mostrando filas", "a", "de", "filas"]}
                  fullPagination
                />
              </>
            )}
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <TableChartIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                TABLA DE LECTURAS
              </span>
            </div>
            {rowsLectura.length <= 0 ||
            columnasEstadisticasGlobalesLectura.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <>
                <ButtonGroup size="small" variant="contained" className="my-3">
                  <CustomGreenButton
                    onClick={() => {
                      const dat = [...rowsLectura];
                      navigator.clipboard
                        .writeText(JSON.stringify(dat))
                        .then(async () => {
                          await swal({
                            title: "Se ha copiado la tabla!",
                            icon: "success",
                          });
                        });
                    }}
                  >
                    Copy
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rowsLectura,
                        fileName: "estadisticas-globales-tabla-lectura",
                        exportType: exportFromJSON.types.csv,
                      });
                    }}
                  >
                    CSV
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rowsLectura,
                        fileName: "estadisticas-globales-tabla-lectura",
                        exportType: exportFromJSON.types.json,
                      });
                    }}
                  >
                    JSON
                  </CustomGreenButton>
                  {/* <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rowsLectura,
                        fileName: "estadisticas-globales-tabla-lectura",
                        exportType: exportFromJSON.types.xls,
                      });
                    }}
                  >
                    EXCEL
                  </CustomGreenButton> */}

                  <CustomGreenButton>
                    <ExcelWorkBook
                      columns={columnasEstadisticasGlobalesLectura.filter(
                        (obj) => obj.isVisible
                      )}
                      rows={rows}
                      filename={"estadisticas-globales-tabla-lectura"}
                    />
                  </CustomGreenButton>
                </ButtonGroup>
                <MDBDataTableV5
                  className="mt-3"
                  small
                  responsive
                  striped
                  searchTop
                  searchBottom={false}
                  barReverse
                  pagingTop
                  bordered
                  data={{
                    columns: columnasEstadisticasGlobalesLectura.filter(
                      (obj) => obj.isVisible
                    ),
                    rows: rowsLectura,
                  }}
                  noBottomColumns
                  order={["name", "asc"]}
                  entriesOptions={[5, 10, 20, 50]}
                  entriesLabel="Numero de filas"
                  searchLabel="Buscar"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={["Mostrando filas", "a", "de", "filas"]}
                  fullPagination
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EstadisticasGlobalesAccesoPL;
