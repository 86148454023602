import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import ColorIconButton from "../../../components/shared/custom-green-icon-button";
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import { DropzoneArea } from "material-ui-dropzone";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CachedIcon from "@material-ui/icons/Cached";
import exportFromJSON from "export-from-json";
import swal from "sweetalert";

import { GENERAL_URL_API } from "../../../shared/urls";
import { columnasLibrosPaquetes } from "../../../shared/data/columnas";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import { Tv } from "@material-ui/icons";

const AgregarEliminarLibrosPaquete = () => {
  const classes = useStyles();

  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [paquetes, setPaquetes] = useState([]);
  const [libros, setLibros] = useState([]);
  const [loading, setLoading] = useState(false);

  //Definimos un estados para guardar la informacion del  los autocomplete
  const [packageSelected, setPackageSelected] = useState(null);
  const [differentPackage, setdifferentPackage] = useState("");
  const [bookSelected, setBookSelected] = useState(null);

  // Estados para carga masiva
  const [packageSelectedCM, setPackageSelectedCM] = useState(null);
  const [differentPackageCM, setDifferentPackageCM] = useState("");
  const [booksCM, setBooksCM] = useState([]);

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    let temp = columnasLibrosPaquetes;
    //Añadimos un atributo de visible a todas las columnas
    temp.forEach((el, index) => {
      if (!el.isVisible) el.isVisible = false;
    });
    setColumns(temp);

    getPackages();
    getBooks();
  };

  const getPackages = async () => {
    const fd = new FormData();
     
    fd.append("estado", "autorizado");
    const res = await fetch(`${GENERAL_URL_API}/paquetes/verPaquetes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    setPaquetes(resJSON.data);
  };

  const getBooks = async () => {
    const fd2 = new FormData();
     
    //fd2.append("estado", "habilitado");
    const res2 = await fetch(`${GENERAL_URL_API}/verDatos/verLibros.php`, {
      method: "POST", credentials: 'include',
      body: fd2,
    });
    const resJSON2 = await res2.json();
    let temp2 = [];
    for (var j = 0; j < resJSON2.data.length; j++) {
      temp2.push({
        value: resJSON2.data[j].title,
        data: resJSON2.data[j].idLibro,
        author: resJSON2.data[j].author,
      });
    }
    setLibros(temp2);
  };

  //Funciones para actualizar el valor seleccionado por los autocomplete
  const packageSelectedUpdate = async (e, newValue) => {
    setPackageSelected(newValue);
    if (newValue) {
      updateTable(newValue);
    }
  };

  const packageSelectedCMUpdate = async (e, newValue) => {
    setPackageSelectedCM(newValue);
  };

  const updateTable = async (paquete) => {
    const fd = new FormData();
     
    fd.append("nombrePaquete", paquete);
    const res = await fetch(`${GENERAL_URL_API}/paquetes/librosEnPaquete.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    setRows(resJSON.data.map((row) => ({ ...row, paquete: paquete })));
  };

  const bookSelectedUpdate = (e, newValue) => {
    if (newValue) {
      setBookSelected(newValue);
    }
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  const clearForm = () => {
    setBookSelected(null);
  };

  const addBook = async () => {
    setLoading(true);
    const fd = new FormData();
     
    if (packageSelected) fd.append("nombrePaquete", packageSelected);
    if (differentPackage) fd.append("nombrePaquete", differentPackage);

    fd.append("idLibro", bookSelected.data);
    const res = await fetch(
      `${GENERAL_URL_API}/paquetes/agregarLibroPaquete.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    }
    setLoading(false);
    if (packageSelected) {
      updateTable(packageSelected);
    } else if (differentPackage) {
      updateTable(differentPackage);
      getPackages();
    }
    // clearForm();
  };

  const deleteBook = async () => {
    setLoading(true);
    const fd = new FormData();
     
    if (packageSelected) fd.append("nombrePaquete", packageSelected);
    if (differentPackage) fd.append("nombrePaquete", differentPackage);
    fd.append("idLibro", bookSelected.data);
    const res = await fetch(
      `${GENERAL_URL_API}/paquetes/eliminarLibroPaquete.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    }
    setLoading(false);
    if (packageSelected) {
      updateTable(packageSelected);
    } else if (differentPackage) {
      updateTable(differentPackage);
    }
    // clearForm();
  };

  const addBooks = async () => {
    setLoading(true);
    const fd = new FormData();
     
    if (packageSelectedCM) fd.append("nombrePaquete", packageSelectedCM);
    if (differentPackageCM) fd.append("nombrePaquete", differentPackageCM);
    if (booksCM[0]) fd.append("books", booksCM[0]);

    const res = await fetch(
      `${GENERAL_URL_API}/paquetes/agregarLibrosPaquete.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      await swal({
        title: "Éxito",
        text: resJSON.info,
        icon: "success",
      });
    }
    setLoading(false);
    if (packageSelectedCM) {
      updateTable(packageSelectedCM);
    } else if (differentPackageCM) {
      updateTable(differentPackageCM);
      getPackages();
    }
  };

  const downloadMarc = async () => {
    setLoading(true);

    try {
      // the api requires a string: "id1,id2,id3"
      let books = rows.map((row) => row.idLibro).join(",");
      let res = await fetch(
        `${GENERAL_URL_API}/marc/downloadBooksMARC.php?libros=${books}`,
        {
          method: "GET", credentials: 'include',
          headers: {
            "Content-Type": "application/zip",
          },
        }
      );

      let blob = await res.blob();
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `booksMARC.zip`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (e) {
      await swal({
        title: "Error",
        text: "No se pudo descargar el archivo",
        icon: "error",
      });
    }
    setLoading(false);
  };

  const handleDropFileChange = (books) => {
    setBooksCM(books);
    console.log(books);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o eliminar libros de un paquete
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon style={{ fontSize: "20px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar/eliminar un libro a un paquete
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Agregar o eliminar del paquete:
              </label>
              <Autocomplete
                options={paquetes}
                getOptionLabel={(option) => option}
                value={packageSelected}
                onChange={packageSelectedUpdate}
                disabled={differentPackage !== ""}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <TextField
                name="autor"
                value={differentPackage}
                disabled={packageSelected}
                onChange={(e) => setdifferentPackage(e.target.value)}
                variant="outlined"
                placeholder="Paquete que no está en la lista"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">El libro:</label>
              <Autocomplete
                options={libros}
                getOptionLabel={(option) =>
                  `${option.value} - ${option.author}`
                }
                value={bookSelected}
                onChange={bookSelectedUpdate}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <div className="mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !(
                      (packageSelected !== null || differentPackage !== "") &&
                      bookSelected !== null
                    ) || loading
                  }
                  startIcon={<CheckRoundedIcon />}
                  classes={{ root: classes.agregar }}
                  onClick={addBook}
                >
                  Agregar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2"
                  disabled={
                    !(
                      (packageSelected !== null || differentPackage !== "") &&
                      bookSelected !== null
                    ) || loading
                  }
                  startIcon={<CloseRoundedIcon style={{ fontSize: "25px" }} />}
                  classes={{ root: classes.eliminar }}
                  onClick={deleteBook}
                >
                  Eliminar
                </Button>
              </div>
            </form>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <MenuBookIcon style={{ fontSize: "20px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar/eliminar libros a un paquete (carga masiva)
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Agregar o eliminar del paquete:
              </label>
              <Autocomplete
                options={paquetes}
                getOptionLabel={(option) => option}
                value={packageSelectedCM}
                onChange={packageSelectedCMUpdate}
                disabled={differentPackageCM !== ""}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <TextField
                name="autor"
                value={differentPackageCM}
                disabled={packageSelectedCM}
                onChange={(e) => setDifferentPackageCM(e.target.value)}
                variant="outlined"
                placeholder="Paquete que no está en la lista"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">El libro:</label>
              <DropzoneArea
                id="dropzone"
                dropzoneText="Arrastra un archivo o haz click aquí!"
                showFileNames={true}
                // maxFileSize={} //n in bytes
                showAlerts={true}
                filesLimit={1}
                acceptedFiles={[
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "application/vnd.ms-excel",
                ]}
                onChange={(files) => handleDropFileChange(files)}
              />

              <div className="mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !(
                      (packageSelectedCM !== null ||
                        differentPackageCM !== "") &&
                      booksCM.length > 0
                    ) || loading
                  }
                  startIcon={<CheckRoundedIcon />}
                  classes={{ root: classes.agregar }}
                  onClick={addBooks}
                >
                  Agregar
                </Button>
              </div>
            </form>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            {!rows ? (
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Selecciona un paquete para poder ver sus libros
              </div>
            ) : (
              <>
                <div className="admin-main-title-dark-gray  mb-3">
                  <Tv /> Tabla de paquetes de libros
                </div>

                <div className="d-flex align-items-center my-3">
                  <ColorIconButton
                    size="medium"
                    onClick={() => {
                      if (packageSelected) updateTable(packageSelected);
                      if (differentPackage) updateTable(differentPackage);
                    }}
                  >
                    <CachedIcon style={{ fontSize: "20px" }} />
                  </ColorIconButton>
                  <div className="pl-3 admin-muted-text">
                    Refrescar la tabla.
                  </div>
                </div>
                {/* Este componente es el dropdown que permite ocultar columnas*/}
                <TableColumnDropdown
                  columns={columns}
                  changeColumnStatus={changeColumnStatus}
                />
                <br />

                <ButtonGroup size="small" variant="contained" className="my-3">
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: packageSelected + "-books",
                        exportType: exportFromJSON.types.csv,
                      });
                    }}
                  >
                    CSV
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: packageSelected + "-books",
                        exportType: exportFromJSON.types.json,
                      });
                    }}
                  >
                    JSON
                  </CustomGreenButton>

                  <CustomGreenButton>
                    <ExcelWorkBook
                      columns={columns}
                      rows={rows}
                      filename={packageSelected + "-books"}
                    />
                  </CustomGreenButton>

                  <CustomGreenButton onClick={downloadMarc}>
                    MARC
                  </CustomGreenButton>
                </ButtonGroup>
                <MDBDataTable
                  small
                  responsive
                  striped
                  searchTop
                  searchBottom={false}
                  barReverse
                  pagingTop
                  bordered
                  data={{
                    columns: columns.filter((obj) => obj.isVisible),
                    rows: rows,
                  }}
                  noBottomColumns
                  order={["name", "asc"]}
                  entriesOptions={[5, 10, 20, 50]}
                  entriesLabel="Numero de filas"
                  searchLabel="Buscar"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={["Mostrando filas", "a", "de", "filas"]}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarEliminarLibrosPaquete;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
