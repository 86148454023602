export const columnasNotibanner = [
  {
    label: "Id",
    field: "idNotibanner",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Notificación",
    field: "tipoNotificacion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Visibilidad",
    field: "tipoVisibilidad",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Metadatos",
    field: "metadatos",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha de Creación",
    field: "fechaCreacion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Color texto banner",
    field: "colorTexto",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Color texto botón",
    field: "colorTextoBoton",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Color fondo botón",
    field: "colorFondoBoton",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Color primario",
    field: "colorPrimario",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Color secundario",
    field: "colorSecundario",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha Inicio",
    field: "fechaInicio",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha Fin",
    field: "fechaFin",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Imagen",
    field: "imagen",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
