export const columnasNotibuzones = [
  {
    label: "Id",
    field: "idNotibuzon",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Notificación",
    field: "tipoNotificacion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Visibilidad",
    field: "tipoVisibilidad",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Metadatos",
    field: "metadatos",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha de Creación",
    field: "fechaCreacion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha Inicio",
    field: "fechaInicio",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha Fin",
    field: "fechaFin",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
