import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TableColumnDropdown from "../../../../components/shared/table-column-dropdown/table-column-dropdown";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import exportFromJSON from "export-from-json";
import swal from "sweetalert";

import { GENERAL_URL_API } from "../../../../shared/urls";
import { columnasLibrosCursos } from "../../../../shared/data/columnas";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import { CloseRounded } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import { Tv } from "@material-ui/icons";
import CargaMasiva from "../../../../components/shared/carga-masiva";
import { agregarLibrosCursoPlanLectorMasivo } from "../../../../shared/fetchers/plan-lector/agregarLibrosCursoMasivo";

const AgregarEliminarLibrosCursoPlanLector = () => {
  const classes = useStyles();

  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState(null);

  const [cursoMasivo, setCursoMasivo] = useState(null);

  const [libros, setLibros] = useState([]);
  const [libro, setLibro] = useState([]); //libros seleccionados

  const [loading, setLoading] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    getData();
    verLibrosCurso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (curso) getLibrosSede();
    else {
      setLibro([]);
      setLibros([]);
    }
    verLibrosCurso(curso?.idCursoPlanLector);
    setSelectAll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curso]);

  useEffect(() => {
    if (selectAll) updateLibro(libros);
    else updateLibro([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const getData = async () => {
    let temp = columnasLibrosCursos;
    //Añadimos un atributo de visible a todas las columnas
    temp.forEach((el) => {
      if (!el.isVisible) el.isVisible = false;
    });
    setColumns(temp);

    getCursos();
  };

  const getCursos = async () => {
    setCursos([]);
    const fd = new FormData();

    const res = await fetch(`${GENERAL_URL_API}/Cursos/verCursos`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const data = await res.json();
    setCursos(data.data);
  };

  const getLibrosSede = async () => {
    setLibros([]);
    const fd = new FormData();

    fd.append("sede", curso.instituto);
    const url = `${GENERAL_URL_API}/paquetes/verLibrosSede`;
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const data = await res.json();
    setLibros(data.data);
  };

  const verLibrosCurso = async (idCurso) => {
    const fd = new FormData();

    if (idCurso) fd.append("idCurso", idCurso);
    const res = await fetch(`${GENERAL_URL_API}/paquetes/verLibrosCurso.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      setRows(resJSON.data);
      setLibro(resJSON.data);
    }
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  const clearForm = () => {
    setCurso(null);
  };

  const submit = async (route) => {
    setLoading(true);
    const fd = new FormData();

    fd.append("idCurso", curso.idCursoPlanLector);
    fd.append("idLibros", libro.map((libro) => libro.idLibro).join(","));
    const url = `${GENERAL_URL_API}/paquetes/${route}`;
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      await swal({
        title: "Libros modificados exitosamente.",
        text: resJSON.info,
        icon: "success",
      });
    }
    setLoading(false);
    verLibrosCurso();
    clearForm();
  };

  const updateLibro = (list) => {
    list = [...new Map(list.map((item) => [item.idLibro, item])).values()];
    setLibro(list);
    // if (list.length) setSelectAll(true);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o eliminar libros a un curso lector
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon style={{ fontSize: "20px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar/eliminar libros a un paquete plan lector
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">El curso:</label>
              <Autocomplete
                options={cursos}
                getOptionLabel={(option) =>
                  option
                    ? `${option.idCursoPlanLector} : ${option.instituto} - ${option.curso}`
                    : ""
                }
                value={curso}
                onChange={(_, value) => {
                  setCurso(value);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />

              <div className="d-flex justify-content-between flex-wrap">
                <label className="admin-label-text mt-3 d-block">
                  El libro:
                </label>
                {/* select all button */}
                <Button
                  disabled={!curso || !libros.length}
                  variant="contained"
                  color="primary"
                  // disabled={!curso || !libro.length || loading}
                  startIcon={
                    selectAll ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                  }
                  classes={
                    !selectAll
                      ? { root: classes.agregar }
                      : { root: classes.eliminar }
                  }
                  style={{
                    height: "1.5em",
                    alignSelf: "self-end",
                    marginBottom: "0.3em",
                  }}
                  onClick={() => setSelectAll(!selectAll)}
                >
                  {selectAll ? "Eliminar todos" : "Agregar todos"}
                </Button>
              </div>
              <Autocomplete
                disabled={!curso}
                multiple
                options={libros}
                value={libro}
                getOptionLabel={(option) =>
                  option
                    ? `${option.idLibro} - ${option.author} - ${option.title}`
                    : ""
                }
                disableCloseOnSelect
                noOptionsText="Sin resultados"
                onChange={(_, newValue) => updateLibro(newValue)}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        selected ||
                        libro.filter((l) => l.idLibro === option.idLibro)
                          .length > 0
                      }
                      color="primary"
                    />
                    {option ? `${option.author} - ${option.title}` : ""}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />

              <div className="mt-3">
                <Tooltip title="Agregar libros">
                  {/* se pone para que se muestre el tooltip incluso con el buton _disabled_ */}
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!curso || !libro.length || loading}
                      startIcon={<CheckRoundedIcon />}
                      classes={{ root: classes.agregar }}
                      onClick={() => submit("agregarLibrosCurso")}
                    >
                      Agregar
                    </Button>
                  </span>
                </Tooltip>
                {/* <Tooltip title="Eliminar libros">
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      className="ml-2"
                      disabled={!curso || !libro.length || loading}
                      startIcon={
                        <CloseRoundedIcon style={{ fontSize: "25px" }} />
                      }
                      classes={{ root: classes.eliminar }}
                      onClick={() => submit("agregarLibrosCurso")}
                    >
                      Eliminar
                    </Button>
                  </span>
                </Tooltip> */}
                <Tooltip title="Limpiar formulario">
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={clearForm}
                      className="ml-2"
                      startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                      disabled={loading}
                      style={{ background: "#5d9cec" }}
                    >
                      Cancelar
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </form>
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <CargaMasiva
              load={agregarLibrosCursoPlanLectorMasivo}
              title="Agregar o eliminar libros a un curso - Masivo"
              template="/templates/plan-lector/asignar-libros-curso.xlsx"
            />
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="admin-main-title-dark-gray  mb-3">
              <Tv /> Tabla de libros por curso
            </div>

            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />

            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "libros-curso",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "libros-curso",
                    exportType: exportFromJSON.types.json,
                  });
                }}
              >
                JSON
              </CustomGreenButton>
              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columns}
                  rows={rows}
                  filename={"libros-curso"}
                />
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "libros-curso",
                    fields: {
                      idLibro: "090$a",
                      title: "245$a",
                      subject: "084$a",
                      subject2: "65300$a",
                      // 'subject2ENG': '65300$a',
                      description: "520$a",
                      source: "534$a",
                      relation: "544$n",
                      coverage: "?",
                      author: "100$a",
                      // 'Autor (entidad)': '110$a',
                      // 'Autor' : '245$c',
                      // 'Lugar de publicación/distribución del libro': '264#2$a',
                      publisher: "264#2$b",
                      // 'Autor secundario (entidad)': '710$a',
                      // 'autor secundario-traductor': '70010$a',
                      // 'función del autor secundario': '70010$e',
                      rights: "540$f",
                      isbn: "020$a",
                      date: "264#2$c",
                      bookType: "300$a",
                      language: "041$a",
                      nivelUsuarioLector: "52110#$a",
                      edad: "5211#$a",
                      curso: "5212#$a",
                      disponibilidadRegional: "522$a",
                      genero: "655#4$a",
                      portada: "85640$a",
                      recomendado1: "544$n",
                      webUrl: "85642$a",
                    },
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                MARC
              </CustomGreenButton>
            </ButtonGroup>
            <MDBDataTable
              small
              responsive
              striped
              searchtop="true"
              searchbottom="false"
              barreverse="true"
              pagingtop="true"
              bordered
              data={{
                columns: columns.filter((obj) => obj.isVisible),
                rows: rows,
              }}
              noBottomColumns
              order={["name", "asc"]}
              entriesOptions={[5, 10, 20, 50]}
              entriesLabel="Numero de filas"
              searchLabel="Buscar"
              paginationLabel={["Anterior", "Siguiente"]}
              infoLabel={["Mostrando filas", "a", "de", "filas"]}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarEliminarLibrosCursoPlanLector;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
