import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

/**
 * Edita un usuario academia
 * @param {object} user - Objeto que contiene los datos del usuario a crear.
 * @param {string} user.username - Nombre de usuario del nuevo usuario.
 * @param {string} user.password - Contraseña del nuevo usuario.
 * @param {string} user.email - Correo electrónico del nuevo usuario.
 * @param {string} user.nombre - Nombre completo del nuevo usuario.
 * @param {string} user.celular - Número de celular del nuevo usuario.
 * @param {{inicio: string, fin: string}} user.fechas - Fechas de inicio y fin de la suscripción del nuevo usuario.
 * @returns {Promise<any>} - Una promesa que se resuelve en los datos del nuevo usuario.
 */
export async function editarUsuarioAcademia({ username, password, email, nombre, celular, fechas }) {
  const fd = new FormData();
  fd.append("usernameAcademia", username);
  fd.append("email", email);
  fd.append("nombreCompleto", nombre);
  fd.append("celular", celular);
  fd.append("fechaIniSub", fechas.inicio);
  fd.append("fechaFinSub", fechas.fin);

  if (password) fd.append("password", password);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/academia/editarUsuario`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};
