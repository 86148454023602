export function generatePasswords(amount = 1) {
  const passwords = [];

  for (let i = 0; i < amount; i++) {
    passwords.push(generatePassword());
  }

  return passwords;
}

/**
 * Genera una contraseña de 6 caracteres con letras (al menos una mayúscula), números y símbolos aleatorios *
 */
const generatePassword = () => {
  const symbols = "!@#$%^&*";
  const letters = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";

  const password = [];

  password.push(getRandomChar(letters.toUpperCase()));
  password.push(getRandomChar(letters));
  password.push(getRandomChar(numbers));
  password.push(getRandomChar(numbers));
  password.push(getRandomChar(numbers));
  password.push(getRandomChar(symbols));

  return password.join("");
};

const getRandomChar = (str) => str[Math.floor(Math.random() * str.length)];
