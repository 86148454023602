import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PersonIcon from "@material-ui/icons/Person";

const EliminarCliente = ({ type, urlApiGet, urlApiDel }) => {
  //Estados para gestionar todo lo relacionado con los datos
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //Definimos un estado para guardar la informacion del autocomplete
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);

  //Funcion para traer los datos de la api
  useEffect(() => {
    setLoading(true);
    fetchUsers();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlApiGet, type]);

  const fetchUsers = async () => {
    setData([]);
    const fd = new FormData();
     
    fd.append("show", "active");
    fd.append("tipoUsuario", type);
    const res = await fetch(urlApiGet, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();

    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      setData(resJSON.data);
    }
  };

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = async (e, newValue) => {
    if (newValue) {
      setAutoCompleteValue(newValue);
      //Si se selecciono un valor creamos un modal para confirmar si la persona desea borrar un elemento
      const res = await swal({
        title: "Está seguro?",
        text: "Esta acción no se puede revertir!",
        icon: "warning",
        buttons: ["Cancelar", "Borrar"],
      });
      //Si se quiere borrar un elemento se llama a la api y se actualiza localmente el estado
      if (res) {
        try {
          //Llamamos la api
          const fd = new FormData();
           
           
          fd.append("usuario_cliente", newValue.username);
          fd.append("tipoUsuario", type);
          const res = await fetch(urlApiDel, {
            method: "POST", credentials: 'include',
            credentials: "include",
            body: fd,
          });
          const resJSON = await res.json();
          if (resJSON.status === 0) {
            await swal({
              title: "Error",
              text: resJSON.info,
              icon: "error",
            });
          } else {
            swal({
              title: `Se ha eliminado correctamente el cliente: ${newValue.username}`,
              icon: "success",
            });
          }
          //Actualizamos localmente
          setAutoCompleteValue(null);
          await fetchUsers();
          return;
        } catch (e) {
          swal({
            title: e.message,
            icon: "error",
          });
        }
      }
      setAutoCompleteValue(null);
    }
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <DeleteOutlineIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Eliminar un cliente {type}</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "22px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Borrar un cliente {type} existente
              </div>
            </div>
            {loading ? (
              <div className="mt-2">
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </div>
            ) : (
              <>
                <label className="admin-label-text mt-3 d-block">Buscar:</label>
                <Autocomplete
                  options={data}
                  getOptionLabel={(option) => option.username}
                  value={autoCompleteValue}
                  onChange={autoCompleteUpdate}
                  noOptionsText="Sin resultados"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  )}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EliminarCliente;
