import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { GENERAL_URL_API } from "../../../shared/urls";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Close, CloseRounded, LockRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";
import IOSSwitch from "../../../components/shared/ios-switch";
import swal from "sweetalert";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import { DropzoneArea } from "material-ui-dropzone";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CustomGreenButton from "../../../components/shared/custom-green-button";

import { Link } from "react-router-dom";
import { generatePasswords } from "../../../shared/utils/generatePassword";

const AgregarNuevoProfesor = ({ profesor, clearProfesor }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  //Definimos los estaos para almacenar los datos del formulario y sus errores
  const [institutos, setInstitutos] = useState([]);
  const [profesoresCM, setProfesoresCM] = useState([]);
  const [formData, setFormData] = useState({
    usuario: { value: "", error: false },
    nombre: { value: "", error: false },
    email: { value: "", error: false },
    password: {
      value: "",
      error: false,
      show: false,
      errorMsg: "Este campo no puede estar vacío",
    },
    passwordCheck: {
      value: "",
      error: false,
      show: false,
    },
    instituto: { value: null, error: false },
    rector: { value: false, error: false },
  });

  useEffect(() => {
    fetchInstitutes();
  }, []);

  useEffect(() => {
    if (profesor)
      setFormData({
        ...formData,
        usuario: { value: profesor.username, error: false },
        nombre: { value: profesor.nombreCompleto, error: false },
        email: { value: profesor.email, error: false },
        instituto: { value: profesor.institutoPlanLector, error: false },
        rector: { value: profesor.rector, error: false },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profesor]);

  const fetchInstitutes = async () => {
    const fd = new FormData();

    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const data = await res.json();
    setInstitutos(data.data.map((instituto) => instituto.nombre));
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const value = v || e.target.value;
    const name = n || e.target.name;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };
  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      if (formElement !== "colorHeader" && formElement !== "colorLetras") {
        temp[formElement] = { value: "", error: false };
      } else {
        temp[formElement] = { value: "#000000", error: false };
      }
    }
    setFormData(temp);
  };

  const clearErrors = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], error: false };
    }
  };

  const validatePasswords = (temp, isValid) => {
    if (!temp.password.value.length)
      temp.password.errorMsg = "Este campo no puede estar vacío";
    // validate pass
    if (temp.password.value.length < 6 && temp.password.value.length) {
      temp.password.error = true;

      temp.password.errorMsg = "La contraseña debe tener al menos 6 caracteres";
      isValid = false;
    } else if (temp.password.value !== temp.passwordCheck.value) {
      // validate password match
      temp.password.errorMsg = "Las contraseñas deben ser iguales";
      temp.password.error = true;
      temp.passwordCheck.error = true;
      isValid = false;
    }
  };

  const validate = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      if (
        (temp[formElement].value === "" || temp[formElement].value === null) &&
        formElement !== "password" &&
        formElement !== "passwordCheck"
      ) {
        temp[formElement].error = true;
        isValid = false;
      }
    }

    if (!profesor) validatePasswords(temp, isValid);

    //Actualizamos el estado con todos los cambios
    setFormData(temp);
    if (isValid) clearErrors();
    return isValid;
  };

  //Funcion para subir la informacion y validad todos los datos
  const SubmitForm = async () => {
    setLoading(true);
    if (validate()) {
      await submitProfesor();
      if (profesor) await clearProfesor();
    }
    setLoading(false);
  };

  const submitProfesor = async () => {
    const fd = new FormData();

    fd.append("profesorUsername", formData.usuario.value);
    fd.append("nombre", formData.nombre.value);
    fd.append("correo", formData.email.value);
    if (formData.password.value) fd.append("pass", formData.password.value);
    fd.append("instituto", formData.instituto.value);
    fd.append("esRector", +formData.rector.value);
    if (profesor) fd.append("idProfesor", profesor.idProfesoresPlanLector);
    const url = `${GENERAL_URL_API}/profesores/${
      !profesor ? "agregar" : "editar"
    }Profesor.php`;
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const data = await res.json();
    if (!data.status)
      await swal({
        title: "Error creando entrada.",
        text: data.info,
        icon: "error",
      });
    else
      await swal({
        title: "La entrada se ha creado exitosamente.",
        text: data.info,
        icon: "success",
      });
  };

  const handleDropFileChange = (profesores) => {
    setProfesoresCM(profesores);
  };

  const addProfesores = async () => {
    setLoading(true);
    const fd = new FormData();

    if (profesoresCM[0]) fd.append("profesores", profesoresCM[0]);

    const res = await fetch(
      `${GENERAL_URL_API}/profesores/agregarProfesores.php`,
      {
        method: "POST",
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      await swal({
        title: "Éxito",
        text: resJSON.info,
        icon: "success",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          {profesor ? "Editar" : "Agregar"} un {!profesor && "nuevo"} profesor
          plan lector
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                {profesor
                  ? "EDITAR UN PROFESOR EXISTENTE"
                  : "CREAR UN PROFESOR NUEVO"}
              </div>
            </div>
            <div className="admin-muted-text-small mt-2">
              Por favor ingresar la siguiente info:
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">Usuario*</label>
              <TextField
                name="usuario"
                value={formData.usuario.value}
                onChange={updateForm}
                error={formData.usuario.error}
                helperText={
                  formData.usuario.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Usuario (min 4 caracteres y sin espacios)"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Nombre Completo*
              </label>
              <TextField
                name="nombre"
                value={formData.nombre.value}
                onChange={updateForm}
                error={formData.nombre.error}
                helperText={
                  formData.nombre.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Nombre Completo"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">Email*</label>
              <TextField
                name="email"
                value={formData.email.value}
                onChange={updateForm}
                error={formData.email.error}
                helperText={
                  formData.email.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Email"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">Password*</label>
              <TextField
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                type={formData.password.show ? "text" : "password"}
                value={formData.password.value}
                name="password"
                placeholder="Password"
                onChange={updateForm}
                error={formData.password.error}
                helperText={formData.password.error ? "Campo no válido" : null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        aria-label="clear password"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            password: {
                              ...formData.password,
                              value: "",
                            },
                            passwordCheck: {
                              ...formData.passwordCheck,
                              value: "",
                            },
                          });
                        }}
                        edge="end"
                      >
                        <Tooltip title="Eliminar contraseñas">
                          <Close />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        aria-label="generate password"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          const autoPassword = generatePasswords(1)[0];
                          setFormData({
                            ...formData,
                            password: {
                              ...formData.password,
                              value: autoPassword,
                            },
                            passwordCheck: {
                              ...formData.passwordCheck,
                              value: autoPassword,
                            },
                          });
                        }}
                        edge="end"
                      >
                        <Tooltip title="Generar contraseña">
                          <LockRounded />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            password: {
                              ...formData.password,
                              show: !formData.password.show,
                            },
                          });
                        }}
                        edge="end"
                      >
                        {formData.password.show ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Confirmar Password*
              </label>
              <TextField
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                type={formData.passwordCheck.show ? "text" : "password"}
                value={formData.passwordCheck.value}
                name="passwordCheck"
                placeholder="Password"
                onChange={updateForm}
                error={formData.passwordCheck.error}
                helperText={
                  formData.passwordCheck.error ? "Campo no válido" : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            passwordCheck: {
                              ...formData.passwordCheck,
                              show: !formData.passwordCheck.show,
                            },
                          });
                        }}
                        edge="end"
                      >
                        {formData.passwordCheck.show ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Instituto*
              </label>
              <Autocomplete
                fullWidth
                options={institutos}
                getOptionLabel={(option) => option}
                value={formData.instituto.value}
                onChange={(e, value) => {
                  updateForm(e, "instituto", value);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                      error={formData.instituto.error}
                      helperText={
                        formData.instituto.error ? "Campo no válido" : null
                      }
                    />
                  </>
                )}
              />

              <label
                className="admin-label-text mt-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Rector:
              </label>
              <IOSSwitch
                name="rector"
                checked={formData.rector.value}
                onClick={(e) => {
                  setFormData({
                    ...formData,
                    rector: {
                      ...formData.rector,
                      value: !formData.rector.value,
                    },
                  });
                }}
              />

              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitForm}
                  startIcon={<CheckRounded />}
                  disabled={loading}
                  style={{ background: "#81c868" }}
                >
                  {!profesor ? "Agregar" : "Editar"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!profesor ? clearForm : clearProfesor}
                  className="ml-2"
                  startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                  disabled={loading}
                  style={{ background: "#f05050" }}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </Col>
        </Row>

        {!profesor && (
          <Row>
            <Col xs={12} className="content-white-box px-4 py-4 mt-3">
              <div className="d-flex align-items-center">
                <MenuBookIcon style={{ fontSize: "20px" }} />
                <div className="admin-main-title-dark-gray ml-2 mt-1">
                  Agregar profesores (carga masiva)
                </div>
              </div>
              <div className="mt-3">
                <Link to="/templates/profesores.xlsx" target="_blank" download>
                  <CustomGreenButton size="small" variant="contained">
                    Descargar plantilla
                  </CustomGreenButton>
                </Link>
              </div>
              <div className="mt-3" />
              <form>
                <DropzoneArea
                  id="dropzone"
                  dropzoneText="Arrastra un archivo o haz click aquí!"
                  showFileNames={true}
                  // maxFileSize={} //n in bytes
                  showAlerts={true}
                  filesLimit={1}
                  acceptedFiles={[
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-excel",
                  ]}
                  onChange={(files) => handleDropFileChange(files)}
                />

                <div className="mt-3">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!(profesoresCM.length > 0) || loading}
                    startIcon={<CheckRoundedIcon />}
                    classes={{ root: classes.agregar }}
                    onClick={addProfesores}
                  >
                    Agregar
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default AgregarNuevoProfesor;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
