import { GENERAL_URL_API } from "../urls";

export const fetchLibros = async () => {
  const fd = new FormData();

  const res = await fetch(`${GENERAL_URL_API}/verDatos/verLibros.php`, {
    method: "POST",
    credentials: "include",
    body: fd,
  });

  if (!res.ok) throw new Error("Error al obtener los libros");

  return res.json();
};
