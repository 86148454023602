import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

export async function fetchTiposDeIngreso() {
  const { data } = await axios.get(
    `${GENERAL_URL_API}/app/listaTiposConexion`,
    {
      withCredentials: true,
    }
  );

  return data?.tiposIngreso ?? [];
}
