import React, { useState } from "react";
// Bootstrap
import { Container, Row, Col } from "react-bootstrap";
// Material UI
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { Button, Checkbox, TextField, makeStyles } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
// Icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// Alerts and toasts
import swal from "sweetalert";
import { toast } from "react-hot-toast";
// Tanstack Query
import { useQueryClient } from "@tanstack/react-query";
// Share
import { paisesAutocomplete } from "../../../../shared/data/paises";
import {
  deleteFilaHome,
  editFilaHome,
} from "../../../../shared/fetchers/filasHome";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditarFilasHome = ({ filasHome }) => {
  const [filaHome, setFilaHome] = useState({});
  const [descripcion, setDescripcion] = useState("");
  const [disponibilidadRegional, setDisponibilidadRegional] = useState([]);

  const queryClient = useQueryClient();

  const classes = useStyles();

  const clearForm = () => {
    setFilaHome({});
    setDescripcion("");
    setDisponibilidadRegional([]);
  };

  const editFila = async (e) => {
    e.preventDefault();
    console.log("Editando Fila...");

    try {
      const response = await editFilaHome({
        nombre: filaHome.filaHomeName,
        descripcion,
        disponibilidadRegional: disponibilidadRegional.join(","),
      });

      if (!response.status) throw new Error("Error editando la fila");

      queryClient.invalidateQueries({
        queryKey: ["filasHome"],
      });

      clearForm();

      toast.success("Fila editada");
    } catch (error) {
      toast.error("Error al editar fila");
    }
  };

  const deleteFila = async (e) => {
    e.preventDefault();
    console.log("Eliminar");

    const verify = await swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminada, no podrás recuperar esta fila",
      icon: "warning",
      buttons: ["Cancelar", "Eliminar"],
    });

    if (!verify) return;

    try {
      const response = await deleteFilaHome(filaHome?.filaHomeName);

      if (!response.status) throw new Error("Error al eliminar la fila");

      queryClient.invalidateQueries({
        queryKey: ["filasHome"],
      });

      clearForm();

      toast.success("Fila eliminada");
    } catch (error) {
      toast.error("Error al eliminar la fila");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="d-flex align-items-center">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Editar o Eliminar Fila Home
            </div>
          </div>
          <hr />
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Autocomplete
              options={filasHome}
              getOptionLabel={(option) => option?.filaHomeName ?? ""}
              value={filaHome}
              placeholder="Selecciona una fila"
              onChange={(_, value) => {
                setFilaHome(value);
                setDescripcion(value?.description ?? "");
                setDisponibilidadRegional(
                  value?.disponibilidadRegional
                    ? value.disponibilidadRegional.split(",")
                    : []
                );
              }}
              noOptionsText="Sin resultados"
              disabled={!filasHome.length}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <TextField
              name="descripcion"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              variant="outlined"
              placeholder="Descripción"
              disabled={!filaHome?.filaHomeName}
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />

            <Autocomplete
              multiple
              disableCloseOnSelect
              disabled={!filaHome?.filaHomeName}
              options={paisesAutocomplete}
              getOptionLabel={(option) => option}
              value={disponibilidadRegional}
              placeholder="Disponibilidad regional"
              noOptionsText="Sin resultados"
              onChange={(_, value) => setDisponibilidadRegional(value)}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    color="primary"
                  />
                  {option}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder={
                    disponibilidadRegional.length
                      ? ""
                      : "Disponibilidad regional"
                  }
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!filaHome?.filaHomeName}
                startIcon={<CheckRounded />}
                onClick={editFila}
                classes={{ root: classes.agregar }}
              >
                Editar
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!filaHome?.filaHomeName}
                startIcon={<CheckRounded />}
                onClick={deleteFila}
                classes={{ root: classes.eliminar }}
              >
                Eliminar
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditarFilasHome;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
