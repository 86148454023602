import React, { useState, useEffect } from "react";
// components
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Button, Tooltip } from "@material-ui/core";
import swal from "sweetalert";
// icons
import { CheckRounded, Close, LockRounded } from "@material-ui/icons";
// hooks
import { useClient } from "../../../../shared/contex/client/client-context";
// data
import { GENERAL_URL_API } from "../../../../shared/urls";
import { paisesAutocomplete } from "../../../../shared/data/paises";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { generatePasswords } from "../../../../shared/utils/generatePassword";

const EditarClienteAdministrativoForm = ({ user, clearUser }) => {
  const { getClient } = useClient();
  const client = getClient();
  //Definimos los estados para almacenar los datos del formulario y sus errores
  //La informacion por defecto es la del blog seleccionado
  const [formData, setFormData] = useState({
    username: { value: "", error: false },
    email: { value: "", error: false },
    passwd: { value: "", error: false, show: false },
    passwd2: { value: "", error: false, show: false },
    pais: { value: "", error: false },
  });

  const [loading, setLoading] = useState(false);
  const [rolSelected, setRolSelected] = useState(null);
  const [rol, setRolSel] = useState(null);
  const [editorialesSel, setEditoriales] = useState(null);
  const [editorialSelected, setEditorialSelected] = useState([]);
  const [editoriales, setListaEditoriales] = useState([]);
  const [estado, setEstado] = useState("activo");

  useEffect(() => {
    setFormData({
      username: { value: user.username, error: false },
      email: { value: user.email, error: false },
      passwd: { value: "", error: false, show: false },
      passwd2: { value: "", error: false, show: false },
      pais: { value: user.region, error: false },
    });
    setRolSelected(user.rol);
    setRolSel(user.rol);
    setEditoriales(user.editoriales.join(", "));
    setEditorialSelected(user.editoriales);
    console.log(user.editoriales.join(", "));
    setEstado(`${user.activo}` === "1" ? "activo" : "inactivo");
  }, [user]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const fd = new FormData();

    const res = await fetch(`${GENERAL_URL_API}/verDatos/verEditoriales.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const resJSON = await res.json();

    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      setListaEditoriales(resJSON.data.map((e) => e.nombre));
    }
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const name = n || e.target.name;
    const value = v || e.target.value;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };

  const rolSelectedUpdate = (_, newValue) => {
    if (newValue) {
      setRolSelected(newValue);
      setRolSel(newValue);
    }
  };

  const estadoUpdate = (_, value) => {
    setEstado(value);
  };

  const editorialSelectedUpdate = (_, newValue) => {
    setEditoriales(newValue.join(","));
  };

  //Funcion para subir la informacion y validad todos los datos
  const SubmitForm = async () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      if (
        formElement !== "passwd" &&
        formElement !== "passwd2" &&
        temp[formElement].value === ""
      ) {
        temp[formElement].error = true;
        isValid = false;
      }
    }

    if (temp.passwd.value !== temp.passwd2.value) {
      temp.passwd.error = true;
      temp.passwd2.error = true;
      isValid = false;
    }
    //Actualizamos el estado con todos los cambios
    setFormData(temp);

    if (rolSelected == null) {
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const fd = new FormData();

      fd.append("newUsername", formData.username.value);
      if (formData.passwd.value !== "")
        fd.append("newUserPass", formData.passwd.value);
      fd.append("newUserEmail", formData.email.value);
      fd.append("newUserRegion", formData.pais.value);
      fd.append("newUserRol", rol);
      fd.append("editoriales", rol === "Editorial" ? editorialesSel : "");
      fd.append("activo", estado === "activo" ? "1" : "0");

      const url = `${GENERAL_URL_API}/userAdmin/editUser.php`;
      const res = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: fd,
      });
      try {
        const resJSON = await res.json();
        if (resJSON.status === 1) {
          await swal({
            title: "Cliente administrativo Editado",
            text: resJSON.info,
            icon: "success",
          });
          clearUser();
        } else {
          await swal({
            title: "Error",
            text: resJSON.info,
            icon: "error",
          });
        }
        setLoading(false);
      } catch (error) {
        await swal({
          title: "Error",
          text: "Error al realizar la peticion",
          icon: "error",
        });
        console.error(error);
        setLoading(false);
      }
      setLoading(false);
    }
  };

  return (
    <form>
      <label className="admin-label-text mt-3 d-block">Usuario*</label>
      <TextField
        name="username"
        value={formData.username.value}
        onChange={updateForm}
        error={formData.username.error}
        helperText={
          formData.username.error ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        placeholder="Usuario (min 4 caracteres y sin espacios)"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />
      <label className="admin-label-text mt-3 d-block">Email*</label>
      <TextField
        name="email"
        value={formData.email.value}
        onChange={updateForm}
        error={formData.email.error}
        helperText={
          formData.email.error ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        placeholder="E-mail"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <label className="admin-label-text mt-3 d-block">Password</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type={formData.passwd.show ? "text" : "password"}
        value={formData.passwd.value}
        name="passwd"
        placeholder="Password (min 6 caracteres, letras, numeros y simbolos (!@#$%^&*)"
        onChange={updateForm}
        error={formData.passwd.error}
        helperText={formData.passwd.error ? "Campo no válido" : null}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                aria-label="clear password"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setFormData({
                    ...formData,
                    passwd: {
                      ...formData.passwd,
                      value: "",
                    },
                    passwd2: {
                      ...formData.passwd2,
                      value: "",
                    },
                  });
                }}
                edge="end"
              >
                <Tooltip title="Eliminar contraseñas">
                  <Close />
                </Tooltip>
              </IconButton>
              <IconButton
                aria-label="generate passwd"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  const autoPassword = generatePasswords(1)[0];
                  setFormData({
                    ...formData,
                    passwd: {
                      ...formData.passwd,
                      value: autoPassword,
                    },
                    passwd2: {
                      ...formData.passwd2,
                      value: autoPassword,
                    },
                  });
                }}
                edge="end"
              >
                <Tooltip title="Generar contraseña">
                  <LockRounded />
                </Tooltip>
              </IconButton>
              <IconButton
                aria-label="toggle password visibility"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setFormData({
                    ...formData,
                    passwd: {
                      ...formData.passwd,
                      show: !formData.passwd.show,
                    },
                  });
                }}
                edge="end"
              >
                {formData.passwd.show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <label className="admin-label-text mt-3 d-block">
        Confirmar Password
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type={formData.passwd2.show ? "text" : "password"}
        value={formData.passwd2.value}
        name="passwd2"
        placeholder="Password "
        onChange={updateForm}
        error={formData.passwd2.error}
        helperText={formData.passwd2.error ? "Campo no válido" : null}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                aria-label="toggle password visibility"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setFormData({
                    ...formData,
                    passwd2: {
                      ...formData.passwd2,
                      show: !formData.passwd2.show,
                    },
                  });
                }}
                edge="end"
              >
                {formData.passwd2.show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <label className="admin-label-text mt-3 d-block">Rol*</label>
      <Autocomplete
        options={
          client?.rol !== "Dios"
            ? ["Editorial", "Vendedor", "Dios", "Superdios"]
            : ["Vendedor"]
        }
        getOptionLabel={(option) => option}
        value={rolSelected}
        onChange={rolSelectedUpdate}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />
      {rol === "Editorial" && (
        <div>
          <label className="admin-label-text mt-3 d-block">Editoriales *</label>
          <Autocomplete
            multiple
            options={editoriales}
            getOptionLabel={(option) => option}
            defaultValue={editorialSelected}
            disableCloseOnSelect
            noOptionsText="Sin resultados"
            onChange={editorialSelectedUpdate}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color="primary"
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
            )}
          />
        </div>
      )}
      <label className="admin-label-text mt-3 d-block">Codigo de País*</label>
      <Autocomplete
        options={paisesAutocomplete}
        getOptionLabel={(option) => option}
        value={formData.pais.value}
        onChange={(e, value) => updateForm(e, "pais", value)}
        noOptionsText="Sin resultados"
        error={formData.pais.error}
        helperText={formData.pais.error ? "Campo no válido" : null}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Selecciona un país"
            variant="outlined"
            size="small"
            error={formData.pais.error}
            helperText={formData.pais.error ? "Campo no válido" : null}
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Estado*</label>
      <Autocomplete
        options={["activo", "inactivo"]}
        getOptionLabel={(option) => option}
        value={estado}
        onChange={estadoUpdate}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <div className="d-flex justify-content-end mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={SubmitForm}
          startIcon={<CheckRounded />}
          disabled={loading}
          style={{ background: "#81c868" }}
        >
          Editar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="ml-2"
          onClick={clearUser}
          startIcon={<CheckRounded />}
          disabled={loading}
          style={{ background: "#f05050" }}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default EditarClienteAdministrativoForm;
