import React from "react";

import "./agregar-nueva-actividad.css";

import { Container, Row, Col } from "react-bootstrap";
import { CardGiftcardOutlined } from "@material-ui/icons";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import AsignarCodigoForm from "./asignar-codigo-form";

const AsignarCodigo = () => {
  return (
    <div>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Asignar un nuevo código</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <CardGiftcardOutlined style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                ASIGNAR UN NUEVO CÓDIGO PARA VENDER O REGALAR
              </span>
            </div>
            <span className="admin-muted-text">
              Por favor ingresar la siguiente información:
            </span>
            <AsignarCodigoForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AsignarCodigo;
