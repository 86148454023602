import React, { useState, useEffect } from "react";
// components
import { Container, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
// data
import { GENERAL_URL_API } from "../../../../shared/urls";
// icons
import AgregarEliminarPaqueteForm from "./agregar-eliminar-libro-paquete-pl-form";
import AuthorizePackageCursoForm from "./authorize-package-pl-form";
import CursosPackagesTable from "./books-packages-table";
import { Book } from "@material-ui/icons";
import TvIcon from "@material-ui/icons/Tv";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

const AutorizarPaquetePL = () => {
  const [packets, setPackets] = useState([]);
  const [packet, setPacket] = useState({
    value: null,
    error: false,
    required: true,
  });

  useEffect(() => {
    fetchPackets();
  }, []);

  const fetchPackets = async () => {
    const fd = new FormData();
     
    fd.append("estado", "habilitado");
    const res = await fetch(`${GENERAL_URL_API}/paquetes/verPaquetesPL.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo clientes.",
        text: data.info,
        icon: "error",
      });
    setPackets(data.data);
  };

  return (
    <div>
      <div className="mb-4 d-flex align-items-center">
        <span className="admin-page-tilte">
          (
          <i>
            <CreateOutlinedIcon />
          </i>
          ) Autorizar paquete personalizado para instituto
        </span>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <Book style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                AGREGAR/ELIMINAR UN LIBRO A UN PAQUETE PERSONALIZADO (OPCIONAL)
              </span>
            </div>
            <AgregarEliminarPaqueteForm
              packets={packets}
              packet={packet}
              setPacket={setPacket}
            />
          </Col>
          <Col
            xs={12}
            style={{ marginTop: "1em" }}
            className="content-white-box px-4 py-4"
          >
            <div className="d-flex align-items-center">
              <Book style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                AUTORIZAR PAQUETE PERSONALIZADO PARA INSTITUTO
              </span>
            </div>
            <AuthorizePackageCursoForm packets={packets} />
          </Col>
          <Col
            xs={12}
            style={{ marginTop: "1em" }}
            className="content-white-box px-4 py-4"
          >
            <div className="d-flex align-items-center">
              <TvIcon style={{ fontSize: "20px" }} />
              {
                <span className="admin-main-title-dark-gray ml-2 mt-1">
                  {packet.value
                    ? "TABLA DE PAQUETES DE LIBROS"
                    : "SELECCIONA UN PAQUETE EN EL PRIMER FORMULARIO PARA VER SUS LIBROS"}
                </span>
              }
            </div>
            <CursosPackagesTable
              packets={packets}
              packet={packet}
              setPacket={setPacket}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AutorizarPaquetePL;
