import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * @returns {Promise<Array<Notibuzon>>}
 */
export async function fetchNotibuzones() {
  const { data } = await axios.get(`${GENERAL_URL_API}/notificaciones/notibuzon/verNotibuzones`, {
    withCredentials: true,
  });

  return data.data;
}
