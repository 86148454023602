import React, { useState } from "react";
// Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
import { Button } from "@material-ui/core";
// data
import { GENERAL_URL_API } from "../../../../shared/urls";
// icons
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";

const AuthorizePackageForm = ({ packets }) => {
  const [loading, setLoading] = useState(false);

  const [packet, setPacket] = useState({
    value: null,
    error: false,
    required: true,
  });

  const authorize = async () => {
    setLoading(true);
    const fd = new FormData();
     
    if (packet) fd.append("nombrePaquete", packet.value.nombrePaquete);

    const url = `${GENERAL_URL_API}/paquetes/autorizarPaquetePL.php`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    setLoading(false);
    if (resJSON.status === 0)
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    else
      await swal({
        title: "Éxito",
        text: resJSON.info,
        icon: "success",
      });
  };

  const changePacket = (value) => {
    setPacket({
      ...packet,
      value,
    });
  };

  const clearForm = () =>
    setPacket({
      ...packet,
      value: null,
      error: false,
    });

  const validateForm = () => {
    let isValid = true;

    if (!packet.value) {
      setPacket({
        ...packet,
        error: true,
      });
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      await authorize();
      clearForm();
    }
  };

  return (
    <>
      <form>
        <label className="admin-label-text mt-3 d-block">
          Agregar o eliminar del paquete:
        </label>
        <Autocomplete
          options={packets}
          getOptionLabel={(option) => option?.nombrePaquete}
          value={packet.value}
          onChange={(e, value) => changePacket(value)}
          noOptionsText="Sin resultados"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={packet.error}
              helperText={packet.error ? "Campo no válido" : null}
            />
          )}
        />

        <div className="mt-3">
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit(e, "paquetes/agregarLibroPaquete.php")}
            startIcon={<CheckRounded />}
            disabled={loading || !packet.value}
            style={{ background: "#81c868" }}
          >
            Autorizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={clearForm}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            disabled={loading}
            style={{ background: "#f05050" }}
          >
            cancelar
          </Button>
        </div>
      </form>
    </>
  );
};

export default AuthorizePackageForm;
