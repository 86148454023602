import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import IOSSwitch from "../../../../components/shared/ios-switch";
import { GENERAL_URL_API } from "../../../../shared/urls";
import swal from "sweetalert";
import { paisesAutocomplete } from "../../../../shared/data/paises";
import {
  CheckRounded,
  Close,
  LockRounded,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { CloseRounded } from "@material-ui/icons";
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { generatePasswords } from "../../../../shared/utils/generatePassword";

const EditarClienteForm = (props) => {
  //Definimos los estados para almacenar los datos del formulario y sus errores
  //La informacion por defecto es la del blog seleccionado
  const [loading, setLoading] = useState(false);
  const [institutes, setInstitutes] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [formData, setFormData] = useState({
    username: {
      value: props.selectedOption.username,
      error: false,
      required: true,
    },
    tipo: {
      value: props.selectedOption.tipo,
      error: false,
      required: true,
    },
    email: { value: props.selectedOption.email, error: false, required: true },
    passwd: {
      value: props.selectedOption.passwd,
      error: false,
      required: true,
    },
    passwConfirm: {
      value: props.selectedOption.passwd,
      error: false,
      required: true,
    },
    ipStart: {
      value: props.selectedOption.ipStart,
      error: false,
      required: false,
    },
    identificador: {
      value: props.selectedOption.identificador,
      error: false,
      required: true,
    },
    instituto: {
      value: { nombre: props.selectedOption.institute },
      error: false,
      required: true,
    },
    concurrents: {
      value: props.selectedOption.concurrents,
      error: false,
      required: true,
    },
    limiteLecturasDiarias: {
      value: props.selectedOption.limiteLecturasDiarias,
      error: false,
      required: true,
    },
    pais: { value: props.selectedOption.pais, error: false, required: true },
    interP: {
      value: props.selectedOption.interP,
      error: false,
      required: true,
    },
    interB: {
      value: props.selectedOption.interB,
      error: false,
      required: true,
    },
    epubP: { value: props.selectedOption.epubP, error: false, required: true },
    epubB: { value: props.selectedOption.epubB, error: false, required: true },
    fechaIniSub: {
      value: props.selectedOption.fechaIniSub,
      error: false,
      required: true,
    },
    fechaFinSub: {
      value: props.selectedOption.fechaFinSub,
      error: false,
      required: true,
    },
  });
  const [vendedor, setVendedor] = useState({
    value: { username: props.selectedOption.vendedor },
    error: false,
    required: true,
  });
  const [booleanData, setBooleanData] = useState({
    accessLow: {
      value: `${props.selectedOption.accessLow}` === "0" ? false : true,
      error: false,
    },
    accessMid: {
      value: `${props.selectedOption.accessMid}` === "0" ? false : true,
      error: false,
    },
    accessFull: {
      value: `${props.selectedOption.accessFull}` === "0" ? false : true,
      error: false,
    },
    betaTester: {
      value: `${props.selectedOption.betaTester}` === "0" ? false : true,
      error: false,
    },
    activo: {
      value: `${props.selectedOption.activo}` === "0" ? false : true,
      error: false,
      required: true,
    },
  });

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const name = n || e.target.name;
    const value = v || e.target.value;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };

  //Funcion para actualizar un switch del formulario
  const updateBoolean = (key) => {
    setBooleanData({
      ...booleanData,
      [key]: { value: !booleanData[key].value, error: false },
    });
  };

  useEffect(() => {
    fetchInstitutes();
    fetchVendedores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // code from stack overflow
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const fetchVendedores = async () => {
    const fd = new FormData();

    fetch(`${GENERAL_URL_API}/userAdmin/getUsers.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    })
      .then(async (res) => {
        if (!res.ok) throw new Error();
        const data = await res.json();
        setVendedores(data.data);
        setLoading(false);
      })
      .catch(() => {
        swal({
          title: "Sorry :(",
          text: "¡Hubo un problema obteniendo los clientes!, comunícate con soporte!",
          icon: "warning",
          buttons: ["Cancelar"],
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...formData };
    let isValid = true;

    // email validation
    if (!validateEmail(formData.email.value)) {
      formData.email.error = true;
      isValid = false;
    }

    // ip validations
    if (formData.ipStart.value && formData.ipStart.value.includes(" ")) {
      formData.ipStart.error = true;
      isValid = false;
    }

    if (props.type === "Individual") {
      formData.instituto.required = false;
    }

    // verifica que los que son obligatorios no estén vacíos
    for (const formElement in data) {
      if (
        data[formElement].required &&
        !data[formElement].value &&
        data[formElement].value !== 0
      ) {
        data[formElement].error = true;
        isValid = false;
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(data);
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      setLoading(true);
      const data = {};
      // console.log("form data: ");
      Object.keys(formData).forEach((key) => {
        data[key] = formData[key].value;
      });
      // api req
      const fd = new FormData();

      fd.append("usuario_cliente", formData.username.value);
      fd.append("passwd", formData.passwd.value);
      fd.append("acceso_cliente", +booleanData.accessMid.value);
      fd.append("acceso_subcliente", +booleanData.accessLow.value);
      fd.append("fechaInicio", formData.fechaIniSub.value);
      fd.append("fechaFin", formData.fechaFinSub.value);
      fd.append("identificador", formData.identificador.value);
      fd.append("email", formData.email.value);
      fd.append("concurrencias", formData.concurrents.value);
      fd.append(
        "instituto",
        props.type === "Individual"
          ? "ManuvoIndividual"
          : formData.instituto.value.nombre
      );
      fd.append("ipInicio", formData.ipStart.value);
      fd.append("ipFin", "");
      fd.append("interPR", formData.interP.value);
      fd.append("interPB", formData.interB.value);
      fd.append("epubPR", formData.epubP.value);
      fd.append("epubBR", formData.epubB.value);
      fd.append("pais", formData.pais.value);
      fd.append("tipo", formData.tipo.value);
      fd.append(
        "limite_lecturas_diarias",
        formData.limiteLecturasDiarias.value
      );
      fd.append("tipoUsuario", props.type);
      fd.append("vendedor", vendedor.value.username);
      fd.append("betaTester", +booleanData.betaTester.value);
      fd.append("activo", +booleanData.activo.value);

      fetch(`${GENERAL_URL_API}/clientes/editarCliente.php`, {
        method: "POST",
        credentials: "include",
        body: fd,
      })
        .then(async (res) => {
          const data = await res.json();
          if (data.status === 1) {
            await swal({
              title: "Cliente Editado",
              text: "El cliente se edito satisfactoriamente!",
              icon: "success",
            });
            props.clearSelection();
          } else {
            await swal({
              title: "Error",
              text: data.info,
              icon: "error",
            });
          }
          setLoading(false);
        })
        .catch((e) => console.log(e.message));
    }
  };

  const changeVendedor = (newBook) => {
    setVendedor({ ...vendedor, value: newBook });
  };

  const fetchInstitutes = async () => {
    const fd = new FormData();

    fetch(`${GENERAL_URL_API}/verDatos/verInstitutos.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    })
      .then(async (res) => {
        if (!res.ok) throw new Error();
        const data = await res.json();
        setInstitutes(data.data);
      })
      .catch(() => {
        swal({
          title: "Sorry :(",
          text: "¡Hubo un problema obteniendo los institutos!, comunícate con soporte!",
          icon: "warning",
          buttons: ["Cancelar"],
        });
      });
  };

  return (
    <>
      <div className="admin-muted-text-small mt-3">
        Por favor modifique la información que desee cambiar (Si el usuario no
        existe, debe crearse primero):
      </div>
      <CustomGreenButton
        className="mt-3"
        size="small"
        onClick={props.clearSelection}
      >
        Regresar
      </CustomGreenButton>
      <form>
        <label className="admin-label-text mt-3 d-block">Usuario*</label>
        <TextField
          name="username"
          value={formData.username.value}
          onChange={updateForm}
          error={formData.username.error}
          helperText={
            formData.username.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Usuario (min 4 caracteres y sin espacios)"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">Email*</label>
        <TextField
          name="email"
          value={formData.email.value}
          onChange={updateForm}
          error={formData.email.error}
          helperText={
            formData.email.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="E-mail"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label className="admin-label-text mt-3 d-block">Password*</label>
        <TextField
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
          type={formData.passwd.show ? "text" : "password"}
          value={formData.passwd.value}
          name="passwd"
          placeholder="Password (min 6 caracteres, letras, numeros y simbolos (!@#$%^&*)"
          onChange={updateForm}
          error={formData.passwd.error}
          helperText={formData.passwd.error ? "Campo no válido" : null}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  aria-label="clear password"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      passwd: {
                        ...formData.passwd,
                        value: "",
                      },
                      passwConfirm: {
                        ...formData.passwConfirm,
                        value: "",
                      },
                    });
                  }}
                  edge="end"
                >
                  <Tooltip title="Eliminar contraseñas">
                    <Close />
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="generate password"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    const autoPassword = generatePasswords(1)[0];
                    setFormData({
                      ...formData,
                      passwd: {
                        ...formData.passwd,
                        value: autoPassword,
                      },
                      passwConfirm: {
                        ...formData.passwConfirm,
                        value: autoPassword,
                      },
                    });
                  }}
                  edge="end"
                >
                  <Tooltip title="Generar contraseña">
                    <LockRounded />
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      passwd: {
                        ...formData.passwd,
                        show: !formData.passwd.show,
                      },
                    });
                  }}
                  edge="end"
                >
                  {formData.passwd.show ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Confirmar Password *
        </label>
        <TextField
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
          type={formData.passwConfirm.show ? "text" : "password"}
          value={formData.passwConfirm.value}
          name="passwConfirm"
          placeholder="Password"
          onChange={updateForm}
          error={formData.passwConfirm.error}
          helperText={formData.passwConfirm.error ? "Campo no válido" : null}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      passwConfirm: {
                        ...formData.passwConfirm,
                        show: !formData.passwConfirm.show,
                      },
                    });
                  }}
                  edge="end"
                >
                  {formData.passwConfirm.show ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          IPs (no dejar espacios en blanco)
        </label>
        <TextField
          name="ipStart"
          value={formData.ipStart.value}
          onChange={updateForm}
          error={formData.ipStart.error}
          helperText={
            formData.ipStart.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Usar ';' para separar IPs y '-' para separar rangos. EJ: 190.0.0.25-191.0.0.25;192.2.2.3 <dejar vacío si no aplica>"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">Identificador *</label>
        <TextField
          name="identificador"
          value={formData.identificador.value}
          onChange={updateForm}
          error={formData.identificador.error}
          helperText={
            formData.identificador.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Identificador del cliente"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        {props.type === "Instituto" && (
          <div>
            <label className="admin-label-text mt-3 d-block">Instituto *</label>
            <Autocomplete
              options={institutes}
              getOptionLabel={(option) => option.nombre}
              value={formData.instituto.value}
              onChange={(e, value) => updateForm(e, "instituto", value)}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="institute"
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                  error={formData.instituto.error}
                  helperText={
                    formData.instituto.error ? "Campo no válido" : null
                  }
                />
              )}
            />
          </div>
        )}
        <label className="admin-label-text mt-3 d-block">Concurrencias *</label>
        <TextField
          name="concurrents"
          type="number"
          value={formData.concurrents.value}
          onChange={updateForm}
          error={formData.concurrents.error}
          helperText={
            formData.concurrents.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="# de concurrencias"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        {

          (props.type === "Individual") &&
          <>
            <label className="admin-label-text mt-3 d-block">
              Límite de Lecturas Diarias (0 para desactivar el límite)*
            </label>
            <TextField
              name="limiteLecturasDiarias"
              type="number"
              value={formData.limiteLecturasDiarias.value}
              onChange={updateForm}
              error={formData.limiteLecturasDiarias.error}
              helperText={
                formData.limiteLecturasDiarias.error
                  ? "Este campo no puede estar vacío"
                  : null
              }
              variant="outlined"
              size="small"
              placeholder="Límite de Lecturas Diarias"
              style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
            />
          </>
        }

        <label className="admin-label-text mt-3 d-block">Codigo de País*</label>
        <Autocomplete
          options={paisesAutocomplete}
          getOptionLabel={(option) => option}
          value={formData.pais.value}
          onChange={(e, value) => updateForm(e, "pais", value)}
          noOptionsText="Sin resultados"
          error={formData.pais.error}
          helperText={formData.pais.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecciona un país"
              variant="outlined"
              size="small"
              error={formData.pais.error}
              helperText={formData.pais.error ? "Campo no válido" : null}
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <div className="mt-3">
          <label className="admin-label-text" style={{ whiteSpace: "nowrap" }}>
            Administrador *
          </label>
          <IOSSwitch
            checked={booleanData.accessFull.value}
            onClick={() => {
              updateBoolean("accessFull");
            }}
          />
          <label
            className="admin-label-text ml-md-2"
            style={{ whiteSpace: "nowrap" }}
          >
            Cliente *
          </label>
          <IOSSwitch
            checked={booleanData.accessMid.value}
            onClick={() => {
              updateBoolean("accessMid");
            }}
          />
          <label
            className="admin-label-text ml-md-2"
            style={{ whiteSpace: "nowrap" }}
          >
            SubCliente *
          </label>
          <IOSSwitch
            checked={booleanData.accessLow.value}
            onClick={() => {
              updateBoolean("accessLow");
            }}
          />
        </div>
        <label className="admin-label-text mt-3 d-block">
          Precio libro interactivo Premium *
        </label>
        <TextField
          type="number"
          name="interP"
          value={formData.interP.value}
          onChange={updateForm}
          error={formData.interP.error}
          helperText={
            formData.interP.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Precio libro interactivo Premium"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">
          Precio libro interactivo Básico *
        </label>
        <TextField
          type="number"
          name="interB"
          value={formData.interB.value}
          onChange={updateForm}
          error={formData.interB.error}
          helperText={
            formData.interB.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Precio libro interactivo Básico"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">
          Precio libro Epub Premium *
        </label>
        <TextField
          type="number"
          name="epubP"
          value={formData.epubP.value}
          onChange={updateForm}
          error={formData.epubP.error}
          helperText={
            formData.epubP.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Precio libro Epub Premium"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">
          Precio libro Epub Básico *
        </label>
        <TextField
          type="number"
          name="epubB"
          value={formData.epubB.value}
          onChange={updateForm}
          error={formData.epubB.error}
          helperText={
            formData.epubB.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Precio libro Epub Básico"
          style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">
          Fechas de subscripción *
        </label>
        <TextField
          type="date"
          name="fechaIniSub"
          value={formData.fechaIniSub.value}
          onChange={updateForm}
          error={formData.fechaIniSub.error}
          helperText={
            formData.fechaIniSub.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Precio libro Epub Básico"
          style={{ width: "180px", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <CustomGreenButton
          variant="contained"
          style={{ height: "40px" }}
          className="d-block d-md-inline"
          disableTouchRipple
          disableRipple
          disableElevation
          disableFocusRipple
        >
          Hasta
        </CustomGreenButton>
        <TextField
          type="date"
          name="fechaFinSub"
          value={formData.fechaFinSub.value}
          onChange={updateForm}
          error={formData.fechaFinSub.error}
          helperText={
            formData.fechaFinSub.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Precio libro Epub Básico"
          style={{ width: "180px", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <br />
        <label className="admin-label-text mt-3 d-block">Vendedor *</label>
        <Autocomplete
          options={vendedores.filter(
            (v) => v.rol.toLowerCase() !== "editorial"
          )}
          getOptionLabel={(option) => option.username}
          value={vendedor.value}
          onChange={(_, value) => changeVendedor(value)}
          noOptionsText="Sin resultados"
          renderInput={(params) => (
            <TextField
              {...params}
              name="vendedor"
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={vendedor.error}
              helperText={vendedor.error ? "Campo no válido" : null}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">Tipo *</label>
        <Autocomplete
          options={["Individual", "Instituto"]}
          getOptionLabel={(option) => option}
          value={formData.tipo.value}
          onChange={(e, value) => updateForm(e, "tipo", value)}
          noOptionsText="Sin resultados"
          renderInput={(params) => (
            <TextField
              {...params}
              name="tipo"
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={formData.tipo.error}
              helperText={formData.tipo.error ? "Campo no válido" : null}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">Activo *</label>
        <Autocomplete
          options={["Activo", "Inactivo"]}
          getOptionLabel={(option) => option}
          value={booleanData.activo.value ? "Activo" : "Inactivo"}
          onChange={() => updateBoolean("activo")}
          noOptionsText="Sin resultados"
          renderInput={(params) => (
            <TextField
              {...params}
              name="tipo"
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={booleanData.activo.error}
              helperText={booleanData.activo.error ? "Campo no válido" : null}
            />
          )}
        />

        <label
          className="admin-label-text mt-4"
          style={{ whiteSpace: "nowrap" }}
        >
          Beta Tester
        </label>
        <IOSSwitch
          checked={booleanData.betaTester.value}
          onClick={() => {
            updateBoolean("betaTester");
          }}
        />
        <div className="d-flex justify-content-end mt-4">
          <CustomGreenButton
            variant="contained"
            className="mr-2"
            onClick={handleSubmit}
            disabled={loading}
            style={{ background: "#81c868" }}
          >
            <CheckRounded /> Finalizar
          </CustomGreenButton>
          <CustomGreenButton
            style={{ background: "#f05050" }}
            variant="contained"
            onClick={props.clearSelection}
          >
            <CloseRounded style={{ fontSize: "25px" }} /> Cancelar
          </CustomGreenButton>
        </div>
      </form>
    </>
  );
};

export default EditarClienteForm;
