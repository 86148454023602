import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import TvIcon from "@material-ui/icons/Tv";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import CustomGreenButton from "../../../../components/shared/custom-green-button";

import ListaCortesRegaliasTable from "./lista-cortes-regalia-table";
import CortePorEditorialTable from "./corte-por-editoral-table";
import { GENERAL_URL_API } from "../../../../shared/urls";
import LibrosEditorialTable from "./libros-editorial-table";

/* 
Este componente cambia dependiendo de si se tiene una regalia seleccionada o no
Si no esta seleccionada una regalia se retorna una tabla donde la primera columna contiene botones para seleccionar una regalia
Si no esta seleccinada una regalia tambien se retorna un componente con el listado del las regalias para poder seleccinarla
Si ya hay una regalia se retorna una tabla con los datos de esta, y un boton para devolverse
*/
const ListaCortesRegalias = () => {
  //Funcion para poder guardar la regalia seleccionada
  const [regaliaSelected, setRegaliaSelected] = useState(null);
  const [editorialSelected, setEditorialSelected] = useState(undefined);
  const [regalias, setRegalias] = useState([{}]);
  const [regaliaSel, setRegaliasSel] = useState(undefined);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    fetchRoyalties();
  }, []);

  //Funcion para limpiar la regalia seleccionada
  const clearRegalia = () => {
    setRegaliaSelected(null);
    setRegaliasSel(undefined);
  };

  const fetchRoyalties = async () => {
    let fd = new FormData();
     
    const url = `${GENERAL_URL_API}/regalias/verCortesRegalias.php`;
    let res;
    try {
      res = await fetch(url, {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      });
    } catch (e) {}
    const data = await res.json();
    setRegalias(data.data);
  };

  const regaliaSelectedUpdate = (e, newValue) => {
    let temp = newValue;
    let aux = "";
    if (temp.length > 0) {
      for (let i = 0; i < temp.length; i++) {
        aux = aux + temp[i].idCorte + ",";
      }
      setRegaliasSel(aux.substring(0, aux.length - 1));
    }
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Reporte de regalías</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <AttachMoneyOutlinedIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de Regalías
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-3">
              Lista de cortes de regalías
            </div>
            {!editorialSelected && regaliaSelected === null && (
              <div>
                <div className="admin-muted-text-small mt-1 mb-4">
                  Selecciona si quiere ver el resumen de varios cortes de
                  regalías en conjunto
                </div>
                <Autocomplete
                  multiple
                  options={regalias}
                  getOptionLabel={(option) => option.idCorte}
                  defaultValue={regaliaSel}
                  disableCloseOnSelect
                  noOptionsText="Sin resultados"
                  onChange={regaliaSelectedUpdate}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.idCorte}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  )}
                />

                <CustomGreenButton
                  size="small"
                  variant="contained"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  onClick={() => setRegaliaSelected(regaliaSel)}
                >
                  Ver Cortes Regalias
                </CustomGreenButton>

                <ListaCortesRegaliasTable
                  regalias={regalias}
                  setRegaliaSelected={setRegaliaSelected}
                />
              </div>
            )}
            {!editorialSelected && regaliaSelected && (
              <CortePorEditorialTable
                clearRegalia={clearRegalia}
                regaliaSelected={regaliaSelected}
                updateRoyalties={fetchRoyalties}
                setEditorial={setEditorialSelected}
              />
            )}
            {editorialSelected && regaliaSelected && (
              <LibrosEditorialTable
                regalia={regaliaSelected}
                editorial={editorialSelected}
                clearEditorial={() => setEditorialSelected(undefined)}
              />
            )}
          </Col>
        </Row>
        {/* {regaliaSelected === null && (
          <SeleccionCortes
            regalias={regalias}
            setRegaliaSelected={setRegaliaSelected}
          />
        )} */}
      </Container>
    </>
  );
};

export default ListaCortesRegalias;
