import React from "react";

import { Container } from "react-bootstrap";
import AgregarEstudianteCargaMasiva from "./agregar-estudiante-carga-masiva";
import AgregarNuevoEstudianteIndividual from "./agregar-nuevo-estudiante-individual";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

const AgregarNuevoEstudiante = () => {
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar un nuevo estudiante plan lector
        </div>
      </div>
      <Container fluid>
        <AgregarNuevoEstudianteIndividual />
        <AgregarEstudianteCargaMasiva />
      </Container>
    </>
  );
};

export default AgregarNuevoEstudiante;
