import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PersonIcon from "@material-ui/icons/Person";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchUsuariosAcademia } from "../../../shared/fetchers/academia/verUsuarios";
import { borrarUsuarioAcademia } from "../../../shared/fetchers/academia/borrarUsuario";
import { toast } from "react-hot-toast";

const EliminarUsuarioAcademia = () => {
  const queryClient = useQueryClient();

  const {
    data: users,
    isLoading: loadingUsers,
    isError,
  } = useQuery(["usuariosAcademia"], fetchUsuariosAcademia);

  //Definimos un estado para guardar la informacion del autocomplete
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = async (newValue) => {
    if (!newValue) return;

    setAutoCompleteValue(newValue);

    //Si se selecciono un valor creamos un modal para confirmar si la persona desea borrar un elemento
    const confirm = await swal({
      title: "Está seguro?",
      text: "Esta acción no se puede revertir!",
      icon: "warning",
      buttons: ["Cancelar", "Borrar"],
    });

    if (!confirm) return setAutoCompleteValue(null);

    //Si se quiere borrar un elemento se llama a la api y se actualiza localmente el estado
    console.log("deleting Academia user");

    const response = await borrarUsuarioAcademia(newValue.usernameAcademia);

    queryClient.invalidateQueries({
      queryKey: ["usuariosAcademia"],
    });

    setAutoCompleteValue(null);

    if (!response.status)
      return swal({
        title: "Error",
        text: "No se pudo borrar el usuario",
        icon: "error",
      });

    toast.success("Usuario borrado");
  };

  if (loadingUsers)
    return (
      <div className="mt-2">
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </div>
    );

  if (isError)
    return <div>Error al cargar los usuarios, prueba recargando la página</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <DeleteOutlineIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Eliminar un usuario</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "22px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Borrar un usuario de Academia
              </div>
            </div>

            <label className="admin-label-text mt-3 d-block">Buscar:</label>
            <Autocomplete
              options={users}
              getOptionLabel={(option) =>
                option
                  ? `${option.nombre} - ${option.usernameAcademia} - ${option.email}`
                  : ""
              }
              value={autoCompleteValue}
              onChange={(_, newValue) => autoCompleteUpdate(newValue)}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EliminarUsuarioAcademia;
