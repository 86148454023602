import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import EditarClienteSAML from "./editar-cliente-saml-form";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";

const editarClienteSaml = () => {
  return (
    <div>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Editar un IdP SAML</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                EDITAR UN IDP SAML
              </span>
            </div>
            <span className="admin-muted-text">
              Por favor ingresar la siguiente información (los campos con * son
              obligatorios):
            </span>
            <EditarClienteSAML />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default editarClienteSaml;
