export const columnasSedesClassics = [
  {
    label: "Nombre",
    field: "nombreSede",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "País",
    field: "pais",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Ciudad",
    field: "ciudad",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
