import React from "react";
import { useQuery } from "@tanstack/react-query";
import ListaTags from "./lista-tags";
import { fetchTags } from "../../../../shared/fetchers/tags";
import TvIcon from "@material-ui/icons/Tv";
import AgregarTags from "./agregar-tags";
import EditarTags from "./editar-tags";

const AgregarEditarTags = () => {
  const { data: tags, isLoading, error } = useQuery(["tags"], fetchTags);

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error...</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Tags</div>
      </div>

      <AgregarTags />

      <EditarTags tags={tags.data} />

      <ListaTags tags={tags.data} />
    </>
  );
};

export default AgregarEditarTags;
