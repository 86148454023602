import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";

import TvIcon from "@material-ui/icons/Tv";
import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import exportFromJSON from "export-from-json";

import { columnasUsuariosAcademia } from "../../../shared/data/columnas/formacion/listaUsuarios";
import { fetchUsuariosAcademia } from "../../../shared/fetchers/academia/verUsuarios";
import { useQuery } from "@tanstack/react-query";

const ListaUsuariosAcademia = () => {
  const {
    data: rows,
    isLoading,
    isError,
  } = useQuery(["usuariosAcademia"], fetchUsuariosAcademia);

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let columnas = columnasUsuariosAcademia;
    //Añadimos un atributo de visible a todas las columnas
    columnas.map((columna) => (columna.isVisible = true));
    setColumns(columnas);
  }, []);

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  if (isLoading)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isError) return <div>Ha ocurrido un error</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Lista de usuarios Academia</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de usuarios Academia
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Lista de todos los clientes.
            </div>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />
            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                onClick={() => {
                  const dat = [...rows.data];
                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>

              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columns}
                  rows={rows}
                  filename={"Lista-Usuarios-Academia"}
                />
              </CustomGreenButton>

              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "Lista-Usuarios-Academia",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
            </ButtonGroup>
            <MDBDataTableV5
              small
              responsive
              striped
              bordered
              searchTop
              searchBottom={false}
              barReverse
              pagingTop
              noBottomColumns
              order={["name", "asc"]}
              entriesOptions={[5, 10, 20, 50]}
              entriesLabel="Numero de filas"
              searchLabel="Buscar"
              paginationLabel={["Anterior", "Siguiente"]}
              infoLabel={["Mostrando filas", "a", "de", "filas"]}
              fullPagination
              data={{
                columns: columns.filter((obj) => obj.isVisible),
                rows: rows,
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaUsuariosAcademia;
