import { GENERAL_URL_API } from "../urls";

export const fetchCursosProfesor = async (profesor) => {
  const fd = new FormData();
   
   
  fd.append("profesor", profesor);

  const res = await fetch(`${GENERAL_URL_API}/Cursos/verCursosProfesor.php`, {
    method: "POST", credentials: 'include',
    body: fd,
  });

  //if (!res.ok) throw new Error("Network error: " + res.message);

  return res.json();
};

export const assignCursoProfesor = async (
  usernameProfesor,
  idCursoPlanLector
) => {
  const fd = new FormData();
   
   
  fd.append("usernameProfesor", usernameProfesor);
  fd.append("idCursoPlanLector", idCursoPlanLector);

  const res = await fetch(
    `${GENERAL_URL_API}/Cursos/asociarProfesorCurso.php`,
    {
      method: "POST", credentials: 'include',
      credentials: "include",
      body: fd,
    }
  );

  if (!res.ok) throw new Error("Network error: " + res.message);

  return res.json();
};

export const deleteCursoProfesor = async (
  usernameProfesor,
  idCursoPlanLector
) => {
  const fd = new FormData();
   
   
  fd.append("usernameProfesor", usernameProfesor);
  fd.append("idCursoPlanLector", idCursoPlanLector);

  const res = await fetch(
    `${GENERAL_URL_API}/Cursos/desasociarProfesorCurso.php`,
    {
      method: "POST", credentials: 'include',
      credentials: "include",
      body: fd,
    }
  );

  //if (!res.ok) throw new Error("Network error: " + res.message);

  return res.json();
};
