import axios from "axios";
import { GENERAL_URL_API } from "../urls";

export async function fetchFilasHome() {
  const { data } = await axios.get(
    `${GENERAL_URL_API}/filasHome/verFilasHome`,
    {
      withCredentials: true,
    }
  );

  return data?.data?.filasHome ?? [];
}

/**
 * Obtiene los libros de una fila específica.
 * @param {string} filaHome - El nombre de la fila de la que se obtendrán los libros.
 * @returns {Promise<Array>} - Una promesa que se resuelve en un arreglo de libros obtenidos de la fila. Si no se encuentran libros, se devuelve un arreglo vacío.
 * @throws {Error} - Si la solicitud HTTP falla o si hay un error en el servidor.
 */
export async function fetchLibrosFila(filaHome) {
  const fd = new FormData();
  fd.append("filaHome", filaHome);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/filasHome/verLibrosFila`,
    fd,
    {
      withCredentials: true,
    }
  );

  return data?.data ?? [];
}

export async function createFilaHome({
  nombre,
  descripcion,
  disponibilidadRegional,
}) {
  const fd = new FormData();
  fd.append("editionType", "añadirFilaHome");
  fd.append("filaHome", nombre);
  fd.append("description", descripcion);
  fd.append("disponibilidadRegional", disponibilidadRegional);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/filasHome/editarFilasHomeRecomendados`,
    fd,
    {
      withCredentials: true,
    }
  );

  return data;
}

/**
 * Edita una fila de inicio existente con los datos proporcionados.
 * @param {Object} options - Opciones para editar la fila de inicio.
 * @param {string} options.nombre - El nombre de la fila de inicio a editar.
 * @param {string} options.descripcion - La nueva descripción de la fila de inicio.
 * @param {string} options.disponibilidadRegional - Una lista de países separados por comas que indican la disponibilidad regional de la fila de inicio.
 * @returns {Promise<Object>} - Una promesa que se resuelve en el objeto de respuesta de la edición de la fila de inicio.
 * @throws {Error} - Si la solicitud HTTP falla o si hay un error en el servidor.
 */
export async function editFilaHome({
  nombre,
  descripcion,
  disponibilidadRegional,
}) {
  const fd = new FormData();
  fd.append("editionType", "editarFilaHome");
  fd.append("filaHome", nombre);
  fd.append("description", descripcion);
  fd.append("disponibilidadRegional", disponibilidadRegional);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/filasHome/editarFilasHomeRecomendados`,
    fd,
    {
      withCredentials: true,
    }
  );

  return data;
}

/**
 * Elimina una fila home existente según el nombre proporcionado.
 * @param {string} nombre - El nombre de la fila home a eliminar.
 * @returns {Promise<Object>} - Una promesa que se resuelve en el objeto de respuesta de la eliminación de la fila de inicio.
 * @throws {Error} - Si la solicitud HTTP falla o si hay un error en el servidor.
 */
export async function deleteFilaHome(nombre) {
  const fd = new FormData();
  fd.append("editionType", "eliminarFilaHome");
  fd.append("filaHome", nombre);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/filasHome/editarFilasHomeRecomendados`,
    fd,
    {
      withCredentials: true,
    }
  );

  return data;
}

/**
 * Agrega un libro a una fila home específica.
 * @param {Object} options - Opciones para agregar el libro a la fila home.
 * @param {string} options.idLibro - El ID del libro que se va a agregar a la fila home.
 * @param {string} options.nombreFila - El nombre de la fila home a la que se va a agregar el libro.
 * @returns {Promise<Object>} - Una promesa que se resuelve en el objeto de respuesta de la adición del libro a la fila home.
 * @throws {Error} - Si la solicitud HTTP falla o si hay un error en el servidor.
 */
export async function agregarLibroFila({ idLibro, nombreFila }) {
  const fd = new FormData();
  fd.append("editionType", "añadirLibro");
  fd.append("idLibro", idLibro);
  fd.append("filaHome", nombreFila);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/filasHome/editarFilasHomeRecomendados`,
    fd,
    {
      withCredentials: true,
    }
  );

  return data;
}

/**
 * Elimina un libro de una fila home específica.
 * @param {Object} options - Opciones para eliminar el libro de la fila home.
 * @param {string} options.idLibro - El ID del libro que se va a eliminar de la fila home.
 * @param {string} options.nombreFila - El nombre de la fila home de la que se va a eliminar el libro.
 * @returns {Promise<Object>} - Una promesa que se resuelve en el objeto de respuesta de la eliminación del libro de la fila home.
 * @throws {Error} - Si la solicitud HTTP falla o si hay un error en el servidor.
 */
export async function eliminarLibroFila({ idLibro, nombreFila }) {
  const fd = new FormData();
  fd.append("editionType", "eliminarLibro");
  fd.append("idLibro", idLibro);
  fd.append("filaHome", nombreFila);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/filasHome/editarFilasHomeRecomendados`,
    fd,
    {
      withCredentials: true,
    }
  );

  return data;
}

export async function fetchPaquetesLibro(idLibro) {
  const fd = new FormData();
  fd.append("idLibro", idLibro);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/paquetes/verPaquetesLibro`,
    fd,
    {
      withCredentials: true,
    }
  );

  return data?.data ?? [];
}
