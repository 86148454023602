import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GENERAL_URL_API } from "../../../shared/urls";

import BrushOutlinedIcon from "@material-ui/icons/BrushOutlined";
import PersonIcon from "@material-ui/icons/Person";
import AgregarNuevoInstituto from "../adicionar-elementos/agregar-nuevo-instituto";

const EditarUnInstituto = () => {
  //En este estado se guarda el instituto seleccionado por la persona
  const [institutoSeleccionado, setInstitutoSeleccionado] = useState(null);
  const [institutos, setInstitutos] = useState([]);

  useEffect(() => {
    fetchInstitutos();
  }, []);

  const fetchInstitutos = async () => {
    setInstitutos([]);
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setInstitutos(data.data);
  };

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = (e, newValue) => {
    if (newValue) {
      setInstitutoSeleccionado(newValue);
    }
  };

  const clearInstituto = async () => {
    await fetchInstitutos();
    setInstitutoSeleccionado(null);
  };

  return (
    <>
      {!institutoSeleccionado ? (
        <>
          <div className="mb-4 d-flex align-items-center">
            <div className="admin-page-tilte">(</div>
            <BrushOutlinedIcon />
            <div className="admin-page-tilte mr-2">)</div>
            <div className="admin-page-tilte">Editar un instituto lector</div>
          </div>
          <Container fluid>
            <Row>
              <Col xs={12} className="content-white-box px-4 py-4">
                <div className="d-flex align-items-center">
                  <PersonIcon style={{ fontSize: "17px" }} />
                  <div className="admin-main-title-dark-gray ml-2 mt-1">
                    Por favor ingresar la siguiente info:
                  </div>
                </div>
                <form>
                  <label className="admin-label-text mt-3 d-block">
                    Nombre del Insituto a editar
                  </label>
                  <Autocomplete
                    options={institutos}
                    getOptionLabel={(option) => option.nombre}
                    value={institutoSeleccionado}
                    onChange={autoCompleteUpdate}
                    noOptionsText="Sin resultados"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="autocomplete"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "100%",
                          fontFamily: "'Noto Sans JP', sans-serif",
                        }}
                      />
                    )}
                  />
                </form>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <AgregarNuevoInstituto
          instituto={institutoSeleccionado}
          clearInstituto={clearInstituto}
        />
      )}
    </>
  );
};

export default EditarUnInstituto;
