import React, { useState, useEffect } from "react";
// Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
import { Button } from "@material-ui/core";
// icons
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
// data
import { GENERAL_URL_API } from "../../../../shared/urls";

const AgregarEliminarPaqueteCursoForm = ({ packets, packet, setPacket }) => {
  const [loading, setLoading] = useState(false);

  const [books, setBooks] = useState([]);
  const [book, setBook] = useState({
    value: null,
    error: false,
    required: true,
  });

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    const fd = new FormData();
     

    const res = await fetch(`${GENERAL_URL_API}/verDatos/verLibros.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo clientes.",
        text: data.info,
        icon: "error",
      });
    setBooks(data.data);
  };

  const sendReq = async (route) => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("nombrePaquete", packet.value.nombrePaquete);
    fd.append("idLibro", book.value.idLibro);
    fd.append("estado", "habilitado");

    const url = `${GENERAL_URL_API}${route}`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.status === 0)
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    setLoading(false);
  };

  const changePacket = (value) => {
    setPacket({
      ...packet,
      value: value || null,
    });
  };

  const changeBook = (value) => {
    setBook({
      ...book,
      value,
    });
  };

  const clearForm = () => {
    setPacket({
      ...packet,
      error: false,
    });
    setBook({
      ...book,
      value: null,
      error: false,
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (!packet.value) {
      setPacket({
        ...packet,
        error: true,
      });
      isValid = false;
    }

    if (!book.value) {
      setBook({
        ...book,
        error: true,
      });
      isValid = false;
    }

    return isValid;
  };

  // route es la ruta a la que debe hacer la req ej: /paquetes/eliminarLibroPaquete.php
  const handleSubmit = async (e, route) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      await sendReq(route);
      clearForm();
    }
  };

  return (
    <>
      <form>
        <label className="admin-label-text mt-3 d-block">
          Agregar o eliminar del paquete:
        </label>
        <Autocomplete
          options={packets}
          getOptionLabel={(option) => `${option?.nombrePaquete}`}
          value={packet?.value?.nombrePaquete}
          onChange={(e, value) => changePacket(value)}
          noOptionsText="Sin resultados"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={packet.error}
              helperText={packet.error ? "Campo no válido" : null}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">El libro:</label>
        <Autocomplete
          options={books}
          getOptionLabel={(option) => `${option.title} - ${option.author}`}
          value={book.value}
          onChange={(e, value) => changeBook(value)}
          noOptionsText="Sin resultados"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={book.error}
              helperText={book.error ? "Campo no válido" : null}
            />
          )}
        />

        <div className="mt-3">
          <Button
            variant="contained"
            color="primary"
            onClick={(e) =>
              handleSubmit(e, "/paquetes/agregarLibroPaquete.php")
            }
            startIcon={<CheckRounded />}
            disabled={!packet?.value || !book?.value || loading}
            style={{ background: "#81c868" }}
          >
            Agregar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) =>
              handleSubmit(e, "/paquetes/eliminarLibroPaquete.php")
            }
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            disabled={loading}
            style={{ background: "#f05050" }}
          >
            Eliminar
          </Button>
        </div>
      </form>
    </>
  );
};

export default AgregarEliminarPaqueteCursoForm;
