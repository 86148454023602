import React, { useEffect, useState } from "react";

import { Route, Redirect, Switch } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
import { GENERAL_URL_API } from "../../shared/urls";

import Navbar from "../navigation/navbar/navbar";
import Sidebar from "../navigation/sidebar/sidebar";

import Home from "../../pages/dashboard/home";

/*Consultas*/
import ListaLibros from "../../pages/consultas/ver-datos/lista-libros";
import ListaClientes from "../../pages/consultas/ver-datos/lista-clientes";
import ReporteLecturas from "../../pages/consultas/ver-datos/reporte-lecturas";
import ReporteAccesoClientes from "../../pages/consultas/ver-datos/reporte-acceso-clientes";
import ReporteSolicitudesDemo from "../../pages/consultas/ver-datos/reporte-solicitudes-demo";
import EstadiscticasAccesoInstituto from "../../pages/consultas/ver-estadisticas/estadisticas-acceso-instituto";
import EstadiscticasAccesoPersonales from "../../pages/consultas/ver-estadisticas/estadisticas-acceso-personales";
import EstadiscticasGlobalesAcceso from "../../pages/consultas/ver-estadisticas/estadisticas-globales-acceso";
import EstadisticasAccesoInstitutoPL from "../../pages/plan-lector/ver-estadisticas/estadisticas-acceso-instituto";
import EstadisticasGlobalesAccesoPL from "../../pages/plan-lector/ver-estadisticas/estadisticas-globales-acceso";
/*Registrar y Editar*/
import AgregarEliminarLibrosPaquete from "../../pages/registrar-y-editar/adicionar-elementos/agregar-eliminar-libros-paquete";
import AgregarEliminarLibrosColeccion from "../../pages/registrar-y-editar/adicionar-elementos/agregar-eliminar-libros-coleccion";
import AgregarEliminarPaqueteCliente from "../../pages/registrar-y-editar/adicionar-elementos/agregar-eliminar-paquete-cliente/agregar-eliminar-paquete-cliente";
import AgregarNuevoLibro from "../../pages/registrar-y-editar/adicionar-elementos/agregar-nuevo-libro";
import AgregarClienteAdministrativo from "../../pages/registrar-y-editar/adicionar-elementos/agregar-cliente-administrativo";
import EditarClienteExistente from "../../pages/registrar-y-editar/editar-elementos/editar-cliente-existente/editar-cliente-existente.jsx";
import EditarActividad from "../../pages/registrar-y-editar/editar-elementos/editar-actividad/editar-actividad.jsx";
import EditarArteColeccion from "../../pages/registrar-y-editar/editar-elementos/editar-arte-coleccion/editar-arte-coleccion.jsx";
import EditarArteEditorial from "../../pages/registrar-y-editar/editar-elementos/editar-arte-editorial/editar-arte-editorial.jsx";
import EditarArteInstituto from "../../pages/registrar-y-editar/editar-elementos/editar-arte-instituto/editar-arte-instituto.jsx";
import EliminarCliente from "../../pages/registrar-y-editar/borrar-elementos/eliminar-cliente";
import EliminarGeneral from "../../pages/registrar-y-editar/borrar-elementos/eliminar-general";
/*Home y otros*/
import CambiarLosBanners from "../../pages/home-y-otros/cambiar-los-banners";
/*Manejo del Blog*/
import CrearEntradaBlog from "../../pages/blog/manejo-del-blog/crear-entrada-blog";
import EditarEntradaBlog from "../../pages/blog/manejo-del-blog/editar-entrada-blog/editar-entrada-blog";
/*Plan Lector*/
import ListaInstitutos from "../../pages/plan-lector/ver-datos/lista-institutos";
import AgregarNuevoInstituto from "../../pages/plan-lector/adicionar-elementos/agregar-nuevo-instituto";
import EditarUnInstituto from "../../pages/plan-lector/editar-elementos/editar-un-instituto";
import EliminarUnProfesor from "../../pages/plan-lector/borrar-elementos/eliminar-un-profesor";
/*Formacion*/
import ListaUsuariosAcademia from "../../pages/formacion/ver-datos/lista-usuarios-academia";
/*Nuevas Regalias  */
import ListaCortesRegalias from "../../pages/nuevas-regalias/ver-datos/lista-cortes-regalia/lista-cortes-regalia";
import CrearNuevoCorte from "../../pages/nuevas-regalias/adicionar-elementos/crear-nuevo-corte";
/*
Este componente corresponde al contenedor global de todos los elementtos del admin
Aqui es donde se renderiza:
-La barra  de navegacion superior
-El sidebar que es donde se va a poder cambiar de pagina
-El contenido de la pagina (Este contenido cambia dependiento de la url por medio de react-router-dom)
Para poder cambiar de pagina se tiene que añadir la categoria en navigation-elements de la carpeta navigation
*/
import AgregarNuevoClienteSAML from "../../pages/registrar-y-editar/adicionar-elementos/agregar-nuevo-cliente-saml";
import AgregarNuevaActividad from "../../pages/registrar-y-editar/adicionar-elementos/agregar-nueva-actividad";
import editarClienteSaml from "../../pages/registrar-y-editar/editar-elementos/editar-cliente-saml/editar-cliente-saml";
import AgregarNuevoCliente from "../../pages/registrar-y-editar/adicionar-elementos/agregar-nuevo-cliente";
import editarLibro from "../../pages/registrar-y-editar/editar-elementos/editar-libro/editar-libro";
import AsignarCodigo from "../../pages/registrar-y-editar/adicionar-elementos/asignar-codigo";
import HabilitarPaqueteCliente from "../../pages/registrar-y-editar/adicionar-elementos/habilitar-paquete-cliente";
import AutorizarPaqueteCliente from "../../pages/registrar-y-editar/adicionar-elementos/autorizar-paquete-cliente/autorizar-paquete-cliente";
import ListaCodigosRegalo from "../../pages/consultas/ver-datos/lista-codigos";
import ListaActividades from "../../pages/consultas/ver-datos/lista-actividades";
import ListaIDPS from "../../pages/consultas/ver-datos/lista-idps-saml";
import { useClient } from "../../shared/contex/client/client-context";
import { useAuth } from "../../shared/contex/auth/auth-context";
import ListaCursos from "../../pages/plan-lector/ver-datos/lista-cursos";
import ListaProfesores from "../../pages/plan-lector/ver-datos/lista-profesores";
import ReporteLecturasPlanLector from "../../pages/plan-lector/ver-datos/reporte-lecturas";
import ReporteAccesoEstudiantes from "../../pages/plan-lector/ver-datos/reporte-accesos-estudiantes";
import AgregarNuevoCurso from "../../pages/plan-lector/adicionar-elementos/agregar-nuevo-curso";
import AsignarCursos from "../../pages/plan-lector/adicionar-elementos/asignar-cursos/asignar-cursos";
import AgregarNuevoEstudiante from "../../pages/plan-lector/adicionar-elementos/agregar-estudiante-pl/agregar-nuevo-estudiante";
import AgregarNuevoProfesor from "../../pages/plan-lector/adicionar-elementos/agregar-nuevo-profesor";
import EliminarUnInstituto from "../../pages/plan-lector/borrar-elementos/eliminar-un-instituto";
import EliminarUnCurso from "../../pages/plan-lector/borrar-elementos/eliminar-un-curso";
import EliminarUnaSede from "../../pages/plan-lector/borrar-elementos/eliminar-una-sede";
import EliminarUnEstudiante from "../../pages/plan-lector/borrar-elementos/eliminar-un-estudiante";
//import ReporteAccesoProfesores from "../../pages/plan-lector/ver-datos/reporte-accesos-profesores";
import ListaEstudiantes from "../../pages/plan-lector/ver-datos/lista-estudiantes";
import ListaSedes from "../../pages/plan-lector/ver-datos/lista-sedes";
import AgregarEliminarLibrosPaquetePlanLector from "../../pages/plan-lector/adicionar-elementos/agregar-eliminar-libros-paquete";
import AgregarEliminarPaquetePlanLector from "../../pages/plan-lector/adicionar-elementos/agregar-eliminar-paquete";
import HabilitarPaqueteCurso from "../../pages/plan-lector/adicionar-elementos/habilitar-paquete-pl/habilitar-paquete-pl";
import AutorizarPaquetePL from "../../pages/plan-lector/adicionar-elementos/autorizar-paquete-pl/autorizar-paquete-pl";
import EditarCursoPL from "../../pages/plan-lector/editar-elementos/editar-curso/editar-curso-pl";
import EditarUsuarioAdministrativo from "../../pages/registrar-y-editar/editar-elementos/editar-usuario/editar-usuario";
import EditarProfesorPL from "../../pages/plan-lector/editar-elementos/editar-profesor/editar-profesor-pl";
import AgregarNuevaSede from "../../pages/plan-lector/adicionar-elementos/agregar-sede-pl/agregar-nueva-sede";
import EditarSedePL from "../../pages/plan-lector/editar-elementos/editar-sede/editar-sede-pl";
import AgregarEditarTags from "../../pages/registrar-y-editar/editar-elementos/agregar-editar-tags";
import EditarEstudiantePL from "../../pages/plan-lector/editar-elementos/editar-estudiante/editar-estudiante-pl";
import AsignarCursoUsuario from "../../pages/formacion/adicionar-elementos/asignar-curso-usuario/asignar-curso-usuario";
import AgregarConexionOAuth from "../../pages/registrar-y-editar/adicionar-elementos/agregar-conexion-oauth/agregar-conexion-oauth";
//Armagedon
import Armagedon from "../../pages/Armagedon";
import ListaCursosAcademia from "../../pages/formacion/ver-datos/lista-cursos-academia";
import EliminarUsuarioAcademia from "../../pages/formacion/borrar-elementos/borrar-usuario-academia";
import FilasHome from "../../pages/registrar-y-editar/adicionar-elementos/filas-home";
import PaquetesLibro from "../../pages/registrar-y-editar/adicionar-elementos/paquetes-libro";
import PasswordGenerator from "../../pages/home-y-otros/password-generator";
import CrearUsuarioAcademia from "../../pages/formacion/adicionar-elementos/crear-usuario-academia/index";
import ListaInstitutosClassics from "../../pages/consultas/ver-datos/lista-institutos";
import ListaSedesClassics from "../../pages/consultas/ver-datos/lista-sedes";
import CrearInstitutoClassics from "../../pages/registrar-y-editar/adicionar-elementos/crear-instituto";
import EliminarInstitutoClassics from "../../pages/registrar-y-editar/borrar-elementos/eliminar-instituto";
import EliminarSedeClassics from "../../pages/registrar-y-editar/borrar-elementos/eliminar-sede";
import CrearSedeClassics from "../../pages/registrar-y-editar/adicionar-elementos/crear-sede";
import EditarSedeClassics from "../../pages/registrar-y-editar/editar-elementos/editar-sede";
import EditarInstitutoClassics from "../../pages/registrar-y-editar/editar-elementos/editar-instituto";
import AgregarEliminarLibrosCursoPlanLector from "../../pages/plan-lector/adicionar-elementos/agregar-eliminar-libros-curso/index";
import ReporteUsuariosAcademia from "../../pages/formacion/ver-datos/reporte-usuarios-academia";
//Eventos
import ListaEventos from "../../pages/home-y-otros/eventos/lista-eventos.jsx";
import CrearEvento from "../../pages/home-y-otros/eventos/crear-evento/index.jsx";
import EditarEvento from "../../pages/home-y-otros/eventos/editar-evento/index.jsx";
import EliminarEvento from "../../pages/home-y-otros/eventos/eliminar-evento.jsx";
// Recursos
import ListaRecursos from "../../pages/home-y-otros/recursos/lista-recursos.jsx";
import EliminarRecurso from "../../pages/home-y-otros/recursos/eliminar-recurso.jsx";
import CrearRecurso from "../../pages/home-y-otros/recursos/crear-recurso/index.jsx";
import EditarRecurso from "../../pages/home-y-otros/recursos/editar-recurso/index.jsx";
//Notibuzon
import ListaNotibuzon from "../../pages/home-y-otros/notibuzon/lista-notibuzon.jsx";
import EliminarNotibuzon from "../../pages/home-y-otros/notibuzon/eliminar-notibuzon.jsx";
import CrearNotibuzon from "../../pages/home-y-otros/notibuzon/crear-notibuzon/index.jsx";
import EditarNotibuzon from "../../pages/home-y-otros/notibuzon/editar-notibuzon/index.jsx";
//Notibanner
import ListaNotibanner from "../../pages/home-y-otros/notibanner/lista-notibanner.jsx";
import EliminarNotibanner from "../../pages/home-y-otros/notibanner/eliminar-notibanner.jsx";
import CrearNotibanner from "../../pages/home-y-otros/notibanner/crear-notibanner/index.jsx";
import EditarNotibanner from "../../pages/home-y-otros/notibanner/editar-notibanner/index.jsx";
import RoutesFormularios from "../../routes/classics/formularios/index.js";
import RoutesUsuariosAcademia from "../../routes/formacion/usuarios/index.js";

const AdminDashboad = () => {
  const auth = useAuth();
  const { getClient } = useClient();
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (auth.isLoggedIn) {
      setClient(getClient());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isLoggedIn]);

  const toRouteComponent = (routes) =>
    routes.map(({ path, component, access }, index) =>
      client?.access?.[access] ? (
        <Route key={index} path={path} exact={true} component={component} />
      ) : (
        <div></div>
      ),
    );

  return (
    <>
      <Navbar />
      <Container fluid>
        <Row>
          <Col xs={0} lg={2} className="p-0">
            <Sidebar />
          </Col>
          <Col xs={12} lg={10} className="p-0 ">
            <div className="navbar-replacement"></div>
            <div className="px-3 px-md-4 pt-4">
              <Switch>
                <Route path="/" exact={true} component={Home} />
                {/*Consultas*/}
                {client?.access?.ver_libros && (
                  <Route
                    path="/consultas/lista-libros"
                    exact={true}
                    component={ListaLibros}
                  />
                )}
                {/* <Route path='/consultas/lista-actividades' exact={true} component={ListaActividaes} /> */}
                {client?.access?.ver_clientes && (
                  <Route
                    path="/consultas/lista-clientes"
                    exact={true}
                    component={ListaClientes}
                  />
                )}
                {client?.access?.ver_actividades && (
                  <Route
                    path="/consultas/lista-actividades"
                    exact={true}
                    component={ListaActividades}
                  />
                )}
                {client?.access?.ver_idp_saml && (
                  <Route
                    path="/consultas/lista-idps-saml"
                    exact={true}
                    component={ListaIDPS}
                  />
                )}
                <Route
                  path="/consultas/agregar-conexion-oauth"
                  exact={true}
                  component={AgregarConexionOAuth}
                />
                {client?.access?.ver_lectura_libros && (
                  <Route
                    path="/consultas/reporte-lecturas"
                    exact={true}
                    component={ReporteLecturas}
                  />
                )}
                {client?.access?.ver_acceso_clientes && (
                  <Route
                    path="/consultas/reporte-acceso-clientes"
                    exact={true}
                    component={ReporteAccesoClientes}
                  />
                )}
                {client?.access?.ver_solicitudes_demo && (
                  <Route
                    path="/consultas/reporte-solicitudes-demo"
                    exact={true}
                    component={ReporteSolicitudesDemo}
                  />
                )}
                {client?.access?.ver_codigos_regalo && (
                  <Route
                    path="/consultas/lista-codigos-regalo"
                    exact={true}
                    component={ListaCodigosRegalo}
                  />
                )}

                {client?.access?.ver_acceso_institutos && (
                  <Route
                    path="/consultas/estadisticas-acceso-instituto"
                    exact={true}
                    component={EstadiscticasAccesoInstituto}
                  />
                )}

                {client?.access?.ver_acceso_institutos && (
                  <Route
                    path="/consultas/lista-institutos"
                    exact={true}
                    component={ListaInstitutosClassics}
                  />
                )}

                {client?.access?.ver_acceso_institutos && (
                  <Route
                    path="/consultas/lista-sedes"
                    exact={true}
                    component={ListaSedesClassics}
                  />
                )}

                {/* TODO: Cambiar permiso de eventos cuando sea creado */}
                {client?.access?.ver_acceso_institutos && (
                  <Route
                    path="/consultas/lista-eventos"
                    exact={true}
                    component={ListaEventos}
                  />
                )}

                {/* TODO: Cambiar permiso de recursos cuando sea creado */}
                {client?.access?.ver_acceso_institutos && (
                  <Route
                    path="/consultas/lista-recursos"
                    exact={true}
                    component={ListaRecursos}
                  />
                )}

                {/* TODO: Cambiar permiso de NOTIBUZON cuando sea creado */}
                {client?.access?.ver_acceso_institutos && (
                  <Route
                    path="/consultas/lista-notibuzon"
                    exact={true}
                    component={ListaNotibuzon}
                  />
                )}

                {/* TODO: Cambiar permiso de NOTIBANNER cuando sea creado */}
                {client?.access?.ver_acceso_institutos && (
                  <Route
                    path="/consultas/lista-notibanner"
                    exact={true}
                    component={ListaNotibanner}
                  />
                )}

                {client?.access?.ver_acceso_personal && (
                  <Route
                    path="/consultas/estadisticas-acceso-personales"
                    exact={true}
                    component={EstadiscticasAccesoPersonales}
                  />
                )}
                {client?.access?.ver_estadisticas_globales && (
                  <Route
                    path="/consultas/estadisticas-globales-lectura-acceso"
                    exact={true}
                    component={EstadiscticasGlobalesAcceso}
                  />
                )}
                {/*Registrar y Editar*/}
                {client?.access?.agregar_libro_paquete && (
                  <Route
                    path="/registrar-y-editar/agregar-eliminar-libros-paquete"
                    exact={true}
                    component={AgregarEliminarLibrosPaquete}
                  />
                )}
                {client?.access?.ver_libros && (
                  <Route
                    path="/registrar-y-editar/paquetes-libro"
                    exact={true}
                    component={PaquetesLibro}
                  />
                )}
                {/* No tiene permiso propio */}
                {client?.access?.autorizar_paquete_cliente && (
                  <Route
                    path="/registrar-y-editar/agregar-eliminar-paquete-cliente"
                    exact={true}
                    component={AgregarEliminarPaqueteCliente}
                  />
                )}
                {client?.access?.agregar_libro_coleccion && (
                  <Route
                    path="/registrar-y-editar/agregar-eliminar-libros-coleccion"
                    exact={true}
                    component={AgregarEliminarLibrosColeccion}
                  />
                )}
                {client?.access?.habilitar_paquete_cliente && (
                  <Route
                    path="/registrar-y-editar/habilitar-paquete-cliente"
                    exact={true}
                    component={HabilitarPaqueteCliente}
                  />
                )}
                {client?.access?.autorizar_paquete_cliente && (
                  <Route
                    path="/registrar-y-editar/autorizar-paquete-cliente"
                    exact={true}
                    component={AutorizarPaqueteCliente}
                  />
                )}
                {/* no tiene permiso propiio */}
                {client?.access?.CRUD_usuario_dios ||
                  client?.access?.CRUD_usuario_editorial ||
                  client?.access?.CRUD_usuario_vendedor ? (
                  <Route
                    path="/registrar-y-editar/agregar-cliente-administrativo"
                    exact={true}
                    component={AgregarClienteAdministrativo}
                  />
                ) : undefined}
                {client?.access?.crear_libro_contenido && (
                  <Route
                    path="/registrar-y-editar/agregar-nuevo-libro"
                    exact={true}
                  >
                    <AgregarNuevoLibro book={false} edit={false} />
                  </Route>
                )}
                {client?.access?.crear_actividad && (
                  <Route
                    path="/registrar-y-editar/agregar-nueva-actividad"
                    exact={true}
                    component={AgregarNuevaActividad}
                  />
                )}
                {client?.access?.crear_codigo && (
                  <Route
                    path="/registrar-y-editar/asignar-codigo"
                    exact={true}
                    component={AsignarCodigo}
                  />
                )}
                {client?.access?.agregar_cliente_SAML && (
                  <Route
                    path="/registrar-y-editar/agregar-nuevo-cliente-saml"
                    exact={true}
                    component={AgregarNuevoClienteSAML}
                  />
                )}
                {(client?.access?.crear_cliente_individual ||
                  client?.access?.crear_cliente_demo) && (
                    <Route
                      path="/registrar-y-editar/agregar-cliente-individual"
                      exact={true}
                    >
                      <AgregarNuevoCliente
                        type="Individual"
                        urlApi={GENERAL_URL_API + "/clientes/crearCliente.php"}
                      />
                    </Route>
                  )}
                {client?.access?.crear_cliente_instituto && (
                  <Route
                    path="/registrar-y-editar/agregar-cliente-institucional"
                    exact={true}
                  >
                    <AgregarNuevoCliente
                      type="Instituto"
                      urlApi={GENERAL_URL_API + "/clientes/crearCliente.php"}
                    />
                  </Route>
                )}

                <Route path="/registrar-y-editar/filas-home" exact={true}>
                  <FilasHome />
                </Route>

                {client?.access?.crear_cliente_instituto && (
                  <Route
                    path="/registrar-y-editar/crear-instituto"
                    exact={true}
                  >
                    <CrearInstitutoClassics />
                  </Route>
                )}

                {client?.access?.crear_cliente_instituto && (
                  <Route path="/registrar-y-editar/crear-sede" exact={true}>
                    <CrearSedeClassics />
                  </Route>
                )}

                {/* TODO: Cambiar permiso de eventos cuando sea creado */}
                {client?.access?.crear_cliente_instituto && (
                  <Route path="/registrar-y-editar/crear-evento" exact={true}>
                    <CrearEvento />
                  </Route>
                )}

                {/* TODO: Cambiar permiso de recursos cuando sea creado */}
                {client?.access?.crear_cliente_instituto && (
                  <Route path="/registrar-y-editar/crear-recurso" exact={true}>
                    <CrearRecurso />
                  </Route>
                )}

                {/* TODO: Cambiar permiso de notibuzon cuando sea creado */}
                {client?.access?.crear_cliente_instituto && (
                  <Route path="/registrar-y-editar/crear-notibuzon" exact={true}>
                    <CrearNotibuzon />
                  </Route>
                )}

                {/* TODO: Cambiar permiso de NOTIBANNER cuando sea creado */}
                {client?.access?.crear_cliente_instituto && (
                  <Route path="/registrar-y-editar/crear-notibanner" exact={true}>
                    <CrearNotibanner />
                  </Route>
                )}

                {client?.access?.editar_actividad && (
                  <Route
                    path="/registrar-y-editar/editar-actividad"
                    exact={true}
                  >
                    <EditarActividad />
                  </Route>
                )}
                {client?.access?.editar_coleccion_arte && (
                  <Route
                    path="/registrar-y-editar/editar-arte-coleccion"
                    exact={true}
                  >
                    <EditarArteColeccion />
                  </Route>
                )}
                {client?.access?.editar_arte_editorial && (
                  <Route
                    path="/registrar-y-editar/editar-arte-editorial"
                    exact={true}
                  >
                    <EditarArteEditorial />
                  </Route>
                )}

                <Route
                  path="/registrar-y-editar/agregar-editar-tags"
                  exact={true}
                >
                  <AgregarEditarTags />
                </Route>

                {(client?.access?.editar_cliente_individual ||
                  client?.access?.editar_cliente_demo) && (
                    <Route
                      path="/registrar-y-editar/editar-cliente-individual-existente"
                      exact={true}
                    >
                      <EditarClienteExistente
                        type="Individual"
                        urlApiGet={GENERAL_URL_API + "/verDatos/verClientes.php"}
                      />
                    </Route>
                  )}
                {client?.access?.editar_cliente_instituto && (
                  <Route
                    path="/registrar-y-editar/editar-cliente-institucional-existente"
                    exact={true}
                  >
                    <EditarClienteExistente
                      type="Instituto"
                      urlApiGet={GENERAL_URL_API + "/verDatos/verClientes.php"}
                    />
                  </Route>
                )}

                {client?.access?.editar_cliente_instituto && (
                  <Route path="/registrar-y-editar/editar-sede" exact={true}>
                    <EditarSedeClassics />
                  </Route>
                )}

                {/* TODO: Cambiar permiso de eventos cuando sea creado*/}
                {client?.access?.editar_cliente_instituto && (
                  <Route path="/registrar-y-editar/editar-evento" exact={true}>
                    <EditarEvento />
                  </Route>
                )}

                {/* TODO: Cambiar permiso de recursos cuando sea creado*/}
                {client?.access?.editar_cliente_instituto && (
                  <Route path="/registrar-y-editar/editar-recurso" exact={true}>
                    <EditarRecurso />
                  </Route>
                )}

                {/* TODO: Cambiar permiso de Notibuzón cuando sea creado*/}
                {client?.access?.editar_cliente_instituto && (
                  <Route path="/registrar-y-editar/editar-notibuzon" exact={true}>
                    <EditarNotibuzon />
                  </Route>
                )}

                {/* TODO: Cambiar permiso de NOTIBANNER cuando sea creado*/}
                {client?.access?.editar_cliente_instituto && (
                  <Route path="/registrar-y-editar/editar-notibanner" exact={true}>
                    <EditarNotibanner />
                  </Route>
                )}

                {client?.access?.editar_cliente_instituto && (
                  <Route
                    path="/registrar-y-editar/editar-instituto"
                    exact={true}
                  >
                    <EditarInstitutoClassics />
                  </Route>
                )}

                {client?.access?.editar_cliente_instituto && (
                  <Route
                    path="/registrar-y-editar/editar-usuario-admin"
                    exact={true}
                  >
                    <EditarUsuarioAdministrativo />
                  </Route>
                )}
                {client?.access?.editar_libro_contenido &&
                  client?.access?.editar_libro_metadatos && (
                    <Route
                      path="/registrar-y-editar/editar-libro"
                      exact={true}
                      component={editarLibro}
                    />
                  )}
                {client?.access?.editar_cliente_SAML && (
                  <Route
                    path="/registrar-y-editar/editar-cliente-saml"
                    exact={true}
                    component={editarClienteSaml}
                  />
                )}
                {client?.access?.eliminar_cliente_individual && (
                  <Route
                    path="/registrar-y-editar/eliminar-cliente-individual"
                    exact={true}
                  >
                    <EliminarCliente
                      type="Individual"
                      urlApiGet={GENERAL_URL_API + "/verDatos/verClientes.php"}
                      urlApiDel={
                        GENERAL_URL_API + "/clientes/eliminarCliente.php"
                      }
                    />
                  </Route>
                )}
                {client?.access?.eliminar_cliente_instituto && (
                  <Route
                    path="/registrar-y-editar/eliminar-cliente-institucional"
                    exact={true}
                  >
                    <EliminarCliente
                      type="Instituto"
                      urlApiGet={GENERAL_URL_API + "/verDatos/verClientes.php"}
                      urlApiDel={
                        GENERAL_URL_API + "/clientes/eliminarCliente.php"
                      }
                    />
                  </Route>
                )}
                {client?.access?.editar_cliente_SAML && (
                  <Route
                    path="/registrar-y-editar/eliminar-cliente-saml"
                    exact={true}
                  >
                    <EliminarCliente
                      type="saml"
                      urlApiGet="https://jsonplaceholder.typicode.com/posts/"
                      urlApiDel="https://jsonplaceholder.typicode.com/todos/1"
                    />
                  </Route>
                )}
                {client?.access?.eliminar_paquete_libros && (
                  <Route
                    path="/registrar-y-editar/eliminar-paquete-libros"
                    exact={true}
                  >
                    <EliminarGeneral
                      type="paquete de libros"
                      urlApiGet={GENERAL_URL_API + "/paquetes/verPaquetes.php"}
                      urlApiDel={
                        GENERAL_URL_API + "/paquetes/eliminarPaquete.php"
                      }
                    />
                  </Route>
                )}
                {(client?.access?.CRUD_usuario_dios ||
                  client?.access?.CRUD_usuario_editorial ||
                  client?.access?.CRUD_usuario_vendedor) && (
                    <Route
                      path="/registrar-y-editar/eliminar-usuario-admin"
                      exact={true}
                    >
                      <EliminarGeneral
                        type="usuario administrativo"
                        urlApiGet={GENERAL_URL_API + "/userAdmin/getUsers.php"}
                        urlApiDel={GENERAL_URL_API + "/userAdmin/deleteUser.php"}
                      />
                    </Route>
                  )}
                {client?.access?.eliminar_libro_contenido && (
                  <Route path="/registrar-y-editar/eliminar-libro" exact={true}>
                    <EliminarGeneral
                      type="libro"
                      urlApiGet={GENERAL_URL_API + "/verDatos/verLibros.php"}
                      urlApiDel={GENERAL_URL_API + "/libros/eliminarLibro.php"}
                    />
                  </Route>
                )}
                {client?.access?.eliminar_actividad && (
                  <Route
                    path="/registrar-y-editar/eliminar-actividad"
                    exact={true}
                  >
                    <EliminarGeneral
                      type="actividad"
                      urlApiGet={
                        GENERAL_URL_API + "/verDatos/verActividades.php"
                      }
                      urlApiDel={
                        GENERAL_URL_API + "/actividades/eliminarActividad.php"
                      }
                    />
                  </Route>
                )}
                {client?.access?.eliminar_coleccion && (
                  <Route
                    path="/registrar-y-editar/eliminar-coleccion"
                    exact={true}
                  >
                    <EliminarGeneral
                      type="colección"
                      urlApiGet={
                        GENERAL_URL_API + "/coleccion/verColecciones.php"
                      }
                      urlApiDel={
                        GENERAL_URL_API + "/coleccion/eliminarColeccion.php"
                      }
                    />
                  </Route>
                )}
                {client?.access?.eliminar_SAML && (
                  <Route path="/registrar-y-editar/eliminar-saml" exact={true}>
                    <EliminarGeneral
                      type="cliente SAML"
                      urlApiGet={
                        GENERAL_URL_API + "/verDatos/verConexionesSAML.php"
                      }
                      urlApiDel={
                        GENERAL_URL_API + "/clientes/eliminarConexionSAML.php"
                      }
                    />
                  </Route>
                )}

                {client?.access?.eliminar_cliente_instituto && (
                  <Route
                    path="/registrar-y-editar/eliminar-instituto"
                    exact={true}
                  >
                    <EliminarInstitutoClassics />
                  </Route>
                )}

                {client?.access?.eliminar_cliente_instituto && (
                  <Route path="/registrar-y-editar/eliminar-sede" exact={true}>
                    <EliminarSedeClassics />
                  </Route>
                )}

                {/* TODO: Cambiar por el access de eventos cuando sea creado */}
                {client?.access?.eliminar_cliente_instituto && (
                  <Route path="/registrar-y-editar/eliminar-evento" exact={true}>
                    <EliminarEvento />
                  </Route>
                )}

                {/* TODO: Cambiar por el access de recursos cuando sea creado */}
                {client?.access?.eliminar_cliente_instituto && (
                  <Route path="/registrar-y-editar/eliminar-recurso" exact={true}>
                    <EliminarRecurso />
                  </Route>
                )}

                {/* TODO: Cambiar por el access de NOTIBUZON cuando sea creado */}
                {client?.access?.eliminar_cliente_instituto && (
                  <Route path="/registrar-y-editar/eliminar-notibuzon" exact={true}>
                    <EliminarNotibuzon />
                  </Route>
                )}

                {/* TODO: Cambiar por el access de NOTIBANNER cuando sea creado */}
                {client?.access?.eliminar_cliente_instituto && (
                  <Route path="/registrar-y-editar/eliminar-notibanner" exact={true}>
                    <EliminarNotibanner />
                  </Route>
                )}

                {/*Home y otros*/}
                {client?.access?.editar_banners && (
                  <Route
                    path="/home-y-otros/cambiar-los-banners"
                    exact={true}
                    component={CambiarLosBanners}
                  />
                )}
                <Route
                  path="/home-y-otros/generador-contrasenas"
                  exact={true}
                  component={PasswordGenerator}
                />
                {/*Manejo del Blog*/}
                {client?.access?.crear_entrada_blog && (
                  <Route
                    path="/blog/crear-entrada-blog"
                    exact={true}
                    component={CrearEntradaBlog}
                  />
                )}
                {client?.access?.editar_entrada_blog && (
                  <Route
                    path="/blog/editar-entrada-blog"
                    exact={true}
                    component={EditarEntradaBlog}
                  />
                )}
                {client?.access?.eliminar_entrada_blog && (
                  <Route path="/blog/eliminar-entrada-blog" exact={true}>
                    <EliminarGeneral
                      type="blog"
                      urlApiGet={GENERAL_URL_API + "/blog/verBlogs.php"}
                      urlApiDel={GENERAL_URL_API + "/blog/eliminarEntrada.php"}
                    />
                  </Route>
                )}
                {/*Plan Lector*/}
                {/* {process.env.REACT_APP_API ===
                  "https://nf.api.makemake.reallabs.com.co/admin" && (
                  <> */}
                {client?.access?.reporte_acceso_estudiantes && (
                  <Route
                    path="/plan-lector/estadisticas-acceso"
                    exact={true}
                    component={EstadisticasAccesoInstitutoPL}
                  />
                )}
                {client?.access?.reporte_lecturas && (
                  <Route
                    path="/plan-lector/estadisticas-generales"
                    exact={true}
                    component={EstadisticasGlobalesAccesoPL}
                  />
                )}
                {/* VER DATOS */}
                {client?.access?.ver_institutos_PL && (
                  <Route
                    path="/plan-lector/lista-institutos"
                    exact={true}
                    component={ListaInstitutos}
                  />
                )}
                {client?.access?.ver_sedes && (
                  <Route
                    path="/plan-lector/lista-sedes"
                    exact={true}
                    component={ListaSedes}
                  />
                )}
                {client?.access?.ver_cursos && (
                  <Route
                    path="/plan-lector/lista-cursos"
                    exact={true}
                    component={ListaCursos}
                  />
                )}
                {client?.access?.ver_profesores && (
                  <Route
                    path="/plan-lector/lista-profesores"
                    exact={true}
                    component={ListaProfesores}
                  />
                )}
                {client?.access?.ver_estudiantes && (
                  <Route
                    path="/plan-lector/lista-estudiantes"
                    exact={true}
                    component={ListaEstudiantes}
                  />
                )}
                {client?.access?.reporte_lecturas && (
                  <Route
                    path="/plan-lector/reporte-lecturas-pl"
                    exact={true}
                    component={ReporteLecturasPlanLector}
                  />
                )}
                {client?.access?.reporte_acceso_estudiantes && (
                  <Route
                    path="/plan-lector/reporte-acceso-estudiantes"
                    exact={true}
                    component={ReporteAccesoEstudiantes}
                  />
                )}
                {/* <Route
                  path="/plan-lector/reporte-acceso-profesores"
                  exact={true}
                  component={ReporteAccesoProfesores}
                /> */}
                {/* ADICIONAR ELEMENTOS */}
                {client?.access?.agregar_instituto_PL && (
                  <Route
                    path="/plan-lector/agregar-nuevo-instituto"
                    exact={true}
                    component={AgregarNuevoInstituto}
                  />
                )}
                {client?.access?.agregar_sede && (
                  <Route
                    path="/plan-lector/agregar-nueva-sede"
                    exact={true}
                    component={AgregarNuevaSede}
                  />
                )}
                {client?.access?.agregar_curso && (
                  <Route
                    path="/plan-lector/agregar-nuevo-curso"
                    exact={true}
                    component={AgregarNuevoCurso}
                  />
                )}
                {client?.access?.agregar_curso && (
                  <Route
                    path="/plan-lector/asignar-cursos"
                    exact={true}
                    component={AsignarCursos}
                  />
                )}
                {client?.access?.agregar_estudiante && (
                  <Route
                    path="/plan-lector/agregar-nuevo-estudiante"
                    exact={true}
                    component={AgregarNuevoEstudiante}
                  />
                )}
                {client?.access?.agregar_profesor && (
                  <Route
                    path="/plan-lector/agregar-nuevo-profesor"
                    exact={true}
                    component={AgregarNuevoProfesor}
                  />
                )}
                {client?.access?.agregar_instituto_PL && (
                  <Route
                    path="/plan-lector/agregar-eliminar-paquete-instituto-pl"
                    exact={true}
                  >
                    <AgregarEliminarPaquetePlanLector type="instituto" />
                  </Route>
                )}
                {client?.access?.agregar_sede && (
                  <Route
                    path="/plan-lector/agregar-eliminar-paquete-sede-pl"
                    exact={true}
                  >
                    <AgregarEliminarPaquetePlanLector type="sede" />
                  </Route>
                )}
                {client?.access?.habilitar_paquete_PL && (
                  <Route
                    path="/plan-lector/habilitar-paquete-pl"
                    exact={true}
                    component={HabilitarPaqueteCurso}
                  />
                )}
                {client?.access?.escoger_libros_PL && (
                  <Route
                    path="/plan-lector/autorizar-paquete-pl"
                    exact={true}
                    component={AutorizarPaquetePL}
                  />
                )}
                {client?.access?.escoger_libros_PL && (
                  <Route
                    path="/plan-lector/agregar-eliminar-libros-curso"
                    exact={true}
                    component={AgregarEliminarLibrosCursoPlanLector}
                  />
                )}
                {/* EDITAR ELEMENTOS */}
                {client?.access?.editar_instituto_PL && (
                  <Route
                    path="/plan-lector/editar-un-instituto"
                    exact={true}
                    component={EditarUnInstituto}
                  />
                )}
                {client?.access?.editar_estudiante && (
                  <Route
                    path="/plan-lector/editar-un-estudiante"
                    exact={true}
                    component={EditarEstudiantePL}
                  />
                )}
                {client?.access?.editar_sede && (
                  <Route
                    path="/plan-lector/editar-una-sede"
                    exact={true}
                    component={EditarSedePL}
                  />
                )}
                {client?.access?.editar_curso && (
                  <Route
                    path="/plan-lector/editar-un-curso"
                    exact={true}
                    component={EditarCursoPL}
                  />
                )}
                {client?.access?.editar_profesor && (
                  <Route
                    path="/plan-lector/editar-un-profesor"
                    exact={true}
                    component={EditarProfesorPL}
                  />
                )}
                {/* BORRAR ELEMENTOS */}
                {client?.access?.eliminar_estudiante && (
                  <Route
                    path="/plan-lector/eliminar-un-estudiante"
                    exact={true}
                    component={EliminarUnEstudiante}
                  />
                )}
                {client?.access?.eliminar_profesor && (
                  <Route
                    path="/plan-lector/eliminar-un-profesor"
                    exact={true}
                    component={EliminarUnProfesor}
                  />
                )}
                {client?.access?.eliminar_curso && (
                  <Route
                    path="/plan-lector/eliminar-un-curso"
                    exact={true}
                    component={EliminarUnCurso}
                  />
                )}
                {client?.access?.eliminar_instituto_PL && (
                  <Route
                    path="/plan-lector/eliminar-un-instituto"
                    exact={true}
                    component={EliminarUnInstituto}
                  />
                )}
                {client?.access?.eliminar_sede && (
                  <Route
                    path="/plan-lector/eliminar-una-sede"
                    exact={true}
                    component={EliminarUnaSede}
                  />
                )}
                {/*Formacion*/}
                <Route
                  path="/formacion/lista-usuarios-academia"
                  exact={true}
                  component={ListaUsuariosAcademia}
                />
                <Route
                  path="/formacion/lista-cursos-academia"
                  exact={true}
                  component={ListaCursosAcademia}
                />
                <Route
                  path="/formacion/reporte-usuarios-academia"
                  exact={true}
                  component={ReporteUsuariosAcademia}
                />
                <Route
                  path="/formacion/crear-usuario-academia"
                  exact={true}
                  component={CrearUsuarioAcademia}
                />
                {toRouteComponent(RoutesUsuariosAcademia)}

                <Route
                  path="/formacion/asignar-curso-usuario"
                  exact={true}
                  component={AsignarCursoUsuario}
                />
                <Route
                  path="/formacion/borrar-usuario-academia"
                  exact={true}
                  component={EliminarUsuarioAcademia}
                />

                {/* </>
                )} */}
                {/*Nuevas Regalias*/}
                {client?.access?.ver_regalias && (
                  <Route
                    path="/nuevas-regalias/lista-cortes-regalia"
                    exact={true}
                    component={ListaCortesRegalias}
                  />
                )}
                {client?.access?.crear_regalias && (
                  <Route
                    path="/nuevas-regalias/crear-nuevo-corte"
                    exact={true}
                    component={CrearNuevoCorte}
                  />
                )}
                {
                  <Route
                    path="/root/armagedon"
                    exact={true}
                    component={Armagedon}
                  />
                }

                {/*FORMULARIOS*/}
                {toRouteComponent(RoutesFormularios)}
                <Redirect to="/" />
              </Switch>
            </div>
            <footer>
              <hr />
              <div className="px-4 footer-text pb-3">
                © 2021. All rights reserved.
              </div>
            </footer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminDashboad;
