export const columnasFormularios = [
  {
    label: "Id Formulario Evento",
    field: "idFormularioEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Id Evento",
    field: "idEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Nombre Evento",
    field: "nombreEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Número de inscritos",
    field: "NumInscritos",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Límite de inscritos",
    field: "limiteInscritos",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Estado",
    field: "estado",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Enlace del evento",
    field: "linkEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Enlace del formulario",
    field: "linkFormulario",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Color Fondo",
    field: "colorFondo",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Color Fuente",
    field: "colorFuente",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Imagen",
    field: "imagen",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Mensaje Inscripción",
    field: "mensajeInscripcion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Mensaje Recordatorio",
    field: "mensajeRecordatorio",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
