import React, { useContext, createContext } from "react";

// context
const ClientContext = createContext();

// hook
export const useClient = () => useContext(ClientContext);

const ClientProvider = ({ children }) => {
  const updateClient = (c) => {
    localStorage.setItem("client", JSON.stringify(c));
  };

  const getClient = () => JSON.parse(localStorage.getItem("client"));

  return (
    <ClientContext.Provider
      value={{
        getClient,
        updateClient,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;
