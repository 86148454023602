import React, { useState, useEffect } from "react";
// components
import { MDBDataTableV5 } from "mdbreact";
import swal from "sweetalert";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

// functions
import exportFromJSON from "export-from-json";
// data
import { columnasAutorizarPaqueteCliente } from "../../../../shared/data/columnas";
import { GENERAL_URL_API } from "../../../../shared/urls";
import ColorIconButton from "../../../../components/shared/custom-green-icon-button";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import { Cached } from "@material-ui/icons";

const ClientsPackagesTable = ({ packets, packet, setPacket }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(columnasAutorizarPaqueteCliente);
  }, []);

  useEffect(() => {
    fetchBooksFromPacket(packet.value);
  }, [packet]);

  const fetchBooksFromPacket = async (packetName) => {
    const fd = new FormData();
     
    fd.append("nombrePaquete", packetName);
    fd.append("estado", "habilitado");

    const res = await fetch(`${GENERAL_URL_API}/paquetes/librosEnPaquete.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo clientes.",
        text: data.info,
        icon: "error",
      });
    const rs = [...data.data].map((r) => ({ ...r, paquete: packetName }));
    setRows(rs);
  };

  return (
    <>
      {!packet.value ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <>
          <div className="mt-3 admin-muted-text">Paquete: {packet.value}.</div>
          <div className="d-flex align-items-center my-3">
            <ColorIconButton
              size="medium"
              onClick={() => fetchBooksFromPacket(packet.value)}
            >
              <Cached style={{ fontSize: "20px" }} />
            </ColorIconButton>
            <div className="pl-3 admin-muted-text">Refrescar la tabla.</div>
          </div>
          <ButtonGroup size="small" variant="contained" className="my-3">
            <CustomGreenButton
              onClick={() => {
                const dat = [...rows];
                navigator.clipboard
                  .writeText(JSON.stringify(dat))
                  .then(async () => {
                    await swal({
                      title: "Se ha copiado la tabla!",
                      icon: "success",
                    });
                  });
              }}
            >
              Copy
            </CustomGreenButton>
            <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-clientes",
                  exportType: exportFromJSON.types.csv,
                });
              }}
            >
              CSV
            </CustomGreenButton>
            <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-clientes",
                  exportType: exportFromJSON.types.json,
                });
              }}
            >
              JSON
            </CustomGreenButton>
            {/* <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-clientes",
                  exportType: exportFromJSON.types.xls,
                });
              }}
            >
              EXCEL
            </CustomGreenButton> */}

            <CustomGreenButton>
              <ExcelWorkBook
                columns={columns}
                rows={rows}
                filename={"lista-clientes"}
              />
            </CustomGreenButton>
          </ButtonGroup>
          <MDBDataTableV5
            small
            responsive
            striped
            bordered
            searchTop
            searchBottom={false}
            barReverse
            pagingTop
            data={{
              columns: columns.filter((obj) => obj.isVisible),
              rows: rows,
            }}
            noBottomColumns
            order={["username", "asc"]}
            entriesOptions={[5, 10, 20, 50]}
            entriesLabel="Numero de filas"
            searchLabel="Buscar"
            paginationLabel={["Anterior", "Siguiente"]}
            infoLabel={["Mostrando filas", "a", "de", "filas"]}
            fullPagination
          />
        </>
      )}
    </>
  );
};

export default ClientsPackagesTable;
