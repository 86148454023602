import React, { useState, useEffect } from "react";

import "./agregar-nueva-actividad.css";
import TvIcon from "@material-ui/icons/Tv";
import { Container, Row, Col } from "react-bootstrap";
import HabilitarPaqueteClienteForm from "./habilitar-paquete-cliente-form";
import { GENERAL_URL_API } from "../../../shared/urls";
import ClientsPacketsTable from "./clients-packets-table";
import { ShoppingBasket } from "@material-ui/icons";
import swal from "sweetalert";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

const HabilitarPaqueteCliente = () => {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    fetchClientes();
  }, []);

  const fetchClientes = async () => {
    const fd = new FormData();
     
    fd.append("show", "active");
    fd.append("tipoUsuario", "Todos");

    const res = await fetch(`${GENERAL_URL_API}/verDatos/verClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo clientes.",
        text: data.info,
        icon: "error",
      });
    setClientes(data.data);
  };

  return (
    <div>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o eliminar un paquete a un cliente
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <ShoppingBasket style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                HABILITAR UN PAQUETE A UN CLIENTE
              </span>
            </div>
            <HabilitarPaqueteClienteForm clientes={clientes} />
          </Col>
          <Col xs={12} className="content-white-box px-4 mt-1 py-4">
            <div className="d-flex align-items-center">
              <TvIcon style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                HABILITAR UN PAQUETE A UN CLIENTE
              </span>
            </div>
            <ClientsPacketsTable estado="habilitado" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HabilitarPaqueteCliente;
