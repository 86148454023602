import React, { useState, useEffect } from "react";

// Components
import { Container, Row, Col } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
import { GENERAL_URL_API } from "../../../../shared/urls";
import {
  CreateOutlined,
  CloseRounded,
  CheckRounded,
  ShoppingBasketRounded,
  RedoOutlined,
} from "@material-ui/icons";

import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";

const AgregarConexionOAuth = () => {
  const [user, setUser] = useState("");
  const [instituto, setInstituto] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const fd = new FormData();
     
    fd.append("show", "all");
    fd.append("tipoUsuario", "Instituto");
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setUsers(data.data);
  };

  const handleChangeUser = (event, value) => {
    setUser(value);
    if (value) setInstituto(value?.institute);
    else setInstituto("");
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    let url = `${GENERAL_URL_API}/clientes/crearConexionOauth`;
    // send request
    setLoading(true);
    const fd = new FormData();
     
    fd.append("user", user?.username);
    fd.append("instituto", instituto);
    fd.append("accion", type ?? "Agregar");

    fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    })
      .then(async (res) => {
        const data = await res.json();
        if (`${data.status}` === "0") {
          await swal({
            title: "Error.",
            text: data?.info,
            icon: "error",
          });
        } else {
          await swal({
            title: "Correcto.",
            text: data?.info,
            icon: "success",
          });
        }
      })
      .catch(async (error) => {
        await swal({
          title: "Error.",
          text: error.message,
          icon: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlined />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o eliminar conexión OAuth
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <ShoppingBasketRounded style={{ fontSize: "20px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar/Eliminar conexión OAuth
              </div>
            </div>

            {users.length === 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <form>
                <label className="admin-label-text mt-3 d-block">
                  Username
                </label>
                <Autocomplete
                  options={users}
                  getOptionLabel={(option) => option.username}
                  onChange={handleChangeUser}
                  noOptionsText="Sin resultados"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  )}
                />

                <label className="admin-label-text mt-3 d-block">
                  Instituto
                </label>
                <TextField
                  fullWidth
                  multiline={true}
                  variant="outlined"
                  size="small"
                  type="text"
                  value={instituto}
                  disabled={!user}
                  onChange={(e) => setInstituto(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <Tooltip title="Reestablecer Instituto">
                          <IconButton
                            aria-label="toggle password visibility"
                            disabled={!user}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              if (user && instituto !== user.institute)
                                setInstituto(user ? user.institute : "");
                            }}
                            edge="end"
                          >
                            <RedoOutlined />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="d-flex justify-content-end mt-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleSubmit(e, "Agregar")}
                    startIcon={<CheckRounded />}
                    disabled={!instituto || loading}
                    style={{ background: "#81c868" }}
                  >
                    Agregar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-2"
                    onClick={(e) => handleSubmit(e, "Borrar")}
                    startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                    disabled={!instituto || loading}
                    style={{ background: "#f05050" }}
                  >
                    Eliminar
                  </Button>
                </div>
              </form>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarConexionOAuth;
