import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { TextField } from "@material-ui/core";
import { fetchUsuariosAcademia } from "../../../../shared/fetchers/academia/verUsuarios";
import CrearUsuarioAcademiaIndividual from "../../adicionar-elementos/crear-usuario-academia/crear-usuario-individual";
import Loading from "../../../../components/shared/loading";
import Error from "../../../../components/shared/error";

const EditarUsuarioAcademia = () => {
  const [user, setUser] = useState(null);

  const {
    data: users,
    isLoading,
    isError,
  } = useQuery(["usuariosAcademia"], fetchUsuariosAcademia);

  if (isLoading)
    return <Loading />;

  if (isError)
    return <Error />;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Editar un usuario</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <label className="admin-label-text mt-3 d-block">
              Selecciona un usuario para editarlo
            </label>
            <Autocomplete
              options={users}
              value={user}
              disabled={Boolean(user)}
              getOptionLabel={(user) =>
                `${user.usernameAcademia} - ${user.nombre}`
              }
              onChange={(_, newValue) => {
                setUser(newValue);
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />
          </Col>

          {user && (
            <CrearUsuarioAcademiaIndividual
              user={{ ...user }}
              clearEdit={() => {
                setUser(null);
              }}
            />
          )}
        </Row>
      </Container>
    </>
  );
};

export default EditarUsuarioAcademia;
