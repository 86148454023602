import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { DropzoneArea } from "material-ui-dropzone";
import { Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { CheckRounded } from "@material-ui/icons";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import CustomGreenButton from "../custom-green-button";

/**
 * Componente para la carga masiva de archivos con plantilla y función de carga.
 * Se debe agregar dentro de una columna
 *
 * @component
 * @param {{
 * load: (file: File) => Promise<{
 * status: Boolean,
 * info: String,
 * }>,
 * title: String,
 * template: String
 * }} props.
 * @returns {JSX.Element} Componente de carga masiva.
 */
const CargaMasiva = ({ load, title, template }) => {
  const [Files, setFiles] = useState("");
  const [loading, setLoading] = useState(false);

  const add = async () => {
    setLoading(true);

    const res = await load(Files[0]);

    if (!res.status) {
      await swal({
        title: "Error",
        text: res.info,
        icon: "error",
      });
    } else {
      await swal({
        title: "Éxito",
        text: res.info,
        icon: "success",
      });
    }

    setLoading(false);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">{title}</div>
      </div>
      <div className="my-3">
        <Link to={template} target="_blank" download>
          <CustomGreenButton size="small" variant="contained">
            Descargar plantilla
          </CustomGreenButton>
        </Link>
      </div>
      <form>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          showAlerts={true}
          disabled={loading}
          filesLimit={1}
          acceptedFiles={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          onChange={(files) => setFiles(files)}
        />

        <div className="mt-3">
          <Button
            variant="contained"
            color="primary"
            disabled={!Files.length || loading}
            startIcon={<CheckRounded style={{ fontSize: "25px" }} />}
            onClick={add}
            style={{ background: "#81c868" }}
          >
            Agregar
          </Button>
        </div>
      </form>
    </>
  );
};

export default CargaMasiva;
