import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";

import TvIcon from "@material-ui/icons/Tv";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { CheckRounded, CloseRounded } from "@material-ui/icons";
import { fetchNotibuzones } from "../../../shared/fetchers/classics/notibuzon/verNotibuzon";
import { eliminarNotibuzon } from "../../../shared/fetchers/classics/notibuzon/eliminarNotibuzon";

const EliminarNotibuzon = () => {
  const [notibuzon, setNotibuzon] = useState(null);

  const {
    data: notibuzones,
    isLoading,
    isError,
  } = useQuery(["notibuzones"], fetchNotibuzones);

  const queryClient = useQueryClient();

  if (isLoading)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isError) return <div>Ha ocurrido un error</div>;

  const SubmitForm = async (e) => {
    e.preventDefault();

    const check = await swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este notibuzon",
      icon: "warning",
      buttons: ["Cancelar", "Eliminar"],
      dangerMode: true,
    });

    if (!notibuzon || !check) return toast.success("Operación cancelada");

    const { status } = await eliminarNotibuzon(notibuzon?.idNotibuzon ?? "");

    setNotibuzon(null);

    queryClient.invalidateQueries({
      queryKey: ["notibuzones"],
    });

    if (!status)
      return toast.error(
        "Ha ocurrido un error eliminando el notibuzon, intenta de nuevo",
      );

    toast.success("Notibuzon eliminado correctamente");
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Eliminar un Notibuzón</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <form>
              <label className="admin-label-text mt-3 d-block">
                Selecciona un Notibuzón:
              </label>
              <Autocomplete
                options={notibuzones}
                value={notibuzon}
                getOptionLabel={(option) =>
                  `${option.idNotibuzon} - ${option?.metadatos?.titulo.es ?? "SIN TITULO"}`
                }
                onChange={(_, newValue) => {
                  setNotibuzon(newValue);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!notibuzon}
                  type="submit"
                  onClick={(e) => SubmitForm(e)}
                  startIcon={<CheckRounded />}
                  style={{ background: "#f05050" }}
                >
                  Eliminar
                </Button>
                <Button
                  variant="contained"
                  disabled={!notibuzon}
                  color="primary"
                  onClick={() => {
                    setNotibuzon(null);
                  }}
                  className="ml-2"
                  startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                  style={{ background: "#81c868" }}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EliminarNotibuzon;
