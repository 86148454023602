import React, { useState, useEffect } from "react";

import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TableColumnDropdown from "../../../../components/shared/table-column-dropdown/table-column-dropdown";

import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import exportFromJSON from "export-from-json";
import { columnasInstitutosClassics } from "../../../../shared/data/columnas/classics/institutos/institutos";
import { useQuery } from "@tanstack/react-query";
import { fetchSedes } from "../../../../shared/fetchers/classics/institutos/verSedes";
import { Skeleton } from "@material-ui/lab";
import { columnasSedesClassics } from "../../../../shared/data/columnas/classics/sedes/sedes";

const TablaSedes = ({ instituto }) => {
  const {
    data: sedes,
    isLoading,
    isError,
  } = useQuery(["sedesClassics", instituto], () => fetchSedes(instituto));

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let columnas = columnasSedesClassics;
    //Añadimos un atributo de visible a todas las columnas
    columnas.map((columna) => (columna.isVisible = true));
    setColumns(columnas);
  }, []);

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  if (isLoading || !instituto)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isError) return <div>Ha ocurrido un error</div>;

  return (
    <>
      <div className="d-flex align-items-center">
        <PersonIcon />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          Tabla de sedes
        </div>
      </div>
      <div className="admin-muted-text-small mt-1 mb-4">
        Lista de todas las sedes.
      </div>

      {/* Este componente es el dropdown que permite ocultar columnas*/}
      <TableColumnDropdown
        columns={columns}
        changeColumnStatus={changeColumnStatus}
      />
      <br />
      <ButtonGroup size="small" variant="contained" className="my-3">
        <CustomGreenButton
          onClick={() => {
            const dat = [...sedes];
            navigator.clipboard
              .writeText(JSON.stringify(dat))
              .then(async () => {
                await swal({
                  title: "Se ha copiado la tabla!",
                  icon: "success",
                });
              });
          }}
        >
          Copy
        </CustomGreenButton>

        <CustomGreenButton>
          <ExcelWorkBook
            columns={columns}
            rows={sedes}
            filename={"Lista-institutos-classics"}
          />
        </CustomGreenButton>

        <CustomGreenButton
          onClick={() => {
            exportFromJSON({
              data: sedes,
              fileName: "Lista-institutos-classics",
              exportType: exportFromJSON.types.csv,
            });
          }}
        >
          CSV
        </CustomGreenButton>
      </ButtonGroup>
      <MDBDataTable
        small
        responsive
        searchTop
        searchBottom={false}
        barReverse
        pagingTop
        striped
        bordered
        data={{
          columns: columns.filter((obj) => obj.isVisible),
          rows: sedes,
        }}
        noBottomColumns
        order={["name", "asc"]}
        entriesOptions={[5, 10, 20, 50]}
        entriesLabel="Numero de filas"
        searchLabel="Buscar"
        paginationLabel={["Anterior", "Siguiente"]}
        infoLabel={["Mostrando filas", "a", "de", "filas"]}
      />
    </>
  );
};

export default TablaSedes;
