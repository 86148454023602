import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Creates a new recurso with provided information.
 *
 * @async
 * @param {Object} recurso - The information of the recurso.
 * @param {string} recurso.tipoRecurso - The type of the recurso.
 * @param {string} recurso.titulo - The name of the recurso.
 * @param {string} recurso.tituloEng - The name of the recurso in english.
 * @param {string} recurso.descripcion - The description of the recurso.
 * @param {string} recurso.descripcionEng - The description of the recurso in english.
 * @param {string} recurso.enlace - The link of the recurso.
 * @param {"descargar"|"explorar"|string} recurso.textoBoton - The text of the button.
 * @param {string} recurso.territorio - The territory of the recurso.
 * @param {string} recurso.imagenRecurso - The image of the recurso.
 * @param {string} recurso.fileRecurso - The file of the recurso.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
export async function crearRecurso(recurso) {
  const fd = new FormData();

  Object.entries(recurso).forEach(([key, value]) => {
    if (value) {
      fd.append(key, value);
    }
  });

  const { data } = await axios.post(
    `${GENERAL_URL_API}/recursos/crearRecurso`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return data;
}
