import React, { useState, useEffect } from "react";

import TvIcon from "@material-ui/icons/Tv";
import { Container, Row, Col } from "react-bootstrap";

import { ShoppingBasket } from "@material-ui/icons";
// import { ButtonGroup, Button } from "@material-ui/core";
import swal from "sweetalert";
import HabilitarPaquetePLForm from "./habilitar-paquete-pl-form";
import { GENERAL_URL_API } from "../../../../shared/urls";
import HabilitarCursoTable from "./habilitar-pl-table";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

const HabilitarPaqueteCurso = () => {
  const [autocompleteInfo, setAutocompleteInfo] = useState([]);
  // solo se habilita paquete por instituto
  const [type, setType] = useState("instituto");

  useEffect(() => {
    setAutocompleteInfo([]); // temporal para ver carga
    fetchAutocompleInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const fetchAutocompleInfo = async () => {
    const fd = new FormData();
     
    fd.append("show", "active");
    fd.append("tipoUsuario", "Todos");
    let url = "";
    if (type === "curso") url = `${GENERAL_URL_API}/verDatos/verClientes.php`;
    else if (type === "instituto")
      url = `${GENERAL_URL_API}/institutos/verInstitutos.php`;
    else if (type === "sede")
      url = `${GENERAL_URL_API}/verDatos/verClientes.php`;

    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo clientes.",
        text: data.info,
        icon: "error",
      });
    setAutocompleteInfo(data.data);
  };

  return (
    <div>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Habilitar paquete personalizado a un{type === "sede" && "a"}{" "}
          {type.toLowerCase()}
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <ShoppingBasket style={{ fontSize: "20px" }} />
                <span className="admin-main-title-dark-gray ml-2 mt-1">
                  HABILITAR UN PAQUETE PERSONALIZADO A UN
                  {type === "sede" && "A"} {type.toUpperCase()}
                </span>
              </div>
              {/* por si en algún momento se habilita por tipo */}
              {/* <ButtonGroup variant="text" aria-label="text button group">
                <Button
                  disabled={type === "curso"}
                  onClick={() => setType("curso")}
                >
                  CURSO
                </Button>
                <Button
                  disabled={type === "instituto"}
                  onClick={() => setType("instituto")}
                >
                  INSTITUTO
                </Button>
                <Button
                  disabled={type === "sede"}
                  onClick={() => setType("sede")}
                >
                  SEDE
                </Button>
              </ButtonGroup> */}
            </div>
            <HabilitarPaquetePLForm
              autocompleteInfo={autocompleteInfo}
              type={type}
            />
          </Col>
          <Col xs={12} className="content-white-box px-4 mt-1 py-4">
            <div className="d-flex align-items-center">
              <TvIcon style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                TABLA DE PAQUETES POR {type.toUpperCase()}
              </span>
            </div>
            <HabilitarCursoTable estado="habilitado" type={type} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HabilitarPaqueteCurso;
