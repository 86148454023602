import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
// import CustomGreenButton from "../../../components/shared/custom-green-button";
// import ImageUploadCard from "../../../components/shared/image-upload-card/image-upload-card";
// import { DropzoneArea } from "material-ui-dropzone";
import { GENERAL_URL_API } from "../../../shared/urls";
import swal from "sweetalert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import { DropzoneArea } from "material-ui-dropzone";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import { Link } from "react-router-dom";

const AgregarNuevoCurso = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  //Definimos los estaos para almacenar los datos del formulario y sus errores
  const [institutos, setInstitutos] = useState([]);
  const [cursosCM, setCursosCM] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [formData, setFormData] = useState({
    nombreCurso: { value: "", error: false },
    instituto: { value: null, error: false },
    sede: { value: null, error: false },
  });

  useEffect(() => {
    fetchInstitutes();
  }, []);

  const fetchInstitutes = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setInstitutos(data.data);
  };

  const fetchSedes = async (instituto) => {
    const fd = new FormData();
     
    fd.append("instituto", instituto);
    const res = await fetch(`${GENERAL_URL_API}/sedes/verSedes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setSedes(data.data);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const value = v || e.target.value;
    const name = n || e.target.name;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };
  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      if (formElement !== "colorHeader" && formElement !== "colorLetras") {
        temp[formElement] = { value: "", error: false };
      } else {
        temp[formElement] = { value: "#000000", error: false };
      }
    }
    setFormData(temp);
  };

  const clearErrors = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], error: false };
    }
  };

  const validate = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      if (temp[formElement].value === "") {
        temp[formElement].error = true;
        isValid = false;
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(temp);
    if (isValid) clearErrors();
    return isValid;
  };

  //Funcion para subir la informacion y validad todos los datos
  const SubmitForm = async () => {
    setLoading(true);
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    if (validate()) {
      for (const formElement in temp) {
        console.log(temp[formElement].value);
      }

      const fd = new FormData();
       
       
      fd.append("nombreCurso", formData.nombreCurso.value);
      fd.append("instituto", formData.instituto.value.nombre);
      fd.append("nombreSede", formData.sede.value.instituto);

      const url = `${GENERAL_URL_API}/Cursos/agregarCurso.php`;
      const res = await fetch(url, {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      });

      const data = await res.json();

      if (data.status)
        await swal({
          title: "Curso creado exitosamente.",
          text: data.info,
          icon: "success",
        });
      else
        await swal({
          title: "Error creando curso.",
          text: data.info,
          icon: "error",
        });
    }
    setLoading(false);
  };

  const handleDropFileChange = (profesores) => {
    setCursosCM(profesores);
  };

  const addCursos = async () => {
    setLoading(true);
    const fd = new FormData();
     
    if (cursosCM[0]) fd.append("cursos", cursosCM[0]);

    const res = await fetch(`${GENERAL_URL_API}/Cursos/agregarCursos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      await swal({
        title: "Éxito",
        text: resJSON.info,
        icon: "success",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar un nuevo curso plan lector
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                CREAR UN CURSO NUEVO
              </div>
            </div>
            <div className="admin-muted-text-small mt-2">
              Por favor ingresar la siguiente info:
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Nombre del Curso
              </label>
              <TextField
                name="nombreCurso"
                value={formData.nombreCurso.value}
                onChange={updateForm}
                error={formData.nombreCurso.error}
                helperText={
                  formData.nombreCurso.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Nombre del curso"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Instituto*
              </label>
              <Autocomplete
                fullWidth
                options={institutos}
                getOptionLabel={(option) => option.nombre}
                value={formData.instituto.value}
                onChange={(e, value) => {
                  updateForm(e, "instituto", value);
                  fetchSedes(value.nombre);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Selecciona un instituto"
                      size="small"
                      fullWidth
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                      error={formData.instituto.error}
                      helperText={
                        formData.instituto.error ? "Campo no válido" : null
                      }
                    />
                  </>
                )}
              />

              <label className="admin-label-text mt-3 d-block">Sede*</label>
              <Autocomplete
                fullWidth
                options={sedes}
                getOptionLabel={(option) => option.instituto}
                value={formData.sede.value}
                onChange={(e, value) => {
                  updateForm(e, "sede", value);
                }}
                noOptionsText="Sin resultados"
                disabled={!formData.instituto.value}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      placeholder="Selecciona una sede"
                      variant="outlined"
                      size="small"
                      fullWidth
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                      error={formData.sede.error}
                      helperText={
                        formData.sede.error ? "Campo no válido" : null
                      }
                    />
                  </>
                )}
              />

              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitForm}
                  startIcon={<CheckRounded />}
                  disabled={loading}
                  style={{ background: "#81c868" }}
                >
                  Agregar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={clearForm}
                  className="ml-2"
                  startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                  disabled={loading}
                  style={{ background: "#f05050" }}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <MenuBookIcon style={{ fontSize: "20px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar cursos (carga masiva)
              </div>
            </div>
            <div className="mt-3">
              <Link to="/templates/cursos.xlsx" target="_blank" download>
                {" "}
                <CustomGreenButton size="small" variant="contained">
                  Descargar plantilla
                </CustomGreenButton>
              </Link>
            </div>
            <div className="mt-3" />
            <form>
              <DropzoneArea
                id="dropzone"
                dropzoneText="Arrastra un archivo o haz click aquí!"
                showFileNames={true}
                // maxFileSize={} //n in bytes
                showAlerts={true}
                filesLimit={1}
                acceptedFiles={[
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "application/vnd.ms-excel",
                ]}
                onChange={(files) => handleDropFileChange(files)}
              />

              <div className="mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!(cursosCM.length > 0) || loading}
                  startIcon={<CheckRoundedIcon />}
                  classes={{ root: classes.agregar }}
                  onClick={addCursos}
                >
                  Agregar
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarNuevoCurso;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
