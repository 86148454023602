import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";

import TvIcon from "@material-ui/icons/Tv";
import PersonIcon from "@material-ui/icons/Person";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import exportFromJSON from "export-from-json";

import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { columnasRegistrosFormularios } from "../../../shared/data/columnas/classics/formularios/registros";
import { fetchRegistrosFormularios } from "../../../shared/fetchers/classics/formularios/verRegistrosFormularios";
import { fetchFormularios } from "../../../shared/fetchers/classics/formularios/verFormularios";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const ListaRegistrosFormularios = () => {
  const {
    data: registros,
    isLoading: isLoadingRegistros,
    isError: isErrorRegistros,
  } = useQuery(["registrosFormularios"], fetchRegistrosFormularios);

  const {
    data: formularios,
    isLoading: idLoadingFormularios,
    isError: isErrorFormularios,
  } = useQuery(["formularios"], fetchFormularios);

  const [formulario, setFormulario] = useState(null);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let columnas = columnasRegistrosFormularios;
    // All columns are visible by default
    columnas.map((columna) => (columna.isVisible = true));
    setColumns(columnas);
  }, []);

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  if (isLoadingRegistros || idLoadingFormularios)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isErrorRegistros || isErrorFormularios)
    return <div>Ha ocurrido un error, inténta recargando la página.</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Lista de Registros de Formularios Eventos
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de Registros de los Formularios
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Lista de todos los Registros
            </div>

            <label className="admin-label-text mt-3 d-block">
              Filtrar por formulario
            </label>
            <Autocomplete
              style={{ marginBottom: "1rem" }}
              options={formularios}
              value={formulario}
              getOptionLabel={(formulario) =>
                `${formulario.idFormularioEvento}: ${formulario?.infoEvento.nombre}`
              }
              onChange={(_, newValue) => {
                setFormulario(newValue);
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />
            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                onClick={() => {
                  const dat = !formulario ? registros : registros.filter((registro) => registro.idFormularioEvento === formulario.idFormularioEvento);
                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      toast.success("Copiado al portapapeles");
                    });
                }}
              >
                Copy
              </CustomGreenButton>

              <CustomGreenButton
                onClick={() => {
                  toast.success("Descargando archivo Excel");
                }}
              >
                <ExcelWorkBook
                  columns={columns}
                  rows={!formulario ? registros : registros.filter((registro) => registro.idFormularioEvento === formulario.idFormularioEvento)}
                  filename={"lista-registros-formularios"}
                />
              </CustomGreenButton>

              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: !formulario ? registros : registros.filter((registro) => registro.idFormularioEvento === formulario.idFormularioEvento),
                    fileName: "lista-registros-formularios",
                    exportType: exportFromJSON.types.csv,
                  });

                  toast.success("Descargando archivo CSV");
                }}
              >
                CSV
              </CustomGreenButton>
            </ButtonGroup>
            <MDBDataTableV5
              small
              responsive
              striped
              bordered
              searchTop
              searchBottom={false}
              barReverse
              pagingTop
              noBottomColumns
              order={["name", "asc"]}
              entriesOptions={[5, 10, 20, 50]}
              entriesLabel="Numero de filas"
              searchLabel="Buscar"
              paginationLabel={["Anterior", "Siguiente"]}
              infoLabel={["Mostrando filas", "a", "de", "filas"]}
              fullPagination
              data={{
                columns: columns.filter((obj) => obj.isVisible),
                rows: !formulario ? registros : registros.filter((registro) => registro.idFormularioEvento === formulario.idFormularioEvento),
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaRegistrosFormularios;
