export const columnasRecursos = [
  {
    label: "Id",
    field: "idRecursos",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Título",
    field: "titulo",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Título En Inglés",
    field: "tituloEng",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Descripción",
    field: "descripcion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Descripción En Inglés",
    field: "descripcionEng",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Recurso",
    field: "tipoDeRecurso",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Territorio",
    field: "territorio",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Archivo",
    field: "tipoDeArchivo",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Archivo Zip",
    field: "archivoZip",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Enlace",
    field: "enlace",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Imagen del recurso",
    field: "imagen",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
