import { useAuth } from '../../../shared/contex/auth/auth-context'

import { Dropdown, Menu } from 'antd'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import Button from '@material-ui/core/Button'

//Este componete va a ser el drawer que se encarga de mostrar el boton de cerrar sesion
const SettingsDropdown = () => {

    //creamos el objeto para acceder al contexto de la autentificacion
    const auth = useAuth()

    const content = (
        <Menu style={{ marginTop: '5px' }}>
            <Menu.Item key="0">
                <button
                    className='clear-button d-flex align-items-center'
                    onClick={auth.logout}
                >
                    <PowerSettingsNewIcon style={{ color: 'red', fontSize: 20 }} className='mr-3' />
                    <div>
                        Cerrar sesión
                    </div>
                </button>
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={content} trigger={['click']}>
            <Button className='mr-1 mr-md-3' style={{ color: '#ffffff' }}>
                <SettingsOutlinedIcon style={{ color: '#ffffff', fontSize: 30 }} />
            </Button>
        </Dropdown>
    )
}


export default SettingsDropdown