import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

/**
 * Borra un usuario de academia.
 * @param {String} username - Nombre del usuario
 * @returns {Promise<any>}
 */
export const borrarUsuarioAcademia = async (username) => {
  const fd = new FormData();
  fd.append("usuario", username);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/academia/borrarUsuario`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};
