export const columnasInstitutosClassics = [
  {
    label: "Nombre",
    field: "nombre",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Correo",
    field: "correo",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "País",
    field: "pais",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Ingreso",
    field: "tipoAcceso",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Servicio",
    field: "tipoServicio",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Límite de lecturas diarias",
    field: "lecturasDiarias",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Estado de Activación",
    field: "estadoActivacion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Estado de Subscripción",
    field: "estadoSubscripcion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Ingreso App",
    field: "ingresoApp",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Link Acceso",
    field: "linkAcceso",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Link Tutorial",
    field: "linkTutorial",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
