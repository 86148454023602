import React, { useState } from "react";

import { Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { paisesAutocomplete } from "../../../../shared/data/paises";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button, Checkbox } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { Autocomplete } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { crearEvento } from "../../../../shared/fetchers/classics/eventos/crearEvento";
import { editarEvento } from "../../../../shared/fetchers/classics/eventos/editarEvento";
import { useQueryClient } from "@tanstack/react-query";

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const CrearEventoIndividual = ({ evento, clearEdit }) => {
  const queryClient = useQueryClient();
  const date = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
  const time = new Date().toISOString().split("T")[1].split(".")[0].slice(0, 5); // HH:MM

  const [formData, setFormData] = useState({
    nombreEvento: { value: evento?.nombreEsp ?? "", error: false },
    nombreEng: { value: evento?.nombreEng ?? "", error: false, optional: true },
    invitados: { value: evento?.invitados ?? "", error: false, optional: true },
    descripcion: {
      value: evento?.descripcionEsp ?? "",
      error: false,
      optional: true,
    },
    descripcionEng: {
      value: evento?.descripcionEng ?? "",
      error: false,
      optional: true,
    },
    tipoEvento: {
      value: evento?.tipoEvento ?? "",
      error: false,
    },
    textoBoton: {
      value: evento?.estadoBoton?? "",
      error: false,
    },
    fecha: {
      value: evento?.fechaCo.split(" ")[0] ?? date,
      error: false,
    },
    hora: {
      value: evento?.fechaCo.split(" ")[1] ?? time,
      error: false,
    },
    enlaceBoton: { value: evento?.link ?? "", error: false },
    territorio: {
      value: evento?.territorio.split(",") ?? [],
      error: false,
    },
  });

  //Definimos un estado para guardar la imagen y la preview
  const [imagenEvento, setImagenEvento] = useState({
    imageFile: null,
    imageUrl: null,
    error: false,
  });

  //Funcion para guardar una imagen seleccionada
  const handleImage = (files) => {
    if (files.length === 1) {
      const pickedFile = files[0];
      setImagenEvento({
        imageFile: pickedFile,
        imageUrl: URL.createObjectURL(pickedFile),
        error: false,
      });
    }
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: { ...formData[name], value: value, error: false },
    });
  };

  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], value: "", error: false };

      if(formElement === "territorio") temp[formElement] = { ...temp[formElement], value: [], error: false };
    }

    temp = { ...temp, fecha: { value: date, error: false }, hora: { value: time, error: false } };
    setFormData(temp);
  };

  const validate = () => {
    let isValid = true;

    if(!Boolean(evento) && !imagenEvento.imageFile) {
      setImagenEvento({ ...imagenEvento, error: true });
      isValid = false;
      toast.error("Debes subir una imagen para el evento");
    } 

    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };

    console.log(imagenEvento);

    //Recorremos todos los campos del formulario y validamos que no esten vacios si no son opcionales
    for (const formElement of Object.entries(temp)) {
      const [key, value] = formElement;

      if (key !== "territorio" && (value.optional || Boolean(value.value)))
        continue;
      if (key === "territorio" && value.value.length) continue;

      value.error = true;
      isValid = false;
      toast.error(`El campo ${key} no puede estar vacío`);
    }

    setFormData(temp);
    return isValid;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    let datosEvento = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (key === "territorio") return [key, value.value.join(",")];
        if (key === "fecha")
          return ["fechaHora", value.value + " " + formData.hora.value];

        return [key, value.value];
      }),
    );

    datosEvento = { ...datosEvento, imagenEvento: imagenEvento.imageFile };

    if (evento) datosEvento = { ...datosEvento, idEvento: evento.idEvento };

    console.log(imagenEvento);

    const { status } = !evento
      ? await crearEvento(datosEvento)
      : await editarEvento({
          ...datosEvento,
          nombreSedeNuevo: datosEvento.nombreSede,
          nombreSede: evento.nombreSede,
        });

    if (evento) clearEdit();

    queryClient.invalidateQueries({
      queryKey: ["eventos"],
    });

    if (!status)
      return swal({
        title: "Error",
        text: `Hubo un error ${
          evento ? "editando" : "creando"
        } el evento, inténtalo de nuevo.`,
        icon: "error",
      });

    !evento
      ? toast.success("Evento creado correctamente")
      : toast.success("Evento editado correctamente");
  };

  return (
    <Col xs={12} className="content-white-box px-4 py-4">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          {evento ? "Editar" : "Crear"} un Evento
        </div>
      </div>
      <div className="admin-muted-text-small mt-2">
        Los campos opcionales están marcados con (Opcional), los demás son obligatorios
      </div>
      <div className="admin-muted-text-small mt-2">
        Por favor ingresar la siguiente info:
      </div>
      <form>
        <label className="admin-label-text mt-3 d-block">
          Nombre del evento
        </label>
        <TextField
          name="nombreEvento"
          value={formData.nombreEvento.value}
          onChange={updateForm}
          error={formData.nombreEvento.error}
          hllpertext={
            formData.nombreEvento.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Nombre del evento"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Nombre del evento en inglés (Opcional)
        </label>
        <TextField
          name="nombreEng"
          value={formData.nombreEng.value}
          onChange={updateForm}
          error={formData.nombreEng.error}
          helpertext={
            formData.nombreEng.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Nombre del evento en inglés"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Invitados (Opcional)
        </label>
        <TextField
          name="invitados"
          value={formData.invitados.value}
          onChange={updateForm}
          error={formData.invitados.error}
          helpertext={
            formData.invitados.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Invitados al evento"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block" htmlFor="tipoEvento">
          Tipo de evento
        </label>
        <Autocomplete
          options={["Público", "Exclusivo"]}
          freeSolo
          name="tipoEvento"
          getOptionLabel={(option) => option}
          value={formData.tipoEvento.value}
          inputValue={formData.tipoEvento.value}
          onInputChange={(_, value) => {
            updateForm({
              target: {
                name: "tipoEvento",
                value,
              },
            });
          }}
          noOptionsText="Sin resultados"
          error={formData.tipoEvento.error}
          helpertext={formData.tipoEvento.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Tipo de evento"
              variant="outlined"
              size="small"
              error={formData.tipoEvento.error}
              helpertext={formData.tipoEvento.error ? "Campo no válido" : null}
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">
          Descripcion (Opcional)
        </label>
        <TextField
          name="descripcion"
          value={formData.descripcion.value}
          onChange={updateForm}
          type="text"
          error={formData.descripcion.error}
          helpertext={
            formData.descripcion.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          multiline
          minRows={3}
          placeholder="Descripcion del evento"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Descripcion en inglés (Opcional)
        </label>
        <TextField
          name="descripcionEng"
          value={formData.descripcionEng.value}
          onChange={updateForm}
          type="text"
          error={formData.descripcionEng.error}
          helpertext={
            formData.descripcionEng.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          multiline
          minRows={3}
          placeholder="Descripcion del evento en inglés"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">Fecha del evento (Colombia)</label>
        <TextField
          variant="outlined"
          size="small"
          type="date"
          style={{
            width: "180px",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
          value={formData.fecha.value}
          name="fecha"
          onChange={updateForm}
        />

        <label className="admin-label-text mt-3 d-block">Hora del evento (Colombia)</label>
        <TextField
          variant="outlined"
          size="small"
          type="time"
          style={{
            width: "180px",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
          value={formData.hora.value}
          name="hora"
          onChange={updateForm}
        />

        <label className="admin-label-text mt-3 d-block">Texto Botón</label>
        <Autocomplete
          options={["Ver", "Inscríbete"]}
          freeSolo
          name="textoBotonAutocomplete"
          getOptionLabel={(option) => option}
          value={formData.textoBoton.value}
          inputValue={formData.textoBoton.value}
          onInputChange={(_, value) => {
            updateForm({
              target: {
                name: "textoBoton",
                value,
              },
            });
          }}
          noOptionsText="Sin resultados"
          error={formData.textoBoton.error}
          helpertext={formData.textoBoton.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Tipo de evento"
              name="textoBoton"
              variant="outlined"
              size="small"
              error={formData.textoBoton.error}
              helpertext={formData.textoBoton.error ? "Campo no válido" : null}
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">
          Enlace del evento
        </label>
        <TextField
          name="enlaceBoton"
          value={formData.enlaceBoton.value}
          onChange={updateForm}
          type="text"
          error={formData.enlaceBoton.error}
          helpertext={
            formData.enlaceBoton.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Enlace del evento, al hacer click en el botón"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label htmlFor="territorio" className="admin-label-text mt-3 d-block">
          Territorio
        </label>
        <Autocomplete
          multiple
          name="territorio"
          disableCloseOnSelect
          options={paisesAutocomplete}
          getOptionLabel={(option) => option}
          value={formData.territorio.value}
          placeholder="Disponibilidad regional"
          noOptionsText="Sin resultados"
          onChange={(_, value) =>
            updateForm({
              target: {
                name: "territorio",
                value,
              },
            })
          }
          error={formData.territorio.error}
          helpertext={
            formData.territorio.error
              ? "El campo territorio no puede estar vacío"
              : null
          }
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                color="primary"
              />
              {option}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              error={formData.territorio.error}
              helpertext={formData.territorio.error ? "Campo no válido" : null}
              placeholder={
                formData.territorio.value.length
                  ? ""
                  : "Disponibilidad regional"
              }
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">
          Imagen del evento
        </label>
        <div
          className="admin-muted-text-small"
          style={imagenEvento.error ? { color: "red" } : null}
        >
          1 archivo requerido con extensión png, jpeg o jpg
        </div>

        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          showAlerts={true}
          filesLimit={1}
          acceptedFiles={["image/png", "image/jpeg", "image/jpg"]}
          onChange={(files) => handleImage(files)}
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => SubmitForm(e)}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
          >
            Finalizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              clearForm();
              if (evento) clearEdit();
            }}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearEventoIndividual;
