import React from "react";

import { Row, Col } from "react-bootstrap";
import AgregarEstudianteIndividualForm from "./agregar-estudiante-individual-form";
import MenuBookIcon from "@material-ui/icons/MenuBook";

const AgregarNuevoEstudianteIndividual = ({ estudiante, clearEstudiante }) => {
  return (
    <Row>
      <Col xs={12} className="content-white-box px-4 py-4 ">
        <div className="d-flex align-items-center">
          <MenuBookIcon style={{ fontSize: "20px" }} />
          <div className="admin-main-title-dark-gray ml-2 mt-1">
            Agregar estudiante
          </div>
        </div>
        <AgregarEstudianteIndividualForm />
      </Col>
    </Row>
  );
};

export default AgregarNuevoEstudianteIndividual;
