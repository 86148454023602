import React, { useState, useEffect } from "react";
// Components
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../../components/shared/table-column-dropdown/table-column-dropdown";
import swal from "sweetalert";
// Functions
import exportFromJSON from "export-from-json";
// icons
import { ArrowLeftRounded } from "@material-ui/icons";
//Data
import { columnasCortePorEditorial } from "../../../../shared/data/columnas";
import { GENERAL_URL_API } from "../../../../shared/urls";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import { Button } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

const CortePorEditorialTable = (props) => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    let temp = [...columnasCortePorEditorial].map((el) => ({
      ...el,
      isVisible: true,
    }));
    setColumns(temp);

    fetchRegaliasEditorial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eliminarCorte = async () => {
    const fd = new FormData();
     
    fd.append("idCorte", props.regaliaSelected);
    const res = await fetch(`${GENERAL_URL_API}/regalias/eliminarCorte.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });

    const data = await res.json();
    if (!data.status)
      await swal({
        title: `El corte ${props.regaliaSelected} no pudo ser eliminado`,
        icon: "Error",
      });
  };

  const fetchRegaliasEditorial = async () => {
    const fd = new FormData();
     
    fd.append("idCortes", props.regaliaSelected);
    const res = await fetch(
      `${GENERAL_URL_API}/regalias/verRegaliasEditorial.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const data = await res.json();
    const tempRows = [...data.data].map((el, index) => ({
      ...el,
      detalle: (
        <div className="w-100 d-flex justify-content-center">
          <button
            style={{
              border: "none",
              padding: "3px 5px",
              backgroundColor: "#5fbeaa",
              fontWeight: "500",
              color: "#fff",
              borderRadius: "5px",
              fontSize: "0.95em",
            }}
            data-editorial={el.publisher}
            onClick={(e) => {
              e.stopPropagation();
              props.setEditorial(e.target.getAttribute("data-editorial"));
            }}
            size="small"
          >
            Ver Elemento
          </button>
        </div>
      ),
    }));
    setRows(tempRows);

    if (data.status === 0) {
      await swal({
        title: "Error obteniendo la información!",
        icon: "error",
      });
    }
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  const isNumber = (n) => !isNaN(parseFloat(n)) && isFinite(n);

  const getExcelRows = () => {
    const temp = [...rows];
    temp.forEach((r, i) => {
      Object.keys(r).forEach((k) => {
        if (isNumber(r[k])) {
          temp[i][k] = parseFloat(r[k]);
        }
      });
    });
    return temp;
  };

  return (
    <>
      <div className="admin-muted-text-small mt-1 mb-4">
        {`(${props.regaliaSelected}) Haciendo click en la editorial se puede ir al detalle del corte por libros`}
      </div>

      <CustomGreenButton
        size="small"
        variant="contained"
        className="mb-3 d-flex justify-content-center"
        onClick={props.clearRegalia}
      >
        <i>
          <ArrowLeftRounded />
        </i>
        Regresar
      </CustomGreenButton>

      {/* Este componente es el dropdown que permite ocultar columnas*/}
      <TableColumnDropdown
        columns={columns}
        changeColumnStatus={changeColumnStatus}
      />
      <br />

      <ButtonGroup size="small" variant="contained" className="my-3">
        <CustomGreenButton
          onClick={() => {
            const dat = [...rows];
            navigator.clipboard
              .writeText(JSON.stringify(dat))
              .then(async () => {
                await swal({
                  title: "Se ha copiado la tabla!",
                  icon: "success",
                });
              });
          }}
        >
          Copy
        </CustomGreenButton>
        <CustomGreenButton
          onClick={() => {
            exportFromJSON({
              data: rows,
              fileName: "lista-cortes-regalias",
              exportType: exportFromJSON.types.csv,
            });
          }}
        >
          CSV
        </CustomGreenButton>
        <CustomGreenButton
          onClick={() => {
            exportFromJSON({
              data: rows,
              fileName: "lista-cortes-regalias",
              exportType: exportFromJSON.types.json,
            });
          }}
        >
          JSON
        </CustomGreenButton>
        <CustomGreenButton>
          <ExcelWorkBook
            columns={[...columns].slice(1)}
            rows={getExcelRows()}
            filename={"lista-cortes-regalias"}
          />
        </CustomGreenButton>
        {/* <CustomGreenButton
          onClick={() => {
            const temp = [...rows];
            temp.forEach((r, i) =>
              Object.keys(r).forEach((k) => {
                if (isNumber(r[k])) {
                  temp[i][k] = parseFloat(r[k])
                    .toFixed(2)
                    .toString()
                    .replace(".", ",");
                }
              })
            );
            exportFromJSON({
              data: temp,
              fileName: "lista-cortes-regalias",
              exportType: exportFromJSON.types.xls,
            });
          }}
        >
          EXCEL
        </CustomGreenButton> */}
      </ButtonGroup>
      {rows.length <= 0 || columns.length <= 0 ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <>
          <MDBDataTable
            small
            responsive
            striped
            searchTop
            searchBottom={false}
            barReverse
            pagingTop
            bordered
            data={{
              columns: columns.filter((obj) => obj.isVisible),
              rows: rows,
            }}
            noBottomColumns
            order={["name", "asc"]}
            entriesOptions={[5, 10, 20, 50]}
            entriesLabel="Numero de filas"
            searchLabel="Buscar"
            paginationLabel={["Anterior", "Siguiente"]}
            infoLabel={["Mostrando filas", "a", "de", "filas"]}
          />
          {/* botón para eliminar */}
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              const res = await swal({
                title: "Está seguro?",
                text: "Esta acción no se puede revertir!",
                icon: "warning",
                buttons: ["Cancelar", "Borrar"],
              });
              if (res) {
                await eliminarCorte();
                await props.updateRoyalties();
                props.clearRegalia();
              }
            }}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Eliminar corte
          </Button>
        </>
      )}
    </>
  );
};

export default CortePorEditorialTable;
