import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import IOSSwitch from "../../../components/shared/ios-switch";

import TvIcon from "@material-ui/icons/Tv";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import { GENERAL_URL_API } from "../../../shared/urls";
import { columnasClientes } from "../../../shared/data/columnas";
import exportFromJSON from "export-from-json";
import swal from "sweetalert";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";

const ListaClientes = () => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tipoValue, setTipoValue] = useState(null);
  const [error, setError] = useState(false);
  const [filtroActividad, setFiltroActividad] = useState({
    all: true,
    active: false,
    unactive: false,
  });

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  const toggleSwitch = (e) => {
    const name = e.target.name;
    const temp = {
      all: false,
      active: false,
      unactive: false,
      [name]: true,
    };
    setFiltroActividad({ ...temp });
  };

  const autoCompleteUpdate = (e, newValue) => {
    //Si efectivametne se seleccion un nuevo valor actualizamos el estado
    if (newValue) {
      setError(false);
      setTipoValue(newValue);
    }
  };

  const submit = async () => {
    setLoading(true);
    const fd = new FormData();
    let show;
    Object.keys(filtroActividad).forEach((key) => {
      if (filtroActividad[key] === true) show = key;
    });
     
    fd.append("show", show);
    fd.append("tipoUsuario", tipoValue.tipo);
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    let tempColumns = columnasClientes;
    tempColumns.map((_, index) => (tempColumns[index].isVisible = true));
    setRows(resJSON.data);
    setColumns(tempColumns);
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Lista de clientes</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de clientes
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Consultas de clientes.
            </div>

            <form>
              <label className="admin-label-text mt-3 d-block">
                Tipo usuario:
              </label>
              <Autocomplete
                options={[
                  { label: "Individual", tipo: "Individual" },
                  { label: "Institucional", tipo: "Instituto" },
                ]}
                getOptionLabel={(option) => option.label}
                value={tipoValue}
                onChange={autoCompleteUpdate}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    helperText={error ? "Seleccione un tipo de cliente" : null}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />

              <label
                className="admin-label-text mt-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Todos:
              </label>
              <IOSSwitch
                name="all"
                checked={filtroActividad.all}
                onClick={toggleSwitch}
              />

              <label
                className="admin-label-text mt-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Activos:
              </label>
              <IOSSwitch
                checked={filtroActividad.active}
                onClick={toggleSwitch}
                name="active"
              />
              <label
                className="admin-label-text mt-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Inactivos:
              </label>
              <IOSSwitch
                checked={filtroActividad.unactive}
                onClick={toggleSwitch}
                name="unactive"
              />

              <ButtonGroup className="d-block">
                <CustomGreenButton
                  variant="contained"
                  className="mt-3"
                  onClick={submit}
                  disabled={!tipoValue || loading}
                >
                  Consultar
                </CustomGreenButton>
              </ButtonGroup>
            </form>
            <hr />

            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <>
                {/* Este componente es el dropdown que permite ocultar columnas*/}
                <TableColumnDropdown
                  columns={columns}
                  changeColumnStatus={changeColumnStatus}
                />
                <br />
                <ButtonGroup size="small" variant="contained" className="my-3">
                  <CustomGreenButton
                    onClick={() => {
                      const dat = [...rows];
                      navigator.clipboard
                        .writeText(JSON.stringify(dat))
                        .then(async () => {
                          await swal({
                            title: "Se ha copiado la tabla!",
                            icon: "success",
                          });
                        });
                    }}
                  >
                    Copy
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "lista-clientes",
                        exportType: exportFromJSON.types.csv,
                      });
                    }}
                  >
                    CSV
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "lista-clientes",
                        exportType: exportFromJSON.types.json,
                      });
                    }}
                  >
                    JSON
                  </CustomGreenButton>
                  <CustomGreenButton>
                    <ExcelWorkBook
                      columns={columns}
                      rows={rows}
                      filename={"Lista-clientes"}
                    />
                  </CustomGreenButton>
                </ButtonGroup>
                <MDBDataTableV5
                  small
                  responsive
                  striped
                  searchTop
                  searchBottom={false}
                  barReverse
                  pagingTop
                  bordered
                  data={{
                    columns: columns.filter((obj) => obj.isVisible),
                    rows: rows,
                  }}
                  noBottomColumns
                  order={["username", "asc"]}
                  entriesOptions={[5, 10, 20, 50]}
                  entriesLabel="Numero de filas"
                  searchLabel="Buscar"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={["Mostrando filas", "a", "de", "filas"]}
                  fullPagination
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaClientes;
