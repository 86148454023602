import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

/**
 * Obtiene un reporte de usuarios de la academia.
 *
 * @param {string} users - Los usuarios para generar el reporte.
 * @param {object} dates - Las fechas de inicio y fin del reporte.
 * @param {string} dates.from - La fecha de inicio del reporte en formato de cadena.
 * @param {string} dates.to - La fecha de fin del reporte en formato de cadena.
 * @param {number} maxEntries - (Opcional) El número máximo de entradas en el reporte. Por defecto es 5000.
 * @returns {Promise} - Una promesa que se resuelve con los datos del reporte de usuarios.
 */
export const obtenerReporteUsuariosAcademia = async () => {
  const fd = new FormData();

  const {
    data: { data },
  } = await axios.post(`${GENERAL_URL_API}/academia/reporteUsuarios`, fd, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
