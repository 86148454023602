import axios from "axios";

import { GENERAL_URL_API } from "../../../urls";

/**
 * @param {string} id - id del notibuzon a eliminar
 * @returns {Promise<{info: string, status: boolean}>}
 */
export async function eliminarNotibuzon(id) {
  const fd = new FormData();
  fd.append("idNotibuzon", id);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/notificaciones/notibuzon/borrarNotibuzon`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return data;
}
