export function calcularFactoresRegalias(n_interP, n_interB, n_epubP, n_epubB) {
  const interP = 0.4019292605;
  const interB = 0.2572347267;
  const epubP = 0.2090032154;
  const epubB = 0.1318327974;

  const factorTotal =
    n_interP * interP + n_interB * interB + n_epubP * epubP + n_epubB * epubB;

  const factores = {
    interP: (n_interP * interP) / factorTotal,
    interB: (n_interB * interB) / factorTotal,
    epubP: (n_epubP * epubP) / factorTotal,
    epubB: (n_epubB * epubB) / factorTotal,
  };

  return factores;
}

export function calcularPrecios(a) {
  const factores = calcularFactoresRegalias(
    a.interP,
    a.interB,
    a.epubP,
    a.epubB
  );

  const subtotal =
    a.precioTotalVenta - a.reteFuente - a.reteICA - a.descuentosBancarios;
  const valorNeto =
    subtotal -
    a.costoTransmisionDatos -
    a.costoConfiguracion -
    a.hardwareCajitas -
    a.talleres;

  const precioEpubBasico =
    a.epubB === 0 ? 0 : (factores.epubB * valorNeto) / a.epubB;
  const precioEpubPremium =
    a.epubP === 0 ? 0 : (factores.epubP * valorNeto) / a.epubP;
  const precioInterBasico =
    a.interB === 0 ? 0 : (factores.interB * valorNeto) / a.interB;
  const precioInterPremium =
    a.interP === 0 ? 0 : (factores.interP * valorNeto) / a.interP;

  return {
    valorNeto: valorNeto.toFixed(4),
    subtotal: subtotal.toFixed(4),
    precioEpubBasico: precioEpubBasico.toFixed(2),
    precioEpubPremium: precioEpubPremium.toFixed(2),
    precioInterBasico: precioInterBasico.toFixed(2),
    precioInterPremium: precioInterPremium.toFixed(2),
  };
}
