import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";

import TvIcon from "@material-ui/icons/Tv";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { fetchInstitutos } from "../../../shared/fetchers/classics/institutos/verInstitutos";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { CheckRounded, CloseRounded } from "@material-ui/icons";
import { fetchSedes } from "../../../shared/fetchers/classics/institutos/verSedes";
import { eliminarSedeClassics } from "../../../shared/fetchers/classics/institutos/eliminarSede";

const EliminarSedeClassics = () => {
  const [instituto, setInstituto] = useState(null);
  const [sede, setSede] = useState(null);

  const {
    data: institutos,
    isLoading: isLoadingInstitutos,
    isError: isErrorInstitutos,
  } = useQuery(["institutosClassics"], fetchInstitutos);

  const { data: sedes, isError: isErrorSedes } = useQuery(
    ["sedesClassics", instituto?.nombre],
    () => fetchSedes(instituto?.nombre ?? ""),
    {
      enabled: Boolean(instituto),
    }
  );

  const queryClient = useQueryClient();

  if (isLoadingInstitutos)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isErrorInstitutos || isErrorSedes)
    return <div>Ha ocurrido un error, prueba recargando la página</div>;

  const SubmitForm = async (e) => {
    e.preventDefault();

    const check = await swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminada, no podrás recuperar esta sede",
      icon: "warning",
      buttons: ["Cancelar", "Eliminar"],
      dangerMode: true,
    });

    if (!sede || !check) return toast.success("Operación cancelada");

    const { info, status } = await eliminarSedeClassics(sede?.nombreSede ?? "");

    if (!status)
      return swal({
        title: "Error",
        text: info ?? "Ha ocurrido un error eliminando la sede",
        icon: "error",
      });

    queryClient.invalidateQueries({
      queryKey: ["sedesClassics", instituto?.nombre ?? ""],
    });

    toast.success("Sede eliminada correctamente");

    setInstituto(null);
    setSede(null);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Eliminar un instituto</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <form>
              <label className="admin-label-text mt-3 d-block">
                Selecciona un instituto para ver sus sedes:
              </label>
              <Autocomplete
                options={institutos}
                value={instituto}
                getOptionLabel={(option) => option.nombre}
                onChange={(_, newValue) => {
                  setInstituto(newValue);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />

              {instituto && (
                <>
                  <label className="admin-label-text mt-3 d-block">
                    Selecciona una sede para eliminarla:
                  </label>
                  <Autocomplete
                    options={sedes}
                    value={sede}
                    getOptionLabel={(option) => option.nombreSede}
                    onChange={(_, newValue) => {
                      setSede(newValue);
                    }}
                    noOptionsText="Sin resultados"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        style={{
                          width: "100%",
                          fontFamily: "'Noto Sans JP', sans-serif",
                        }}
                      />
                    )}
                  />
                  <div className="d-flex justify-content-end mt-4">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!sede}
                      type="submit"
                      onClick={(e) => SubmitForm(e)}
                      startIcon={<CheckRounded />}
                      style={{ background: "#f05050" }}
                    >
                      Eliminar
                    </Button>
                    <Button
                      variant="contained"
                      disabled={!sede}
                      color="primary"
                      onClick={() => {
                        setInstituto(null);
                        setSede(null);
                      }}
                      className="ml-2"
                      startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                      style={{ background: "#81c868" }}
                    >
                      Cancelar
                    </Button>
                  </div>
                </>
              )}
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EliminarSedeClassics;
