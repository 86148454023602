import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

/**
 * @returns {Promise<any>}
 */
export const fetchCursosAcademia = async () => {
  const { data } = await axios.get(`${GENERAL_URL_API}/academia/verCursos`, {
    withCredentials: true,
  });

  return data.data;
};
