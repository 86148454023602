import React, { useState, useEffect } from "react";
// Components
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../../components/shared/table-column-dropdown/table-column-dropdown";
import swal from "sweetalert";
// Functions
import exportFromJSON from "export-from-json";
// icons
import { ArrowLeftRounded } from "@material-ui/icons";
//Data
import { columnasLibrosPorEditorialRegalias } from "../../../../shared/data/columnas";
import { GENERAL_URL_API } from "../../../../shared/urls";
import { testRows } from "../../../../shared/data/test-data";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";

const LibrosEditorialTable = ({ regalia, editorial, clearEditorial }) => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    let temp = [...columnasLibrosPorEditorialRegalias].map((el) => ({
      ...el,
      isVisible: true,
    }));
    setColumns(temp);

    const tempRows = [...testRows];
    setRows(tempRows);
    fetchLibrosEditorial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLibrosEditorial = async () => {
    const fd = new FormData();
     
    fd.append("idCortes", regalia);
    fd.append("editorial", editorial);
    const res = await fetch(
      `${GENERAL_URL_API}/regalias/verRegaliasEditorialLibros.php`, //cambiar
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const data = await res.json();
    setRows(
      data.data.map((row) => ({
        ...row,
        ValorRegalias: (row.Total / (row.royalties / 100)).toFixed(2),
      }))
    );
    if (data.status === 0) {
      await swal({
        title: "Error obteniendo la información!",
        icon: "error",
      });
    }
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  const isNumber = (n) => !isNaN(parseFloat(n)) && isFinite(n);

  const getExcelRows = () => {
    const temp = [...rows].slice();
    temp.forEach((r, i) => {
      Object.keys(r).forEach((k) => {
        if (isNumber(r[k])) {
          temp[i][k] = parseFloat(r[k]);
        }
      });
    });
    return temp;
  };

  return (
    <>
      <div className="admin-muted-text-small mt-1 mb-4">{`(${editorial}) - (${regalia})`}</div>

      <CustomGreenButton
        size="small"
        variant="contained"
        className="mb-3 d-flex justify-content-center"
        onClick={clearEditorial}
      >
        <i>
          <ArrowLeftRounded />
        </i>
        Regresar
      </CustomGreenButton>

      {/* Este componente es el dropdown que permite ocultar columnas*/}
      <TableColumnDropdown
        columns={columns}
        changeColumnStatus={changeColumnStatus}
      />
      <br />

      <ButtonGroup size="small" variant="contained" className="my-3">
        <CustomGreenButton
          onClick={() => {
            const dat = [...rows];
            navigator.clipboard
              .writeText(JSON.stringify(dat))
              .then(async () => {
                await swal({
                  title: "Se ha copiado la tabla!",
                  icon: "success",
                });
              });
          }}
        >
          Copy
        </CustomGreenButton>
        <CustomGreenButton
          onClick={() => {
            exportFromJSON({
              data: rows,
              fileName: "lista-cortes-regalias",
              exportType: exportFromJSON.types.csv,
            });
          }}
        >
          CSV
        </CustomGreenButton>
        <CustomGreenButton
          onClick={() => {
            exportFromJSON({
              data: rows,
              fileName: "lista-cortes-regalias",
              exportType: exportFromJSON.types.json,
            });
          }}
        >
          JSON
        </CustomGreenButton>
        {/* <CustomGreenButton
          onClick={() => {
            const temp = [...rows];
            temp.forEach((r, i) =>
              Object.keys(r).forEach((k) => {
                if (isNumber(r[k])) {
                  temp[i][k] = parseFloat(r[k])
                    .toFixed(2)
                    .toString()
                    .replace(".", ",");
                }
              })
            );
            exportFromJSON({
              data: temp,
              fileName: "lista-cortes-regalias",
              exportType: exportFromJSON.types.xls,
            });
          }}
        >
          EXCEL
        </CustomGreenButton> */}

        <CustomGreenButton>
          <ExcelWorkBook
            columns={columns}
            rows={getExcelRows()}
            filename={`Reporte de regalias - ${editorial}`}
          />
        </CustomGreenButton>
      </ButtonGroup>
      {rows.length <= 0 || columns.length <= 0 ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <MDBDataTable
          small
          responsive
          striped
          bordered
          searchTop
          searchBottom={false}
          barReverse
          pagingTop
          data={{
            columns: columns.filter((obj) => obj.isVisible),
            rows: rows,
          }}
          noBottomColumns
          order={["name", "asc"]}
          entriesOptions={[5, 10, 20, 50]}
          entriesLabel="Numero de filas"
          searchLabel="Buscar"
          paginationLabel={["Anterior", "Siguiente"]}
          infoLabel={["Mostrando filas", "a", "de", "filas"]}
        />
      )}
    </>
  );
};

export default LibrosEditorialTable;
