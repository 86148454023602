import React, { useState } from "react";

import { Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { IconButton, Tooltip } from "@material-ui/core";
import { Close, LockRounded, Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import { validatePassword } from "../../../../shared/utils/validatePassword";
import { crearUsuarioAcademia } from "../../../../shared/fetchers/academia/crearUsuario";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { generatePasswords } from "../../../../shared/utils/generatePassword";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { editarUsuarioAcademia } from "../../../../shared/fetchers/academia/editarUsuario";

const CrearUsuarioAcademiaIndividual = ({ user, clearEdit }) => {
  const queryClient = useQueryClient();

  //Definimos los estaos para almacenar los datos del formulario y sus errores
  const [formData, setFormData] = useState({
    usuario: { value: user?.usernameAcademia ?? "", error: false },
    email: { value: user?.email ?? "", error: false },
    password: { value: "", error: false, optional: user, show: false },
    passwordConf: { value: "", error: false, optional: user, show: false },
    nombre: { value: user?.nombre ?? "", error: false },
    celular: { value: user?.celular ?? "", error: false },
    fechaIniSub: { value: user?.fechaIniSub ?? "", error: false },
    fechaFinSub: { value: user?.fechaFinSub ?? "", error: false },
  });

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };

  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { value: "", error: false };
    }
    setFormData(temp);

    if (user) clearEdit();
  };

  // code from stack overflow
  function validateEmail(email) {
    if (!email) return false;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validate = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      if (!temp[formElement].optional && temp[formElement].value === "") {
        console.log(`fields cannot be empty -> ${formElement}`);
        toast.error(`El campo ${formElement} no puede estar vacío`);
        temp[formElement].error = true;
        isValid = false;
      }
    }
    // email validation
    if (!validateEmail(temp.email.value)) {
      toast.error("El email no es válido");
      temp.email.error = true;
      isValid = false;
    }
    // Password validation
    if (!user && !validatePassword(temp.password.value)) {
      toast.error(
        "Contraseña inválida (min 6 caracteres, letras, numeros y simbolos (!@#$%^&*)",
      );
      temp.password.error = true;
      isValid = false;
    }

    if (temp.password.value !== temp.passwordConf.value) {
      console.log("password do not match");
      toast.error("Las contraseñas no coinciden");
      temp.passwordConf.error = true;
      isValid = false;
    }
    //Actualizamos el estado con todos los cambios
    setFormData(temp);
    return isValid;
  };

  //Funcion para subir la informacion y validad todos los datos
  const SubmitForm = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const { usuario, email, password, nombre, celular } = formData;
    let temp = {
      username: usuario.value,
      email: email.value,
      password: password.value,
      nombre: nombre.value,
      celular: celular.value,
      fechas: {
        inicio: formData.fechaIniSub.value,
        fin: formData.fechaFinSub.value,
      },
    };

    const { info, status } = !user
      ? await crearUsuarioAcademia(temp)
      : await editarUsuarioAcademia(temp);

    if (!status)
      return swal({
        title: "Error",
        text: info,
        icon: "error",
      });

    queryClient.invalidateQueries({
      queryKey: ["usuariosAcademia"],
    });

    await swal({
      title: `Usuario ${!user ? "creado" : "editado"}`,
      text: `El usuario se ha ${!user ? "creado" : "editado"} correctamente`,
      icon: "success",
    });

    if (user) clearEdit();
  };

  return (
    <Col xs={12} className="content-white-box px-4 py-4">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          {!user ? "Crear " : "Editar "} un cliente
        </div>
      </div>
      <div className="admin-muted-text-small mt-2">
        Por favor ingresar la siguiente info:
      </div>
      <form>
        <label className="admin-label-text mt-3 d-block">Usuario*</label>
        <TextField
          name="usuario"
          value={formData.usuario.value}
          disabled={Boolean(user)}
          onChange={updateForm}
          error={formData.usuario.error}
          helperText={
            formData.usuario.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Usuario (min 4 caracteres y sin espacios)"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">Email*</label>
        <TextField
          name="email"
          value={formData.email.value}
          onChange={updateForm}
          error={formData.email.error}
          helperText={
            formData.email.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="E-mail"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">Contraseña*</label>
        <TextField
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
          type={formData.password.show ? "text" : "password"}
          value={formData.password.value}
          name="password"
          placeholder={`Contraseña(min 6 caracteres, letras, numeros y simbolos(!@#$ %^&*)`}
          onChange={updateForm}
          error={formData.password.error}
          helperText={formData.password.error ? "Campo no válido" : null}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  aria-label="clear password"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      password: {
                        ...formData.password,
                        value: "",
                      },
                      passwordConf: {
                        ...formData.passwordConf,
                        value: "",
                      },
                    });
                  }}
                  edge="end"
                >
                  <Tooltip title="Eliminar contraseñas">
                    <Close />
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="generate password"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    const autoPassword = generatePasswords(1)[0];
                    setFormData({
                      ...formData,
                      password: {
                        ...formData.password,
                        value: autoPassword,
                      },
                      passwordConf: {
                        ...formData.passwordConf,
                        value: autoPassword,
                      },
                    });
                  }}
                  edge="end"
                >
                  <Tooltip title="Generar contraseña">
                    <LockRounded />
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      password: {
                        ...formData.password,
                        show: !formData.password.show,
                      },
                    });
                  }}
                  edge="end"
                >
                  {formData.password.show ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Confirmar Contraseña *
        </label>
        <TextField
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
          type={formData.passwordConf.show ? "text" : "password"}
          value={formData.passwordConf.value}
          name="passwordConf"
          placeholder="Confirmar Password"
          onChange={updateForm}
          error={formData.passwordConf.error}
          helperText={formData.passwordConf.error ? "Campo no válido" : null}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      passwordConf: {
                        ...formData.passwordConf,
                        show: !formData.passwordConf.show,
                      },
                    });
                  }}
                  edge="end"
                >
                  {formData.passwordConf.show ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Nombre Completo*
        </label>
        <TextField
          name="nombre"
          value={formData.nombre.value}
          onChange={updateForm}
          error={formData.nombre.error}
          helperText={
            formData.nombre.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Nombre Completo"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">Celular*</label>
        <TextField
          name="celular"
          value={formData.celular.value}
          onChange={updateForm}
          error={formData.celular.error}
          helperText={
            formData.celular.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Celular"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Entre las fechas:
        </label>
        <div className="d-flex">
          <TextField
            name="fechaIniSub"
            value={formData.fechaIniSub.value}
            onChange={updateForm}
            variant="outlined"
            size="small"
            type="date"
            style={{
              width: "180px",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
          <CustomGreenButton
            variant="contained"
            style={{
              height: "40px",
              pointerEvents: "none",
            }}
            className="d-block d-md-inline"
            disableTouchRipple
            disableRipple
            disableElevation
            disableFocusRipple
          >
            Hasta
          </CustomGreenButton>
          <TextField
            name="fechaFinSub"
            value={formData.fechaFinSub.value}
            onChange={updateForm}
            variant="outlined"
            size="small"
            type="date"
            style={{
              width: "180px",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        </div>

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => SubmitForm(e)}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
          >
            Finalizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={clearForm}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearUsuarioAcademiaIndividual;
