import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { Button, Checkbox, TextField, makeStyles } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { createFilaHome } from "../../../../shared/fetchers/filasHome";
import { Autocomplete } from "@material-ui/lab";
import { paisesAutocomplete } from "../../../../shared/data/paises";
// Icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AgregarFilasHome = () => {
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [disponibilidadRegional, setDisponibilidadRegional] = useState([]);

  const queryClient = useQueryClient();

  const classes = useStyles();

  const addFilaHome = async (e) => {
    e.preventDefault();
    console.log("Agregando fila...");

    try {
      const response = await createFilaHome({
        nombre,
        descripcion,
        disponibilidadRegional: disponibilidadRegional.join(","),
      });

      if (!response.status) throw new Error("Error al agregar la fila");

      queryClient.invalidateQueries({
        queryKey: ["filasHome"],
      });

      clearForm();

      toast.success("Fila agregada");
    } catch (error) {
      toast.error("Error al agregar la fila");
    }
  };

  const clearForm = () => {
    setNombre("");
    setDescripcion("");
    setDisponibilidadRegional([]);
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="d-flex align-items-center">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Agregar Fila Home
            </div>
          </div>
          <hr />
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <TextField
              name="nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              variant="outlined"
              placeholder="Nombre de la fila"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />

            <TextField
              name="description"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              variant="outlined"
              placeholder="Descripción de la fila"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />

            <Autocomplete
              multiple
              disableCloseOnSelect
              disabled={!nombre}
              options={paisesAutocomplete}
              getOptionLabel={(option) => option}
              value={disponibilidadRegional}
              placeholder="Disponibilidad regional"
              noOptionsText="Sin resultados"
              onChange={(_, value) => setDisponibilidadRegional(value)}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    color="primary"
                  />
                  {option}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder={
                    disponibilidadRegional.length
                      ? ""
                      : "Disponibilidad regional"
                  }
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <div className="mt-3">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!nombre}
                startIcon={<CheckRounded />}
                onClick={addFilaHome}
                classes={{ root: classes.agregar }}
              >
                Agregar
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default AgregarFilasHome;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
