import React from "react";

import "./agregar-nueva-actividad.css";

import { Container, Row, Col } from "react-bootstrap";
import AgregarNuevaActividadForm from "./agregar-nueva-actividad-form";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import BookIcon from "@material-ui/icons/Book";

const AgregarNuevaActividad = () => {
  return (
    <div>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Agregar una actividad</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <BookIcon style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                AGREGAR UNA ACTIVIDAD
              </span>
            </div>
            <span className="admin-muted-text">
              Por favor ingresar la siguiente información (los campos con * son
              obligatorios):
            </span>
            <AgregarNuevaActividadForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AgregarNuevaActividad;
