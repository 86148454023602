import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * @returns {Promise<Array<Evento>>}
 */
export const fetchEventos = async () => {
  const { data } = await axios.get(`${GENERAL_URL_API}/eventos/verEventos`, {
    withCredentials: true,
  });

  return data.data.map(evento => ({
    ...evento,
    fechaCo: evento.fechasZonas.co,
    fechaBs: evento.fechasZonas.bs,
    fechaCdmx: evento.fechasZonas.cdmx,
    fechaCl: evento.fechasZonas.cl,
    fechaUsa: evento.fechasZonas.usa,
    nombreEsp: evento["Nombre del evento"].es,
    nombreEng: evento["Nombre del evento"].en,
    invitados: evento["Invitados"].toString(),
    descripcionEsp: evento.descripcion.es,
    descripcionEng: evento.descripcion.en,
  }))
};
