import React, { useState, useEffect } from "react";

// material ui components
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IOSSwitch from "../../../components/shared/ios-switch";
import Button from "@material-ui/core/Button";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import { Close, CloseRounded, LockRounded } from "@material-ui/icons";
import CheckRounded from "@material-ui/icons/CheckRounded";
import Skeleton from "@material-ui/lab/Skeleton";

import swal from "sweetalert";
import { GENERAL_URL_API } from "../../../shared/urls";
import { useClient } from "../../../shared/contex/client/client-context";
import { paisesAutocomplete } from "../../../shared/data/paises";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { generatePasswords } from "../../../shared/utils/generatePassword";

const AgregarNuevoClienteForm = ({ urlApi, type }) => {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [formData, setFormData] = useState(fData);
  const [institutes, setInstitutes] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [vendedor, setVendedor] = useState({
    value: null,
    error: false,
    required: true,
  });

  const { getClient } = useClient();
  const [client, setClient] = useState(undefined);

  useEffect(() => {
    clearForm();
    setClient(getClient());
    fetchInstitutes();
    fetchVendedores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlApi]);

  const handleChange = (e, inName, inValue) => {
    const name = inName || e.target.name;
    const value = inValue || e.target.value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
      },
    });
  };

  const changeSwitch = (e) => {
    const name = e.target.name;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: !formData[name].value,
      },
    });
  };

  const fetchVendedores = async () => {
    const fd = new FormData();

    fetch(`${GENERAL_URL_API}/userAdmin/getUsers.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    })
      .then(async (res) => {
        if (!res.ok) throw new Error();
        const data = await res.json();
        setVendedores(data.data);
        setLoading(false);
      })
      .catch(() => {
        swal({
          title: "Sorry :(",
          text: "¡Hubo un problema obteniendo los clientes!, comunícate con soporte!",
          icon: "warning",
          buttons: ["Cancelar"],
        });
      });
  };

  const changeVendedor = (newBook) => {
    setVendedor({ ...vendedor, value: newBook });
  };

  const fetchInstitutes = async () => {
    const fd = new FormData();

    fetch(`${GENERAL_URL_API}/verDatos/verInstitutos.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    })
      .then(async (res) => {
        if (!res.ok) throw new Error();
        const data = await res.json();
        setInstitutes(data.data);
        setLoading(false);
      })
      .catch((e) => {
        swal({
          title: "Sorry :(",
          text: "¡Hubo un problema obteniendo los institutos!, comunícate con soporte!",
          icon: "warning",
          buttons: ["Cancelar"],
        });
        console.log(e.message);
      });
  };

  // code from stack overflow
  function validateEmail(email) {
    if (!email) return false;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = (e) => {
    setLoadingButton(true);
    e.preventDefault();
    clearErrors();
    const data = { ...formData };
    let isValid = true;

    // email validation
    if (!validateEmail(formData.email.value)) {
      console.log("wrong email");
      console.log("we- ", data.email.value);
      data.email.error = true;
      isValid = false;
    } else {
      data.email.error = false;
    }

    // ip validations
    if (formData.ips.value.includes(" ")) {
      console.log("wrong ip");
      data.ips.error = true;
      isValid = false;
    } else {
      data.ips.error = false;
    }

    if (type === "Individual") {
      data.institute.required = false;
    } else {
      if (!data.institute.value && !data.noInstitute.value) {
        console.log("wrong institute");
        data.institute.error = true;
        data.noInstitute.error = true;
        isValid = false;
      } else {
        data.noInstitute.error = false;
      }
    }

    // verifica que los que son obligatorios no estén vacíos
    for (const formElement in data) {
      if (
        data[formElement].required &&
        !data[formElement].value &&
        data[formElement].value !== 0
      ) {
        console.log(`wrong ${formElement}`);
        data[formElement].error = true;
        isValid = false;
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(data);
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      clearErrors();
      const fd = new FormData();

      fd.append("usuario_cliente", formData?.user?.value);
      fd.append("passwordCliente", formData?.password?.value);
      fd.append("acceso_cliente", +formData?.client?.value);
      fd.append("acceso_subcliente", +formData?.subClient?.value);
      fd.append("fechaInicio", formData?.dateFrom?.value);
      fd.append("fechaFin", formData?.dateTo?.value);
      fd.append("identificador", formData?.id?.value);
      fd.append("email", formData?.email?.value);
      fd.append("concurrencias", formData?.concurrences?.value);
      if (type === "Individual") fd.append("instituto", "ManuvoIndividual");
      else {
        formData?.noInstitute?.value
          ? fd.append("instituto", formData?.noInstitute?.value)
          : fd.append("instituto", formData?.institute?.value.nombre);
      }

      fd.append("ipInicio", formData?.ips?.value);
      fd.append("ipFin", "");
      fd.append("interPR", formData?.premiumInteractiveBoookPrice?.value);
      fd.append("interPB", formData?.basicInteractiveBoookPrice?.value);
      fd.append("epubPR", formData?.premiumInteractiveEpubPrice?.value);
      fd.append("epubBR", formData?.basicInteractiveEpubPrice?.value);
      fd.append("pais", formData?.countryCode?.value);
      fd.append("limite_lecturas_diarias", formData?.dailyReadLimit?.value);
      fd.append("tipoUsuario", type);
      fd.append("vendedor", vendedor?.value?.username);
      fetch(urlApi, {
        method: "POST",
        credentials: "include",
        body: fd,
      })
        .then(async (res) => {
          const data = await res.json();
          if (data.status === 1) {
            await swal({
              title: "Cliente creado",
              text: "El cliente se creo satisfactoriamente!",
              icon: "success",
            });
          } else {
            await swal({
              title: "Error",
              text: data.info,
              icon: "error",
            });
          }
        })
        .catch((e) => console.log(e.message));
    }
    setLoadingButton(false);
  };

  const clearErrors = () => {
    const data = { ...formData };
    Object.keys(data).forEach((key) => {
      data[key].error = false;
    });
  };

  const clearForm = () => {
    const data = { ...fData };
    setVendedor({
      value: null,
      error: false,
      required: true,
    });
    clearErrors();
    setFormData(data);
  };

  return loading ? (
    <div className="mt-2">
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton variant="rect" height={58} />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </div>
  ) : (
    <form onSubmit={handleSubmit}>
      <label className="admin-label-text mt-3 d-block">Usuario *</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        value={formData.user.value}
        name="user"
        placeholder="Usuario (min 4 caracteres y sin espacios)"
        onChange={handleChange}
        error={formData.user.error}
        helperText={formData.user.error ? "Campo no válido" : null}
      />

      <label className="admin-label-text mt-3 d-block">Email *</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        value={formData.email.value}
        name="email"
        placeholder="E-mail"
        onChange={handleChange}
        error={formData.email.error}
        helperText={formData.email.error ? "Campo no válido" : null}
      />

      <label className="admin-label-text mt-3 d-block">Password *</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type={formData.password.show ? "text" : "password"}
        value={formData.password.value}
        name="password"
        placeholder="Password (min 6 caracteres)"
        onChange={handleChange}
        error={formData.password.error}
        helperText={formData.password.error ? "Campo no válido" : null}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                aria-label="clear password"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setFormData({
                    ...formData,
                    password: {
                      ...formData.password,
                      value: "",
                    },
                    passwordConfirmation: {
                      ...formData.passwordConfirmation,
                      value: "",
                    },
                  });
                }}
                edge="end"
              >
                <Tooltip title="Eliminar contraseñas">
                  <Close />
                </Tooltip>
              </IconButton>
              <IconButton
                aria-label="generate password"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  const autoPassword = generatePasswords(1)[0];
                  setFormData({
                    ...formData,
                    password: {
                      ...formData.password,
                      value: autoPassword,
                    },
                    passwordConfirmation: {
                      ...formData.passwordConfirmation,
                      value: autoPassword,
                    },
                  });
                }}
                edge="end"
              >
                <Tooltip title="Generar contraseña">
                  <LockRounded />
                </Tooltip>
              </IconButton>
              <IconButton
                aria-label="toggle password visibility"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setFormData({
                    ...formData,
                    password: {
                      ...formData.password,
                      show: !formData.password.show,
                    },
                  });
                }}
                edge="end"
              >
                {formData.password.show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <label className="admin-label-text mt-3 d-block">
        Confirmar Password *
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type={formData.passwordConfirmation.show ? "text" : "password"}
        value={formData.passwordConfirmation.value}
        name="passwordConfirmation"
        placeholder="Password"
        onChange={handleChange}
        error={formData.passwordConfirmation.error}
        helperText={
          formData.passwordConfirmation.error ? "Campo no válido" : null
        }
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                aria-label="toggle password visibility"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setFormData({
                    ...formData,
                    passwordConfirmation: {
                      ...formData.passwordConfirmation,
                      show: !formData.passwordConfirmation.show,
                    },
                  });
                }}
                edge="end"
              >
                {formData.passwordConfirmation.show ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <label className="admin-label-text mt-3 d-block">
        IPs (no dejar espacios en blanco)
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        value={formData.ips.value}
        name="ips"
        placeholder="Usar ';' para separar IPs y '-' para separar rangos. EJ: 190.0.0.25-191.0.0.25;192.2.2.3 <dejar vacío si no aplica>"
        onChange={(e) => {
          let lastIn = e.target.value
            .charAt(e.target.value.length - 1)
            .toLowerCase();
          // solo permite ingresar números|";"|"-"|"."
          if (
            e.target.value === "" ||
            (lastIn &&
              (lastIn.match(/[0-9]/i) || [";", "-", "."].includes(lastIn)))
          )
            handleChange(e);
        }}
        error={formData.ips.error}
        helperText={formData.ips.error ? "Campo no válido" : null}
      />

      <label className="admin-label-text mt-3 d-block">Identificador *</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        value={formData.id.value}
        name="id"
        placeholder="Identificador del cliente"
        onChange={handleChange}
        error={formData.id.error}
        helperText={formData.id.error ? "Campo no válido" : null}
      />

      {type === "Instituto" && (
        <>
          <label className="admin-label-text mt-3 d-block">Instituto *</label>
          <Autocomplete
            options={institutes}
            getOptionLabel={(option) => option.nombre}
            value={formData.institute.value}
            onChange={(e, newVal) => handleChange(e, "institute", newVal)}
            noOptionsText="Sin resultados"
            disabled={formData.noInstitute.value}
            renderInput={(params) => (
              <TextField
                {...params}
                name="institute"
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                error={formData.institute.error}
                helperText={formData.institute.error ? "Campo no válido" : null}
              />
            )}
          />

          <label className="admin-muted-text mt-2">
            Si el instituto no está en la lista escríbelo aquí:
          </label>
          <TextField
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            type="text"
            value={formData.noInstitute.value}
            name="noInstitute"
            placeholder="Nombre del instituto"
            onChange={handleChange}
            disabled={formData.institute.value?.nombre ? true : false}
            error={formData.noInstitute.error}
            helperText={formData.noInstitute.error ? "Campo no válido" : null}
          />
        </>
      )}

      <label className="admin-label-text mt-3 d-block">Concurrencias *</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        value={formData.concurrences.value}
        name="concurrences"
        placeholder="# de concurrencias"
        onChange={handleChange}
        error={formData.concurrences.error}
        helperText={formData.concurrences.error ? "Campo no válido" : null}
      />

      {
        (type === "Individual") &&
        <>
          <label className="admin-label-text mt-3 d-block">
            Límite de Lecturas Diarias (0 para desactivar el límite) *
          </label>
          <TextField
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            type="number"
            value={formData.dailyReadLimit.value}
            name="dailyReadLimit"
            onChange={(e) => {
              // no permite valores menores a 0
              if (e.target.value >= 0) handleChange(e);
            }}
            error={formData.dailyReadLimit.error}
            helperText={formData.dailyReadLimit.error ? "Campo no válido" : null}
          />
        </>}

      <label className="admin-label-text mt-3 d-block">Codigo de País *</label>
      <Autocomplete
        options={paisesAutocomplete}
        getOptionLabel={(option) => option}
        value={formData.countryCode.value}
        onChange={(e, value) => handleChange(e, "countryCode", value)}
        noOptionsText="Sin resultados"
        error={formData.countryCode.error}
        helperText={formData.countryCode.error ? "Campo no válido" : null}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Selecciona un país"
            variant="outlined"
            size="small"
            error={formData.countryCode.error}
            helperText={formData.countryCode.error ? "Campo no válido" : null}
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">Administrador *</label>
        <IOSSwitch
          name="admin"
          checked={formData.admin.value}
          onClick={changeSwitch}
        />
      </div>

      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">Cliente *</label>
        <IOSSwitch
          name="client"
          checked={formData.client.value}
          onClick={changeSwitch}
        />
      </div>

      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">Subcliente *</label>
        <IOSSwitch
          name="subClient"
          checked={formData.subClient.value}
          onClick={changeSwitch}
        />
      </div>

      <label className="admin-label-text mt-3 d-block">
        Precio libro interactivo Premium *
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="number"
        value={formData.premiumInteractiveBoookPrice.value}
        name="premiumInteractiveBoookPrice"
        onChange={(e) => {
          // no permite valores menores a 0
          if (e.target.value >= 0) handleChange(e);
        }}
        error={formData.premiumInteractiveBoookPrice.error}
        helperText={
          formData.premiumInteractiveBoookPrice.error ? "Campo no válido" : null
        }
      />

      <label className="admin-label-text mt-3 d-block">
        Precio libro interactivo Básico *
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="number"
        value={formData.basicInteractiveBoookPrice.value}
        name="basicInteractiveBoookPrice"
        onChange={(e) => {
          // no permite valores menores a 0
          if (e.target.value >= 0) handleChange(e);
        }}
        error={formData.basicInteractiveBoookPrice.error}
        helperText={
          formData.basicInteractiveBoookPrice.error ? "Campo no válido" : null
        }
      />

      <label className="admin-label-text mt-3 d-block">
        Precio libro Epub Premium *
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="number"
        value={formData.premiumInteractiveEpubPrice.value}
        name="premiumInteractiveEpubPrice"
        onChange={(e) => {
          // no permite valores menores a 0
          if (e.target.value >= 0) handleChange(e);
        }}
        error={formData.premiumInteractiveEpubPrice.error}
        helperText={
          formData.premiumInteractiveEpubPrice.error ? "Campo no válido" : null
        }
      />

      <label className="admin-label-text mt-3 d-block">
        Precio libro Epub Básico *
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="number"
        value={formData.basicInteractiveEpubPrice.value}
        name="basicInteractiveEpubPrice"
        onChange={(e) => {
          // no permite valores menores a 0
          if (e.target.value >= 0) handleChange(e);
        }}
        error={formData.basicInteractiveEpubPrice.error}
        helperText={
          formData.basicInteractiveEpubPrice.error ? "Campo no válido" : null
        }
      />

      <label className="admin-label-text mt-3 d-block">Entre las fechas:</label>
      <div className="d-flex">
        <TextField
          name="dateFrom"
          variant="outlined"
          size="small"
          type="date"
          style={{
            width: "180px",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
          value={formData.dateFrom.value}
          onChange={handleChange}
          error={formData.dateFrom.error}
          helperText={formData.dateFrom.error ? "Campo no válido" : null}
        />
        <CustomGreenButton
          variant="contained"
          style={{
            height: "40px",
            pointerEvents: "none",
          }}
          className="d-block d-md-inline"
          disableTouchRipple
          disableRipple
          disableElevation
          disableFocusRipple
        >
          Hasta
        </CustomGreenButton>
        <TextField
          name="dateTo"
          variant="outlined"
          size="small"
          type="date"
          style={{
            width: "180px",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
          value={formData.dateTo.value}
          onChange={handleChange}
          error={formData.dateTo.error}
          helperText={formData.dateTo.error ? "Campo no válido" : null}
        />
      </div>

      <label className="admin-label-text mt-3 d-block">Vendedor *</label>
      <Autocomplete
        options={vendedores.length ? vendedores : [{ ...client }]}
        getOptionLabel={(option) => option.username}
        value={vendedor.value}
        onChange={(_, value) => changeVendedor(value)}
        noOptionsText="Sin resultados"
        // disabled={client.rol === "Vendedor"}
        renderInput={(params) => (
          <TextField
            {...params}
            name="vendedor"
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={vendedor.error}
            helperText={vendedor.error ? "Campo no válido" : null}
          />
        )}
      />

      <div className="mt-3 w-100 d-flex justify-content-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckRounded />}
          style={{
            background: "#81c868",
          }}
          onClick={handleSubmit}
          disabled={loadingButton}
        >
          Agregar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="ml-2"
          startIcon={
            <CloseRounded
              style={{
                fontSize: "25px",
              }}
            />
          }
          style={{
            background: "#f05050",
          }}
          onClick={clearForm}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default AgregarNuevoClienteForm;

const fData = {
  user: {
    value: "",
    error: false,
    required: true,
  },
  email: {
    value: "",
    error: false,
    required: false,
  },
  password: {
    value: "",
    error: false,
    required: true,
    show: false,
  },
  passwordConfirmation: {
    value: "",
    error: false,
    required: true,
    show: false,
  },
  ips: {
    value: "",
    error: false,
    required: false,
  },
  id: {
    value: "",
    error: false,
    required: true,
  },
  institute: {
    value: null,
    error: false,
    required: false,
  },
  noInstitute: {
    value: "",
    error: false,
    required: false,
  },
  concurrences: {
    value: "",
    error: false,
    required: true,
  },
  dailyReadLimit: {
    value: 0,
    error: false,
    required: true,
  },
  countryCode: {
    value: "",
    error: false,
    required: true,
  },
  admin: {
    value: false,
    error: false,
    required: false,
  },
  client: {
    value: false,
    error: false,
    required: false,
  },
  subClient: {
    value: false,
    error: false,
    required: false,
  },
  premiumInteractiveBoookPrice: {
    value: 0,
    error: false,
    required: true,
  },
  basicInteractiveBoookPrice: {
    value: 0,
    error: false,
    required: true,
  },
  premiumInteractiveEpubPrice: {
    value: 0,
    error: false,
    required: true,
  },
  basicInteractiveEpubPrice: {
    value: 0,
    error: false,
    required: true,
  },
  dateFrom: {
    value: "",
    error: false,
    required: true,
  },
  dateTo: {
    value: "",
    error: false,
    required: true,
  },
};
