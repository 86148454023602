import { useAuth } from './shared/contex/auth/auth-context'

import Login from './pages/login/login'
import AdminDashboad from './components/main/admin-dashboard'
import React, { useEffect } from "react"
import { GENERAL_URL_API } from "./shared/urls";

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import 'antd/dist/antd.css'
import './App.css';

const App = () => {

  //creamos el objeto para acceder al contexto de la autentificacion
  const auth = useAuth()

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    let fd = new FormData();
    const url = `${GENERAL_URL_API}/confirmLogin.php`;
    let res;
    try {
      res = await fetch(url, {
        method: "POST", credentials: 'include',
        body: fd,
      });
    } catch (e) {}
    const data = await res.json();
    if(data.status == 0){
      auth.logout()
    }
  };

  return (
    !auth.isLoggedIn ?
      <Login />
      :
      <AdminDashboad />
  );
}

export default App
