import { useHistory, useLocation } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

//Este componente corresponde al collapse del menu de navegacion
const NavigationCollapse = (props) => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const ListItem = withStyles({
    root: {
      "&$selected": {
        backgroundColor: "#f4f8fb",
        color: "#5fbeaa",
        borderLeft: "solid 2px",
      },
      "&$selected:hover": {
        backgroundColor: "#f4f8fb",
        color: "#5fbeaa",
      },
      "&:hover": {
        color: "#5fbeaa",
      },
      color: "#75798B",
    },
    selected: {},
  })(MuiListItem);

  let categoireSelected = false;
  let items = [];

  //Recorremos todos los items de esta categoria y hacemos un boton
  //Al hacer click en un boton redirige a la pagina de la url correspondiente
  props.categorieItems.forEach((item, index) => {
    if (!item.hide) {
      items.push(
        <ListItem
          button
          onClick={() => {
            history.push(item.url);
          }}
          selected={location.pathname === item.url}
          key={index}
        >
          <ListItemText
            primary={item.name}
            classes={{ primary: classes.categorytext }}
            className="pl-3"
          />
        </ListItem>
      );
      if (item.url === location.pathname) {
        categoireSelected = true;
      }
    }
  });

  /*
     Si la url actual corresponde a una de las url de los items de esta categoria o
     la categoria esta seleccionada este collapse aparece como seleccionado
    */
  return (
    <>
      <ListItem
        button
        onClick={() => props.changeCategorie(props.categorieID)}
        className={classes.parent}
        selected={props.currentCategorie || categoireSelected}
      >
        {props.children}
        <ListItemText
          primary={props.categorieLabel}
          classes={{ primary: classes.categorytext }}
        />
        {props.currentCategorie ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={props.currentCategorie} timeout="auto">
        <List component="div" disablePadding>
          {items.length ? (
            items
          ) : (
            <span className="admin-muted-text mx-2 w-100 mt-2 py-4 px-2">
              Nada que mostrar
            </span>
          )}
        </List>
      </Collapse>
    </>
  );
};

export default NavigationCollapse;

const useStyles = makeStyles(() => ({
  categorytext: {
    fontSize: "14px",
    fontFamily: "'Noto Sans JP', sans-serif",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: "1.3",
  },
}));
