import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import swal from "sweetalert";
import Skeleton from "@material-ui/lab/Skeleton";
import { GENERAL_URL_API } from "../../../../shared/urls";
import EditarClienteAdministrativoForm from "./editar-usuario-form";
import IOSSwitch from "../../../../components/shared/ios-switch";

import BrushOutlinedIcon from "@material-ui/icons/BrushOutlined";
import PersonIcon from "@material-ui/icons/Person";

const EditarUsuarioAdministrativo = () => {
  //Definimos un estado para guardar la informacion del autocomplete
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [filtroActividad, setFiltroActividad] = useState({
    all: true,
    active: false,
    inactive: false,
  });

  useEffect(() => {
    setAutoCompleteOptions([]);
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroActividad]);

  //fetch Users List
  const fetchUsers = async () => {
    let show;
    Object.keys(filtroActividad).forEach((key) => {
      if (filtroActividad[key] === true) show = key;
    });
    const fd = new FormData();
     
    fd.append("show", show);
    const url = `${GENERAL_URL_API}/userAdmin/getUsers.php`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();

    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      setAutoCompleteOptions(resJSON.data);
    }
  };

  const fetchUser = async (username) => {
    const fd = new FormData();
     
    fd.append("getUsername", username);
    const url = `${GENERAL_URL_API}/userAdmin/getUser.php`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();

    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      setAutoCompleteValue(resJSON.data);
    }
  };

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = async (e, newValue) => {
    const { username } = newValue;
    fetchUser(username);
  };

  const clearSelection = async () => {
    await fetchUsers();
    setAutoCompleteValue(null);
  };

  const toggleSwitch = (e) => {
    const name = e.target.name;
    const temp = {
      all: false,
      active: false,
      inactive: false,
      [name]: true,
    };
    setFiltroActividad({ ...temp });
  };

  /*
    Esta pagina cambia dependiendo si un usuario ha seleccionado un elemento del autocomplete
    si el valor del autoCompleteValue es null se retorna el autocomplete
    si ese valor no es null se retorna el formulario para editar un clietne
  */
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <BrushOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Editar un Usuario Administrativo</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "22px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Buscar y editar un Usuario administrativo existente
              </div>
            </div>
            {!autoCompleteValue ? (
              <>
                <label
                  className="admin-label-text mt-3 d-inline"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Todos:
                </label>
                <IOSSwitch
                  name="all"
                  checked={filtroActividad.all}
                  onClick={toggleSwitch}
                />

                <label
                  className="admin-label-text mt-2 d-inline"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Activos:
                </label>
                <IOSSwitch
                  checked={filtroActividad.active}
                  onClick={toggleSwitch}
                  name="active"
                />
                <label
                  className="admin-label-text mt-2 d-inline"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Inactivos:
                </label>
                <IOSSwitch
                  checked={filtroActividad.inactive}
                  onClick={toggleSwitch}
                  name="inactive"
                />
                {autoCompleteOptions.length ? (
                  <>
                    <label className="admin-label-text mt-3 d-block">
                      Buscar:
                    </label>
                    <Autocomplete
                      options={autoCompleteOptions}
                      getOptionLabel={(option) => option.username}
                      value={autoCompleteValue}
                      onChange={autoCompleteUpdate}
                      noOptionsText="Sin resultados"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          style={{
                            width: "100%",
                            fontFamily: "'Noto Sans JP', sans-serif",
                          }}
                        />
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </>
                )}
              </>
            ) : (
              <EditarClienteAdministrativoForm
                user={autoCompleteValue}
                clearUser={clearSelection}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditarUsuarioAdministrativo;
