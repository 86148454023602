// import AnimatedNumber from "animated-number-react"
import { Container, Row, Col } from "react-bootstrap";

// import BookIcon from '@material-ui/icons/Book'
// import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
// import TvIcon from '@material-ui/icons/Tv'
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { useClient } from "../../shared/contex/client/client-context";

const Home = () => {
  //   const formatValue = (value) => value.toFixed(0);
  const { getClient } = useClient();
  const client = getClient();

  return (
    <Container fluid>
      <Row className="mb-lg-4">
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="admin-main-title-gray">
            Bienvenido al panel de administrador de Make-Make
          </div>
          <div className="admin-main-title-green mt-4">
            {localStorage.getItem("userAdminMake")}
          </div>
        </Col>
      </Row>
      <Row className="mb-lg-4">
        <Col
          xs={12}
          md={6}
          lg={3}
          className="p-0 d-flex justify-content-between pr-md-2 mb-3 mb-lg-0"
        >
          <div className="icon-with-text-left pl-4">
            <div className="icon-with-text-font" style={{ fontSize: "12px" }}>
              {client?.email}
            </div>
            <div className="admin-muted-text">Email</div>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          lg={3}
          className="p-0 d-flex justify-content-between pl-md-2 pr-lg-2 mb-3 mb-lg-0"
        >
          <div className="icon-with-text-left pl-4">
            <div className="icon-with-text-font" style={{ fontSize: "20px" }}>
              {client?.rol}
            </div>
            <div className="admin-muted-text">Rol</div>
          </div>
          <div className="icon-with-text-right"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
