import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import TvIcon from "@material-ui/icons/Tv";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import swal from "sweetalert";

import { GENERAL_URL_API } from "../../../shared/urls";
import { columnasLecturasLibros } from "../../../shared/data/columnas";
import exportFromJSON from "export-from-json";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";

import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const ReporteLecturas = () => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fullRows, setFullRows] = useState([]);
  const [tipoValue, setTipoValue] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [userSelected] = useState([]);
  const [usuariosSel, setUsuarios] = useState("");
  const [formData, setFormData] = useState({
    fechaIni: { value: "", error: false },
    fechaFin: { value: "", error: false },
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };

  const editorialSelectedUpdate = (e, newValue) => {
    let temp = newValue;
    let aux = "";
    if (temp.length > 0) {
      for (let i = 0; i < temp.length; i++) {
        aux = aux + temp[i].username + ",";
      }
      setUsuarios(aux.substring(0, aux.length - 1));
    }
  };
  const autoComplete2Update = async (e, newValue) => {
    //Si efectivametne se seleccion un nuevo valor actualizamos el estado
    if (newValue) {
      setError(false);
      setTipoValue(newValue);
    }
    const fd = new FormData();
     
    fd.append("show", "all");
    fd.append("tipoUsuario", newValue.tipo);
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      setCheck(false);
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      setCheck(true);
      setClientes(resJSON.data);
    }
  };

  const submitText = async () => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("cliente", usuariosSel);
    if (formData.fechaIni.value !== "" && formData.fechaFin.value !== "") {
      fd.append("fechaInicio", formData.fechaIni.value);
      fd.append("fechaFin", formData.fechaFin.value);
    }
    fd.append("maxEntradas", 5000);

    const res = await fetch(`${GENERAL_URL_API}/verDatos/lecturaLibros.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.info.includes("No hay resultados")) {
      await swal({
        title: "Error",
        text: "No se encontraron entradas validas",
        icon: "error",
      });
    }
    let tempColumns = columnasLecturasLibros;
    tempColumns.map((_, index) => (tempColumns[index].isVisible = true));
    setRows(resJSON.data);
    setColumns(tempColumns);

    const fd2 = new FormData();
     
    fd2.append("cliente", usuariosSel);
    if (formData.fechaIni.value !== "" && formData.fechaFin.value !== "") {
      fd2.append("fechaInicio", formData.fechaIni.value);
      fd2.append("fechaFin", formData.fechaFin.value);
    }
    fd2.append("maxEntradas", 50000000);

    const res2 = await fetch(`${GENERAL_URL_API}/verDatos/lecturaLibros.php`, {
      method: "POST", credentials: 'include',
      body: fd2,
    });
    const resJSON2 = await res2.json();
    if (resJSON2.info.includes("No hay resultados")) {
      await swal({
        title: "Error",
        text: "No se encontraron entradas validas",
        icon: "error",
      });
    }
    setFullRows(resJSON2.data);
    setLoading(false);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Reporte de lecturas</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <AccountBalanceIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Selecciona un cliente (Dejar vacio para ver el reporte general)
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Tipo usuario:
              </label>
              <Autocomplete
                options={[{ tipo: "Individual" }, { tipo: "Instituto" }]}
                getOptionLabel={(option) => option.tipo}
                value={tipoValue}
                onChange={autoComplete2Update}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    helperText={error ? "Seleccione un tipo de cliente" : null}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              {check && (
                <div>
                  <label className="admin-label-text mt-3 d-block">
                    Buscar (Si no selecciona ninguno se mostrara el reporte
                    general):
                  </label>
                  <Autocomplete
                    multiple
                    options={clientes}
                    getOptionLabel={(option) => option.username}
                    defaultValue={userSelected}
                    disableCloseOnSelect
                    noOptionsText="Sin resultados"
                    onChange={editorialSelectedUpdate}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          color="primary"
                        />
                        {option.username}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        style={{
                          width: "100%",
                          fontFamily: "'Noto Sans JP', sans-serif",
                        }}
                      />
                    )}
                  />
                  <label className="admin-label-text mt-3 d-block">
                    Entre las fechas (Si no selecciona ninguna se mostraran los
                    ultimos 5000 registros):
                  </label>
                  <div className="d-flex">
                    <TextField
                      name="fechaIni"
                      value={formData.fechaIni.value}
                      onChange={updateForm}
                      variant="outlined"
                      size="small"
                      type="date"
                      style={{
                        width: "180px",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                    <CustomGreenButton
                      variant="contained"
                      style={{
                        height: "40px",
                        pointerEvents: "none",
                      }}
                      className="d-block d-md-inline"
                      disableTouchRipple
                      disableRipple
                      disableElevation
                      disableFocusRipple
                    >
                      Hasta
                    </CustomGreenButton>
                    <TextField
                      name="fechaFin"
                      value={formData.fechaFin.value}
                      onChange={updateForm}
                      variant="outlined"
                      size="small"
                      type="date"
                      style={{
                        width: "180px",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  </div>
                  <br />
                  <CustomGreenButton
                    variant="contained"
                    className="mt-4"
                    onClick={submitText}
                    disabled={loading}
                  >
                    Consultar
                  </CustomGreenButton>
                </div>
              )}
            </form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Reporte de lecturas por cliente
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Consultas de lecturas por cliente
            </div>

            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <>
                {/* Este componente es el dropdown que permite ocultar columnas*/}
                <TableColumnDropdown
                  columns={columns}
                  changeColumnStatus={changeColumnStatus}
                />
                <br />

                <ButtonGroup size="small" variant="contained" className="my-3">
                  <CustomGreenButton
                    onClick={() => {
                      const dat = [...rows];
                      navigator.clipboard
                        .writeText(JSON.stringify(dat))
                        .then(async () => {
                          await swal({
                            title: "Se ha copiado la tabla!",
                            icon: "success",
                          });
                        });
                    }}
                  >
                    Copy
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: fullRows,
                        fileName: "reporte-lecturas",
                        exportType: exportFromJSON.types.csv,
                      });
                    }}
                  >
                    CSV
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: fullRows,
                        fileName: "reporte-lecturas",
                        exportType: exportFromJSON.types.json,
                      });
                    }}
                  >
                    JSON
                  </CustomGreenButton>
                  <CustomGreenButton>
                    <ExcelWorkBook
                      columns={columns}
                      rows={fullRows}
                      filename={"reporte-lecturas"}
                    />
                  </CustomGreenButton>
                </ButtonGroup>
                <MDBDataTableV5
                  small
                  responsive
                  searchTop
                  searchBottom={false}
                  barReverse
                  pagingTop
                  striped
                  bordered
                  data={{
                    columns: columns.filter((obj) => obj.isVisible),
                    rows: rows,
                  }}
                  noBottomColumns
                  order={["username", "asc"]}
                  entriesOptions={[5, 10, 20, 50]}
                  entriesLabel="Numero de filas"
                  searchLabel="Buscar"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={["Mostrando filas", "a", "de", "filas"]}
                  fullPagination
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReporteLecturas;
