import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import { DropzoneArea } from "material-ui-dropzone";
import { GENERAL_URL_API } from "../../../shared/urls";
import swal from "sweetalert";
import { paisesAutocomplete } from "../../../shared/data/paises";
import IOSSwitch from "../../../components/shared/ios-switch";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";
import { fetchTiposDeIngreso } from "../../../shared/fetchers/classics/institutos/verTiposDeIngreso";
import { Skeleton } from "@material-ui/lab";

const AgregarNuevoInstituto = ({ instituto, clearInstituto }) => {
  const [loading, setLoading] = useState(false);
  //Definimos los estaos para almacenar los datos del formulario y sus errores
  const [formData, setFormData] = useState({
    nombreInstituto: { value: instituto?.nombre ?? "", error: false },
    email: {
      value: instituto?.correo ?? "",
      error: false,
    },
    telefono: {
      value: instituto?.telefono ?? "",
      error: false,
    },
    codigoPais: {
      value: instituto?.pais ?? "",
      error: false,
    },
    numeroSedes: {
      value: instituto?.sedes ?? 0,
      error: false,
    },
    maxEstudiantes: {
      value: instituto?.maxEstudiantes ?? 0,
      error: false,
    },
    tipoIngreso: {
      value: instituto?.tipoIngreso ?? "",
      error: false,
      optional: true,
    },
    tipoServicio: {
      value: instituto?.tipoServicio ?? "",
      error: false,
      optional: true,
    },
    linkAccesoApp: {
      value: instituto?.linkAccesoApp ?? "",
      error: false,
      optional: true,
    },
    linkAccesoWeb: {
      value: instituto?.linkAccesoWeb ?? "",
      error: false,
      optional: true,
    },
    linkTutorial: {
      value: instituto?.linkTutorial ?? "",
      error: false,
      optional: true,
    },
    fechaSusInicial: {
      value: instituto?.fechaIniSub ?? "",
      error: false,
    },
    fechaSusFinal: {
      value: instituto?.fechaFinSub ?? "",
      error: false,
    },
    colorHeader: {
      value: instituto?.colorHeader ?? "#ffffff",
      error: false,
    },
    colorLetras: {
      value: instituto?.colorLetra ?? "#000000",
      error: false,
    },
    direccion: {
      value: instituto?.direccion ?? "",
      error: false,
    },
    vendedor: {
      value: instituto?.vendedor ?? "",
      error: false,
    },
    activo: {
      value: Boolean(Number(instituto?.activo)) ?? false,
      error: false,
    },
    estadoActivacion: {
      value: Boolean(Number(instituto?.estadoActivacion)) ?? false,
      error: false,
      optional: true,
    },
    estadoSubscripcion: {
      value: Boolean(Number(instituto?.estadoSubscripcion)) ?? false,
      error: false,
      optional: true,
    },
    ingresoApp: {
      value: Boolean(Number(instituto?.ingresoApp)) ?? false,
      error: false,
      optional: true,
    },
  });

  const [vendedores, setVendedores] = useState([]);

  const {
    data: tiposIngreso,
    isLoading: isLoadingTiposIngreso
  } = useQuery(["tiposIngreso"], fetchTiposDeIngreso);

  //Definimos un estado para guardar la imagen y la preview
  const [imageState, setImageState] = useState({
    imageFile: null,
    imageUrl: null,
    error: false,
  });

  useEffect(() => {
    fetchVendedores();
  }, []);

  const fetchVendedores = async () => {
    const fd = new FormData();

    fetch(`${GENERAL_URL_API}/userAdmin/getUsers.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    })
      .then(async (res) => {
        if (!res.ok) throw new Error();
        const data = await res.json();
        setVendedores(data.data.map((vendedor) => vendedor.username));
        setLoading(false);
      })
      .catch((_) => {
        swal({
          title: "Sorry :(",
          text: "¡Hubo un problema obteniendo los vendedores!",
          icon: "warning",
        });
      });
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const value = v || e.target.value;
    const name = n || e.target.name;
    setFormData({
      ...formData,
      [name]: { value, error: false },
    });
  };
  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      if (
        formElement !== "colorHeader" &&
        formElement !== "colorLetras" &&
        formElement !== "numeroSedes"
      ) {
        temp[formElement] = { value: "", error: false };
      } else if (
        formElement === "colorHeader" &&
        formElement === "colorLetras"
      ) {
        temp[formElement] = { value: "#000000", error: false };
      } else if (formElement === "numeroSedes") {
        temp[formElement] = { value: 0, error: false };
      }
    }
    setFormData(temp);
    setImageState({
      ...imageState,
      error: false,
    });
  };

  const clearErrors = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], error: false };
    }
    setImageState({
      ...imageState,
      error: false,
    });
  };

  //Funcion para guardar una imagen seleccionada
  const handleImage = (files) => {
    if (files.length === 1 && files[0].type === "image/png") {
      const pickedFile = files[0];
      setImageState({
        imageFile: pickedFile,
        imageUrl: URL.createObjectURL(pickedFile),
        error: false,
      });
    }
  };

  const validate = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      if (!temp[formElement].optional && (temp[formElement].value === "" || temp[formElement].value === "0")) {
        temp[formElement].error = true;
        isValid = false;
      }
    }

    //Actualizamos el estado con todos los cambios
    setFormData(temp);

    if (isValid) clearErrors();
    return isValid;
  };

  //Funcion para subir la informacion y validad todos los datos
  const SubmitForm = async () => {
    setLoading(true);
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    if (validate()) {
      for (const formElement in temp) {
        console.log(temp[formElement].value);
      }
      console.log(imageState.imageFile);

      const fd = new FormData();

      fd.append("nombre", formData.nombreInstituto.value);
      fd.append("correo", formData.email.value);
      fd.append("telefono", formData.telefono.value);
      fd.append("pais", formData.codigoPais.value);
      fd.append("direccion", formData.direccion.value);
      fd.append("sedes", formData.numeroSedes.value);
      fd.append("maxEstudiantes", formData.maxEstudiantes.value);
      fd.append("fechaIniSub", formData.fechaSusInicial.value);
      fd.append("fechaFinSub", formData.fechaSusFinal.value);
      fd.append("colorHeader", formData.colorHeader.value);
      fd.append("colorLetras", formData.colorLetras.value);
      fd.append("color", formData.colorHeader.value);
      fd.append("vendedor", formData.vendedor.value);
      fd.append("tipoIngreso", formData.tipoIngreso.value);
      fd.append("tipoServicio", formData.tipoServicio.value);
      fd.append("linkAccesoApp", formData.linkAccesoApp.value);
      fd.append("linkAccesoWeb", formData.linkAccesoWeb.value);
      fd.append("linkTutorial", formData.linkTutorial.value);
      fd.append("estadoActivacion", formData.estadoActivacion.value ? 1 : 0);
      fd.append("estadoSubscripcion", formData.estadoSubscripcion.value ? 1 : 0);
      fd.append("ingresoApp", formData.ingresoApp.value ? 1 : 0);
      if (instituto) {
        fd.append("idInstituto", instituto.idSuperinstitutoPlanLector);
        fd.append("activo", formData.activo.value ? 1 : 0);
      }
      if (imageState.imageFile) fd.append("logo", imageState.imageFile);

      const url = `${GENERAL_URL_API}/institutos/${instituto ? "editar" : "agregar"
        }Instituto.php`;
      try {
        const res = await fetch(url, {
          method: "POST",
          credentials: "include",
          credentials: "include",
          body: fd,
        });

        const data = await res.json();

        if (data.status) {
          swal({
            title: "Éxito!",
            text: data.info,
            icon: "success",
          });
          clearForm();
          if (instituto && clearInstituto) await clearInstituto();
        } else {
          swal({
            title: "Alerta",
            text: data.info,
            icon: "warning",
          });
        }
      } catch (e) {
        swal({
          title: "Error",
          text: "Error al conectar con el servidor",
          icon: "error",
        });
      }
    }
    setLoading(false);
  };

  if (isLoadingTiposIngreso)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar un nuevo instituto lector
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Crear un instituto lector nuevo
              </div>
            </div>
            <div className="admin-muted-text-small mt-2">
              Por favor ingresar la siguiente info:
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Nombre del Insituto*
              </label>
              <TextField
                name="nombreInstituto"
                value={formData.nombreInstituto.value}
                onChange={updateForm}
                error={formData.nombreInstituto.error}
                helperText={
                  formData.nombreInstituto.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Instituto (min 4 caracteres y sin espacios)"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Email*</label>
              <TextField
                name="email"
                value={formData.email.value}
                onChange={updateForm}
                error={formData.email.error}
                helperText={
                  formData.email.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="E-mail"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Telefono*</label>
              <TextField
                name="telefono"
                value={formData.telefono.value}
                onChange={updateForm}
                error={formData.telefono.error}
                helperText={
                  formData.telefono.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Telefono"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Dirección *
              </label>
              <TextField
                name="direccion"
                value={formData.direccion.value}
                onChange={updateForm}
                error={formData.direccion.error}
                helperText={
                  formData.direccion.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="direccion"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Codigo de País*
              </label>
              <Autocomplete
                options={paisesAutocomplete}
                getOptionLabel={(option) => option}
                value={formData.codigoPais.value}
                onChange={(e, value) => updateForm(e, "codigoPais", value)}
                noOptionsText="Sin resultados"
                error={formData.codigoPais.error}
                helperText={
                  formData.codigoPais.error ? "Campo no válido" : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Selecciona un país"
                    variant="outlined"
                    size="small"
                    error={formData.codigoPais.error}
                    helperText={
                      formData.codigoPais.error ? "Campo no válido" : null
                    }
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />

              <label className="admin-label-text mt-3 d-block">
                Vendedor *
              </label>
              <Autocomplete
                options={vendedores.length ? vendedores : ["No vendors"]}
                getOptionLabel={(option) => option}
                value={formData.vendedor.value}
                onChange={(e, value) => updateForm(e, "vendedor", value)}
                noOptionsText="Sin resultados"
                // disabled={client.rol === "Vendedor"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="vendedor"
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                    error={formData.vendedor.error}
                    helperText={
                      formData.vendedor.error ? "Campo no válido" : null
                    }
                  />
                )}
              />

              <label className="admin-label-text mt-3 d-block">
                Número de sedes*
              </label>
              <TextField
                name="numeroSedes"
                type="number"
                value={formData.numeroSedes.value}
                onChange={updateForm}
                error={formData.numeroSedes.error}
                helperText={
                  formData.numeroSedes.error
                    ? "Este campo no puede estar vacío ni ser cero"
                    : null
                }
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Máximo de estudiantes por sede*
              </label>
              <TextField
                name="maxEstudiantes"
                type="number"
                value={formData.maxEstudiantes.value}
                onChange={updateForm}
                error={formData.maxEstudiantes.error}
                helperText={
                  formData.maxEstudiantes.error
                    ? "Este campo no puede estar vacío ni ser cero"
                    : null
                }
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Tipo Ingreso
              </label>
              <Autocomplete
                options={tiposIngreso.filter((tipo) => tipo)}
                freeSolo
                name="tipoIngreso"
                getOptionLabel={(option) => option}
                value={formData.tipoIngreso.value}
                inputValue={formData.tipoIngreso.value}
                onInputChange={(_, value) =>
                  updateForm({
                    target: { name: "tipoIngreso", value },
                  })
                }
                noOptionsText="Sin resultados"
                error={formData.tipoIngreso.error}
                helperText={
                  formData.tipoIngreso.error ? "Campo no válido" : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tipos de ingreso"
                    variant="outlined"
                    size="small"
                    error={formData.tipoIngreso.error}
                    helperText={
                      formData.tipoIngreso.error ? "Campo no válido" : null
                    }
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />

              <label className="admin-label-text mt-3 d-block">
                Tipo Servicio
              </label>
              <TextField
                name="tipoServicio"
                value={formData.tipoServicio.value}
                onChange={updateForm}
                error={formData.tipoServicio.error}
                helperText={
                  formData.tipoServicio.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Tipo Servicio"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Link Acceso Web
              </label>
              <TextField
                name="linkAccesoWeb"
                value={formData.linkAccesoWeb.value}
                onChange={updateForm}
                error={formData.linkAccesoWeb.error}
                helperText={
                  formData.linkAccesoWeb.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Link Acceso Web"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Link Acceso App
              </label>
              <TextField
                name="linkAccesoApp"
                value={formData.linkAccesoApp.value}
                onChange={updateForm}
                error={formData.linkAccesoApp.error}
                helperText={
                  formData.linkAccesoApp.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Link Acceso App"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Link Tutorial
              </label>
              <TextField
                name="linkTutorial"
                value={formData.linkTutorial.value}
                onChange={updateForm}
                error={formData.linkTutorial.error}
                helperText={
                  formData.linkTutorial.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Link Tutorial"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                Fechas de subscripción *
              </label>
              <TextField
                name="fechaSusInicial"
                value={formData.fechaSusInicial.value}
                onChange={updateForm}
                error={formData.fechaSusInicial.error}
                variant="outlined"
                size="small"
                type="date"
                style={{
                  width: "180px",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <CustomGreenButton
                variant="contained"
                style={{ height: "40px" }}
                className="d-block d-md-inline"
                disableTouchRipple
                disableRipple
                disableElevation
                disableFocusRipple
              >
                Hasta
              </CustomGreenButton>
              <TextField
                name="fechaSusFinal"
                value={formData.fechaSusFinal.value}
                onChange={updateForm}
                error={formData.fechaSusFinal.error}
                variant="outlined"
                size="small"
                type="date"
                style={{
                  width: "180px",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <br />
              <label className="admin-label-text mt-3 d-block">
                Color Header*
              </label>
              <TextField
                name="colorHeader"
                value={formData.colorHeader.value}
                onChange={updateForm}
                error={formData.colorHeader.error}
                helperText={
                  formData.colorHeader.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                type="color"
                style={{
                  width: "30%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Color letras*
              </label>
              <TextField
                name="colorLetras"
                value={formData.colorLetras.value}
                onChange={updateForm}
                error={formData.colorHeader.error}
                helperText={
                  formData.colorLetras.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                type="color"
                style={{
                  width: "30%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <div className="d-flex">
                <label className="admin-label-text mt-3 d-block">Activo</label>
                <IOSSwitch
                  name="activo"
                  checked={formData.activo.value}
                  onClick={(_) => {
                    let fd = { ...formData };
                    fd.activo.value = !fd.activo.value;
                    setFormData(fd);
                  }}
                />
              </div>

              <div className="d-flex">
                <label className="admin-label-text mt-3 d-block">
                  Estado Activación
                </label>
                <IOSSwitch
                  checked={formData.estadoActivacion.value}
                  onClick={() => {
                    updateForm({
                      target: {
                        name: "estadoActivacion",
                        value: !formData.estadoActivacion.value,
                      },
                    });
                  }}
                />
              </div>

              <div className="d-flex">
                <label className="admin-label-text mt-3 d-block">
                  Estado Subscripción
                </label>
                <IOSSwitch
                  checked={formData.estadoSubscripcion.value}
                  onClick={() => {
                    updateForm({
                      target: {
                        name: "estadoSubscripcion",
                        value: !formData.estadoSubscripcion.value,
                      },
                    });
                  }}
                />
              </div>

              <div className="d-flex">
                <label className="admin-label-text mt-3 d-block">
                  Ingreso App
                </label>
                <IOSSwitch
                  checked={formData.ingresoApp.value}
                  onClick={() => {
                    updateForm({
                      target: {
                        name: "ingresoApp",
                        value: !formData.ingresoApp.value,
                      },
                    });
                  }}
                />
              </div>



              <label className="admin-label-text mt-3 d-block">
                Logo de instituto*
              </label>
              <div
                className="admin-muted-text-small"
                style={imageState.error ? { color: "red" } : null}
              >
                1 archivo requerido con extensión png ["logo_instituto.png"]
              </div>

              <DropzoneArea
                id="dropzone"
                dropzoneText="Arrastra un archivo o haz click aquí!"
                showFileNames={true}
                showAlerts={true}
                filesLimit={1}
                acceptedFiles={["image/png"]}
                onChange={(files) => handleImage(files)}
              />

              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitForm}
                  startIcon={<CheckRounded />}
                  disabled={loading}
                  style={{ background: "#81c868" }}
                >
                  {!instituto ? "Agregar" : "Editar"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (instituto) clearInstituto();
                    else clearForm();
                  }}
                  className="ml-2"
                  startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                  disabled={loading}
                  style={{ background: "#f05050" }}
                >
                  {!instituto ? "Eliminar" : "Cancelar"}
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarNuevoInstituto;
