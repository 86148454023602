export const fData = {
  manuvoId: {
    value: "",
    error: false,
    required: true,
  },
  title: {
    value: "",
    error: false,
    required: true,
  },
  bisac: {
    value: "",
    error: false,
    required: true,
  },
  subject: {
    value: null,
    error: false,
    required: true,
  },
  subjectEng: {
    value: null,
    error: false,
    required: true,
  },
  description: {
    value: "",
    error: false,
    required: true,
  },
  descriptionEng: {
    value: "",
  },
  source: {
    value: "",
  },
  date: {
    value: "",
  },
  relation: {
    value: "",
  },
  coverage: {
    value: "",
  },
  author: {
    value: "",
    error: false,
    required: true,
  },
  publisher: {
    value: "",
    error: false,
    required: true,
  },
  contributor: {
    value: "",
  },
  rights: {
    value: "",
  },
  isbn: {
    value: "",
    error: false,
    required: true,
  },
  format: {
    value: null,
    error: false,
    required: true,
  },
  type: {
    value: null,
    error: false,
    required: true,
  },
  royalties: {
    value: "",
    error: false,
    required: true,
  },
  lang: {
    value: "",
    error: false,
    required: true,
  },
  lang2: {
    value: "",
  },
  lang3: {
    value: "",
  },
  topic1: {
    value: null,
    error: false,
    required: true,
  },
  topic2: {
    value: "",
  },
  topic3: {
    value: "",
  },
  value: {
    value: "",
  },
  tag5: {
    value: "",
  },
  topic1Eng: {
    value: "",
  },
  topic2Eng: {
    value: "",
  },
  topic3Eng: {
    value: "",
  },
  valueEng: {
    value: "",
  },
  tag5Eng: {
    value: "",
  },
  dba: {
    value: null,
  },
  dbaKeyword1: {
    value: "",
  },
  dbaKeyword2: {
    value: "",
  },
  userLevel: {
    value: null,
    error: false,
    required: true,
  },
  readTime: {
    value: "",
    error: false,
    required: true,
  },
  age: {
    value: null,
    error: false,
    required: true,
  },
  course: {
    value: null,
    error: false,
    required: true,
  },
  levelling: {
    value: "",
  },
  levellingENG: {
    value: "",
  },
  values: {
    value: "",
  },
  valuesEng: {
    value: "",
  },
  compatibility: {
    value: null,
    error: false,
    required: true,
  }, //dd
  sound: {
    value: false,
  },
  interactive: {
    value: false,
  },
  free: {
    value: false,
  },
  libroPremiado: {
    value: false,
  },
  libroAccesible: {
    value: false,
  },
  libroBilingue: {
    value: false,
  },
  genre: {
    value: null,
    error: false,
    required: true,
  }, //dd
  tipoDeLibro: {
    value: null,
    error: false,
    required: true,
  }, //dd
  genreEng: {
    value: null,
  },
  recommended1: {
    value: "",
  },
  recommended2: {
    value: "",
  },
  linkExterno: { value: "" },
  link1: {
    value: "",
  },
  link1Text: {
    value: "",
  },
  link2: {
    value: "",
  },
  link2Text: {
    value: "",
  },
  link3: {
    value: "",
  },
  link3Text: {
    value: "",
  },
  blogLink: {
    value: "",
  },
  countries: {
    value: [],
  },
  publicationPlace: {
    value: "",
  },
  authorAlt: {
    value: "",
  },
  authorSecundaryFunction: {
    value: "",
  },
  englishLevel: {
    value: "",
  },
  active: {
    value: 0,
    error: false,
    required: true,
  },
};
