import React from "react";
import Workbook from "react-excel-workbook";

/**
 * Este componente se usa para exportar un archivo excel con los datos de la tabla
 *
 * @param {Array} columns - Array of columns
 * @param {Array} rows - Array of rows
 * @param {String} fileName - Name of the file to be downloaded
 */
const ExcelWorkBook = ({ columns, rows, filename }) => {
  return (
    <Workbook filename={`${filename}.xlsx`} element={<span>EXCEL</span>}>
      <Workbook.Sheet data={rows} name="Sheet A">
        {/* solo se toman las columnas que sean visibles */}
        {/* se filtra solo las que sean isVisible===true y se retornan Workbook.column por cada columna visible */}
        {columns
          .filter((col) => col.isVisible === true)
          .map((column) => (
            <Workbook.Column
              label={column.label}
              value={column.field}
              key={column.field}
            />
          ))}
      </Workbook.Sheet>
    </Workbook>
  );
};

export default ExcelWorkBook;
