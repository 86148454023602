import React, { useEffect } from "react";
// Bootstrap
import { Container, Row, Col } from "react-bootstrap";
// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import MenuBookIcon from "@material-ui/icons/MenuBook";
// MDB
import { MDBDataTableV5 } from "mdbreact";
// Alerts and toasts
import swal from "sweetalert";
// Shared
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import { columnasTags } from "../../../../shared/data/columnas";

const ListaTags = ({ tags }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="d-flex align-items-center">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Tabla de Tags
            </div>
          </div>
          <div className="admin-muted-text-small mt-1 mb-4">Tabla de Tags.</div>

          <ButtonGroup size="small" variant="contained" className="my-3">
            <CustomGreenButton
              onClick={() => {
                navigator.clipboard
                  .writeText(JSON.stringify([...tags]))
                  .then(async () => {
                    await swal({
                      title: "Se ha copiado la tabla!",
                      icon: "success",
                    });
                  });
              }}
            >
              Copy
            </CustomGreenButton>
            <CustomGreenButton>
              <ExcelWorkBook
                columns={columnasTags}
                rows={tags.filter((tag) => tag.tag?.length > 0)}
                filename={"Lista-Tags"}
              />
            </CustomGreenButton>
          </ButtonGroup>

          {tags.length <= 0 ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <MDBDataTableV5
              small
              responsive
              striped
              bordered
              searchTop
              searchBottom={false}
              barReverse
              pagingTop
              data={{
                columns: columnasTags,
                rows: tags.filter((tag) => tag.tag?.length > 0),
              }}
              noBottomColumns
              order={["name", "asc"]}
              entriesOptions={[5, 10, 20, 50]}
              entriesLabel="Numero de filas"
              searchLabel="Buscar"
              paginationLabel={["Anterior", "Siguiente"]}
              infoLabel={["Mostrando filas", "a", "de", "filas"]}
              fullPagination
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ListaTags;
