import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

/**
 * @returns {Promise<any>}
 */
export const fetchUsuariosAcademia = async () => {
  const { data } = await axios.get(
    `${GENERAL_URL_API}/academia/verUsuariosDatos`,
    {
      withCredentials: true,
    }
  );

  return data.data;
};
