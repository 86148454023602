import React, { useContext, useReducer } from "react";

import { LOGOUT, LOGIN } from "./auth-types";
import { GENERAL_URL_API } from "../../urls";

import { authReducer } from "./auth-reducer";

//Creamos un contexto con la Api de context
const AuthContext = React.createContext();

//Exportamos un objeto para poder acceder a los elementos del contexto donde sea
export const useAuth = () => {
  return useContext(AuthContext);
};

//Creamos un provider para que este contexto sea accesible a los componetes que
//esten dentro de este provider
export const AuthProvider = ({ children }) => {
  const initialState = {
    //Definimos un estado para guardar la indentificacion del usuario
    //Si hay una session en el localstorage inicia logaeado por defecto
    isLoggedIn: JSON.parse(localStorage.getItem("auth-value")) || false,
  };

  //Definimos este useReducer para gestionar todo lo relacionado con los estados de la autenticacion
  const [authState, dispatch] = useReducer(authReducer, initialState);

  //Definimos las funciones para cambiar el estado de autentificacion
  const login = () => {
    dispatch({ type: LOGIN, payload: null });
  };

  const logout = async () => {
    let fd = new FormData();
    const url = `${GENERAL_URL_API}/closeSession.php`;
    let res;
    try {
      res = await fetch(url, {
        method: "POST", credentials: 'include',
        body: fd,
      });
    } catch (e) {}

    dispatch({ type: LOGOUT, payload: null });
  };

  //Creamos un provider con el contexto creado con el API de context
  //le pasamos como valores el estado y funciones creadas anteriormente
  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: authState.isLoggedIn,
        login: login,
        logout: logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
