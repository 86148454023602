import EditarUsuarioAcademia from "../../../pages/formacion/editar-elementos/editar-usuario-academia";

//TODO: Add proper access to the routes
const RoutesUsuariosAcademia = [
  {
    path: "/formacion/editar-usuario-academia",
    component: EditarUsuarioAcademia,
    access: "ver_codigos_regalo",
    name: "Editar Usuario Academia",
  },
];

export default RoutesUsuariosAcademia;
