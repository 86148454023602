import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchProfesores } from "../../../../shared/fetchers/profesores";
import {
  deleteCursoProfesor,
  fetchCursosProfesor,
} from "../../../../shared/fetchers/cursoProfesor";

const DesasignarIndividual = () => {
  const classes = useStyles();

  const [profesor, setProfesor] = useState(null);
  const [curso, setCurso] = useState(null);

  const { isLoading: loadingProfesores, data: profesores } = useQuery({
    queryKey: ["profesores"],
    queryFn: async () => {
      const data = await fetchProfesores();
      return data.data ?? [];
    },
    refetchOnMount: "always",
  });

  const { isLoading: loadingCursos, data: cursos } = useQuery({
    queryKey: ["cursos", profesor?.username],
    queryFn: async () => {
      const data = await fetchCursosProfesor(profesor?.username);
      return data.data ?? [];
    },
    enabled: Boolean(profesor), // this query is called everyTime the profesor change
  });

  const { isLoading: deletingCursoProfesor, mutate: mutateCursoProfesor } =
    useMutation({
      mutationFn: ({ profesor, curso }) => deleteCursoProfesor(profesor, curso),
      onSettled: () => {
        setProfesor(null);
        setCurso(null);
      },
    });

  return (
    <Row>
      <Col xs={12} className="content-white-box px-4 py-4 mt-3">
        <div className="d-flex align-items-center">
          <MenuBookIcon style={{ fontSize: "20px" }} />
          <span className="admin-main-title-dark-gray ml-2 mt-1">
            Eliminar cursos a un profesor
          </span>
        </div>
        {!profesores?.length || loadingProfesores ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <form>
            <label className="admin-label-text mt-3 d-block">Profesor:</label>
            <Autocomplete
              options={profesores ?? []}
              getOptionLabel={(option) =>
                `${option.institutoPlanLector} - ${option.username}`
              }
              value={profesor}
              onChange={(e, value) => {
                setProfesor(value);
                if (!value) setCurso(null);
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <label className="admin-label-text mt-3 d-block">Curso:</label>
            <Autocomplete
              options={cursos ?? []}
              getOptionLabel={(option) =>
                `${option.instituto} - ${option.curso}`
              }
              value={curso}
              onChange={(e, value) => {
                setCurso(value);
              }}
              noOptionsText="Sin resultados"
              disabled={!profesor || loadingCursos}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <div className="d-flex">
              <Tooltip title="desasignar curso">
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!profesor || !curso || deletingCursoProfesor}
                    starticon={<CheckRoundedIcon />}
                    classes={{ root: classes.eliminar }}
                    onClick={() => {
                      mutateCursoProfesor({
                        profesor: profesor.username,
                        curso: curso.idCursoPlanLector,
                      });
                    }}
                  >
                    Eliminar
                  </Button>
                </span>
              </Tooltip>
            </div>
          </form>
        )}
      </Col>
    </Row>
  );
};

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
    marginTop: "1em",
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
    marginTop: "1em",
  },
});

export default DesasignarIndividual;
