import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { crearUsuariosAcademia } from "../../../../shared/fetchers/academia/crearUsuario";
import { DropzoneArea } from "material-ui-dropzone";
import { Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { CheckRounded } from "@material-ui/icons";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { crearSedesClassics } from "../../../../shared/fetchers/classics/institutos/crearSedeClassics";

const CrearSedeClassicsMasivo = () => {
  const [sedes, setSedes] = useState("");
  const [loading, setLoading] = useState(false);

  const crearSedes = async () => {
    setLoading(true);

    const { status, info } = await crearSedesClassics(sedes[0]);

    if (!status) {
      await swal({
        title: "Error",
        text: info,
        icon: "error",
      });
    } else {
      await swal({
        title: "Éxito",
        text: info,
        icon: "success",
      });
    }

    setLoading(false);
  };

  return (
    <Col xs={12} className="content-white-box px-4 py-4 mt-3">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          Agregar Sedes (carga masiva)
        </div>
      </div>
      <div className="my-3">
        <Link
          to="/templates/classics/crear-sedes.xlsx"
          target="_blank"
          download
        >
          <CustomGreenButton size="small" variant="contained">
            Descargar plantilla
          </CustomGreenButton>
        </Link>
      </div>
      <form>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          showAlerts={true}
          disabled={loading}
          filesLimit={1}
          acceptedFiles={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          onChange={(files) => setSedes(files)}
        />

        <div className="mt-3">
          <Button
            variant="contained"
            color="primary"
            disabled={!sedes.length || loading}
            startIcon={<CheckRounded style={{ fontSize: "25px" }} />}
            onClick={crearSedes}
            style={{ background: "#81c868" }}
          >
            Agregar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearSedeClassicsMasivo;
