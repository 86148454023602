import React, { useState, useEffect } from "react";
// Components
import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import swal from "sweetalert";
// icons
import TvIcon from "@material-ui/icons/Tv";
import PersonIcon from "@material-ui/icons/Person";
//Datos
import { listaProfesoresC } from "../../../shared/data/columnas-plan-lector";
// Datos de prueba
import exportFromJSON from "export-from-json";
import { GENERAL_URL_API } from "../../../shared/urls";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const ListaProfesores = () => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [institutos, setInstitutos] = useState([]);
  const [instituto, setInstituto] = useState(null);

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    let temp = listaProfesoresC;
    //Añadimos un atributo de visible a todas las columnas
    temp.map((_, index) => (temp[index].isVisible = true));
    setColumns(temp);

    fetchInstitutos();
  }, []);

  useEffect(() => {
    // console.log(instituto);
    fetchProfesores(instituto);
  }, [instituto]);

  const fetchProfesores = async (inst) => {
    setRows([]);
    const fd = new FormData();
     
    if (inst) fd.append("instituto", inst.nombre);
    const url = `${GENERAL_URL_API}/profesores/verProfesores.php`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (!data.data.length)
      await swal({
        title: "Alerta",
        text: data.info,
        icon: "warning",
      });
    else if (!data.status)
      await swal({
        title: "Error",
        text: data.info,
        icon: "error",
      });
    setRows(
      data.data.map((profesor) => ({
        ...profesor,
        rector: profesor.rector ? "Sí" : "No",
      }))
    );
  };

  const fetchInstitutos = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setInstitutos(data.data);
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = async (e, n, v) => {
    // const name = n || e.target.name;
    const value = v || e.target.value;
    value ? setInstituto(value) : setInstituto(null);

    // if (value) await fetchProfesores(value);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Lista de Profesores plan lector</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de Profesores plan lector
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Lista de todos los profesores.
            </div>

            <form className="mb-3">
              <label className="admin-label-text mt-3 d-block">
                Instituto*
              </label>
              <Autocomplete
                fullWidth
                options={institutos}
                getOptionLabel={(option) => option.nombre}
                value={instituto}
                onChange={(e, value) => {
                  updateForm(e, "instituto", value);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Selecciona un instituto"
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  </>
                )}
              />
            </form>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />

            <ButtonGroup size="small" variant="contained" className="my-3">
              {/* <CustomGreenButton>Copiar</CustomGreenButton>
              <CustomGreenButton>Archivo csv</CustomGreenButton>
              <CustomGreenButton>Excel</CustomGreenButton>
              <CustomGreenButton>PDF</CustomGreenButton>
              <CustomGreenButton>Imprimir</CustomGreenButton> */}

              <CustomGreenButton
                onClick={() => {
                  const dat = [...rows];

                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-profesores",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-profesores",
                    exportType: exportFromJSON.types.json,
                  });
                }}
              >
                JSON
              </CustomGreenButton>
              {/* <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-profesores",
                    exportType: exportFromJSON.types.xls,
                  });
                }}
              >
                EXCEL
              </CustomGreenButton> */}
              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columns}
                  rows={rows}
                  filename={"lista-profesores"}
                />
              </CustomGreenButton>
            </ButtonGroup>
            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <MDBDataTable
                small
                responsive
                striped
                searchTop
                searchBottom={false}
                barReverse
                pagingTop
                bordered
                data={{
                  columns: columns.filter((obj) => obj.isVisible),
                  rows: rows,
                }}
                noBottomColumns
                order={["name", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaProfesores;
