import React from "react";
import { Container, Row } from "react-bootstrap";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CrearUsuarioAcademiaIndividual from "./crear-usuario-individual";
import CrearUsuarioAcademiaMasivo from "./crear-usuario-masivo";

const CrearUsuarioAcademia = () => {
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Agregar un nuevo cliente</div>
      </div>
      <Container fluid>
        <Row>
          <CrearUsuarioAcademiaIndividual />
          <CrearUsuarioAcademiaMasivo />
        </Row>
      </Container>
    </>
  );
};

export default CrearUsuarioAcademia;
