import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Fetches a list of institutes.
 *
 * @async
 * @returns {Promise<Array>} A promise that resolves to an array of institute objects.
 */
export async function fetchInstitutos() {
  const { data } = await axios.get(`${GENERAL_URL_API}/app/verInstitutos`, {
    withCredentials: true,
  });

  return data?.institutos ?? [];
}
