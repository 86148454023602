import { useState, useEffect } from "react";
//components
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../../components/shared/table-column-dropdown/table-column-dropdown";
import swal from "sweetalert";
//utils
import exportFromJSON from "export-from-json";
//Datos de prueba
import { columnasListaCortesRegalias } from "../../../../shared/data/columnas";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";

const ListaCortesRegaliasTable = (props) => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    let tempCol = [...columnasListaCortesRegalias];
    //Añadimos un atributo de visible a todas las columnas
    tempCol.forEach((el) => (el.isVisible = true));
    let tempRow = props.regalias;

    setColumns(tempCol);

    tempRow &&
      tempRow.forEach((el) => {
        el.detalle = (
          <div className="w-100 d-flex justify-content-center">
            <button
              style={{
                border: "none",
                padding: "3px 5px",
                backgroundColor: "#5fbeaa",
                fontWeight: "500",
                color: "#fff",
                borderRadius: "5px",
                fontSize: "0.95em",
              }}
              data-corte={el.idCorte}
              onClick={(e) => {
                e.stopPropagation();
                props.setRegaliaSelected(e.target.getAttribute("data-corte"));
              }}
              size="small"
            >
              Ver Elemento
            </button>
          </div>
        );
      });

    setRows(tempRow);
  }, [props]);

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  const isNumber = (n) => !isNaN(parseFloat(n)) && isFinite(n);

  const getExcelRows = () => {
    const temp = [...rows];
    temp.forEach((r, i) => {
      Object.keys(r).forEach((k) => {
        if (isNumber(r[k])) {
          temp[i][k] = parseFloat(r[k]);
        }
      });
    });
    return temp;
  };

  return (
    <>
      <div className="admin-muted-text-small mt-1 mb-4">
        Haciendo click en el boton de "Ver Elemento" se puede ir al detalle del
        corte por editoriales
      </div>

      {!rows || !columns || rows.length <= 0 || columns.length <= 0 ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <>
          {/* Este componente es el dropdown que permite ocultar columnas*/}
          <TableColumnDropdown
            columns={columns}
            changeColumnStatus={changeColumnStatus}
          />
          <br />
          <ButtonGroup size="small" variant="contained" className="my-3">
            <CustomGreenButton
              onClick={() => {
                const dat = [...rows];
                navigator.clipboard
                  .writeText(JSON.stringify(dat))
                  .then(async () => {
                    await swal({
                      title: "Se ha copiado la tabla!",
                      icon: "success",
                    });
                  });
              }}
            >
              Copy
            </CustomGreenButton>
            <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-cortes-regalias",
                  exportType: exportFromJSON.types.csv,
                });
              }}
            >
              CSV
            </CustomGreenButton>
            <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-cortes-regalias",
                  exportType: exportFromJSON.types.json,
                });
              }}
            >
              JSON
            </CustomGreenButton>
            {/* <CustomGreenButton
              onClick={() => {
                const temp = [...rows];
                temp.forEach((r, i) =>
                  Object.keys(r).forEach((k) => {
                    if (isNumber(r[k])) {
                      temp[i][k] = parseFloat(r[k])
                        .toFixed(2)
                        .toString()
                        .replace(".", ",");
                    }
                  })
                );
                exportFromJSON({
                  data: temp,
                  fileName: "lista-cortes-regalias",
                  exportType: exportFromJSON.types.xls,
                });
              }}
            >
              EXCEL
            </CustomGreenButton> */}
            <CustomGreenButton>
              <ExcelWorkBook
                columns={[...columns].slice(1)}
                rows={getExcelRows()}
                filename={"lista-cortes-regalias"}
              />
            </CustomGreenButton>
          </ButtonGroup>
          <MDBDataTable
            multipleCheckboxes
            small
            responsive
            striped
            searchTop
            searchBottom={false}
            pagingTop
            bordered
            data={{
              columns: columns.filter((obj) => obj.isVisible),
              rows: rows,
            }}
            noBottomColumns
            order={["corteId", "asc"]}
            entriesOptions={[5, 10, 20, 50]}
            entriesLabel="Numero de filas"
            searchLabel="Buscar"
            paginationLabel={["Anterior", "Siguiente"]}
            infoLabel={["Mostrando filas", "a", "de", "filas"]}
          />
        </>
      )}
    </>
  );
};

export default ListaCortesRegaliasTable;
