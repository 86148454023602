import React, { useState, useEffect } from "react";
// Components
import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import swal from "sweetalert";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
// Icons
import TvIcon from "@material-ui/icons/Tv";
import PersonIcon from "@material-ui/icons/Person";
// Datos
import { listaInstitutosC } from "../../../shared/data/columnas-plan-lector";
import { GENERAL_URL_API } from "../../../shared/urls";
import exportFromJSON from "export-from-json";

const ListaInstitutos = () => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    let tempColumns = listaInstitutosC;
    //Añadimos un atributo de visible a todas las columnas
    tempColumns.map((column) => (column.isVisible = true));
    setColumns(tempColumns);

    fetchInstitutos();
  }, []);

  const fetchInstitutos = async () => {
    setRows([]);

    const fd = new FormData();


    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setRows(data.data);
    if (data.status === 0) {
      await swal({
        title: "Error obteniendo la información!",
        icon: "error",
      });
    }
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Lista de institutos plan lector</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de institutos plan lector
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Lista de todos los clientes.
            </div>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />

            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                onClick={() => {
                  const dat = [...rows];

                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-institutos",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-institutos",
                    exportType: exportFromJSON.types.json,
                  });
                }}
              >
                JSON
              </CustomGreenButton>
              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columns}
                  rows={rows}
                  filename={"lista-institutos"}
                />
              </CustomGreenButton>
            </ButtonGroup>
            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <MDBDataTable
                small
                responsive
                striped
                searchTop
                searchBottom={false}
                barReverse
                pagingTop
                bordered
                data={{
                  columns: columns.filter((obj) => obj.isVisible),
                  rows: rows,
                }}
                noBottomColumns
                order={["name", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaInstitutos;
