import FullscreenIcon from '@material-ui/icons/Fullscreen'
import Button from '@material-ui/core/Button';

//Boton para poner la pantalla completa
const FullScreenButton = () => {

    const setFullScreen = () => {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }
    return (
        <Button
            className='mr-2 d-none d-lg-inline'
            style={{ color: '#ffffff' }}
            onClick={setFullScreen}
        >
            <FullscreenIcon style={{ color: '#ffffff', fontSize: 30 }} />
        </Button>
    )
}

export default FullScreenButton