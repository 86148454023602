export const subjectOptions = [
  "Narrativa",
  "Ciencias",
  "Letras y números",
  "Canción y poesía",
  "Historia y ensayo",
  "Artes y manualidades",
];

export const subjectEngOptions = [
  "Fiction",
  "Science",
  "ABC & 123",
  "Poetry & Songs",
  "Non Fiction",
  "Arts & Crafts",
];

export const authorSecundaryFunctionOptions = [
  "Ilustrador",
  "Compilador",
  "Editor",
  "Traductor",
  "Selección",
];

export const activeOptions = [
  { data: 0, value: "Inactivo" },
  { data: 1, value: "Activo" },
];

export const formatOptions = [
  { data: "html", value: "html" },
  { data: "jpg", value: "jpg" },
  { data: "epub", value: "epub" },
  { data: "htmlScroll", value: "htmlScroll" },
  { data: "pdf", value: "pdf" },
];

export const typeOptions = [
  "Interactivo Premium",
  "Interactivo Basico",
  "Epub Premium",
  "Epub Basico",
];

export const userLevelOptions = [
  { data: "primeros_lectores", value: "Primeros Lectores" },
  {
    data: "lectores_intermedios",
    value: "Lectores Intermedios",
  },
  { data: "lectores_avanzados", value: "Lectores Avanzados" },
];

export const idiomaOptions = ["Español", "Inglés", "Francés", "Alemán"];

export const engLevelOptions = [
  "First Readers",
  "Intermediate Readers",
  "Advanced Readers",
];

export const bookTypeOptions = [
  "Pasapaginas",
  "Epub",
  "Interactivo",
  "Comic",
];

export const dbaOptions = [
  { code: "DBA_1T" },
  { code: "DBA_2T" },
  { code: "DBA_3T" },
  { code: "DBA_4T" },
  { code: "DBA_5T" },
  { code: "DBA_6T" },
  { code: "DBA_7T" },
  { code: "DBA_8T" },
  { code: "DBA_9T" },
  { code: "DBA_10T" },
  { code: "DBA_11T" },
  { code: "DBA_12T" },
  { code: "DBA_13T" },
  { code: "DBA_14T" },
  { code: "DBA_15T" },
  { code: "DBA_16T" },
  { code: "DBA_1A" },
  { code: "DBA_2A" },
  { code: "DBA_3A" },
  { code: "DBA_4A" },
  { code: "DBA_5A" },
  { code: "DBA_6A" },
  { code: "DBA_7A" },
  { code: "DBA_8A" },
  { code: "DBA_1B" },
  { code: "DBA_2B" },
  { code: "DBA_3B" },
  { code: "DBA_4B" },
  { code: "DBA_5B" },
  { code: "DBA_6B" },
  { code: "DBA_7B" },
  { code: "DBA_8B" },
  { code: "DBA_1C" },
  { code: "DBA_2C" },
  { code: "DBA_3C" },
  { code: "DBA_4C" },
  { code: "DBA_5C" },
  { code: "DBA_6C" },
  { code: "DBA_7C" },
  { code: "DBA_8C" },
  { code: "DBA_1D" },
  { code: "DBA_2D" },
  { code: "DBA_3D" },
  { code: "DBA_4D" },
  { code: "DBA_5D" },
  { code: "DBA_6D" },
  { code: "DBA_7D" },
  { code: "DBA_8D" },
  { code: "DBA_1E" },
  { code: "DBA_2E" },
  { code: "DBA_3E" },
  { code: "DBA_4E" },
  { code: "DBA_5E" },
  { code: "DBA_6E" },
  { code: "DBA_7E" },
  { code: "DBA_8E" },
  { code: "DBA_1F" },
  { code: "DBA_2F" },
  { code: "DBA_3F" },
  { code: "DBA_4F" },
  { code: "DBA_5F" },
  { code: "DBA_6F" },
  { code: "DBA_7F" },
  { code: "DBA_8F" },
  { code: "DBA_1G" },
  { code: "DBA_2G" },
  { code: "DBA_3G" },
  { code: "DBA_4G" },
  { code: "DBA_5G" },
  { code: "DBA_6G" },
  { code: "DBA_7G" },
  { code: "DBA_8G" },
  { code: "DBA_1H" },
  { code: "DBA_2H" },
  { code: "DBA_3H" },
  { code: "DBA_4H" },
  { code: "DBA_5H" },
  { code: "DBA_6H" },
  { code: "DBA_7H" },
  { code: "DBA_8H" },
  { code: "DBA_1I" },
  { code: "DBA_2I" },
  { code: "DBA_3I" },
  { code: "DBA_4I" },
  { code: "DBA_5I" },
  { code: "DBA_6I" },
  { code: "DBA_7I" },
  { code: "DBA_8I" },
  { code: "DBA_1J" },
  { code: "DBA_2J" },
  { code: "DBA_3J" },
  { code: "DBA_4J" },
  { code: "DBA_5J" },
  { code: "DBA_6J" },
  { code: "DBA_7J" },
  { code: "DBA_8J" },
  { code: "DBA_1K" },
  { code: "DBA_2K" },
  { code: "DBA_3K" },
  { code: "DBA_4K" },
  { code: "DBA_5K" },
  { code: "DBA_6K" },
  { code: "DBA_7K" },
  { code: "DBA_8K" },
];

export const ageOptions = [
  { data: "3+", value: "3+" },
  { data: "5+", value: "5+" },
  { data: "7+", value: "7+" },
  { data: "9+", value: "9+" },
  { data: "11+", value: "11+" },
  { data: "13+", value: "13+" },
  { data: "15+", value: "15+" },
];

export const courseOptions = [
  { data: "Preescolar", value: "Preescolar" },
  { data: "jardin-transicion", value: "Jardín-Transición" },
  { data: "transición-primero", value: "Transición-Primero" },
  { data: "primero-segundo", value: "Primero-Segundo" },
  { data: "segundo-tercero", value: "Segundo-Tercero" },
  { data: "tercero-cuarto", value: "Tercero-Cuarto" },
  { data: "cuarto-quinto", value: "Cuarto-Quinto" },
  { data: "quinto-sexto", value: "Quinto-Sexto" },
  { data: "sexto-septimo", value: "Sexto-Séptimo" },
  { data: "septimo-octavo", value: "Séptimo-Octavo" },
  { data: "octavo-noveno", value: "Octavo-Noveno" },
  { data: "noveno-decimo", value: "Noveno-Décimo" },
  { data: "decimo-once", value: "Décimo-Once" },
];

export const compatibilityOptions = [
  { data: "desktop", value: "Solo desktop" },
  { data: "all", value: "Todos los dispositivos" },
];

export const genreOptions = [
  { data: "comic", value: "Cómic" },
  { data: "Crónica y ensayo", value: "Crónica y ensayo" },
  { data: "cuento", value: "Cuento" },
  { data: "poesia", value: "Poesía" },
  { data: "teatro", value: "Teatro" },
  { data: "Libro álbum", value: "Libro álbum" },
  { data: "interactivo", value: "Interactivo" },
  { data: "novela", value: "Novela" },
  { data: "didactico", value: "Didáctico" },
  { data: "informativo", value: "Informativo" },
  { data: "Cuento Ilustrado", value: "Cuento ilustrado" },
  { data: "referencia", value: "Referencia" },
];

export const genreOptionsEng = [
  { value: "Comic" },
  { value: "Journalism or Essay" },
  { value: "Short Story" },
  { value: "Poetry" },
  { value: "Theatre" },
  { value: "Picture Book" },
  { value: "Interactive" },
  { value: "Novel" },
  { value: "Didactic" },
  { value: "Non Fiction" },
  { value: "Illustrated Story" },
  { value: "Reference" },
];
