export const columnasFilasHome = [
  {
    label: "Nombre",
    field: "filaHomeName",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Descripción",
    field: "description",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Disponibilidad Regional",
    field: "disponibilidadRegional",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasLibrosFila = [
  {
    label: "Nombre Fila",
    field: "FilaHomeName",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Id Libro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Nombre Libro",
    field: "title",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasPaquetesLibro = [
  {
    label: "Nombre Paquete",
    field: "packageName",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
