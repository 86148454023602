import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * @returns {Promise<Array<Notibanner>>}
 */
export async function fetchRegistrosFormularios() {
  const { data } = await axios.get(
    `${GENERAL_URL_API}/eventos/formularios/verRegistrosFormEventos`,
    {
      withCredentials: true,
    },
  );

  return data.data;
}
