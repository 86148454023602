import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
// import CustomGreenButton from "../../../components/shared/custom-green-button";
// import ImageUploadCard from "../../../components/shared/image-upload-card/image-upload-card";
// import { DropzoneArea } from "material-ui-dropzone";
import swal from "sweetalert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { GENERAL_URL_API } from "../../../../shared/urls";

const AgregarEstudianteIndividualForm = ({ estudiante, clearEstudiante }) => {
  const [loading, setLoading] = useState(false);
  //Definimos los estaos para almacenar los datos del formulario y sus errores
  const [sedes, setSedes] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [formData, setFormData] = useState({
    nombre: "",
    codigoColegio: "",
    correo: "",
    sede: null,
    curso: null,
  });
  const [formErrors, setFormErrors] = useState({
    nombre: false,
    codigoColegio: false,
    correo: false,
    sede: false,
    curso: false,
  });

  useEffect(() => {
    fetchSedes();
    fetchCursos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("in--------------------");
    if (estudiante && sedes.length && cursos.length) {
      console.log("-------------------in--------------------");
      const curso = cursos.find(
        (c) => c.idCursoPlanLector === estudiante.idCursoPlanLector
      );
      const sede = sedes.find(
        (s) => s.instituto === estudiante.nombreInstituto
      );
      setFormData({
        nombre: estudiante.nombre,
        codigoColegio: estudiante.codigoColegio,
        correo: estudiante.referencia || "",
        sede,
        curso,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estudiante, sedes, cursos]);

  const fetchSedes = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/sedes/verSedes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setSedes(data.data);
  };

  const fetchCursos = async () => {
    setCursos([]);
    const fd = new FormData();
     

    const res = await fetch(`${GENERAL_URL_API}/Cursos/verCursos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setCursos(data.data);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const value = v || e.target.value;
    const name = n || e.target.name;
    if (name === "sede") {
      setFormData({ ...formData, curso: "", sede: value });
      setFormErrors({ ...formErrors, curso: false, sede: false });
    } else {
      setFormData({ ...formData, [name]: value });
      setFormErrors({ ...formErrors, [name]: false });
    }
  };
  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      if (formElement !== "sede" && formElement !== "curso") {
        temp[formElement] = "";
      } else {
        temp[formElement] = null;
      }
    }
    setFormData(temp);
    clearErrors();
  };

  const clearErrors = () => {
    const fe = { ...formErrors };
    for (const formElement in fe) {
      fe[formElement] = false;
    }
    setFormErrors(fe);
  };

  const validate = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const fd = { ...formData };
    const fe = { ...formErrors };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in fd) {
      if (formElement !== "codigoColegio") {
        if (!fd[formElement]) {
          fe[formElement] = true;
          console.log("formElement: ", formElement);
          isValid = false;
        }
      }
    }

    //Actualizamos el estado con todos los cambios
    setFormData(fd);
    setFormErrors(fe);
    if (isValid) clearErrors();
    return isValid;
  };

  //Funcion para subir la informacion y validad todos los datos
  const SubmitForm = async () => {
    setLoading(true);
    //Creamos una variable temporal para actualizar el estado
    if (validate()) {
      const fd = new FormData();
       
       
      fd.append("nombre", formData.nombre);
      fd.append("codigoColegio", formData.codigoColegio);
      fd.append("correo", formData.correo);
      fd.append("sede", formData.sede.instituto);
      fd.append("idCurso", formData.curso.idCursoPlanLector);
      if (estudiante)
        fd.append("idEstudiante", estudiante.idEstudiantePlanLector);

      const url = `${GENERAL_URL_API}/estudiantes/${
        !estudiante ? "agregar" : "editar"
      }Estudiante.php`;
      const res = await fetch(url, {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      });

      const data = await res.json();

      if (data.status)
        await swal({
          title: "Éxito.",
          text: data.info,
          icon: "success",
        });
      else
        await swal({
          title: "Error creando curso.",
          text: data.info,
          icon: "error",
        });
      if (estudiante && clearEstudiante) clearEstudiante();
    }
    setLoading(false);
  };

  return (
    <form>
      <label className="admin-label-text mt-3 d-block">Nombre *</label>
      <TextField
        name="nombre"
        value={formData.nombre}
        onChange={updateForm}
        error={formErrors.nombre}
        helperText={
          formErrors.nombre ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        placeholder="Nombre del estudiante"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      {/* cambiar */}
      <label className="admin-label-text mt-3 d-block">
        Código Colegio (Dejar vacío si no tiene)
      </label>
      <TextField
        name="codigoColegio"
        value={formData.codigoColegio}
        onChange={updateForm}
        error={formErrors.codigoColegio}
        helperText={
          formErrors.codigoColegio ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        placeholder="Código del estudiante dentro del colegio"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <label className="admin-label-text mt-3 d-block">Correo * </label>
      <TextField
        name="correo"
        value={formData.correo}
        onChange={updateForm}
        error={formErrors.correo}
        helperText={
          formErrors.correo ? "Este campo no puede estar vacío" : null
        }
        variant="outlined"
        size="small"
        placeholder="Correo del estudiante"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />

      <label className="admin-label-text mt-3 d-block">Sede*</label>
      <Autocomplete
        fullWidth
        options={sedes}
        getOptionLabel={(option) =>
          option ? `${option.superInstituto} - ${option.instituto}` : ""
        }
        value={formData.sede}
        onChange={(e, value) => {
          updateForm(e, "sede", value);
        }}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              placeholder="Selecciona una sede"
              variant="outlined"
              size="small"
              fullWidth
              style={{
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={formErrors.sede}
              helperText={formErrors.sede ? "Campo no válido" : null}
            />
          </>
        )}
      />

      <label className="admin-label-text mt-3 d-block">Curso*</label>
      <Autocomplete
        fullWidth
        options={
          !formData.sede
            ? cursos
            : cursos.filter(
                (curso) => curso.instituto === formData.sede.instituto
              )
        }
        getOptionLabel={(option) => (option ? `${option.curso}` : "")}
        value={formData.sede ? formData.curso : ""}
        onChange={(e, value) => {
          updateForm(e, "curso", value);
        }}
        noOptionsText="Sin resultados"
        disabled={!formData.sede || !cursos.length}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              placeholder="Selecciona un curso"
              variant="outlined"
              size="small"
              fullWidth
              style={{
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={formErrors.curso}
              helperText={formErrors.curso ? "Campo no válido" : null}
            />
          </>
        )}
      />

      <div className="d-flex justify-content-end mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={SubmitForm}
          startIcon={<CheckRounded />}
          disabled={loading}
          style={{ background: "#81c868" }}
        >
          {!estudiante ? "Agregar" : "Editar"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={
            !estudiante
              ? clearForm
              : () => {
                  clearForm();
                  clearEstudiante();
                }
          }
          className="ml-2"
          startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
          disabled={loading}
          style={{ background: "#f05050" }}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default AgregarEstudianteIndividualForm;
