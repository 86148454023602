export const columnasCursosAcademia = [
  {
    label: "Id",
    field: "idCursoAcademia",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Título",
    field: "titulo",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Autor",
    field: "autor",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Publisher",
    field: "publisher",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Descripción",
    field: "descripcion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Categoría",
    field: "categoria",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Duración",
    field: "duracion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
