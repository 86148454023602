import React, { useState } from "react";
// Bootstrap
import { Container, Row, Col } from "react-bootstrap";
// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import MenuBookIcon from "@material-ui/icons/MenuBook";
// MDB
import { MDBDataTableV5 } from "mdbreact";
// Alerts and toasts
import swal from "sweetalert";
// Shared
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { fetchPaquetesLibro } from "../../../../shared/fetchers/filasHome";
import { columnasPaquetesLibro } from "../../../../shared/data/columnas/classics/filasHome";
import { Cancel, CheckCircle } from "@material-ui/icons";

const PaquetesLibroTable = ({ libros }) => {
  const [libro, setLibro] = useState({});

  const { data: paquetes } = useQuery(
    ["paquetesLibro", libro?.idLibro],
    () => fetchPaquetesLibro(libro?.idLibro),
    {
      enabled: Boolean(libro?.idLibro),
    }
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="d-flex align-items-center">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Tabla de paquetes de un libro
            </div>
          </div>
          <div className="admin-muted-text-small mt-1 mb-4">
            Listado de paquetes autorizados y no autorizados de un libro
          </div>

          <Autocomplete
            options={libros.data}
            getOptionLabel={(option) =>
              option.idLibro ? `${option.idLibro} - ${option.title}` : ""
            }
            value={libro}
            onChange={(_, value) => {
              setLibro(value);
            }}
            noOptionsText="Sin resultados"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Libros"
                style={{
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
            )}
          />

          {!paquetes ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              <div className="d-flex align-items-center mt-4">
                <CheckCircle />
                <div className="admin-main-title-dark-gray ml-2 mt-1">
                  Paquetes autorizados
                </div>
              </div>
              <ButtonGroup size="small" variant="contained" className="my-3">
                <CustomGreenButton
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        JSON.stringify([...paquetes?.paquetesAutorizados])
                      )
                      .then(async () => {
                        await swal({
                          title: "Se ha copiado la tabla!",
                          icon: "success",
                        });
                      });
                  }}
                >
                  Copy
                </CustomGreenButton>
                <CustomGreenButton>
                  <ExcelWorkBook
                    columns={columnasPaquetesLibro}
                    rows={paquetes?.paquetesAutorizados}
                    filename={`Lista-paquetes-autorizados-libro-${libro?.title}`}
                  />
                </CustomGreenButton>
              </ButtonGroup>

              <MDBDataTableV5
                small
                responsive
                striped
                bordered
                searchTop
                searchBottom={false}
                barReverse
                pagingTop
                data={{
                  columns: columnasPaquetesLibro,
                  rows: paquetes?.paquetesAutorizados,
                }}
                noBottomColumns
                order={["name", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
                fullPagination
              />

              <div className="d-flex align-items-center mt-4">
                <Cancel />
                <div className="admin-main-title-dark-gray ml-2 mt-1">
                  Paquetes No Autorizados
                </div>
              </div>
              <ButtonGroup size="small" variant="contained" className="my-3">
                <CustomGreenButton
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        JSON.stringify([...paquetes?.paquetesNOAutorizados])
                      )
                      .then(async () => {
                        await swal({
                          title: "Se ha copiado la tabla!",
                          icon: "success",
                        });
                      });
                  }}
                >
                  Copy
                </CustomGreenButton>
                <CustomGreenButton>
                  <ExcelWorkBook
                    columns={columnasPaquetesLibro}
                    rows={paquetes?.paquetesNOAutorizados}
                    filename={`Lista-paquetes-no-autorizados-libro-${libro?.title}`}
                  />
                </CustomGreenButton>
              </ButtonGroup>

              <MDBDataTableV5
                small
                responsive
                striped
                bordered
                searchTop
                searchBottom={false}
                barReverse
                pagingTop
                data={{
                  columns: columnasPaquetesLibro,
                  rows: paquetes?.paquetesNOAutorizados,
                }}
                noBottomColumns
                order={["name", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
                fullPagination
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PaquetesLibroTable;
