import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../shared/contex/auth/auth-context";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import { GENERAL_URL_API } from "../../shared/urls";
import swal from "sweetalert";

import "./login.css";

import logoMake from "./images/logoMake.png";
import { useClient } from "../../shared/contex/client/client-context";
import ReCAPTCHA from "react-google-recaptcha";

//Este componente es la pagina del login
const Login = () => {
  //Creamos un objeto para poder cambiar la url de la pagina
  const history = useHistory();

  const captchaRef = useRef(null);

  //creamos el objeto para acceder al contexto de la autentificacion
  const auth = useAuth();

  const { updateClient } = useClient();

  //Definimos un estado para guardar los inputs del usuario, y si hay un error en estos
  const [username, setUsername] = useState({ value: "", error: false });
  const [password, setPassword] = useState({ value: "", error: false });
  const [loading, setLoading] = useState(false);

  //Limpiamos la url (no se recarga ni nada)
  useEffect(() => {
    history.push("/");
  }, [history]);

  //Funcion para ejecutar el login solo si los campos ingresados son correctos
  const onSubmitForm = async () => {
    setLoading(true);
    let valid = true;
    if (username.value === "") {
      setUsername({ ...username, error: true });
      valid = false;
    }
    if (password.value === "") {
      setPassword({ ...password, error: true });
      valid = false;
    }

    if (valid) {
      const fd = new FormData();
      fd.append("username", username.value);
      fd.append("password", password.value);
      fd.append("captcha", captchaRef.current.getValue());
      const res = await fetch(`${GENERAL_URL_API}/confirmLogin.php`, {
        method: "POST",
        credentials: "include",
        credentials: "include",
        body: fd,
      });
      const resJSON = await res.json();

      captchaRef.current.reset();

      if (resJSON.status === 0) {
        swal({
          title: "Acceso denegado",
          icon: "error",
        });
      } else if (resJSON.status === 1) {
        auth.login();
        updateClient(resJSON.data);
        localStorage.setItem("userAdminMake", username.value);
        // localStorage.setItem("passwordAdminMake", password.value);
      }
    }
    setLoading(false);
  };

  return (
    <div className="login-background d-flex justify-content-center">
      <div className="login-box p-3">
        <form className="d-flex flex-column align-items-center">
          <img src={logoMake} alt="logomakemake" className="login-image my-3" />
          <TextField
            value={username.value}
            onChange={(e) => {
              setUsername({ value: e.target.value, error: false });
            }}
            error={username.error}
            helperText={
              username.error ? "Este campo no puede estar vacío" : null
            }
            placeholder="usuario"
            variant="outlined"
            size="small"
            className="mt-4"
            style={{ width: "90%", fontFamily: "'Noto Sans JP', sans-serif" }}
          />
          <TextField
            value={password.value}
            onChange={(e) => {
              setPassword({ value: e.target.value, error: false });
            }}
            error={password.error}
            helperText={
              password.error ? "Este campo no puede estar vacío" : null
            }
            type="password"
            placeholder="contraseña"
            variant="outlined"
            size="small"
            className="mt-3"
            style={{ width: "90%" }}
          />

          <div className="mt-4">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
            />
          </div>

          <Button
            variant="contained"
            style={{ width: "90%", background: "#6767cd", color: "#ffffff" }}
            className="mb-4 mt-4"
            onClick={onSubmitForm}
            disabled={loading}
          >
            Entrar
          </Button>
        </form>
        <div className="pl-3 sidebar-item-text d-flex align-items-center mb-1">
          <LockIcon style={{ fontSize: "20px" }} />
          <div className="sidebar-item-text pl-1">
            ¿Olvidaste tu contraseña?
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
