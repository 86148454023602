import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import exportFromJSON from "export-from-json";
import swal from "sweetalert";

import { GENERAL_URL_API } from "../../../shared/urls";
import {
  columnasPaquetesInstitutosPL,
  columnasPaquetesSedesPL,
} from "../../../shared/data/columnas";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import { Tv } from "@material-ui/icons";

const AgregarEliminarPaquetePlanLector = ({ type }) => {
  const classes = useStyles();

  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [paquetes, setPaquetes] = useState([]);
  const [options, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //Definimos un estados para guardar la informacion del  los autocomplete
  const [packageSelected, setPackageSelected] = useState(null);
  const [differentPackage, setdifferentPackage] = useState("");
  const [data, setDataElementSelected] = useState(null);

  // effect cada vez que cambia el tipo
  useEffect(() => {
    let temp;
    if (type === "instituto") temp = [...columnasPaquetesInstitutosPL];
    if (type === "sede") temp = [...columnasPaquetesSedesPL];
    //Añadimos un atributo de visible a todas las columnas
    temp.forEach((el) => {
      if (!el.isVisible) el.isVisible = false;
    });
    setColumns(temp);

    setPackageSelected(null);
    setdifferentPackage("");
    setDataElementSelected(null);
    getData();
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (data) getPackages();
    else if (!data && paquetes.length) setPaquetes([]);
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getPackages = async () => {
    const fd = new FormData();
     
    fd.append("estado", "autorizado");

    let endpoint;
    if (type === "instituto") {
      endpoint = "verPaquetes";
    } else {
      endpoint = "verPaquetesPL";
    }

    const res = await fetch(`${GENERAL_URL_API}/paquetes/${endpoint}`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    console.log(resJSON);
    console.log(data);
    if (type === "sede") {
      setPaquetes([
        ...new Set(
          resJSON.data.filter(
            (paquete) => paquete.superinstituto === data.superInstituto
          )
        ),
      ]);
    }
    if (type === "instituto") {
      setPaquetes([...new Set(resJSON.data)]);
    }
  };

  const fetchTableData = async () => {
    const fd = new FormData();
     
    fd.append("estado", "autorizado");
    if (data && type === "instituto") fd.append("instituto", data.nombre);
    if (data && type === "sede") fd.append("sede", data?.instituto);
    let urlEnd;
    if (type === "instituto") urlEnd = "verPaquetesPL";
    if (type === "sede") urlEnd = "verPaquetesSede";
    const url = `${GENERAL_URL_API}/paquetes/${urlEnd}`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    setRows(resJSON.data);
  };

  const getData = async () => {
    setData([]);
    const fd2 = new FormData();
     
    let urlEnd = "";
    if (type === "curso") urlEnd = "/Cursos/verCursos.php";
    if (type === "instituto") urlEnd = "/institutos/verInstitutos.php";
    if (type === "sede") urlEnd = "/sedes/verSedes.php";
    const url = `${GENERAL_URL_API}${urlEnd}`;
    const res2 = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd2,
    });
    const resJSON2 = await res2.json();
    setData(resJSON2.data);
  };

  //Funciones para actualizar el valor seleccionado por los autocomplete
  const packageSelectedUpdate = async (e, newValue) => {
    setPackageSelected(newValue);
    console.log(newValue);
  };

  const dataSelectedUpdate = (e, newValue) => {
    setDataElementSelected(newValue);
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  // Función para limpiar los campos del formulario
  const clearForm = () => {
    // setDataElementSelected(null);
    setPackageSelected(null);
    setdifferentPackage("");
  };

  const addDelBook = async (action) => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("accion", action);
    if (packageSelected) {
      fd.append(
        "nombrePaquete",
        type === "sede" ? packageSelected.nombrePaquete : packageSelected
      );
    }
    if (differentPackage) fd.append("nombrePaquete", differentPackage);
    if (type === "curso") fd.append("curso", data.curso);
    if (type === "instituto") fd.append("instituto", data.nombre);
    if (type === "sede") fd.append("sede", data.instituto);

    let urlEnd = "";
    if (type === "curso") urlEnd = "asociarPaqueteCurso.php";
    if (type === "instituto") urlEnd = "asociarPaqueteInstituto.php";
    if (type === "sede") urlEnd = "asociarPaqueteSede.php";
    const url = `${GENERAL_URL_API}/paquetes/${urlEnd}`;

    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    }
    setLoading(false);
    getPackages();
    fetchTableData();
    clearForm();
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o eliminar paquete a un{type === "sede" && "a"} {type}
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon style={{ fontSize: "20px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar/eliminar un paquete a un{type === "sede" && "a"} {type}
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                {type !== "sede" ? "El" : "La"} {type}:
              </label>
              <Autocomplete
                options={options}
                getOptionLabel={(option) => {
                  if (type === "curso") return option.curso;
                  if (type === "instituto") return `${option.nombre}`;
                  if (type === "sede")
                    return `${option.superInstituto} - ${option.instituto}`;
                }}
                value={data}
                onChange={dataSelectedUpdate}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              {/* cambiar por curso.idCurso (o correspondiente) */}
              <TextField
                name="autor"
                value={
                  type === "curso"
                    ? data?.idCursoPlanLector || "" //para curso
                    : type === "instituto"
                    ? data?.idSuperinstitutoPlanLector || "" //para instituto
                    : data?.idInstitutoPlanLector || "" //para sede
                }
                disabled={true}
                variant="outlined"
                placeholder=""
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />

              <label className="admin-label-text mt-3 d-block">
                El paquete:
              </label>
              <Autocomplete
                // las sedes pueden ver los paquetes del instituto, los institutos pueden ver todos los paquetes
                options={[...paquetes]}
                getOptionLabel={(option) =>
                  type === "instituto" ? `${option}` : `${option.nombrePaquete}`
                }
                value={packageSelected}
                onChange={packageSelectedUpdate}
                disabled={!data || differentPackage !== ""}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              {type !== "instituto" && type !== "sede" && (
                <TextField
                  name="autor"
                  value={differentPackage}
                  disabled={!data || packageSelected}
                  onChange={(e) => setdifferentPackage(e.target.value)}
                  variant="outlined"
                  placeholder="Paquete que no está en la lista"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}

              <div className="mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !(
                      (packageSelected !== null || differentPackage !== "") &&
                      data !== null
                    ) || loading
                  }
                  startIcon={<CheckRoundedIcon />}
                  classes={{ root: classes.agregar }}
                  onClick={() => addDelBook("asociar")}
                >
                  Agregar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2"
                  disabled={
                    !(
                      (packageSelected !== null || differentPackage !== "") &&
                      data !== null
                    ) || loading
                  }
                  startIcon={<CloseRoundedIcon style={{ fontSize: "25px" }} />}
                  classes={{ root: classes.eliminar }}
                  onClick={() => addDelBook("disociar")}
                >
                  Eliminar
                </Button>
              </div>
            </form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="admin-main-title-dark-gray  mb-3">
              <Tv /> Tabla de paquetes por {type}
            </div>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />

            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data:
                      type === "instituto"
                        ? rows.map((r) => ({
                            instituto: r.superinstituto,
                            nombrePaquete: r.nombrePaquete,
                          }))
                        : rows.map((r) => ({
                            instituto: r.superinstituto,
                            nombrePaquete: r.nombrePaquete,
                            sede: r?.sede || "",
                          })),
                    fileName: `Paquetes_por_${type}`,
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data:
                      type === "instituto"
                        ? rows.map((r) => ({
                            instituto: r.superinstituto,
                            nombrePaquete: r.nombrePaquete,
                          }))
                        : rows.map((r) => ({
                            instituto: r.superinstituto,
                            nombrePaquete: r.nombrePaquete,
                            sede: r?.sede || "",
                          })),
                    fileName: `Paquetes_por_${type}`,
                    exportType: exportFromJSON.types.json,
                  });
                }}
              >
                JSON
              </CustomGreenButton>
              {/* <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data:
                      type === "instituto"
                        ? rows.map((r) => ({
                            instituto: r.superinstituto,
                            nombrePaquete: r.nombrePaquete,
                          }))
                        : rows.map((r) => ({
                            instituto: r.superinstituto,
                            nombrePaquete: r.nombrePaquete,
                            sede: r?.sede || "",
                          })),
                    fileName: `Paquetes_por_${type}`,
                    exportType: exportFromJSON.types.xls,
                  });
                }}
              >
                EXCEL
              </CustomGreenButton> */}
              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columns}
                  rows={
                    type === "instituto"
                      ? rows.map((r) => ({
                          instituto: r.superinstituto,
                          nombrePaquete: r.nombrePaquete,
                        }))
                      : rows.map((r) => ({
                          instituto: r.superinstituto,
                          nombrePaquete: r.nombrePaquete,
                          sede: r?.sede || "",
                        }))
                  }
                  filename={`Paquetes_por_${type}`}
                />
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data:
                      type === "instituto"
                        ? rows.map((r) => ({
                            instituto: r.superinstituto,
                            nombrePaquete: r.nombrePaquete,
                          }))
                        : rows.map((r) => ({
                            instituto: r.superinstituto,
                            nombrePaquete: r.nombrePaquete,
                            sede: r?.sede || "",
                          })),
                    fileName: `Paquetes_por_${type}`,
                    fields: {
                      idLibro: "090$a",
                      title: "245$a",
                      subject: "084$a",
                      subject2: "65300$a",
                      // 'subject2ENG': '65300$a',
                      description: "520$a",
                      source: "534$a",
                      relation: "544$n",
                      coverage: "?",
                      author: "100$a",
                      // 'Autor (entidad)': '110$a',
                      // 'Autor' : '245$c',
                      // 'Lugar de publicación/distribución del libro': '264#2$a',
                      publisher: "264#2$b",
                      // 'Autor secundario (entidad)': '710$a',
                      // 'autor secundario-traductor': '70010$a',
                      // 'función del autor secundario': '70010$e',
                      rights: "540$f",
                      isbn: "020$a",
                      date: "264#2$c",
                      bookType: "300$a",
                      language: "041$a",
                      nivelUsuarioLector: "52110#$a",
                      edad: "5211#$a",
                      curso: "5212#$a",
                      disponibilidadRegional: "522$a",
                      genero: "655#4$a",
                      portada: "85640$a",
                      recomendado1: "544$n",
                      webUrl: "85642$a",
                    },
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                MARC
              </CustomGreenButton>
            </ButtonGroup>
            <MDBDataTable
              small
              responsive
              striped
              searchtop="true"
              searchbottom="false"
              barreverse="true"
              pagingtop="true"
              bordered
              data={{
                columns: columns.filter((obj) => obj.isVisible),
                rows: rows,
                // type === "sede"
                //   ? !data
                //     ? []
                //     : rows.filter(
                //         (row) => row?.superinstituto === data?.superInstituto
                //       )
                //   : !data
                //   ? rows
                //   : rows.filter(
                //       (row) => row?.superinstituto === data?.nombre
                //     ),
                // si type es sede y no hay data no muestra nada
                // si sí hay data, muestra los paquetes de esa sede
                //si type es instituto y no hay data muestra todos los paquetes
                // si sí hay data, muestra los paquetes de ese instituto
              }}
              noBottomColumns
              order={["name", "asc"]}
              entriesOptions={[5, 10, 20, 50]}
              entriesLabel="Numero de filas"
              searchLabel="Buscar"
              paginationLabel={["Anterior", "Siguiente"]}
              infoLabel={["Mostrando filas", "a", "de", "filas"]}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarEliminarPaquetePlanLector;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
