import React, { useState } from "react";

import { Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { Link } from "react-router-dom";

import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { Autocomplete } from "@material-ui/lab";

import { useQueryClient } from "@tanstack/react-query";
import { DropzoneArea } from "material-ui-dropzone";
import { crearNotibanner } from "../../../../shared/fetchers/classics/notibanner/crearNotibanner";
import { editarNotibanner } from "../../../../shared/fetchers/classics/notibanner/editarNotibanner";
import axios from "axios";

const CrearNotibannerIndividual = ({ notibanner, clearEdit }) => {
  const queryClient = useQueryClient();
  const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD

  const [formData, setFormData] = useState({
    tipoNotificacion: {
      value: notibanner?.tipoNotificacion ?? "",
      error: false,
    },
    tipoVisibilidad: { value: notibanner?.tipoVisibilidad ?? "", error: false },
    metadatos: { value: JSON.stringify(notibanner?.metadatos) ?? "", error: false },
    colorTexto: {
      value: notibanner?.colorTexto ?? "#000000",
      error: false,
    },
    colorTextoBoton: {
      value: notibanner?.colorTextoBoton ?? "#000000",
      error: false,
    },
    colorFondoBoton: {
      value: notibanner?.colorFondoBoton ?? "#000000",
      error: false,
    },
    colorPrimario: {
      value: notibanner?.colorPrimario ?? "#00ffff",
      error: false,
    },
    colorSecundario: {
      value: notibanner?.colorSecundario ?? "#008000",
      error: false,
    },
    fechaInicio: { value: notibanner?.fechaInicio ?? today, error: false },
    fechaFin: { value: notibanner?.fechaFin ?? "", error: false },
  });

  //Definimos un estado para guardar la imagen
  const [imagen, setImagen] = useState({
    imageFile: null,
    imageUrl: null,
    error: false,
  });

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: { ...formData[name], value: value, error: false },
    });
  };

  //Funcion para guardar una imagen seleccionada
  const handleImage = (files) => {
    if (files.length === 1) {
      const pickedFile = files[0];
      setImagen({
        imageFile: pickedFile,
        imageUrl: URL.createObjectURL(pickedFile),
        error: false,
      });
    }
  };

  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], value: "", error: false };
    }

    temp = { ...temp, fechaInicio: { value: today, error: false } };

    setFormData(temp);
  };

  const validate = () => {
    let isValid = true;

    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };

    if (!notibanner && !imagen.imageFile) {
      setImagen({ ...imagen, error: true });
      isValid = false;
      toast.error("Debes seleccionar una imagen");
    }

    //Recorremos todos los campos del formulario y validamos que no esten vacios si no son opcionales
    for (const formElement of Object.entries(temp)) {
      const [key, value] = formElement;

      if (!value.optional && !value.value) {
        value.error = true;
        isValid = false;
        console.log(key, value);
        toast.error(`El campo ${key} no puede estar vacío`);
      }
    }

    setFormData(temp);
    return isValid;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    let datosNotibanner = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        return [key, value.value];
      }),
    );

    const { status, info } = !notibanner
      ? await crearNotibanner({ ...datosNotibanner, imagen: imagen.imageFile })
      : await editarNotibanner({
        ...datosNotibanner,
        idNotibanner: notibanner.idNotibanner,
        imagen: imagen.imageFile,
      });

    if (notibanner) clearEdit();

    queryClient.invalidateQueries({
      queryKey: ["notibanner"],
    });

    if (!status)
      return swal({
        title: "Error",
        text: info ?? `Hubo un error ${notibanner ? "editando" : "creando"
          } el notibanner, inténtalo de nuevo.`,
        icon: "error",
      });

    !notibanner
      ? toast.success("Notibanner creado correctamente")
      : toast.success("Notibanner editado correctamente");
  };

  return (
    <Col xs={12} className="content-white-box px-4 py-4">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          {notibanner ? "Editar" : "Crear"} un Notibanner
        </div>
      </div>
      <div className="admin-muted-text-small mt-2">
        Los campos opcionales están marcados con (Opcional), los demás son
        obligatorios
      </div>
      <div className="admin-muted-text-small mt-2">
        Por favor ingresar la siguiente info:
      </div>
      <form>
        <label className="admin-label-text mt-3 d-block">
          Tipo de notificación
        </label>
        <Autocomplete
          options={["Evento", "Recomendado", "Oferta", "MakeBlog", "Noticia"]}
          freeSolo
          name="tipoNotificacionAutocomplete"
          getOptionLabel={(option) => option}
          value={formData.tipoNotificacion.value}
          inputValue={formData.tipoNotificacion.value}
          onInputChange={(_, value) => {
            updateForm({
              target: {
                name: "tipoNotificacion",
                value,
              },
            });
          }}
          noOptionsText="Sin resultados"
          error={formData.tipoNotificacion.error}
          helpertext={
            formData.tipoNotificacion.error ? "Campo no válido" : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Tipo de Notificación"
              name="tipoNotificacion"
              variant="outlined"
              size="small"
              error={formData.tipoNotificacion.error}
              helpertext={
                formData.tipoNotificacion.error ? "Campo no válido" : null
              }
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label
          htmlFor="tipoVisibilidad"
          className="admin-label-text mt-3 d-block"
        >
          Tipo de Visibilidad
        </label>
        <Autocomplete
          options={["LOGUEADO", "NO LOGUEADO"]}
          freeSolo
          name="tipoVisibilidadAutocomplete"
          getOptionLabel={(option) => option}
          value={formData.tipoVisibilidad.value}
          inputValue={formData.tipoVisibilidad.value}
          onInputChange={(_, value) => {
            updateForm({
              target: {
                name: "tipoVisibilidad",
                value,
              },
            });
          }}
          noOptionsText="Sin resultados"
          error={formData.tipoVisibilidad.error}
          helpertext={formData.tipoVisibilidad.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Tipo de visibilidad"
              name="tipoVisibilidad"
              variant="outlined"
              size="small"
              error={formData.tipoVisibilidad.error}
              helpertext={
                formData.tipoVisibilidad.error ? "Campo no válido" : null
              }
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">
          Color Texto Botón
        </label>
        <TextField
          name="colorTextoBoton"
          value={formData.colorTextoBoton.value}
          onChange={updateForm}
          error={formData.colorTextoBoton.error}
          helperText={
            formData.colorTextoBoton.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label className="admin-label-text mt-3 d-block">
          Color Fondo Botón
        </label>
        <TextField
          name="colorFondoBoton"
          value={formData.colorFondoBoton.value}
          onChange={updateForm}
          error={formData.colorFondoBoton.error}
          helperText={
            formData.colorFondoBoton.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label className="admin-label-text mt-3 d-block">
          Color texto banner
        </label>
        <TextField
          name="colorTexto"
          value={formData.colorTexto.value}
          onChange={updateForm}
          error={formData.colorTexto.error}
          helperText={
            formData.colorTexto.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label htmlFor="metadata" className="admin-label-text mt-3 d-block">
          Metadatos
        </label>
        <div className="my-3 d-flex" style={{ gap: "0.15rem" }}>
          <Link
            to="/templates/classics/notibanner/template.json"
            target="_blank"
            download
          >
            <CustomGreenButton size="small" variant="contained">
              Descargar plantilla
            </CustomGreenButton>
          </Link>

          <CustomGreenButton
            size="small"
            variant="contained"
            onClick={async () => {
              const { data: metadata } = await axios.get(
                `/templates/classics/notibanner/template.json`,
              );

              navigator.clipboard
                .writeText(JSON.stringify(metadata))
                .then(async () => {
                  toast.success("Metadatos copiados al portapapeles");
                });
            }}
          >
            Copiar
          </CustomGreenButton>
        </div>
        <TextField
          name="metadatos"
          value={formData.metadatos.value}
          onChange={updateForm}
          type="text"
          error={formData.metadatos.error}
          helpertext={
            formData.metadatos.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          multiline
          minRows={4}
          placeholder="Metadatos de la notificación"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Entre las fechas:
        </label>
        <div className="d-flex">
          <TextField
            name="fechaInicio"
            value={formData.fechaInicio.value}
            onChange={updateForm}
            variant="outlined"
            size="small"
            type="date"
            error={formData.fechaInicio.error}
            style={{
              width: "180px",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
          <CustomGreenButton
            variant="contained"
            style={{
              height: "40px",
              pointerEvents: "none",
            }}
            className="d-block d-md-inline"
            disableTouchRipple
            disableRipple
            disableElevation
            disableFocusRipple
          >
            Hasta
          </CustomGreenButton>
          <TextField
            name="fechaFin"
            value={formData.fechaFin.value}
            onChange={updateForm}
            variant="outlined"
            size="small"
            type="date"
            error={formData.fechaFin.error}
            style={{
              width: "180px",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        </div>

        <label className="admin-label-text mt-3 d-block">Color Principal</label>
        <TextField
          name="colorPrimario"
          value={formData.colorPrimario.value}
          onChange={updateForm}
          error={formData.colorPrimario.error}
          helperText={
            formData.colorPrimario.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label className="admin-label-text mt-3 d-block">
          Color Secundario
        </label>
        <TextField
          name="colorSecundario"
          value={formData.colorSecundario.value}
          onChange={updateForm}
          error={formData.colorSecundario.error}
          helperText={
            formData.colorSecundario.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label className="admin-label-text mt-3 d-block">Imagen</label>
        <div
          className="admin-muted-text-small"
          style={imagen.error ? { color: "red" } : null}
        >
          1 archivo requerido con extensión png, jpeg o jpg
        </div>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          showAlerts={true}
          filesLimit={1}
          acceptedFiles={["image/png", "image/jpeg", "image/jpg"]}
          onChange={(files) => handleImage(files)}
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => SubmitForm(e)}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
          >
            Finalizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              clearForm();
              if (notibanner) clearEdit();
            }}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearNotibannerIndividual;
