import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import EditarCursoForm from "./editar-curso-form";
import { GENERAL_URL_API } from "../../../../shared/urls";

const EditarCursoPL = () => {
  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState("");

  useEffect(() => {
    fetchCursos();
  }, []);

  const fetchCursos = async () => {
    const fd = new FormData();
     
    const url = `${GENERAL_URL_API}/Cursos/verCursos.php`;
    const response = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    // const response = await fetch(url, { method: "POST", credentials: 'include', credentials: 'include', body: fd });
    const data = await response.json();
    setCursos(data.data);
  };

  const clearCurso = async () => {
    await fetchCursos();
    setCurso("");
  };

  return (
    <div>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Editar un curso</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                BUSCAR Y EDITAR UN CURSO EXISTENTE
              </span>
            </div>
            {!cursos.length ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : !curso ? (
              <>
                <label className="admin-label-text mt-3 d-block">
                  Selecciona un curso para editar:
                </label>
                <Autocomplete
                  options={cursos}
                  getOptionLabel={(option) =>
                    option ? `${option?.instituto} - ${option?.curso} ` : ""
                  } //cambiar .title
                  value={curso}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCurso({
                        nombre: newValue.curso,
                        fechaInicio: newValue.fechaIniSub,
                        fechaFin: newValue.fechaFinSub,
                        costo: newValue.costoPorEstudiante,
                        instituto: newValue.instituto,
                        idCursoPlanLector: newValue.idCursoPlanLector,
                      });
                    }
                  }}
                  noOptionsText="Sin resultados"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  )}
                />
              </>
            ) : (
              <EditarCursoForm curso={curso} clear={clearCurso} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditarCursoPL;
