import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import AgregarNuevoProfesor from "../../adicionar-elementos/agregar-nuevo-profesor";
import { GENERAL_URL_API } from "../../../../shared/urls";
import { TextField } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";

const EditarProfesorPL = () => {
  const [profesores, setProfesores] = useState([]);
  const [profesor, setProfesor] = useState("");

  useEffect(() => {
    fetchProfesores();
  }, []);

  const fetchProfesores = async () => {
    const fd = new FormData();

    const url = `${GENERAL_URL_API}/profesores/verProfesores.php`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const data = await response.json();
    setProfesores(data.data);
  };

  const clearProfesor = async () => {
    await fetchProfesores();
    setProfesor("");
  };

  return (
    <>
      {!profesor ? (
        <div>
          <div className="mb-4 d-flex align-items-center">
            <div className="admin-page-tilte">(</div>
            <CreateOutlinedIcon />
            <div className="admin-page-tilte mr-2">)</div>
            <div className="admin-page-tilte">Editar un profesor</div>
          </div>
          <Container fluid>
            <Row>
              <Col xs={12} className="content-white-box px-4 py-4">
                <div className="d-flex align-items-center">
                  <PersonIcon style={{ fontSize: "20px" }} />
                  <span className="admin-main-title-dark-gray ml-2 mt-1">
                    BUSCAR Y EDITAR UN profesor EXISTENTE
                  </span>
                </div>
                {!profesores.length ? (
                  <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </>
                ) : (
                  <>
                    <label className="admin-label-text mt-3 d-block">
                      Selecciona un profesor para editar:
                    </label>
                    <Autocomplete
                      options={profesores}
                      getOptionLabel={(option) => option.nombreCompleto}
                      value={profesor}
                      onChange={(_, newValue) => {
                        if (newValue)
                          setProfesor({
                            ...newValue,
                          });
                      }}
                      noOptionsText="Sin resultados"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          style={{
                            width: "100%",
                            fontFamily: "'Noto Sans JP', sans-serif",
                          }}
                        />
                      )}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <AgregarNuevoProfesor
          profesor={profesor}
          clearProfesor={clearProfesor}
        />
      )}
    </>
  );
};

export default EditarProfesorPL;
