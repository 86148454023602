import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
// import CustomGreenButton from "../../../components/shared/custom-green-button";
// import ImageUploadCard from "../../../components/shared/image-upload-card/image-upload-card";
// import { DropzoneArea } from "material-ui-dropzone";
import { GENERAL_URL_API } from "../../../../shared/urls";
import swal from "sweetalert";
import { Button } from "@material-ui/core";
// import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import { DropzoneArea } from "material-ui-dropzone";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { Link } from "react-router-dom";

const CargaMasiva = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  //Definimos los estaos para almacenar los datos del formulario y sus errores
  const [asignarCM, setAsignarCM] = useState([]);

  const handleDropFileChange = (profesores) => {
    setAsignarCM(profesores);
  };

  const addAsignar = async () => {
    setLoading(true);
    const fd = new FormData();
     
    if (asignarCM[0]) fd.append("asignar", asignarCM[0]);

    const res = await fetch(
      `${GENERAL_URL_API}/Cursos/asociarProfesoresCursos.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      await swal({
        title: "Éxito",
        text: resJSON.info,
        icon: "success",
      });
    }
    setLoading(false);
  };

  return (
    <Row>
      <Col xs={12} className="content-white-box px-4 py-4 mt-3">
        <div className="d-flex align-items-center">
          <MenuBookIcon style={{ fontSize: "20px" }} />
          <div className="admin-main-title-dark-gray ml-2 mt-1">
            Asignar cursos (carga masiva)
          </div>
        </div>
        <div className="mt-3">
          <Link to="/templates/asignar.xlsx" target="_blank" download>
            {" "}
            <CustomGreenButton size="small" variant="contained">
              Descargar plantilla
            </CustomGreenButton>
          </Link>
        </div>
        <div className="mt-3" />
        <form>
          <DropzoneArea
            id="dropzone"
            dropzoneText="Arrastra un archivo o haz click aquí!"
            showFileNames={true}
            // maxFileSize={} //n in bytes
            showAlerts={true}
            filesLimit={1}
            acceptedFiles={[
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
            ]}
            onChange={(files) => handleDropFileChange(files)}
          />

          <Button
            variant="contained"
            color="primary"
            disabled={!(asignarCM.length > 0) || loading}
            startIcon={<CheckRoundedIcon />}
            classes={{ root: classes.agregar }}
            onClick={addAsignar}
          >
            Agregar
          </Button>
        </form>
      </Col>
    </Row>
  );
};

export default CargaMasiva;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
    marginTop: "1em",
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
