import React, { useState, useEffect } from "react";
// Components
import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import swal from "sweetalert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
// icons
import TvIcon from "@material-ui/icons/Tv";
//Datos
import { reporteLecturasC } from "../../../shared/data/columnas-plan-lector";
// Datos de prueba
import exportFromJSON from "export-from-json";
import { GENERAL_URL_API } from "../../../shared/urls";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import { Tooltip } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { Book } from "@material-ui/icons";

const ReporteLecturasPlanLector = () => {
  const [loading, setLoading] = useState(false);
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [fullRows, setFullRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [institutos, setInstitutos] = useState([]);

  const [formData, setFormData] = useState({
    instituto: null,
    fechaIni: "",
    fechaFin: "",
  });

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    let temp = reporteLecturasC;
    //Añadimos un atributo de visible a todas las columnas
    temp.map((_, index) => (temp[index].isVisible = true));
    setColumns(temp);
    fetchInstitutos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInstitutos = async () => {
    const fd = new FormData();
     
    fd.append("show", "all");
    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setInstitutos(data.data);
  };

  const fetchReportesLecturas = async () => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("instituto", formData.instituto.nombre);
    if (formData.fechaIni !== "" && formData.fechaFin !== "") {
      fd.append("fechaInicio", formData.fechaIni);
      fd.append("fechaFin", formData.fechaFin);
    }
    fd.append("maxEntradas", 5000);

    const url = `${GENERAL_URL_API}/verDatos/lecturaLibrosPL.php`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setRows(data.data);

    const fd2 = new FormData();
     
    fd2.append("instituto", formData.instituto.nombre);
    if (formData.fechaIni !== "" && formData.fechaFin !== "") {
      fd2.append("fechaInicio", formData.fechaIni);
      fd2.append("fechaFin", formData.fechaFin);
    }
    fd2.append("maxEntradas", 5000000);

    const url2 = `${GENERAL_URL_API}/verDatos/lecturaLibrosPL.php`;
    const res2 = await fetch(url2, {
      method: "POST", credentials: 'include',
      body: fd2,
    });
    const data2 = await res2.json();
    setFullRows(data2.data);

    setLoading(false);
    if (data.status !== 1)
      await swal({
        title: "Alerta.",
        text: data.info,
        icon: "warning",
      });
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = async (e, n, v) => {
    const value = v || e.target.value;
    const name = n || e.target.name;
    setFormData({ ...formData, [name]: value });
  };

  const clearForm = () => {
    setFormData({
      instituto: null,
      fechaIni: "",
      fechaFin: "",
    });
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Reporte de lecturas de libros plan lector
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <Book />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                REPORTE DE LECTURAS DE LIBROS
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-2">
              Lista de lecturas de libros por fecha, cliente y duración.
            </div>

            <form>
              <label className="admin-label-text d-block">Instituto*</label>
              <Autocomplete
                fullWidth
                options={institutos}
                getOptionLabel={(option) => option.nombre}
                value={formData.instituto}
                onChange={(e, value) => {
                  updateForm(e, "instituto", value);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Selecciona un instituto"
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  </>
                )}
              />
              <label className="admin-label-text mt-3 d-block">
                Entre las fechas:
              </label>
              <div className="d-flex">
                <TextField
                  name="fechaIni"
                  value={formData.fechaIni}
                  onChange={updateForm}
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
                <CustomGreenButton
                  variant="contained"
                  style={{
                    height: "40px",
                    pointerEvents: "none",
                  }}
                  className="d-block d-md-inline"
                  disableTouchRipple
                  disableRipple
                  disableElevation
                  disableFocusRipple
                >
                  Hasta
                </CustomGreenButton>
                <TextField
                  name="fechaFin"
                  value={formData.fechaFin}
                  onChange={updateForm}
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              </div>

              <div className="d-flex mt-3">
                <Tooltip title="Consultar reporte">
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        loading ||
                        !formData.instituto ||
                        (formData.fechaIni && !formData.fechaFin)
                      }
                      startIcon={<CheckRounded />}
                      onClick={fetchReportesLecturas}
                      style={{ background: "#81c868" }}
                    >
                      Consultar
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title="Limpiar entradas">
                  <span>
                    <Button
                      className="ml-3"
                      variant="contained"
                      disabled={loading}
                      color="primary"
                      startIcon={<CheckRounded />}
                      onClick={clearForm}
                      style={{ background: "#f05050" }}
                    >
                      Cancelar
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </form>

            <hr />

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />

            <ButtonGroup size="small" variant="contained" className="my-3">
              {/* <CustomGreenButton>Copiar</CustomGreenButton>
              <CustomGreenButton>Archivo csv</CustomGreenButton>
              <CustomGreenButton>Excel</CustomGreenButton>
              <CustomGreenButton>PDF</CustomGreenButton>
              <CustomGreenButton>Imprimir</CustomGreenButton> */}

              <CustomGreenButton
                onClick={() => {
                  const dat = [...rows];

                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: fullRows,
                    fileName: "reporte-lecturas",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: fullRows,
                    fileName: "reporte-lecturas",
                    exportType: exportFromJSON.types.json,
                  });
                }}
              >
                JSON
              </CustomGreenButton>
              {/* <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: fullRows,
                    fileName: "reporte-lecturas",
                    exportType: exportFromJSON.types.xls,
                  });
                }}
              >
                EXCEL
              </CustomGreenButton> */}

              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columns}
                  rows={rows}
                  filename={"reporte-lecturas"}
                />
              </CustomGreenButton>
            </ButtonGroup>
            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <MDBDataTable
                small
                responsive
                striped
                searchtop="true"
                searchbottom="false"
                barreverse="true"
                pagingtop="true"
                bordered
                data={{
                  columns: columns.filter((obj) => obj.isVisible),
                  rows: rows,
                }}
                noBottomColumns
                order={["name", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReporteLecturasPlanLector;
