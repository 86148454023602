import React, { useState } from "react";
// Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
import { GENERAL_URL_API } from "../../../shared/urls";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
// icons
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";

const HabilitarPaqueteClienteForm = ({ clientes }) => {
  const [loading, setLoading] = useState(false);

  const [cliente, setCliente] = useState({
    value: null,
    error: false,
    required: true,
  });

  const [formData, setFormData] = useState(fData);

  const enable = async () => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("cliente", cliente.value.username);
    fd.append("nombrePaquete", formData.pkgName.value);
    fd.append("maxLibros", formData.nBooks.value);
    fd.append("fechaLimite", formData.date.value);

    const res = await fetch(
      `${GENERAL_URL_API}/paquetes/habilitarPaquete.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 0)
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    else
      await swal({
        title: "Paquete Habilitado exitosamente",
        text: resJSON.info,
        icon: "success",
      });
    setLoading(false);
  };

  const del = async () => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("cliente", cliente.value.username);
    fd.append("nombrePaquete", formData.pkgName.value);
    fd.append("clientUsername", cliente.value.username);

    const res = await fetch(
      `${GENERAL_URL_API}/paquetes/eliminarPaqueteTemporal.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 0)
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    else
      await swal({
        title: `Paquete ${formData.pkgName.value} eliminado exitosamente a ${cliente.value.username}`,
        text: resJSON.info,
        icon: "success",
      });
    setLoading(false);
  };

  const changeCliente = (value) => {
    setCliente({
      ...cliente,
      value,
    });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
      },
    });
  };

  const clearForm = () => {
    setCliente({
      ...cliente,
      value: null,
      error: false,
    });

    // quitar errores de duración y código de país
    const dt = JSON.parse(JSON.stringify(fData));
    Object.keys(dt).forEach((k) => {
      dt[k].error = false;
    });
    setFormData({
      ...dt,
      duration: {
        ...dt.duration,
        value: 0,
      },
    });
  };

  // Eliminar solo require cliente y nombre de paquete
  const validateFormDelete = () => {
    const data = { ...formData };
    let isValid = true;
    if (!data.pkgName.value) {
      data.pkgName.error = true;
      isValid = true;
    }

    if (!cliente.value) {
      setCliente({
        ...cliente,
        error: true,
      });
      isValid = false;
    }

    return isValid;
  };

  const validateFormSubmit = () => {
    const data = { ...formData };
    let isValid = true;

    if (!cliente.value) {
      setCliente({
        ...cliente,
        error: true,
      });
      isValid = false;
    }

    //si alguno de los campos requeridos está vacío
    for (const formElement in data) {
      if (data[formElement].required && !data[formElement].value) {
        data[formElement].error = true;
        isValid = false;
      } else {
        data[formElement].error = false;
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(data);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = validateFormSubmit();
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      await enable();
      clearForm();
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = validateFormDelete();
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      await del();
      clearForm();
    }
  };

  return (
    <>
      {!clientes.length ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <form>
          <label className="admin-label-text mt-3 d-block">El cliente: </label>
          <Autocomplete
            options={clientes}
            getOptionLabel={(option) => option.username}
            value={cliente.value}
            onChange={(e, value) => changeCliente(value)}
            noOptionsText="Sin resultados"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                error={cliente.error}
                helperText={cliente.error ? "Campo no válido" : null}
              />
            )}
          />

          <label className="admin-label-text mt-3 d-block">
            Nombre del nuevo paquete:
          </label>
          <TextField
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            type="text"
            name="pkgName"
            value={formData.pkgName.value}
            onChange={handleChange}
            error={formData.pkgName.error}
            helperText={formData.pkgName.error ? "Campo no válido" : null}
          />

          <label className="admin-label-text mt-3 d-block">
            Numero máximo de libros
          </label>
          <TextField
            variant="outlined"
            size="small"
            style={{
              width: "25%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            type="number"
            name="nBooks"
            value={formData.nBooks.value}
            onChange={(e) => {
              if (e.target.value >= 0 || e.target.value === "") handleChange(e);
            }}
            error={formData.nBooks.error}
            helperText={formData.nBooks.error ? "Campo no válido" : null}
          />

          <label className="admin-label-text mt-3 d-block">
            Fechas para selección de libros *
          </label>
          <TextField
            variant="outlined"
            size="small"
            style={{
              width: "25%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            type="date"
            name="date"
            value={formData.date.value}
            onChange={handleChange}
            error={formData.date.error}
            helperText={formData.date.error ? "Campo no válido" : null}
          />

          <div className="mt-3">
            <Tooltip title="Habilitar paquete">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleSubmit(e, "submit")}
                startIcon={<CheckRounded />}
                disabled={
                  loading ||
                  !cliente.value ||
                  !formData.pkgName.value ||
                  !formData.nBooks ||
                  !formData.date.value
                }
                style={{ background: "#81c868" }}
              >
                Habilitar
              </Button>
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                variant="contained"
                color="primary"
                className="ml-2"
                onClick={(e) => handleDelete(e, "delete")}
                startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                disabled={
                  loading ||
                  !cliente.value ||
                  !formData.pkgName.value ||
                  !formData.nBooks ||
                  !formData.date.value
                }
                style={{ background: "#f05050" }}
              >
                Eliminar
              </Button>
            </Tooltip>

            <Tooltip title="Limpiar formulario">
              <Button
                variant="contained"
                color="primary"
                onClick={clearForm}
                className="ml-2"
                startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                disabled={loading}
                style={{ background: "#5d9cec" }}
              >
                Cancelar
              </Button>
            </Tooltip>
          </div>
        </form>
      )}
    </>
  );
};

export default HabilitarPaqueteClienteForm;

const fData = {
  pkgName: {
    value: "",
    error: false,
    required: false,
  },
  nBooks: {
    value: "",
    error: false,
    required: false,
  },
  date: {
    value: "",
    required: true,
    error: false,
  },
};
