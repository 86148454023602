import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./shared/contex/auth/auth-context";

import App from "./App";
import ClientProvider from "./shared/contex/client/client-context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();

ReactDOM.render(
  <Router>
    <AuthProvider>
      <ClientProvider>
        <QueryClientProvider client={queryClient}>
          <App />
          <Toaster position="top-right" />
        </QueryClientProvider>
      </ClientProvider>
    </AuthProvider>
  </Router>,
  document.getElementById("root")
);
