import CrearFormulario from "../../../pages/home-y-otros/formularios/crear-formulario";
import EditarFormulario from "../../../pages/home-y-otros/formularios/editar-formulario";
import EliminarFormulario from "../../../pages/home-y-otros/formularios/eliminar-formulario";
import ListaFormularios from "../../../pages/home-y-otros/formularios/lista-formularios";
import ListaRegistrosFormularios from "../../../pages/home-y-otros/formularios/lista-registros-formularios";

//TODO: Add proper access to the routes
const RoutesFormularios = [
  {
    path: "/formularios/lista-formularios",
    component: ListaFormularios,
    access: "ver_codigos_regalo",
    name: "Lista de formularios",
  },
  {
    path: "/formularios/lista-registros-formularios",
    component: ListaRegistrosFormularios,
    access: "ver_codigos_regalo",
    name: "Lista de registros de formularios",
  },
  {
    path: "/formularios/crear-formularios",
    component: CrearFormulario,
    access: "ver_codigos_regalo",
    name: "Crear formulario",
  },
  {
    path: "/formularios/editar-formularios",
    component: EditarFormulario,
    access: "ver_codigos_regalo",
    name: "Editar formulario",
  },
  {
    path: "/formularios/eliminar-formularios",
    component: EliminarFormulario,
    access: "ver_codigos_regalo",
    name: "Eliminar formulario",
  },
];

export default RoutesFormularios;
