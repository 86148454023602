import React, { useState } from "react";
// Bootstrap
import { Container, Row, Col } from "react-bootstrap";
// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Button, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import MenuBookIcon from "@material-ui/icons/MenuBook";
// MDB
import { MDBDataTableV5 } from "mdbreact";
// Alerts and toasts
import swal from "sweetalert";
// Shared
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  agregarLibroFila,
  eliminarLibroFila,
  fetchLibrosFila,
} from "../../../../shared/fetchers/filasHome";
import { columnasLibrosFila } from "../../../../shared/data/columnas/classics/filasHome";
import { CheckRounded } from "@material-ui/icons";
import { toast } from "react-hot-toast";

const LibrosFila = ({ filasHome, libros }) => {
  const [filaHome, setFilaHome] = useState({});
  const [libro, setLibro] = useState({});

  const queryClient = useQueryClient();

  const classes = useStyles();

  const { data: librosFila } = useQuery(
    ["librosFila", filaHome?.filaHomeName],
    () => fetchLibrosFila(filaHome?.filaHomeName),
    {
      enabled: Boolean(filaHome?.filaHomeName),
    }
  );

  const addBook = async (e) => {
    e.preventDefault();
    console.log("Agregando libro 📚 ...");

    try {
      let response = await agregarLibroFila({
        idLibro: libro.idLibro,
        nombreFila: filaHome.filaHomeName,
      });

      if (!response.status) throw new Error("Error agregando libro 📚");

      queryClient.invalidateQueries({
        queryKey: ["librosFila", filaHome?.filaHomeName],
      });

      setLibro({});

      toast.success(
        `📚${libro.idLibro}-${libro.title} agregado a la fila: ${filaHome.filaHomeName}`
      );
    } catch (error) {
      toast.error("Error al agregar el libro 📚");
    }
  };

  const deleteBook = async (e) => {
    e.preventDefault();
    console.log("Eliminando libro 📚 ...");

    try {
      let response = await eliminarLibroFila({
        idLibro: libro.idLibro,
        nombreFila: filaHome.filaHomeName,
      });

      if (!response.status) throw new Error("Error eliminando libro 📚");

      queryClient.invalidateQueries({
        queryKey: ["librosFila", filaHome?.filaHomeName],
      });

      setLibro({});

      toast.success(
        `📚${libro.idLibro}-${libro.title} eliminado de la fila: ${filaHome.filaHomeName}`
      );
    } catch (error) {
      toast.error("Error al eliminar el libro 📚");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="d-flex align-items-center">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Agregar / Eliminar un libro a una fila
            </div>
          </div>
          <div className="admin-muted-text-small mt-1 mb-4">
            Agregar / Eliminar un libro a una fila
          </div>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Autocomplete
              options={filasHome}
              getOptionLabel={(option) => option?.filaHomeName ?? ""}
              value={filaHome}
              onChange={(_, value) => {
                setFilaHome(value);
              }}
              noOptionsText="Sin resultados"
              disabled={!filasHome.length}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Filas"
                  style={{
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <Autocomplete
              options={libros.data}
              getOptionLabel={(option) =>
                option.idLibro ? `${option.idLibro} - ${option.title}` : ""
              }
              value={libro}
              onChange={(_, value) => {
                setLibro(value);
              }}
              noOptionsText="Sin resultados"
              disabled={!filaHome?.filaHomeName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Libros"
                  style={{
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!filaHome?.filaHomeName || !libro?.idLibro}
                startIcon={<CheckRounded />}
                onClick={addBook}
                classes={{ root: classes.agregar }}
              >
                Agregar
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!filaHome?.filaHomeName || !libro?.idLibro}
                startIcon={<CheckRounded />}
                onClick={deleteBook}
                classes={{ root: classes.eliminar }}
              >
                Eliminar
              </Button>
            </div>
          </form>

          <div className="d-flex align-items-center mt-3">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Tabla de libros de una fila
            </div>
          </div>
          <div className="admin-muted-text-small mt-1 mb-4">
            Selecciona una fila para poder ver sus libros.
          </div>
          {!librosFila ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              <ButtonGroup size="small" variant="contained" className="my-3">
                <CustomGreenButton
                  onClick={() => {
                    navigator.clipboard
                      .writeText(JSON.stringify(librosFila))
                      .then(async () => {
                        await swal({
                          title: "Se ha copiado la tabla!",
                          icon: "success",
                        });
                      });
                  }}
                >
                  Copy
                </CustomGreenButton>
                <CustomGreenButton>
                  <ExcelWorkBook
                    columns={columnasLibrosFila}
                    rows={librosFila}
                    filename={`Lista-Libros-Fila-${filaHome?.filaHomeName}`}
                  />
                </CustomGreenButton>
              </ButtonGroup>

              <MDBDataTableV5
                small
                responsive
                striped
                bordered
                searchTop
                searchBottom={false}
                barReverse
                pagingTop
                data={{
                  columns: columnasLibrosFila,
                  rows: librosFila,
                }}
                noBottomColumns
                order={["name", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
                fullPagination
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default LibrosFila;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
