import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Edits a FORMULARIO with provided information.
 *
 * @async
 * @param {Object} formulario - The information of the formulario.
 * @param {string} formulario.idFormularioEvento- The id of the event.
 * @param {string} formulario.idEvento- The id of the event.
 * @param {string} formulario.colorFondo- The background color of the form.
 * @param {string} formulario.colorFuente- The font color of the form.
 * @param {string} formulario.mensajeInscripcion- The message to show when a user registers.
 * @param {string} formulario.mensajeRecordatorio- The message to show when a user is reminded.
 * @param {string} formulario.imagenFormulario- The image of the form.
 * @param {string} formulario.limiteInscritos - The limit of registered users.
 * @param {string} formulario.estado- The state of the form.
 * @param {string} formulario.linkEvento- The link of the recorded event (yt link).
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
export async function editarFormulario(formulario) {
  const fd = new FormData();

  Object.entries(formulario).forEach(([key, value]) => {
    if (value) fd.append(key, value);
  });

  const { data } = await axios.post(
    `${GENERAL_URL_API}/eventos/formularios/editarFormularioEvento`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return data;
}
