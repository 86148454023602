import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * @param {string} id - id del evento a eliminarEvento
 * @returns {Promise<{info: string, status: boolean}>}
 */
export const eliminarEvento = async (id) => {
  const fd = new FormData();
  fd.append("idEvento", id);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/eventos/borrarEvento`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return data;
};
