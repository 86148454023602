export const cursosUsuariosCols = [
  {
    label: "Usuario",
    field: "usernameAcademia",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Curso",
    field: "idCursoAcademia",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
