import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

export const fetchUsuariosCursosAcademia = async () => {
  const { data } = await axios.get(
    `${GENERAL_URL_API}/academia/verUsuariosCursos`,
    {
      withCredentials: true,
    }
  );

  return data.data;
};
