import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { DropzoneArea } from "material-ui-dropzone";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import swal from "sweetalert";
import IOSSwitch from "../../../../components/shared/ios-switch";
import { editarArteColeccion } from "../../../../shared/fetchers/colecciones";

const EditarArteColeccionForm = ({ selectedOption, clearSelection }) => {
  //Definimos los estados para almacenar los datos del formulario y sus errores
  //La informacion por defecto es la del blog seleccionado
  const [loading, setLoading] = useState(false);
  const [file, setColart] = useState(undefined);
  const [colartInterna, setColartInterna] = useState(undefined);
  const [booleanData, setBooleanData] = useState({
    coleccionDeAutor: {
      value: selectedOption.coleccionDeAutor === 0 ? false : true,
      error: false,
    },
    coleccionDePais: {
      value: selectedOption.coleccionDePais === 0 ? false : true,
      error: false,
    },
  });
    
  const [book, setBook] = useState({
    value: { idLibro: selectedOption.idLibroRelacionado },
    error: false,
    required: true,
  });

  const [formData, setFormData] = useState({
    collectionName: {
      label: "Nombre de la coleccion",
      placeholder: "Nombre de la coleccion",
      value: selectedOption.collectionName,
      error: false,
      required: false,
    },
    collectionNameENG: {
      label: "Nombre de la coleccion en ingles",
      placeholder: "Nombre de la coleccion en ingles",
      value: selectedOption.collectionNameENG,
      error: false,
      required: false,
    },
    subtitulo: {
      label: "Subtitulo",
      value: selectedOption.subtitulo,
      placeholder: "Subtitulo",
      error: false,
      required: false,
    },
    subtituloENG: {
      label: "Subtitulo en ingles",
      placeholder: "Subtitulo en ingles",
      value: selectedOption.subtituloENG,
      error: false,
      required: false,
    },
    description: {
      label: "Descripción",
      placeholder: "Descripción",
      multiline: true,
      value: selectedOption.descripcion,
      error: false,
      required: false,
    },
    descriptionENG: {
      label: "Descripción en ingles",
      placeholder: "Descripción en ingles",
      multiline: true,
      value: selectedOption.descripcionENG,
      error: false,
      required: false,
    },
  });

  //Funcion para actualizar un switch del formulario
  const updateIOSSwitch = (key) => {
    setBooleanData({
      ...booleanData,
      [key]: { value: !booleanData[key].value, error: false },
    });
  };

  const [colorData, setColorData] = useState({
    colorLetras: {
      value: selectedOption.colorLetras,
      error: false,
    },
    color1: {
      value: selectedOption.color1,
      error: false,
    },
    color2: {
      value: selectedOption.color2,
      error: false,
    },
  });

  const updateForm = (e) => {
    const { value, name } = e.target;
    setColorData({ ...colorData, [name]: { value: value, error: false } });
    console.log(colorData);
  };

  const changeBook = (value) => {
    setBook({
      ...book,
      value,
    });
    console.log(book);
  };

  const handleChange = (
    e,
    name = e.target.name,
    value = e.target.value,
    condition = true
  ) => {
    if (condition === true || condition(value)) {
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value,
        },
      });
    }
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
      style: {
        opacity: "0.3",
        margin: "auto",
      },
    };
    if (type.startsWith("application/pdf"))
      return <PictureAsPdfOutlinedIcon {...iconProps} />;
  };

  // solo funciona para textfields, si hay autocomplete components no
  const textFields = Object.keys(formData).map((key, index) => {
    const formElement = formData[key];
    let onChangeCondition = true;
    if (formElement.condition) {
      onChangeCondition = formElement.condition;
    }
    return (
      <React.Fragment key={index}>
        <label className="admin-label-text mt-3 d-block">
          {formElement.label}
        </label>
        <TextField
          fullWidth
          multiline={formElement.multiline ? formElement.multiline : false}
          minRows={formElement.multiline ? 4 : 1}
          variant="outlined"
          size="small"
          type="text"
          value={formElement.value}
          name={key}
          placeholder={formElement.placeholder && formElement.placeholder}
          onChange={(e) => {
            const name = e.target.name;
            let value = e.target.value;
            if (formElement.uppercase) value = e.target.value.toUpperCase();
            handleChange(e, name, value, onChangeCondition);
          }}
          error={formElement.error ? formElement.error : false}
          helperText={formElement.error ? "Campo no válido" : null}
        />
      </React.Fragment>
    );
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { ...formData };
    let isValid = true;

    //si alguno de los campos requeridos está vacío
    for (const formElement in data) {
      if (data[formElement].required && !data[formElement].value) {
        data[formElement].error = true;
        isValid = false;
      } else {
        data[formElement].error = false;
      }
    }
    if (!book.value) {
      book.error = true;
      isValid = false;
    }

    //Actualizamos el estado con todos los cambios
    setFormData(data);
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      document.querySelector(".MuiDropzoneArea-root").classList.remove("error");
      //handle data here
      const data = {};
      data.book = book;
      data.file = file;
      Object.keys(formData).forEach((key) => {
        data[key] = formData[key].value;
      });

      try {
        const data = await editarArteColeccion({
          collectionName: selectedOption.collectionName,
          nuevoNombre: formData.collectionName.value,
          collectionNameENG: formData.collectionNameENG.value,
          subtitulo: formData.subtitulo.value,
          subtituloENG: formData.subtituloENG.value,
          descripcion: formData.description.value,
          descripcionENG: formData.descriptionENG.value,
          color1: colorData.color1.value,
          color2: colorData.color2.value,
          colorLetras: colorData.colorLetras.value,
          coleccionDeAutor: +booleanData.coleccionDeAutor.value,
          coleccionDePais: +booleanData.coleccionDePais.value,
          colart: colartInterna[0], //invertidos por error en la api
          colartinterna: file[0],
        });

        if (data.status === 1) {
          await swal({
            title: "Coleccion editada.",
            text: "La coleccion se edito satisfactoriamente!",
            icon: "success",
          });
        } else {
          await swal({
            title: "Sorry.",
            text: "No se pudo editar la coleccion",
            icon: "error",
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className="admin-muted-text-small mt-3">
        Por favor modifique la información que desee cambiar (Si el usuario no
        existe, debe crearse primero):
      </div>
      <CustomGreenButton
        className="mt-3"
        size="small"
        onClick={clearSelection}
      >
        Regresar
      </CustomGreenButton>
      <form>
        {textFields}
        <label className="admin-label-text mt-3 d-block">
          Color segmento 1*
        </label>
        <TextField
          name="color1"
          value={colorData.color1.value}
          onChange={updateForm}
          error={colorData.color1.error}
          helperText={
            colorData.color1.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">
          Color segmento 2*
        </label>
        <TextField
          name="color2"
          value={colorData.color2.value}
          onChange={updateForm}
          error={colorData.color2.error}
          helperText={
            colorData.color2.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <label className="admin-label-text mt-3 d-block">Color letras*</label>
        <TextField
          name="colorLetras"
          value={colorData.colorLetras.value}
          onChange={updateForm}
          error={colorData.colorLetras.error}
          helperText={
            colorData.colorLetras.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />
        <div className="mt-4 w-100">
          <label className="admin-label-text mt-3 d-block">
            Archivo de portada de colección* <br />
            <span className="admin-muted-text">
              1 archivo requerido con extensión png ["colart.png"]
            </span>
          </label>
          <DropzoneArea
            id="dropzone"
            dropzoneText="Arrastra un archivo o haz click aquí!"
            showFileNames={true}
            maxFileSize={30000000} //n in bytes
            showAlerts={true}
            filesLimit={1}
            acceptedFiles={["image/png"]}
            getPreviewIcon={handlePreviewIcon}
            onChange={(file) => {
              setColart(file);
            }}
          />
        </div>
        <div className="mt-4 w-100">
          <label className="admin-label-text mt-3 d-block">
            Imagen interna de la colección* <br />
            <span className="admin-muted-text">
              1 archivo requerido con extensión png ["colartinterna.png"]
            </span>
          </label>
          <DropzoneArea
            id="dropzone"
            dropzoneText="Arrastra un archivo o haz click aquí!"
            showFileNames={true}
            maxFileSize={30000000} //n in bytes
            showAlerts={true}
            filesLimit={1}
            acceptedFiles={["image/png"]}
            getPreviewIcon={handlePreviewIcon}
            onChange={(colartInterna) => {
              setColartInterna(colartInterna);
            }}
          />
        </div>
        <label
          className="admin-label-text mt-4"
          style={{ whiteSpace: "nowrap" }}
        >
          Colección de autor (Activar para que aparezca como ficha circular en
          la parte superior)*
        </label>
        <IOSSwitch
          checked={booleanData.coleccionDeAutor.value}
          onClick={() => {
            updateIOSSwitch("coleccionDeAutor");
          }}
        />
        <label
          className="admin-label-text mt-4"
          style={{ whiteSpace: "nowrap" }}
        >
          Colección de pais (Activar para que aparezca como ficha circular en
          la parte superior)*
        </label>
        <IOSSwitch
          checked={booleanData.coleccionDePais.value}
          onClick={() => {
            updateIOSSwitch("coleccionDePais");
          }}
        />
        <div className="d-flex justify-content-end mt-4">
          <CustomGreenButton
            variant="contained"
            className="mr-2"
            style={{ background: "#5d9cec" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            Finalizar
          </CustomGreenButton>
          <CustomGreenButton variant="contained">Cancelar</CustomGreenButton>
        </div>
      </form>
    </>
  );
};

export default EditarArteColeccionForm;
