import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { TextField } from "@material-ui/core";
import CrearRecursoIndividual from "../crear-recurso/crear-recurso-individual";
import { fetchRecursos } from "../../../../shared/fetchers/classics/recursos/verRecursos";

const EditarRecurso = () => {
  const [recurso, setRecurso] = useState(null);

  const {
    data: recursos,
    isLoading,
    isError,
  } = useQuery(["recursos"], fetchRecursos);

  if (isLoading)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isError)
    return <div>Ha ocurrido un error, prueba recargando la página</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Editar un recurso</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <label className="admin-label-text mt-3 d-block">
              Selecciona un recurso para editarlo
            </label>
            <Autocomplete
              options={recursos}
              value={recurso}
              disabled={Boolean(recurso)}
              getOptionLabel={(option) => `${option.titulo}`}
              onChange={(_, newValue) => {
                setRecurso(newValue);
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />
          </Col>

          {recurso && (
            <CrearRecursoIndividual
              recurso={{ ...recurso}}
              clearEdit={() => {
                setRecurso(null);
              }}
            />
          )}
        </Row>
      </Container>
    </>
  );
};

export default EditarRecurso;
