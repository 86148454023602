import { LOGOUT, LOGIN } from "./auth-types";

//Se limpia el localstorage
//Se borra la informacion del usuario del estado
const logout = (state) => {
  localStorage.removeItem("auth-value");
  localStorage.removeItem("userAdminMake");
  localStorage.removeItem("passwordAdminMake");
  localStorage.removeItem("client");
  return { ...state, isLoggedIn: false };
};

//Se guarda en localstorage que se esta logeado
//Se acutaliza el estado con los datos
const login = (state) => {
  console.log(state);
  localStorage.setItem("auth-value", JSON.stringify(true));
  return { ...state, isLoggedIn: true };
};

//Reducer para gestionar los cambios de estado
export const authReducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case LOGIN:
      return login(state);
    case LOGOUT:
      return logout(state);
    default:
      return state;
  }
};
