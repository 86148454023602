import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Creates a new campus (sede) for an institute with provided information.
 *
 * @async
 * @param {Object} instituto - The object containing campus information.
 * @param {string} instituto.nombreSede - The name of the campus.
 * @param {string} instituto.nombreInstituto - The name of the institute to which the campus belongs.
 * @param {string} instituto.direccionSede - The address of the campus.
 * @param {string} instituto.pais - The country where the campus is located.
 * @param {string} instituto.ciudad - The city where the campus is located.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
export const crearSedeClassics = async (instituto) => {
  const {
    nombreSede,
    nombreInstituto,
    direccionSede,
    pais,
    ciudad,
  } = instituto;

  const fd = new FormData();
  fd.append("nombreSede", nombreSede);
  fd.append("nombreInstituto", nombreInstituto);
  fd.append("direccionSede", direccionSede);
  fd.append("pais", pais);
  fd.append("ciudad", ciudad);

  const { data } = await axios.post(`${GENERAL_URL_API}/app/crearSedes`, fd, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const crearSedesClassics = async (file) => {
  const fd = new FormData();

  fd.append("editionType", "agregarSede");
  fd.append("sedes", file);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/app/editarSedesMasivo`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};
