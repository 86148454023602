import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";

import TvIcon from "@material-ui/icons/Tv";
import { useQuery } from "@tanstack/react-query";
import { fetchInstitutos } from "../../../../shared/fetchers/classics/institutos/verInstitutos";
import TablaSedes from "./tabla-sedes";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const ListaSedesClassics = () => {
  const {
    data: institutos,
    isLoading,
    isError,
  } = useQuery(["institutosClassics"], fetchInstitutos);

  const [instituto, setInstituto] = useState(null);

  if (isLoading)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isError) return <div>Ha ocurrido un error</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Lista de Sedes</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <label className="admin-label-text mt-3 d-block">
              Selecciona un instituto para ver sus sedes:
            </label>
            <Autocomplete
              options={institutos}
              getOptionLabel={(option) => option.nombre}
              value={instituto}
              onChange={(_, newValue) => {
                setInstituto(newValue?.nombre);
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <TablaSedes instituto={instituto} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaSedesClassics;
