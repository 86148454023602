import React, { useState, useEffect } from "react";
// components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import swal from "sweetalert";
import { Button } from "@material-ui/core";
// data
import { GENERAL_URL_API } from "../../../shared/urls";
// icons
import { paisesAutocomplete } from "../../../shared/data/paises";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";

const AsignarCodigoForm = () => {
  const [giftCode, setGiftCode] = useState("");

  const [loading, setLoading] = useState(false);

  const [institutes, setInstitutes] = useState([]);
  const [institute, setInstitute] = useState({
    value: null,
    error: false,
    required: true,
  });

  const [packets, setPackets] = useState([]);
  const [packet, setPacket] = useState({
    value: null,
    error: false,
    required: true,
  });

  const [formData, setFormData] = useState(fData);

  useEffect(() => {
    fetchInstitutes();
    fetchPackets();
    setGiftCode(getGiftCode(12));
  }, []);

  const getGiftCode = (len) => {
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = len; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  const fetchInstitutes = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setInstitutes(data.data);
  };

  const fetchPackets = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/paquetes/verPaquetes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    console.log(data);
    setPackets(data.data);
  };

  const assignCode = async () => {
    const fd = new FormData();
     
    fd.append("codigo", giftCode);
    fd.append("instituto", institute.value.nombre);
    fd.append("paquete", packet.value);
    fd.append("dias", formData.duration.value.toString());
    fd.append("pais", formData.country.value);

    const res = await fetch(
      `${GENERAL_URL_API}/codigoRegalo/codigo_regalo.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const data = await res.json();
    if (data.status === 1)
      await swal({
        title: "Código asignado!",
        text: "",
        icon: "success",
      });
    else
      await swal({
        title: "Error",
        text: "No pudo asignarse el código, comunicate con soporte.",
        icon: "error",
      });
  };

  const changeInstitute = (value) => {
    setInstitute({
      ...institute,
      value,
    });
  };

  const changePacket = (value) => {
    setPacket({
      ...packet,
      value,
    });
  };

  const handleChange = (e, n, v) => {
    let name = n || e.target.name;
    let value = v || e.target.value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
      },
    });
  };

  const clearForm = () => {
    setGiftCode(getGiftCode(12));
    setInstitute({
      ...institute,
      value: null,
      error: false,
    });
    setPacket({
      ...packet,
      value: null,
      error: false,
    });

    // quitar errores de duración y código de país
    const dt = JSON.parse(JSON.stringify(fData));
    Object.keys(dt).forEach((k) => {
      dt[k].error = false;
    });
    setFormData({
      ...dt,
      duration: {
        ...dt.duration,
        value: 0,
      },
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { ...formData };
    let isValid = true;

    if (!institute.value) {
      setInstitute({
        ...institute,
        error: true,
      });
      isValid = false;
    }

    if (!packet.value) {
      setPacket({
        ...packet,
        error: true,
      });
      isValid = false;
    }
    //si alguno de los campos requeridos está vacío
    for (const formElement in data) {
      if (data[formElement].required && !data[formElement].value) {
        data[formElement].error = true;
        isValid = false;
      } else {
        data[formElement].error = false;
      }
    }

    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      await assignCode();
      clearForm();
      setGiftCode(getGiftCode(12));
    }

    //Actualizamos el estado con todos los cambios
    setFormData(data);
    setLoading(false);
  };

  return (
    <form>
      <label className="admin-label-text mt-3 d-block">
        Código (Recuerda copiarlo antes de salir de la página. No será válido
        hasta oprimir el botón de 'submit')
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          fontWeight: "bolder",
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        disabled={true}
        type="text"
        value={giftCode}
      />

      <label className="admin-label-text mt-3 d-block">Instituto *</label>
      <Autocomplete
        options={institutes}
        getOptionLabel={(option) => option.nombre}
        value={institute.value}
        onChange={(e, value) => changeInstitute(value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
            error={institute.error}
            helperText={institute.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">
        Paquete asignado *
      </label>
      <Autocomplete
        options={packets}
        getOptionLabel={(option) => option}
        value={packet.value}
        onChange={(e, value) => changePacket(value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            style={{ width: "100%", fontFamily: "'Noto Sans JP', sans-serif" }}
            error={packet.error}
            helperText={packet.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">
        Duración en días *
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="number"
        name="duration"
        placeholder="150"
        value={formData.duration.value}
        onChange={(e) => {
          let value = e.target.value;
          if (value >= 0 || value === "") handleChange(e);
        }}
        error={formData.duration.error}
        helperText={formData.duration.error ? "Campo no válido" : null}
      />

      <label className="admin-label-text mt-3 d-block">Referencia</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="ref"
        placeholder="Nombre Encargado"
        value={formData.ref.value}
        onChange={handleChange}
        error={formData.ref.error}
        helperText={formData.ref.error ? "Campo no válido" : null}
      />

      <label className="admin-label-text mt-3 d-block">Codigo de País *</label>
      <Autocomplete
        options={paisesAutocomplete}
        getOptionLabel={(option) => option}
        value={formData.country.value}
        onChange={(e, value) => handleChange(e, "country", value)}
        noOptionsText="Sin resultados"
        error={formData.country.error}
        helperText={formData.country.error ? "Campo no válido" : null}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Selecciona un país"
            variant="outlined"
            size="small"
            error={formData.country.error}
            helperText={formData.country.error ? "Campo no válido" : null}
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <div className="mt-3">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          startIcon={<CheckRounded />}
          disabled={loading}
          style={{ background: "#81c868" }}
        >
          Asignar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={clearForm}
          className="ml-2"
          startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
          disabled={loading}
          style={{ background: "#f05050" }}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default AsignarCodigoForm;

const fData = {
  duration: {
    value: 0,
    error: false,
    required: true,
  },
  ref: {
    value: "",
    error: false,
    required: false,
  },
  country: {
    value: "",
    required: true,
    error: false,
  },
};
