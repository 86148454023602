import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

export const eliminarInstitutoClassics = async (nombreInstituto) => {
  const fd = new FormData();
  fd.append("nombreInstituto", nombreInstituto);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/app/eliminarInstitutos`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};
