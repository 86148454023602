import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Edits an existing campus (sede) with provided information.
 *
 * @async
 * @param {Object} sede - The object containing updated campus information.
 * @param {string} sede.nombreSede - The name of the campus to be edited.
 * @param {string} sede.nombreSedeNuevo - The updated name for the campus.
 * @param {string} sede.nombreInstituto - The name of the institute to which the campus belongs.
 * @param {string} sede.direccionSede - The updated address of the campus.
 * @param {string} sede.pais - The updated country where the campus is located.
 * @param {string} sede.ciudad - The updated city where the campus is located.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
export const editarSedeClassics = async (sede) => {
  const {
    nombreSede,
    nombreSedeNuevo,
    nombreInstituto,
    direccionSede,
    pais,
    ciudad,
  } = sede;

  const fd = new FormData();
  fd.append("nombreSede", nombreSede);
  fd.append("nombreInstituto", nombreInstituto);
  fd.append("nombreSedeNuevo", nombreSedeNuevo);
  fd.append("direccionSede", direccionSede);
  fd.append("pais", pais);
  fd.append("ciudad", ciudad);

  const { data } = await axios.post(`${GENERAL_URL_API}/app/editarSedes`, fd, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
