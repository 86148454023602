import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";

import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { GENERAL_URL_API } from "../../../shared/urls";
import swal from "sweetalert";

import { Button, IconButton, InputAdornment, Tooltip } from "@material-ui/core";

import {
  CheckRounded,
  Close,
  LockRounded,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";

import { useClient } from "../../../shared/contex/client/client-context";
import { paisesAutocomplete } from "../../../shared/data/paises";
import { validatePassword } from "../../../shared/utils/validatePassword";
import { generatePasswords } from "../../../shared/utils/generatePassword";

const AgregarClienteAdministrativo = () => {
  const { getClient } = useClient();
  const client = getClient();
  //Definimos los estados para almacenar los datos del formulario y sus errores
  //La informacion por defecto es la del blog seleccionado
  const [formData, setFormData] = useState({
    username: { value: "", error: false },
    email: { value: "", error: false },
    passwd: { value: "", error: false, show: false },
    pais: { value: "", error: false },
  });

  const [loading, setLoading] = useState(false);
  const [rolSelected, setRolSelected] = useState(null);
  const [rol, setRolSel] = useState("");
  const [editorialesSel, setEditoriales] = useState("");
  const [editorialSelected] = useState([]);
  const [editoriales, setListaEditoriales] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const fd = new FormData();

    const res = await fetch(`${GENERAL_URL_API}/verDatos/verEditoriales.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const resJSON = await res.json();

    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      setListaEditoriales(resJSON.data);
    }
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e, n, v) => {
    const name = n || e.target.name;
    const value = v || e.target.value;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };

  const rolSelectedUpdate = (_, newValue) => {
    if (newValue) {
      setRolSelected(newValue);
      setRolSel(newValue.value);
    }
  };

  const editorialSelectedUpdate = (_, newValue) => {
    let temp = newValue;
    let aux = "";
    if (temp.length > 0) {
      for (let i = 0; i < temp.length; i++) {
        aux = aux + temp[i].nombre + ",";
      }
      setEditoriales(aux.substring(0, aux.length - 1));
    }
  };

  //Funcion para subir la informacion y validad todos los datos
  const SubmitForm = async () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      if (temp[formElement].value === "") {
        temp[formElement].error = true;
        isValid = false;
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(temp);

    if (rolSelected == null) {
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const fd = new FormData();

      fd.append("newUsername", formData.username.value);
      fd.append("newUserPass", formData.passwd.value);
      fd.append("newUserEmail", formData.email.value);
      fd.append("newUserRegion", formData.pais.value);
      fd.append("newUserRol", rol);
      fd.append("editoriales", rol === "Editorial" ? editorialesSel : "");
      const res = await fetch(`${GENERAL_URL_API}/userAdmin/createUser.php`, {
        method: "POST",
        credentials: "include",
        body: fd,
      });
      try {
        const resJSON = await res.json();
        if (resJSON.status === 1) {
          await swal({
            title: "Cliente administrativo agregado",
            text: "El cliente administrativo fue agregado satisfactoriamente!",
            icon: "success",
          });
        } else {
          await swal({
            title: "Error",
            text: resJSON.info,
            icon: "error",
          });
        }
        setLoading(false);
      } catch (error) {
        await swal({
          title: "Error",
          text: "Error al realizar la peticion",
          icon: "error",
        });
        console.error(error);
        setLoading(false);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar un nuevo usuario administrativo
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Crear un usuario administrativo nuevo
              </div>
            </div>
            <div className="admin-muted-text-small mt-2">
              Por favor ingresar la siguiente info:
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">Usuario*</label>
              <TextField
                name="username"
                value={formData.username.value}
                onChange={updateForm}
                error={formData.username.error}
                helperText={
                  formData.username.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="Usuario (min 4 caracteres y sin espacios)"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Email*</label>
              <TextField
                name="email"
                value={formData.email.value}
                onChange={updateForm}
                error={formData.email.error}
                helperText={
                  formData.email.error
                    ? "Este campo no puede estar vacío"
                    : null
                }
                variant="outlined"
                size="small"
                placeholder="E-mail"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Password*</label>
              <TextField
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                type={formData.passwd.show ? "text" : "password"}
                value={formData.passwd.value}
                name="passwd"
                placeholder="Password (min 6 caracteres, letras, numeros y simbolos (!@#$%^&*)"
                onChange={updateForm}
                error={formData.passwd.error}
                helperText={formData.passwd.error ? "Campo no válido" : null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        aria-label="clear password"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            passwd: {
                              ...formData.passwd,
                              value: "",
                            },
                          });
                        }}
                        edge="end"
                      >
                        <Tooltip title="Eliminar contraseñas">
                          <Close />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        aria-label="generate password"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          const autoPassword = generatePasswords(1)[0];
                          setFormData({
                            ...formData,
                            passwd: {
                              ...formData.passwd,
                              value: autoPassword,
                            },
                          });
                        }}
                        edge="end"
                      >
                        <Tooltip title="Generar contraseña">
                          <LockRounded />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            passwd: {
                              ...formData.passwd,
                              show: !formData.passwd.show,
                            },
                          });
                        }}
                        edge="end"
                      >
                        {formData.passwd.show ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <label className="admin-label-text mt-3 d-block">Rol*</label>
              <Autocomplete
                options={
                  client?.rol !== "Dios"
                    ? [
                        { data: "Editorial", value: "Editorial" },
                        { data: "Vendedor", value: "Vendedor" },
                        { data: "Dios", value: "Dios" },
                        { data: "Superdios", value: "Superdios" },
                      ]
                    : [{ data: "Vendedor", value: "Vendedor" }]
                }
                getOptionLabel={(option) => option.value}
                value={rolSelected}
                onChange={rolSelectedUpdate}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              {rol === "Editorial" && (
                <div>
                  <label className="admin-label-text mt-3 d-block">
                    Editoriales *
                  </label>
                  <Autocomplete
                    multiple
                    options={editoriales}
                    getOptionLabel={(option) => option.nombre}
                    defaultValue={editorialSelected}
                    disableCloseOnSelect
                    noOptionsText="Sin resultados"
                    onChange={editorialSelectedUpdate}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          color="primary"
                        />
                        {option.nombre}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        style={{
                          width: "100%",
                          fontFamily: "'Noto Sans JP', sans-serif",
                        }}
                      />
                    )}
                  />
                </div>
              )}
              <label className="admin-label-text mt-3 d-block">
                Codigo de País*
              </label>
              <Autocomplete
                options={paisesAutocomplete}
                getOptionLabel={(option) => option}
                value={formData.pais.value}
                onChange={(e, value) => updateForm(e, "pais", value)}
                noOptionsText="Sin resultados"
                error={formData.pais.error}
                helperText={formData.pais.error ? "Campo no válido" : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Selecciona un país"
                    variant="outlined"
                    size="small"
                    error={formData.pais.error}
                    helperText={formData.pais.error ? "Campo no válido" : null}
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />

              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitForm}
                  startIcon={<CheckRounded />}
                  disabled={
                    loading ||
                    !validatePassword(formData.passwd.value) ||
                    Object.values(formData).some(
                      (formInput) => !formInput.value
                    )
                  }
                  style={{ background: "#81c868" }}
                >
                  Crear
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarClienteAdministrativo;
