import React from "react";
// Bootstrap
import { Container, Row, Col } from "react-bootstrap";
// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import MenuBookIcon from "@material-ui/icons/MenuBook";
// MDB
import { MDBDataTableV5 } from "mdbreact";
// Alerts and toasts
import swal from "sweetalert";
// Shared
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import { columnasFilasHome } from "../../../../shared/data/columnas/classics/filasHome";

const ListaFilasHome = ({ filasHome }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="d-flex align-items-center">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Tabla de Filas Home
            </div>
          </div>
          <div className="admin-muted-text-small mt-1 mb-4">
            Tabla de Filas Home.
          </div>

          <ButtonGroup size="small" variant="contained" className="my-3">
            <CustomGreenButton
              onClick={() => {
                navigator.clipboard
                  .writeText(JSON.stringify([...filasHome]))
                  .then(async () => {
                    await swal({
                      title: "Se ha copiado la tabla!",
                      icon: "success",
                    });
                  });
              }}
            >
              Copy
            </CustomGreenButton>
            <CustomGreenButton>
              <ExcelWorkBook
                columns={columnasFilasHome}
                rows={filasHome}
                filename={"Lista-Filas-Home"}
              />
            </CustomGreenButton>
          </ButtonGroup>

          {filasHome.length <= 0 ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <MDBDataTableV5
              small
              responsive
              striped
              bordered
              searchTop
              searchBottom={false}
              barReverse
              pagingTop
              data={{
                columns: columnasFilasHome,
                rows: filasHome,
              }}
              noBottomColumns
              order={["name", "asc"]}
              entriesOptions={[5, 10, 20, 50]}
              entriesLabel="Numero de filas"
              searchLabel="Buscar"
              paginationLabel={["Anterior", "Siguiente"]}
              infoLabel={["Mostrando filas", "a", "de", "filas"]}
              fullPagination
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ListaFilasHome;
