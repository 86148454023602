import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PersonIcon from "@material-ui/icons/Person";

const EliminarGeneral = ({ type, urlApiGet, urlApiDel }) => {
  //Estados para gestionar todo lo relacionado con los datos
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //Definimos un estado para guardar la informacion del autocomplete
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);

  //Funcion para traer los datos de la api
  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlApiGet, type]);

  const fetchData = async () => {
    const fd = new FormData();
     
    const res = await fetch(urlApiGet, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      let temp1 = [];
      for (var i = 0; i < resJSON.data.length; i++) {
        temp1.push({ value: resJSON.data[i], data: resJSON.data[i] });
      }
      setData(temp1);
      console.log(temp1);
    }
  };

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = async (e, newValue) => {
    if (newValue) {
      setAutoCompleteValue(newValue);
      //Si se selecciono un valor creamos un modal para confirmar si la persona desea borrar un elemento
      const res = await swal({
        title: "Está seguro?",
        text: "Esta acción no se puede revertir!",
        icon: "warning",
        buttons: ["Cancelar", "Borrar"],
      });
      //Si se quiere borrar un elemento se llama a la api y se actualiza localmente el estado
      if (res) {
        try {
          //Llamamos la api
          const fd = new FormData();
           
           

          if (type === "paquete de libros")
            fd.append("nombrePaquete", newValue.value);
          else if (type === "libro")
            fd.append("idLibro", newValue.data.idLibro);
          else if (type === "actividad")
            fd.append("idActividad", newValue.data.idActividad);
          else if (type === "colección")
            fd.append("collectionName", newValue.data.collectionName);
          else if (type === "cliente SAML")
            fd.append("idInstitutoSAML", newValue.data.idInstitutoSAML);
          else if (type === "blog")
            fd.append("idBlog", newValue.data.idBlogEntries);
          else if (type === "usuario administrativo")
            fd.append("getUsername", newValue.data.username);
          let res;
          let resJSON;
          try {
            res = await fetch(urlApiDel, {
              method: "POST", credentials: 'include',
              credentials: "include",
              body: fd,
            });
            resJSON = await res.json();
          } catch (err) {
            console.error(err);
          }
          if (resJSON?.status === 0) {
            await swal({
              title: "Error",
              text: resJSON.info,
              icon: "error",
            });
          } else {
            swal({
              title: `Se ha eliminado correctamente el ${type}`,
              icon: "success",
            });
          }
          //Actualizamos localmente
          let temp = [];
          if (type === "paquete de libros")
            temp = [...data].filter((item) => item.data !== newValue.data);
          else if (type === "libro")
            temp = [...data].filter(
              (item) => item.data.idLibro !== newValue.data.idLibro
            );
          else if (type === "actividad")
            temp = [...data].filter(
              (item) => item.data.idActividad !== newValue.data.idActividad
            );
          else if (type === "colección")
            temp = [...data].filter(
              (item) =>
                item.data.collectionName !== newValue.data.collectionName
            );
          else if (type === "cliente SAML")
            temp = [...data].filter(
              (item) =>
                item.data.idInstitutoSAML !== newValue.data.idInstitutoSAML
            );
          else if (type === "blog")
            temp = [...data].filter(
              (item) => item.data.idBlogEntries !== newValue.data.idBlogEntries
            );
          else if (type === "usuario administrativo")
            temp = [...data].filter(
              (item) => item.data.username !== newValue.data.username
            );
          setAutoCompleteValue(null);
          setData(temp);
          return;
        } catch (e) {
          swal({
            title: "Error",
            icon: "error",
          });
        }
      }
      setAutoCompleteValue(null);
    }
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <DeleteOutlineIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Eliminar un
          {type === "actividad" || type === "colección"
            ? `a ${type}`
            : ` ${type}`}
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "22px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Borrar un
                {type === "actividad" || type === "colección"
                  ? `a ${type} `
                  : ` ${type} `}
                existente
              </div>
            </div>
            {loading ? (
              <div className="mt-2">
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </div>
            ) : (
              <>
                <label className="admin-label-text mt-3 d-block">Buscar:</label>
                <Autocomplete
                  options={data}
                  getOptionLabel={(option) => {
                    if (
                      type === "libro" ||
                      type === "actividad" ||
                      type === "blog"
                    )
                      return option.value.title;
                    else if (type === "colección")
                      return option.value.collectionName;
                    else if (type === "cliente SAML") {
                      return option.value.idInstitutoSAML;
                    } else if (type === "usuario administrativo") {
                      return option.value.username;
                    }
                    return option.value;
                  }}
                  value={autoCompleteValue}
                  onChange={autoCompleteUpdate}
                  noOptionsText="Sin resultados"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  )}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EliminarGeneral;
