import React, { useState, useEffect } from "react";
// Components
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
// icons
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { ButtonGroup } from "react-bootstrap";
// data
import { GENERAL_URL_API } from "../../../../shared/urls";
import swal from "sweetalert";

const EditarCursoForm = ({ curso, clear }) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormData(curso);
  }, [curso]);

  const update = (e, n, v) => {
    const name = n || e.target.name;
    const value = v || e.target.value;
    setFormData({ ...formData, [name]: value });
    console.log("aaaaa", value);
    if (value.length) setErrors({ ...errors, [name]: false });
    else setErrors({ ...errors, [name]: true });
  };

  const validate = () => {
    if (!formData.nombre) {
      setErrors({ ...errors, nombre: true });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (validate()) {
      const { nombre, instituto } = formData;
      const fd = new FormData();
       
       
      fd.append("nombreCurso", nombre);
      fd.append("idCurso", curso.idCursoPlanLector);
      fd.append("nombreSede", instituto);

      const url = `${GENERAL_URL_API}/Cursos/${
        curso ? "editar" : "agregar"
      }Curso.php`;
      const response = await fetch(url, {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      });
      const data = await response.json();
      if (data.status)
        await swal({
          title: "Curso editado correctamente.",
          text: data.info,
          icon: "success",
        });
      else
        swal({
          title: "Error editando curso.",
          text: data.info,
          icon: "error",
        });
      await clear();
    }

    setLoading(false);
  };

  return (
    <form>
      <label className="admin-label-text mt-3 d-block">Nombre del curso*</label>
      <TextField
        name="nombre"
        value={formData.nombre}
        onChange={update}
        variant="outlined"
        size="small"
        type="text"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        error={errors.nombre}
        helperText={errors.nombre ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Sede</label>
      <TextField
        value={formData.instituto}
        variant="outlined"
        size="small"
        disabled="true"
        type="text"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />
      <ButtonGroup size="small" variant="contained" className="mt-3">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckRounded />}
          style={{ background: "#81c868" }}
          onClick={handleSubmit}
          disabled={!formData.nombre || loading}
        >
          Editar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="ml-2"
          startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
          style={{ background: "#f05050" }}
          onClick={clear}
          disabled={loading}
        >
          Cancelar
        </Button>
      </ButtonGroup>
      {/* </div> */}
    </form>
  );
};

const initialState = {
  nombre: "",
  instituto: "",
};

const initialErrors = {
  nombre: false,
};

export default EditarCursoForm;
