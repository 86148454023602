import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import { Tooltip } from "@material-ui/core";
// import CustomGreenButton from "../../../components/shared/custom-green-button";
// import ImageUploadCard from "../../../components/shared/image-upload-card/image-upload-card";
// import { DropzoneArea } from "material-ui-dropzone";
import { GENERAL_URL_API } from "../../../../shared/urls";
import swal from "sweetalert";
import { Button } from "@material-ui/core";
// import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import { DropzoneArea } from "material-ui-dropzone";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { Link } from "react-router-dom";
import CloseRounded from "@material-ui/icons/CloseRounded";

const CargaIndividual = () => {
  const classes = useStyles();
  const [profesores, setProfesores] = useState([]);
  const [profesor, setProfesor] = useState(profesores[0]);

  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState(cursos[0]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProfesores();
  }, []);

  const fetchProfesores = async () => {
    const fd = new FormData();
     

    const res = await fetch(`${GENERAL_URL_API}/profesores/verProfesores.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    //   cambiar por data.data cuando la api esté implementada
    setProfesores(data.data);
    // cambiar por data.status!==1 cuando la api ...
    if (!data.status)
      await swal({
        title: "Error obteniendo institutos.",
        text: data.info,
        icon: "error",
      });
  };

  const fetchCursos = async (sede) => {
    setCursos([]);
    const fd = new FormData();
     
    fd.append("instituto", sede);

    const res = await fetch(`${GENERAL_URL_API}/Cursos/verCursos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setCursos(data.data);
  };

  const assignCurso = async () => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("usernameProfesor", profesor.username);
    fd.append("idCursoPlanLector", curso.idCursoPlanLector);

    const res = await fetch(
      `${GENERAL_URL_API}/Cursos/asociarProfesorCurso.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      await swal({
        title: "Éxito",
        text: resJSON.info,
        icon: "success",
      });
    }
    setLoading(false);
  };

  return (
    <Row>
      <Col xs={12} className="content-white-box px-4 py-4 mt-3">
        <div className="d-flex align-items-center">
          <MenuBookIcon style={{ fontSize: "20px" }} />
          <span className="admin-main-title-dark-gray ml-2 mt-1">
            Asignar cursos a un profesor
          </span>
        </div>
        {!profesores.length ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <form>
            <label className="admin-label-text mt-3 d-block">Profesor:</label>
            <Autocomplete
              options={profesores}
              getOptionLabel={(option) =>
                `${option.institutoPlanLector} - ${option.username}`
              }
              value={profesor}
              onChange={(e, value) => {
                setProfesor(value);
                fetchCursos(value.institutoPlanLector);
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <label className="admin-label-text mt-3 d-block">Curso:</label>
            <Autocomplete
              options={cursos}
              getOptionLabel={(option) =>
                `${option.instituto} - ${option.curso}`
              }
              value={curso}
              onChange={(e, value) => setCurso(value)}
              noOptionsText="Sin resultados"
              disabled={!profesor}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />

            <div className="d-flex">
              <Tooltip title="Asignar curso">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!profesor || !curso || loading}
                  startIcon={<CheckRoundedIcon />}
                  classes={{ root: classes.agregar }}
                  onClick={assignCurso}
                >
                  Agregar
                </Button>
              </Tooltip>

              {/* no sé si esto va */}
              {/* <Tooltip title="Eliminar curso">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!profesor || !curso || loading}
                  startIcon={<CloseRounded />}
                  classes={{ root: classes.eliminar }}
                  // onClick={addAsignar}
                >
                  Agregar
                </Button>
              </Tooltip> */}
            </div>
          </form>
        )}
      </Col>
    </Row>
  );
};

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
    marginTop: "1em",
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
    marginTop: "1em",
    marginLeft: "1em",
  },
});

export default CargaIndividual;
