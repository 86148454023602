import axios from "axios";

import { GENERAL_URL_API } from "../../../urls";

/**
 * @param {string} id - id del recurso a eliminar
 * @returns {Promise<{info: string, status: boolean}>}
 */
export async function eliminarRecurso(id) {
  const fd = new FormData();
  fd.append("idRecursos", id);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/recursos/borrarRecurso`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return data;
}
