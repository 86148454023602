import React, { useState } from 'react'

import { Drawer } from 'antd'
import MenuBookIcon from '@material-ui/icons/MenuBook'

import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined'

import useWindowSize from '../../../shared/hooks/use-window-size'

import NavigationElements from '../navigation-elements/navigation-elements'
import SettingsDropdown from './settings-dropdown'
import FullscreenIcon from './full-screen-button'

import './navbar.css'
import logo from './makeLow.png'

//Este componente corresponde a la barra de navegacion de la aplicacion
const Navbar = () => {

    //Definimos un estado para saber cuande esta abierto el drawer
    const [visible, setVisible] = useState(false)

    //Necesitamos saber el tamaño de la pantalla ya que el drawer solo aparece en tablets y celulares
    const size = useWindowSize()

    //Funciones para gestionar el cambio del drawer
    const showDrawer = () => {
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
    }

    /*
        Retornamos la barra y el drawer
        el lg de bootstrap inica en 992px
        Esta barra cambia cuando la pantalla es menor a 992px ya que 
        en ese tamaño se renderiza el menu hamburguesa
    */
    return (
        <>
            {
                size.width <= 992 &&
                /*El sticky-top tiene z-index de 1020*/
                <Drawer
                    placement="left"
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                    bodyStyle={{ padding: 0 }}
                    zIndex={1021}
                >
                    <div className='side-bar-header pt-1 pl-2 mb-3'>
                        <MenuBookIcon style={{ color: '#5fbeaa', fontSize: 40 }} />
                    </div>
                    <NavigationElements />
                    <div className='navbar-replacement'></div>
                </Drawer>
            }
            <nav className='navigation-navbar d-flex justify-content-between align-items-center'>
                {
                    size.width <= 992 &&
                    <MenuOutlinedIcon
                        style={{ color: '#ffffff', fontSize: 40, marginLeft: '12px' }}
                        onClick={showDrawer}
                    />
                }
                <img src={logo} alt='logo' className='ml-md-3' />
                <div>
                    <FullscreenIcon />
                    <SettingsDropdown />
                </div>
            </nav>
        </>
    )
}

export default Navbar
