import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import IOSSwitch from "../../../components/shared/ios-switch";
import { NumberFormatCustom } from "../../../components/shared/number-format-custom";
import { calcularPrecios } from "./calculoPrecios";
import swal from "sweetalert";

import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { GENERAL_URL_API } from "../../../shared/urls";
import { crearCorte } from "./crear-nuevo-corte-functions";

const CrearNuevoCorte = () => {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [packets, setPackets] = useState([]);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({ ...fData });
  const [autocompleteData, setAutocompleteData] = useState({
    ...autocompletes,
  });

  useEffect(() => {
    fetchUsers();
    fetchPackets();
  }, []);

  const fetchUsers = async () => {
    const fd = new FormData();
     
    fd.append("show", "all");
    fd.append("tipoUsuario", "Todos");

    const res = await fetch(`${GENERAL_URL_API}/verDatos/verClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo clientes.",
        text: data.info,
        icon: "error",
      });
    setUsers(data.data);
  };

  const fetchPackets = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/paquetes/verPaquetes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setPackets(data.data);
    console.log(data);
  };

  const fetchBooksFromPacket = async (packetName) => {
    const fd = new FormData();
     
    fd.append("nombrePaquete", packetName);

    const res = await fetch(`${GENERAL_URL_API}/paquetes/librosEnPaquete.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo libros.",
        text: data.info,
        icon: "error",
      });
    return data.data;
  };

  const clearForm = () => {
    setAutocompleteData(autocompletes);
    setFormData(fData);
  };

  const formUpdate = (e, nameIn, valueIn) => {
    const name = nameIn || e?.target.name;
    const value = valueIn || e?.target.value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
      },
    });
  };

  const switchUpdate = (name) =>
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: !formData[name].value,
      },
    });

  const handleUserChange = (user) => {
    if (user) {
      const { username, concurrents, fechaFinSub, fechaIniSub } = user;
      const temp = {
        clientName: { value: username },
        numConcurrences: { value: concurrents },
        subscriptionDateInit: { value: fechaIniSub },
        subscriptionDateEnd: { value: fechaFinSub },
      };
      setFormData({ ...formData, ...temp });
    }
  };

  const handlePacketChange = async (newPacket) => {
    if (newPacket) {
      const books = await fetchBooksFromPacket(newPacket);
      const temp = {
        totalBooks: { value: 0 },
        totalInterPremiumBooks: { value: 0 },
        totalInterBasicBooks: { value: 0 },
        totalEpubPremiumBooks: { value: 0 },
        totalEpubBasicBooks: { value: 0 },
      };
      books.forEach((b) => {
        if (b.bookType === "epubP") temp.totalEpubPremiumBooks.value++;
        if (b.bookType === "epubB") temp.totalEpubBasicBooks.value++;
        if (b.bookType === "interP") temp.totalInterPremiumBooks.value++;
        if (b.bookType === "interB") temp.totalInterBasicBooks.value++;
        temp.totalBooks.value++;
      });
      setFormData({ ...formData, ...temp });
    }
  };

  const autoCompleteUpdate = (value, option) => {
    if (option === "user") handleUserChange(value);
    if (option === "packet") handlePacketChange(value);

    setAutocompleteData({
      ...autocompleteData,
      [option]: value,
    });
  };

  const SubmitForm = async () => {
    setLoading(true);
    const res = await crearCorte({ ...formData, ...autocompleteData });
    if (!res.status)
      await swal({
        title: "Error creando entrada.",
        text: res.info,
        icon: "error",
      });
    else
      await swal({
        title: "Entrada creada exitosamente.",
        text: res.info,
        icon: "success",
      });
    setLoading(false);
  };

  const calcPrices = () => {
    const prices = calcularPrecios({
      interP: formData.totalInterPremiumBooks.value,
      interB: formData.totalInterBasicBooks.value,
      epubP: formData.totalEpubPremiumBooks.value,
      epubB: formData.totalEpubBasicBooks.value,
      precioTotalVenta: formData.sellPrice.value,
      reteFuente: formData.reteFuente.value,
      reteICA: formData.reteIca.value,
      descuentosBancarios: formData.bankDiscounts.value,
      costoTransmisionDatos: formData.DTC.value,
      costoConfiguracion: formData.configurationCost.value,
      hardwareCajitas: formData.cajitasHardware.value,
      talleres: formData.atelier.value,
    });
    const temp = {
      subtotal: { value: prices.subtotal },
      netWorth: { value: prices.valorNeto },
      priceInterPremium: { value: prices.precioInterPremium },
      priceInterBasic: { value: prices.precioInterBasico },
      priceEpubPremium: { value: prices.precioEpubPremium },
      priceEpubBasic: { value: prices.precioEpubBasico },
    };
    setFormData({ ...formData, ...temp });
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Agregar un nuevo corte regalías</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon style={{ fontSize: "22px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Corte regalías
              </div>
            </div>
            <div className="admin-muted-text-small mt-3">
              Por favor ingresar la siguiente info (los campos con * son
              obligatorios):
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">Usuario*</label>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => option.username}
                value={autocompleteData.user}
                onChange={(e, value) => autoCompleteUpdate(value, "user")}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <label className="admin-label-text mt-3 d-block">
                Nombre del Paquete*
              </label>
              <Autocomplete
                options={packets}
                getOptionLabel={(option) => option}
                value={autocompleteData.packet}
                onChange={(e, value) => autoCompleteUpdate(value, "packet")}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <label className="admin-label-text mt-3 d-block">
                Nombre Cliente*
              </label>
              <TextField
                name="clientName"
                value={formData.clientName.value}
                onChange={formUpdate}
                variant="outlined"
                size="small"
                placeholder="Nombre Cliente"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Numero de Concurrencias*
              </label>
              <TextField
                name="numConcurrences"
                value={formData.numConcurrences.value}
                onChange={formUpdate}
                variant="outlined"
                size="small"
                placeholder="Numero de Concurrencias"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Fechas de subscripción *
              </label>
              <TextField
                name="subscriptionDateInit"
                value={formData.subscriptionDateInit.value}
                onChange={formUpdate}
                type="date"
                variant="outlined"
                size="small"
                placeholder="Precio libro Epub Básico"
                style={{
                  width: "180px",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <CustomGreenButton
                variant="contained"
                style={{ height: "40px" }}
                className="d-block d-md-inline"
                disableTouchRipple
                disableRipple
                disableElevation
                disableFocusRipple
              >
                Hasta
              </CustomGreenButton>
              <TextField
                name="subscriptionDateEnd"
                value={formData.subscriptionDateEnd.value}
                onChange={formUpdate}
                type="date"
                variant="outlined"
                size="small"
                placeholder="Precio libro Epub Básico"
                style={{
                  width: "180px",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <br />
              <label className="admin-label-text mt-3 d-block">
                Numero Total de Libros*
              </label>
              <TextField
                name="totalBooks"
                value={formData.totalBooks.value}
                onChange={formUpdate}
                type="number"
                variant="outlined"
                size="small"
                placeholder="Numero Total de Libros"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Numero de libros Interactivo Premium*
              </label>
              <TextField
                name="totalInterPremiumBooks"
                value={formData.totalInterPremiumBooks.value}
                onChange={formUpdate}
                type="number"
                variant="outlined"
                size="small"
                placeholder="Numero de libros Interactivo Premium*"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Numero de libros Interactivo Básicos*
              </label>
              <TextField
                name="totalInterBasicBooks"
                value={formData.totalInterBasicBooks.value}
                onChange={formUpdate}
                type="number"
                variant="outlined"
                size="small"
                placeholder="Numero de libros Interactivo Básicos"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Numero de libros ePub Premium*
              </label>
              <TextField
                name="totalEpubPremiumBooks"
                value={formData.totalEpubPremiumBooks.value}
                onChange={formUpdate}
                type="number"
                variant="outlined"
                size="small"
                placeholder="Numero de libros ePub Premium"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Numero de libros ePub Básicos*
              </label>
              <TextField
                name="totalEpubBasicBooks"
                value={formData.totalEpubBasicBooks.value}
                onChange={formUpdate}
                type="number"
                variant="outlined"
                size="small"
                placeholder="Numero de libros ePub Básicos"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Tipo Suscripción*
              </label>
              <Autocomplete
                options={["Bdigital", "Plan Lector", "MM Plus", "Cajitas"]}
                getOptionLabel={(option) => option}
                value={autocompleteData.suscriptionType}
                onChange={(e, value) =>
                  autoCompleteUpdate(value, "suscriptionType")
                }
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <div className="mt-3">
                <label
                  className="admin-label-text"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Renovación*
                </label>
                <IOSSwitch
                  name="renewal"
                  checked={formData.renewal.value}
                  onChange={() => switchUpdate("renewal")}
                />
                <label
                  className="admin-label-text ml-md-2"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Recompra Dentro de Suscripción*
                </label>
                <IOSSwitch
                  name="repurchase"
                  checked={formData.repurchase.value}
                  onChange={() => switchUpdate("repurchase")}
                />
                <label
                  className="admin-label-text ml-md-2"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Nuevo Cliente*
                </label>
                <IOSSwitch
                  name="newClient"
                  checked={formData.newClient.value}
                  onChange={() => switchUpdate("newClient")}
                />
              </div>
              <label className="admin-label-text mt-3 d-block">Divisa*</label>
              <TextField
                name="currency"
                value={formData.currency.value}
                onChange={formUpdate}
                variant="outlined"
                size="small"
                placeholder="COP/USD"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Número Factura*
              </label>
              <TextField
                name="invoiceNumber"
                value={formData.invoiceNumber.value}
                onChange={formUpdate}
                variant="outlined"
                size="small"
                placeholder="Número Factura"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Fecha de la Factura *
              </label>
              <TextField
                name="invoiceDate"
                value={formData.invoiceDate.value}
                onChange={formUpdate}
                type="date"
                variant="outlined"
                size="small"
                style={{
                  width: "180px",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Precio Venta*
              </label>
              <TextField
                name="sellPrice"
                value={formData.sellPrice.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Retefuente y/o ReteICA*
              </label>
              <TextField
                name="reteFuente"
                value={formData.reteFuente.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Estampillas*</label>
              <TextField
                name="reteIca"
                value={formData.reteIca.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Licenciamiento software*
              </label>
              <TextField
                name="bankDiscounts"
                value={formData.bankDiscounts.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Proporcion De Ocupacion Servidor*
              </label>
              <TextField
                name="SOR"
                value={formData.SOR.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Costo Transmsión de Datos*
              </label>
              <TextField
                name="DTC"
                value={formData.DTC.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Costo Configuración*
              </label>
              <TextField
                name="configurationCost"
                value={formData.configurationCost.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Hardware Cajitas*
              </label>
              <TextField
                name="cajitasHardware"
                value={formData.cajitasHardware.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">Talleres*</label>
              <TextField
                name="atelier"
                value={formData.atelier.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className="mt-3"
                startIcon={<CheckRounded />}
                disabled={loading}
                onClick={() => {
                  calcPrices();
                }}
                classes={{ root: classes.agregar }}
              >
                Calcular Precios
              </Button>
              <label className="admin-label-text mt-3 d-block">
                Valor Neto*
              </label>
              <TextField
                name="netWorth"
                value={formData.netWorth.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Precio libro interactivo Premium *
              </label>
              <TextField
                name="priceInterPremium"
                value={formData.priceInterPremium.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Precio libro interactivo Básico *
              </label>
              <TextField
                name="priceInterBasic"
                value={formData.priceInterBasic.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Precio libro Epub Premium *
              </label>
              <TextField
                name="priceEpubPremium"
                value={formData.priceEpubPremium.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <label className="admin-label-text mt-3 d-block">
                Precio libro Epub Básico *
              </label>
              <TextField
                name="priceEpubBasic"
                value={formData.priceEpubBasic.value}
                onChange={formUpdate}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                variant="outlined"
                size="small"
                placeholder="0.0"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
              />
              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitForm}
                  startIcon={<CheckRounded />}
                  disabled={
                    loading ||
                    !autocompleteData.user ||
                    !autocompleteData.packet ||
                    !autocompleteData.suscriptionType ||
                    !formData.clientName.value ||
                    !formData.subscriptionDateEnd.value ||
                    !formData.subscriptionDateInit.value ||
                    !formData.currency.value ||
                    !formData.invoiceDate.value
                  }
                  style={{ background: "#81c868" }}
                >
                  Finalizar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2"
                  onClick={clearForm}
                  startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                  disabled={loading}
                  style={{ background: "#f05050" }}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CrearNuevoCorte;

const autocompletes = {
  user: null,
  packet: null,
  suscriptionType: null,
};

const fData = {
  clientName: { value: "" },
  numConcurrences: { value: 0 },
  subscriptionDateInit: { value: "" },
  subscriptionDateEnd: { value: "" },
  totalBooks: { value: 0 },
  totalInterPremiumBooks: { value: 0 },
  totalInterBasicBooks: { value: 0 },
  totalEpubPremiumBooks: { value: 0 },
  totalEpubBasicBooks: { value: 0 },
  renewal: { value: false },
  repurchase: { value: false },
  newClient: { value: false },
  currency: { value: "" },
  invoiceNumber: { value: "" },
  invoiceDate: { value: "" },
  sellPrice: { value: 0 },
  reteFuente: { value: 0 },
  reteIca: { value: 0 },
  bankDiscounts: { value: 0 },
  SOR: { value: 0 }, //server occupancy ratio
  DTC: { value: 0 }, //Data Transmission Cost
  configurationCost: { value: 0 },
  cajitasHardware: { value: 0 },
  atelier: { value: 0 },
  netWorth: { value: 0 },
  priceInterPremium: { value: 0 },
  priceInterBasic: { value: 0 },
  priceEpubPremium: { value: 0 },
  priceEpubBasic: { value: 0 },
  subtotal: { value: 0 },
};

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
