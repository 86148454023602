//ignore errors and warnings on console
/* eslint-disable no-console */
/* jshint ignore:start */

//disable react errors on console
/* eslint-disable react/prop-types */
/* jshint ignore:end */
/* eslint-disable */

import React, { useState, useEffect } from "react";
// material ui components
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IOSSwitch from "../../../components/shared/ios-switch";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
// autyocompletye components options
import {
  subjectOptions,
  formatOptions,
  typeOptions,
  userLevelOptions,
  ageOptions,
  courseOptions,
  compatibilityOptions,
  genreOptions,
  subjectEngOptions,
  genreOptionsEng,
  activeOptions,
  dbaOptions,
  authorSecundaryFunctionOptions,
  idiomaOptions,
  engLevelOptions,
  bookTypeOptions,
} from "../../../shared/data/nuevo-libro-autocomplete-options";
import { CloseRounded } from "@material-ui/icons";
import CheckRounded from "@material-ui/icons/CheckRounded";
import swal from "sweetalert";
import { addBook, getDataFromBook } from "./agregar-nuevo-libro-functions";
import { fData } from "./agregar-nuevo-libro-data";
import { useHistory } from "react-router-dom";
import { GENERAL_URL_API } from "../../../shared/urls";
import { paisesAutocomplete } from "../../../shared/data/paises";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const AgregarNuevoLibroForm = ({ book, edit, cancelFn, refreshBooks }) => {
  const [tags, setTags] = useState({});
  const [formData, setFormData] = useState(fData);
  const [loading, setLoading] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const history = useHistory();
  useEffect(() => {
    fetchTags();
    // si hay un libro pone los valores correspondientes
    if (book && edit) {
      const tempData = getDataFromBook(book);
      setFormData(tempData);
    } else clearForm();
  }, [book, edit]);

  const fetchTags = async () => {
    const fd = new FormData();

    const res = await fetch(`${GENERAL_URL_API}/libros/tags.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo tags.",
        text: data.info,
        icon: "error",
      });
    setTags(data.data);
  };

  // AutoComplete debe pasar inName: name y defaultValue: value
  const handleChange = (e, inName, defaultValue, fn) => {
    if (fn) return fn();
    const name = e?.target?.name || inName;
    const value = e?.target?.value || defaultValue;
    setFormData({
      ...formData,
      [name]: {
        value: value,
        error: false,
      },
    });
  };

  const clearForm = () => {
    const dt = JSON.parse(JSON.stringify(fData));
    Object.keys(dt).forEach((key) => (dt[key].error = false));
    setFormData(dt);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { ...formData };
    let isValid = true;

    for (const formElement in data) {
      if (data[formElement].required && !data[formElement].value) {
        data[formElement].error = true;
        isValid = false;
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(data);
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      if (edit) handleEdit();
      else handleAdd();
    }
    setLoading(false);
  };

  const handleEdit = async () => {
    const res = await addBook(formData, true);
    const data = await res.json();
    if (data.status === 1) {
      clearForm();
      cancelFn();
      refreshBooks();
      await swal({
        title: "Libro editado.",
        text: data.info,
        icon: "success",
      });
    } else {
      await swal({
        title: "Sorry.",
        text: data.info,
        icon: "error",
      });
    }
  };

  const handleAdd = async () => {
    const res = await addBook(formData);
    const data = await res.json();
    if (data.status === 1) {
      const swRes = await swal({
        title: "Libro agregado.",
        text: data.info,
        icon: "success",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Seguir aquí", "Ir a agregar archivos"],
      });
      if (swRes)
        history.push(
          `/registrar-y-editar/editar-libro?idLibro=${formData.manuvoId.value}`
        );
    } else {
      await swal({
        title: "Sorry.",
        text: data.info,
        icon: "error",
      });
    }
  };

  const handleCancel = () => clearForm();

  return (
    <form
      onKeyDown={(e) => {
        //dont submit if enter is pressed without using keycode
        //this is bcs freesolos change with enter
        if (e.key === "Enter") e.preventDefault();
      }}
    >
      <label className="admin-label-text mt-3 d-block">
        Identificador Makina*
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        value={formData.manuvoId.value}
        name="manuvoId"
        placeholder="Identificador Makina ej. MM0001"
        disabled={edit}
        onChange={(e) =>
          handleChange(0, 0, 0, () => {
            const value = e.target.value.toUpperCase();
            if (value === "M" || /^MM\d{0,4}$/.test(value) || value === "")
              handleChange(e, e.target.name, value);
          })
        }
        error={formData.manuvoId.error}
        helperText={formData.manuvoId.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Título*</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="title"
        value={formData.title.value}
        placeholder="Title"
        onChange={handleChange}
        error={formData.title.error}
        helperText={formData.title.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Código BISAC*</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="bisac"
        value={formData.bisac.value}
        placeholder="Subject"
        onChange={handleChange}
        error={formData.bisac.error}
        helperText={formData.bisac.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Materia*</label>
      <Autocomplete
        options={subjectOptions}
        getOptionLabel={(option) => option}
        value={formData.subject.value}
        onChange={(e, value) => handleChange(e, "subject", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.subject.error}
            helperText={formData.subject.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">Subject*</label>
      <Autocomplete
        options={subjectEngOptions}
        getOptionLabel={(option) => option}
        value={formData.subjectEng.value}
        onChange={(e, value) => handleChange(e, "subjectEng", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.subjectEng.error}
            helperText={formData.subjectEng.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">Descripción*</label>
      <TextField
        multiline
        rows={4}
        maxrows={4}
        aria-label="description"
        style={{
          width: "100%",
          outline: "none !important",
          border: "1px solid rgba(0, 0, 0, 0.27)",
          borderRadius: "5px",
        }}
        name="description"
        value={formData.description.value}
        placeholder="Descripción"
        onChange={(e) => handleChange(e, e.target.name, e.target.innerText)}
        error={formData.description.error}
        helperText={formData.description.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Description</label>
      <TextField
        multiline
        rows={4}
        maxrows={4}
        aria-label="descriptionEng"
        style={{
          width: "100%",
          outline: "none !important",
          border: "1px solid rgba(0, 0, 0, 0.27)",
          borderRadius: "5px",
        }}
        name="descriptionEng"
        value={formData.descriptionEng.value}
        placeholder="Description"
        onChange={(e) => handleChange(e, e.target.name, e.target.innerText)}
      />

      <label className="admin-label-text mt-3 d-block">Source</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="source"
        value={formData.source.value}
        placeholder="Source"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">
        Date (diferente al campo de fecha en el que fue anadido a la plataforma,
        que se registra automáticamente)
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="date"
        value={formData.date.value}
        placeholder="Date"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">Relation</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="relation"
        value={formData.relation.value}
        placeholder="Relation"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">Número DEWEY</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="coverage"
        value={formData.coverage.value}
        placeholder="DEWEY"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">Autor*</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="author"
        value={formData.author.value}
        placeholder="Registrar en el orden: Nombre Apellido"
        onChange={handleChange}
        error={formData.author.error}
        helperText={formData.author.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Author Alt</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="authorAlt"
        value={formData.authorAlt.value}
        placeholder="Registrar en el orden: Apellido, Nombre"
        onChange={handleChange}
        error={formData.authorAlt.error}
        helperText={formData.authorAlt.error ? "Campo no válido" : null}
      />

      <label className="admin-label-text mt-3 d-block">Autor secundario</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="contributor"
        value={formData.contributor.value}
        placeholder="Autor secundario"
        error={formData.contributor.error}
        helperText={formData.contributor.error ? "Campo no válido" : null}
        onChange={(e) => {
          // if (e.target.value)
          //   setFormData({
          //     ...formData,
          //     authorSecundaryFunction: {
          //       value: formData.authorSecundaryFunction.value,
          //       required: true,
          //       error: false,
          //     },
          //   });
          handleChange(e);
        }}
      />

      <label className="admin-label-text mt-3 d-block">
        Función del autor secundario
      </label>
      <Autocomplete
        freeSolo
        onBlurCapture={(e, value) =>
          handleChange(e, "authorSecundaryFunction", value)
        }
        options={authorSecundaryFunctionOptions}
        getOptionLabel={(option) => option}
        value={formData.authorSecundaryFunction.value}
        onChange={(e, value) =>
          handleChange(e, "authorSecundaryFunction", value)
        }
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Función del autor secundario (ilustrador, compilador, editor, traductor, selección)"
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Publication Place</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="publicationPlace"
        value={formData.publicationPlace.value}
        placeholder="Lugar de publicación del libro (ciudad)"
        onChange={handleChange}
        error={formData.publicationPlace.error}
        helperText={formData.publicationPlace.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Publisher*</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="publisher"
        value={formData.publisher.value}
        placeholder="Publisher"
        onChange={handleChange}
        error={formData.publisher.error}
        helperText={formData.publisher.error ? "Campo no válido" : null}
      />

      <label className="admin-label-text mt-3 d-block">Rights</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="rights"
        value={formData.rights.value}
        placeholder="Rights"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">ISBN*</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="isbn"
        value={formData.isbn.value}
        placeholder="ISBN (999-999-999-999-9)"
        onChange={handleChange}
        error={formData.isbn.error}
        helperText={formData.isbn.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">
        Formato (afecta el visor)*
      </label>
      <Autocomplete
        options={formatOptions}
        getOptionLabel={(option) => option.value}
        value={formData.format.value}
        onChange={(e, value) => handleChange(e, "format", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.format.error}
            helperText={formData.format.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">
        Tipo de libro (afecta las regalías)*
      </label>
      <Autocomplete
        options={typeOptions}
        getOptionLabel={(option) => option}
        value={formData.type.value}
        onChange={(e, value) => handleChange(e, "type", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.type.error}
            helperText={formData.type.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">Regalias*</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="number"
        name="royalties"
        value={formData.royalties.value}
        placeholder="Royalties (0-100)%"
        onChange={(e) =>
          handleChange(0, 0, 0, () => {
            const value = e.target.value;
            //mantiene el valor entre 0 y 100
            //No permite números como: 00, 01, ...
            if (
              (value <= 100 &&
                value >= 0 &&
                !value.toString().match(/^0[0-9]/)) ||
              value === ""
            ) {
              return handleChange(e, e.target.name, value);
            }
          })
        }
        error={formData.royalties.error}
        helperText={formData.royalties.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Idioma*</label>
      <Autocomplete
        freeSolo
        options={idiomaOptions}
        getOptionLabel={(option) => option}
        value={formData.lang.value}
        onChange={(e, value) => handleChange(e, "lang", value)}
        onBlurCapture={(e, value) => handleChange(e, "lang", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.lang.error}
            helperText={formData.lang.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">Idioma 2</label>
      <Autocomplete
        freeSolo
        options={idiomaOptions}
        getOptionLabel={(option) => option}
        value={formData.lang2.value}
        onChange={(e, value) => handleChange(e, "lang2", value)}
        onBlurCapture={(e, value) => handleChange(e, "lang2", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.lang2.error}
            helperText={formData.lang2.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Idioma 3</label>
      <Autocomplete
        freeSolo
        options={idiomaOptions}
        getOptionLabel={(option) => option}
        value={formData.lang3.value}
        onChange={(e, value) => handleChange(e, "lang3", value)}
        onBlurCapture={(e, value) => handleChange(e, "lang3", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.lang3.error}
            helperText={formData.lang3.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">
        **Los tags seleccionados se vincularán automáticamente a un tag en
        inglés.
      </label>
      <label className="admin-label-text mt-3 d-block">Tag 1*</label>
      <Autocomplete
        options={tags}
        getOptionLabel={(option) => option.tag}
        value={formData.topic1.value}
        onChange={(e, value) => handleChange(e, "topic1", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.topic1.error}
            helperText={formData.topic1.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Tag 2</label>
      <Autocomplete
        options={tags}
        getOptionLabel={(option) => option.tag}
        value={formData.topic2.value}
        onChange={(e, value) => handleChange(e, "topic2", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.topic2.error}
            helperText={formData.topic2.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Tag 3</label>
      <Autocomplete
        options={tags}
        getOptionLabel={(option) => option.tag}
        value={formData.topic3.value}
        onChange={(e, value) => handleChange(e, "topic3", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.topic3.error}
            helperText={formData.topic3.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Tag 4</label>
      <Autocomplete
        options={tags}
        getOptionLabel={(option) => option.tag}
        value={formData.value.value}
        onChange={(e, value) => handleChange(e, "value", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.value.error}
            helperText={formData.value.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Tag 5</label>
      <Autocomplete
        options={tags}
        getOptionLabel={(option) => option.tag}
        value={formData.tag5.value}
        onChange={(e, value) => handleChange(e, "tag5", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.tag5.error}
            helperText={formData.tag5.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Nivel de inglés</label>
      <Autocomplete
        options={engLevelOptions}
        getOptionLabel={(option) => option}
        value={formData.englishLevel.value}
        onChange={(e, value) => handleChange(e, "englishLevel", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Código DBA</label>
      <Autocomplete
        options={dbaOptions}
        getOptionLabel={(option) => option.code}
        value={formData.dba.value}
        onChange={(e, value) => handleChange(e, "dba", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.dba.error}
            helperText={formData.dba.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">
        DBA Palabra Clave 1
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="dbaKeyword1"
        value={formData.dbaKeyword1.value}
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">
        DBA Palabra Clave 2
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="dbaKeyword2"
        value={formData.dbaKeyword2.value}
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">
        Nivel Usuario Lector*
      </label>
      <Autocomplete
        options={userLevelOptions}
        getOptionLabel={(option) => option.value}
        value={formData.userLevel.value}
        onChange={(e, value) => handleChange(e, "userLevel", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.userLevel.error}
            helperText={formData.userLevel.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">
        Tiempo de Lectura* (En minutos)
      </label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="number"
        name="readTime"
        value={formData.readTime.value}
        placeholder="Tiempo de Lectura"
        onChange={(e) =>
          handleChange(0, 0, 0, () => {
            const value = e.target.value;
            //No permite números como: 00, 01, ... ni números negativos
            if (
              (value >= 0 && !value.toString().match(/^0[0-9]/)) ||
              value === ""
            ) {
              return handleChange(e, e.target.name, value);
            }
          })
        }
        error={formData.readTime.error}
        helperText={formData.readTime.error ? "Campo no válido" : null}
      />
      <label className="admin-label-text mt-3 d-block">Edad*</label>
      <Autocomplete
        options={ageOptions}
        getOptionLabel={(option) => option.value}
        value={formData.age.value}
        onChange={(e, value) => handleChange(e, "age", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.age.error}
            helperText={formData.age.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">Curso*</label>
      <Autocomplete
        options={courseOptions}
        getOptionLabel={(option) => option.value}
        value={formData.course.value}
        onChange={(e, value) => handleChange(e, "course", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.course.error}
            helperText={formData.course.error ? "Campo no válido" : null}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Leveling</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="levelling"
        placeholder="Nivel de lectura según Fountas &amp; Pinnell (LLI) (SPA) "
        value={formData.levelling.value}
        onChange={handleChange}
      />

      <label className="admin-label-text mt-3 d-block">Leveling Eng</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="levellingENG"
        placeholder="Nivel de lectura según Fountas &amp; Pinnell (LLI) (ENG) "
        value={formData.levellingENG.value}
        onChange={handleChange}
      />

      <label className="admin-label-text mt-3 d-block">Valores</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="values"
        value={formData.values.value}
        placeholder="Valores"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">Values</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="valuesEng"
        value={formData.valuesEng.value}
        placeholder="Values"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">Compatibilidad*</label>
      <Autocomplete
        options={compatibilityOptions}
        getOptionLabel={(option) => option.value}
        value={formData.compatibility.value}
        onChange={(e, value) => handleChange(e, "compatibility", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.compatibility.error}
            helperText={formData.compatibility.error ? "Campo no válido" : null}
          />
        )}
      />
      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">Sonido*</label>
        <IOSSwitch
          checked={formData.sound.value}
          onClick={(e) => handleChange(e, "sound", !formData.sound.value)}
        />
      </div>
      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">Interactivo*</label>
        <IOSSwitch
          checked={formData.interactive.value}
          onClick={(e) =>
            handleChange(e, "interactive", !formData.interactive.value)
          }
        />
      </div>
      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">Gratis*</label>
        <IOSSwitch
          checked={formData.free.value}
          onClick={(e) => handleChange(e, "free", !formData.free.value)}
        />
      </div>
      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">Libro Premiado*</label>
        <IOSSwitch
          checked={formData.libroPremiado.value}
          onClick={(e) =>
            handleChange(e, "libroPremiado", !formData.libroPremiado.value)
          }
        />
      </div>

      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">
          Libro Accesible*
        </label>
        <IOSSwitch
          checked={formData.libroAccesible.value}
          onClick={(e) =>
            handleChange(e, "libroAccesible", !formData.libroAccesible.value)
          }
        />
      </div>
      <div className="d-flex">
        <label className="admin-label-text mt-3 d-block">Libro Bilingüe*</label>
        <IOSSwitch
          checked={formData.libroBilingue.value}
          onClick={(e) =>
            handleChange(e, "libroBilingue", !formData.libroBilingue.value)
          }
        />
      </div>
      <label className="admin-label-text mt-3 d-block">
        Tipo de libro (contenido)*
      </label>
      <Autocomplete
        options={bookTypeOptions}
        getOptionLabel={(option) => option}
        value={formData.tipoDeLibro.value}
        onChange={(e, value) => handleChange(e, "tipoDeLibro", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.tipoDeLibro.error}
            helperText={formData.tipoDeLibro.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">Género*</label>
      <Autocomplete
        options={genreOptions}
        getOptionLabel={(option) => option.value}
        value={formData.genre.value}
        onChange={(e, value) => handleChange(e, "genre", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.genre.error}
            helperText={formData.genre.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">Genre</label>
      <Autocomplete
        options={genreOptionsEng}
        getOptionLabel={(option) => option.value}
        value={formData.genreEng.value}
        onChange={(e, value) => handleChange(e, "genreEng", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.genreEng.error}
            helperText={formData.genreEng.error ? "Campo no válido" : null}
          />
        )}
      />
      <label className="admin-label-text mt-3 d-block">Recomendado 1</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="recommended1"
        value={formData.recommended1.value}
        placeholder="Id manuvo del libro (MM0001)"
        onChange={(e) =>
          handleChange(0, 0, 0, () => {
            const value = e.target.value.toUpperCase();
            if (value === "M" || /^MM\d{0,4}$/.test(value) || value === "")
              handleChange(e, e.target.name, value);
          })
        }
      />
      <label className="admin-label-text mt-3 d-block">Recomendado 2</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="recommended2"
        value={formData.recommended2.value}
        placeholder="Id manuvo del libro (MM0001)"
        onChange={(e) =>
          handleChange(0, 0, 0, () => {
            const value = e.target.value.toUpperCase();
            if (value === "M" || /^MM\d{0,4}$/.test(value) || value === "")
              handleChange(e, e.target.name, value);
          })
        }
      />

      <label className="admin-label-text mt-3 d-block">Link 1</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="link1"
        value={formData.link1.value}
        placeholder="Link 1"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">Texto Link 1</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="link1Text"
        value={formData.link1Text.value}
        placeholder="Texto Link 1"
        onChange={handleChange}
      />

      <label className="admin-label-text mt-3 d-block">Link 2</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="link2"
        value={formData.link2.value}
        placeholder="Link 2"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">Texto Link 2</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="link2Text"
        value={formData.link2Text.value}
        placeholder="Texto Link 2"
        onChange={handleChange}
      />

      <label className="admin-label-text mt-3 d-block">Link 3</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="link3"
        value={formData.link3.value}
        placeholder="Link 3"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">Texto Link 3</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="link3Text"
        value={formData.link3Text.value}
        placeholder="Texto Link 3"
        onChange={handleChange}
      />

      <label className="admin-label-text mt-3 d-block">Link Externo</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="linkExterno"
        value={formData.linkExterno.value}
        placeholder="Link Externo"
        onChange={handleChange}
      />

      <label className="admin-label-text mt-3 d-block">Link al Blog</label>
      <TextField
        variant="outlined"
        size="small"
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
        type="text"
        name="blogLink"
        value={formData.blogLink.value}
        placeholder="(/blog/****)"
        onChange={handleChange}
      />
      <label className="admin-label-text mt-3 d-block">
        Paises donde se puede leer (separado por comas)
      </label>
      <Autocomplete
        multiple
        options={paisesAutocomplete}
        getOptionLabel={(option) => option}
        value={formData.countries.value}
        defaultValue={formData.countries.value}
        disableCloseOnSelect
        noOptionsText="Sin resultados"
        onChange={(e, value) => handleChange(e, "countries", value)}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              color="primary"
            />
            {option}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            placeholder="Selecciona los países donde se puede leer"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">Activo*</label>
      <Autocomplete
        options={activeOptions}
        getOptionLabel={(option) => option.value}
        value={formData.active.value}
        onChange={(e, value) => handleChange(e, "active", value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            style={{
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
            error={formData.active.error}
            helperText={formData.active.error ? "Campo no válido" : null}
          />
        )}
      />
      <div className="mt-3 w-100 d-flex justify-content-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckRounded />}
          style={{ background: "#81c868" }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {edit ? "Editar" : "Agregar"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="ml-2"
          startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
          style={{ background: "#f05050" }}
          onClick={edit && cancelFn ? cancelFn : handleCancel}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default AgregarNuevoLibroForm;
