import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import TextField from "@material-ui/core/TextField";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import TvIcon from "@material-ui/icons/Tv";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import swal from "sweetalert";

import { GENERAL_URL_API } from "../../../shared/urls";
import { columnasAccesos } from "../../../shared/data/columnas";
import exportFromJSON from "export-from-json";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";

const ReporteAccesoClientes = () => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [fullRows, setFullRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fechaIni: { value: "", error: false },
    fechaFin: { value: "", error: false },
  });

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };

  const submitText = async () => {
    setLoading(true);
    const fd = new FormData();
     
    if (formData.fechaIni.value !== "" && formData.fechaFin.value !== "") {
      fd.append("fechaInicio", formData.fechaIni.value);
      fd.append("fechaFin", formData.fechaFin.value);
    }
    fd.append("maxEntradas", 5000);

    const res = await fetch(`${GENERAL_URL_API}/verDatos/accesoClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.info.includes("No hay resultados")) {
      await swal({
        title: "Error",
        text: "No se encontraron entradas validas",
        icon: "error",
      });
    }
    let tempColumns = columnasAccesos;
    tempColumns.map((_, index) => (tempColumns[index].isVisible = true));
    setRows(resJSON.data);
    setColumns(tempColumns);

    const fd2 = new FormData();
     
    if (formData.fechaIni.value !== "" && formData.fechaFin.value !== "") {
      fd2.append("fechaInicio", formData.fechaIni.value);
      fd2.append("fechaFin", formData.fechaFin.value);
    }
    fd2.append("maxEntradas", 50000000);

    const res2 = await fetch(`${GENERAL_URL_API}/verDatos/accesoClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd2,
    });
    const resJSON2 = await res2.json();
    if (resJSON2.info.includes("No hay resultados")) {
      await swal({
        title: "Error",
        text: "No se encontraron entradas validas",
        icon: "error",
      });
    }
    setFullRows(resJSON2.data);

    setLoading(false);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Reporte de accesos de clientes</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <AccountBalanceIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                TABLA DE ACCESOS DE CLIENTES
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Entre las fechas (Si no selecciona ninguna se mostraran los
                ultimos 5000 registros):
              </label>
              <div className="d-flex">
                <TextField
                  name="fechaIni"
                  value={formData.fechaIni.value}
                  onChange={updateForm}
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
                <CustomGreenButton
                  variant="contained"
                  style={{
                    height: "40px",
                    pointerEvents: "none",
                  }}
                  className="d-block d-md-inline"
                  disableTouchRipple
                  disableRipple
                  disableElevation
                  disableFocusRipple
                >
                  Hasta
                </CustomGreenButton>
                <TextField
                  name="fechaFin"
                  value={formData.fechaFin.value}
                  onChange={updateForm}
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              </div>
              <br />
              <CustomGreenButton
                disabled={loading}
                variant="contained"
                className="mt-4"
                onClick={submitText}
              >
                Consultar
              </CustomGreenButton>
            </form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Reporte de accesos por cliente
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Consultas de accesos por cliente
            </div>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />

            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                disabled={!rows.length || loading}
                onClick={() => {
                  const dat = [...rows];
                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>
              <CustomGreenButton
                disabled={!rows.length || loading}
                onClick={() => {
                  exportFromJSON({
                    data: fullRows,
                    fileName: "reporte-acceso-clientes",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
              <CustomGreenButton
                disabled={!rows.length || loading}
                onClick={() => {
                  exportFromJSON({
                    data: fullRows,
                    fileName: "reporte-acceso-clientes",
                    exportType: exportFromJSON.types.json,
                  });
                }}
              >
                JSON
              </CustomGreenButton>
              <CustomGreenButton disabled={!rows.length || loading}>
                <ExcelWorkBook
                  columns={columns}
                  rows={rows}
                  filename={"reporte-acceso-clientes"}
                />
              </CustomGreenButton>
            </ButtonGroup>
            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <MDBDataTableV5
                small
                responsive
                striped
                searchTop
                searchBottom={false}
                barReverse
                pagingTop
                bordered
                data={{
                  columns: columns.filter((obj) => obj.isVisible),
                  rows: rows,
                }}
                noBottomColumns
                order={["username", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
                fullPagination
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReporteAccesoClientes;
