import React, { useState, useEffect } from 'react'
// components
import { Container, Row, Col } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
import swal from 'sweetalert'
// icons
import TvIcon from '@material-ui/icons/Tv'
import SendIcon from '@material-ui/icons/Send'
import Clipboard from '@material-ui/icons/Assignment'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import { Tooltip } from '@material-ui/core'
import { GENERAL_URL_API } from '../../shared/urls'
import { useHistory } from 'react-router'
import { useAuth } from '../../shared/contex/auth/auth-context'
import { useClient } from '../../shared/contex/client/client-context'

let Armagedon = () => {
  const [count, setCount] = useState(10)
  const [response, setResponse] = useState({ secret: '', show: false })
  const history = useHistory()
  const countCondition = count >= 0

  const auth = useAuth()
  const { getClient } = useClient()
  const [client, setClient] = useState(null)

  useEffect(() => {
    if (auth.isLoggedIn) {
      console.log('USERNAME')
      console.log(getClient().username)
      if (getClient().username !== process.env.REACT_APP_ARMAGEDON_USER) {
        return history.push('/')
      }
      setClient(getClient())
      initialWait()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isLoggedIn])

  const armagedon = async () => {
    const swalRes = await swal({
      title: 'Armagedon',
      text: 'Estás seguro? Esta acción no es reversible',
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
      dangerMode: true
    })

    if (swalRes) {
      const fd = new FormData()
       
       
      // credenciales Armagedon
      fd.append('passwordA', process.env.REACT_APP_ARMAGEDON_PASSWORD)
      const api = GENERAL_URL_API + '/muerte/armagedon'
      const res = await fetch(api, {
        method: 'POST',
        body: fd,
        credentials: 'include'
      })
      const data = await res.json()

      setResponse(current => {
        return {
          ...current,
          secret: data.PASSW,
          show: true
        }
      })

      if (data) {
        await swal({
          title: 'Armagedon',
          text: 'Armagedon ha terminado',
          icon: 'warning'
        })
      }
    }
  }

  function delay (time) {
    return new Promise(resolve => setTimeout(resolve, time))
  }

  const initialWait = async () => {
    while (countCondition) {
      await delay(1000)
      setCount(state => state - 1)
    }
  }

  return (
    <>
      <div className='mb-4 d-flex align-items-center'>
        <div className='admin-page-tilte'>(</div>
        <TvIcon />
        <div className='admin-page-tilte mr-2'>)</div>
        <div className='admin-page-tilte'>Armagedon</div>
      </div>

      <Container fluid>
        <Row>
          <Col xs={12} className='content-white-box px-4 py-4'>
            <div className='d-flex align-items-center'>
              <MenuBookIcon />
              <div className='admin-main-title-dark-gray ml-2 mt-1'>
                Armagedon
              </div>
            </div>
            <div className='admin-muted-text-small mt-1 mb-4'>
              Plan Armagedon de Makemake.
            </div>

            <Button
              variant='contained'
              endIcon={<SendIcon />}
              className='w-25'
              size='large'
              style={{
                backgroundColor: countCondition ? '#979797' : '#f44336',
                color: '#fff'
              }}
              disabled={countCondition}
              onClick={armagedon}
            >
              {countCondition ? count : 'Armagedon'}
            </Button>

            <Box sx={{ width: '100%' }} className='mt-4'>
              <Collapse in={response.show}>
                <Alert
                  severity='warning'
                  action={
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(response.secret)
                      }}
                      aria-label='close'
                      color='inherit'
                      size='small'
                    >
                      <Tooltip title='Copy to clipboard'>
                        <Clipboard
                          fontSize='2em'
                          style={{
                            marginLeft: '1em',
                            cursor: 'pointer'
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {response.secret}
                </Alert>
              </Collapse>
            </Box>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Armagedon
