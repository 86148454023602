import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditarClienteForm from "./editar-cliente-form";
import swal from "sweetalert";

import BrushOutlinedIcon from "@material-ui/icons/BrushOutlined";
import PersonIcon from "@material-ui/icons/Person";

const EditarClienteExistente = ({ type, urlApiGet }) => {
  //Definimos un estado para guardar la informacion del autocomplete
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  useEffect(() => {
    clearSelection();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, urlApiGet]);

  //fetch Users List
  const fetchUsers = async () => {
    const fd = new FormData();
     
    fd.append("show", "all");
    fd.append("tipoUsuario", type);
    const res = await fetch(urlApiGet, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();

    if (resJSON.status === 0) {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    } else {
      setAutoCompleteOptions(resJSON.data);
    }
  };

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = async (e, newValue) => {
    // console.log(newValue);
    if (newValue) setAutoCompleteValue(newValue);
  };

  const clearSelection = async () => {
    setAutoCompleteOptions([]);
    await fetchUsers();
    setAutoCompleteValue(null);
  };

  /*
    Esta pagina cambia dependiendo si un usuario ha seleccionado un elemento del autocomplete
    si el valor del autoCompleteValue es null se retorna el autocomplete
    si ese valor no es null se retorna el formulario para editar un clietne
  */
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <BrushOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Editar un cliente {type} existente
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "22px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Buscar y editar un cliente {type} existente
              </div>
            </div>
            {!autoCompleteValue ? (
              <>
                <label className="admin-label-text mt-3 d-block">Buscar:</label>
                <Autocomplete
                  options={autoCompleteOptions}
                  getOptionLabel={(option) => option.username}
                  value={autoCompleteValue}
                  onChange={autoCompleteUpdate}
                  noOptionsText="Sin resultados"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  )}
                />
              </>
            ) : (
              <EditarClienteForm
                clearSelection={clearSelection}
                selectedOption={autoCompleteValue}
                type={type}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditarClienteExistente;
