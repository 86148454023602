import React, { useState, useEffect } from "react";
// Components
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { MDBDataTableV5 } from "mdbreact";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
// functions
import exportFromJSON from "export-from-json";
// data
import { columnasHabilitarPaquetesClientes } from "../../../shared/data/columnas";
import { GENERAL_URL_API } from "../../../shared/urls";
import ColorIconButton from "../../../components/shared/custom-green-icon-button";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import { Cached } from "@material-ui/icons";

const ClientsPacketsTable = ({ estado }) => {
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchPaquetesYrows();
    setCols(columnasHabilitarPaquetesClientes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPaquetesYrows = async () => {
    const fd = new FormData();
     
    fd.append("estado", estado);

    const res = await fetch(
      `${GENERAL_URL_API}/paquetes/verPaquetesYClientes.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const data = await res.json();
    console.log(data);
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo rows.",
        text: data.info,
        icon: "error",
      });
    setRows(data.data);
  };

  return (
    <>
      {rows.length === 0 ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <>
          <div className="d-flex align-items-center my-3">
            <ColorIconButton size="medium" onClick={fetchPaquetesYrows}>
              <Cached style={{ fontSize: "20px" }} />
            </ColorIconButton>
            <div className="pl-3 admin-muted-text">Refrescar la tabla.</div>
          </div>
          <ButtonGroup size="small" variant="contained" className="my-3">
            <CustomGreenButton
              onClick={() => {
                const dat = [...rows];
                navigator.clipboard
                  .writeText(JSON.stringify(dat))
                  .then(async () => {
                    await swal({
                      title: "Se ha copiado la tabla!",
                      icon: "success",
                    });
                  });
              }}
            >
              Copy
            </CustomGreenButton>
            <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-clientes-paquetes",
                  exportType: exportFromJSON.types.csv,
                });
              }}
            >
              CSV
            </CustomGreenButton>
            <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-clientes-paquetes",
                  exportType: exportFromJSON.types.json,
                });
              }}
            >
              JSON
            </CustomGreenButton>
            {/* <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-clientes-paquetes",
                  exportType: exportFromJSON.types.xls,
                });
              }}
            >
              EXCEL
            </CustomGreenButton> */}

            <CustomGreenButton>
              <ExcelWorkBook
                columns={cols}
                rows={rows}
                filename={`lista-clientes-paquetes`}
              />
            </CustomGreenButton>
          </ButtonGroup>
          <MDBDataTableV5
            small
            responsive
            searchTop
            searchBottom={false}
            barReverse
            pagingTop
            striped
            bordered
            data={{
              columns: cols.filter((obj) => obj.isVisible),
              rows: rows,
            }}
            noBottomColumns
            order={["username", "asc"]}
            entriesOptions={[5, 10, 20, 50]}
            entriesLabel="Numero de filas"
            searchLabel="Buscar"
            paginationLabel={["Anterior", "Siguiente"]}
            infoLabel={["Mostrando filas", "a", "de", "filas"]}
            fullPagination
          />
        </>
      )}
    </>
  );
};

export default ClientsPacketsTable;
