import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import IOSSwitch from "../../../components/shared/ios-switch";
import CustomGreenButton from "../../../components/shared/custom-green-button";

import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PeopleIcon from "@material-ui/icons/People";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TableChartIcon from "@material-ui/icons/TableChart";
import swal from "sweetalert";

import exportFromJSON from "export-from-json";

import { Bar } from "react-chartjs-2";
import { GENERAL_URL_API } from "../../../shared/urls";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";

const getChartData = (
  labels = [],
  label = "",
  data = [],
  backgroundColor = []
) => ({
  labels,
  datasets: [
    {
      label,
      data,
      backgroundColor,
    },
  ],
});

const EstadiscticasAccesoPersonales = () => {
  //Definimos un estado para guardar la informacion del autocomplete
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [switchesState, setSwitchesState] = useState({
    sw1: true,
    sw2: false,
    sw3: false,
  });
  const [dates, setDates] = useState({ from: "", to: "" });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    num: getChartData(),
    duration: getChartData(),
    avg: getChartData(),
  });
  const [rows, setRows] = useState([]);

  const columns = [
    {
      label: "Fecha",
      field: "date",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
    {
      label: "Número de sesiones totales",
      field: "numSessions",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
    {
      label: "Número de sesiones Web",
      field: "numWebSessions",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
    {
      label: "Número de sesiones App",
      field: "numAppSessions",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
    {
      label: "Duración de las sesiones (en minutos)",
      field: "durationSessions",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
    {
      label: "Duración promedio de las sesiones (en minutos)",
      field: "avgSessions",
      sort: "",
      minimal: "lg",
      isVisible: true,
    },
  ];

  useEffect(() => {
    fetchInsitutos();
  }, []);

  const fetchInsitutos = async () => {
    const fd = new FormData();
     
    fd.append("show", "all");
    fd.append("tipoUsuario", "Individual");
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setAutoCompleteOptions(data.data);
  };

  const updateCharts = (data) => {
    // las labels son las mismas para las 3 charts
    const labels = data.agregados_duracion_total?.map((el) => {
      if (getPeriodoName() === "day") return el.fechaFiltrar;
      return `${el.fechaFiltrar}-${el.yearT}`;
    });
    // Obtiene los arreglos correspondientes a c/chart
    const numData = data.agregados_fecha?.map((el) => el.total_sessions);
    const numDataApp = data.agregados_fecha?.map((el) => el.app_sessions);
    const numDataWeb = data.agregados_fecha?.map((el) => el.web_sessions);
    const durationData = data.agregados_duracion_total.map(
      (el) => el.total_time
    );
    const avgData = data.agregados_duracion_promedio?.map(
      (el) => el.total_time
    );

    const tableRows = [];
    for (let i = 0; i < labels.length; i++) {
      // fill tableRows
      tableRows.push({
        date: labels[i],
        numSessions: parseFloat(numData[i]),
        numWebSessions: parseFloat(numDataWeb[i]),
        numAppSessions: parseFloat(numDataApp[i]),
        durationSessions: parseFloat(durationData[i]),
        avgSessions: parseFloat(avgData[i]),
      });
    }

    setChartData({
      num: getChartData(labels, "# de sesiones", numData, ["#75c97b"]),
      duration: getChartData(labels, "Duración de las sesiones", durationData, [
        "#66b7fd",
      ]),
      avg: getChartData(labels, "Duración promedio de las sesiones", avgData, [
        "#fdc166",
      ]),
    });
    setRows(tableRows);
  };

  const setSwitch1 = () => {
    setSwitchesState({ sw1: !switchesState.sw1, sw2: false, sw3: false });
  };
  const setSwitch2 = () => {
    setSwitchesState({ sw1: false, sw2: !switchesState.sw2, sw3: false });
  };
  const setSwitch3 = () => {
    setSwitchesState({ sw1: false, sw2: false, sw3: !switchesState.sw3 });
  };

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = (e, newValue) => {
    //Si efectivametne se seleccion un nuevo valor actualizamos el estado
    if (newValue) {
      setError(false);
      setAutoCompleteValue(newValue);
    }
  };

  const getPeriodoName = () => {
    if (switchesState.sw1) return "day";
    if (switchesState.sw2) return "week";
    return "month";
  };

  const fetchEstadisticas = async () => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("client", autoCompleteValue.username);
    fd.append("periodo", getPeriodoName());
    fd.append("fechaInicio", dates.from);
    fd.append("fechaFin", dates.to);
    let res = {};
    try {
      res = await fetch(`${GENERAL_URL_API}/verEstadisticas/individuales.php`, {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      });
    } catch (error) {
      console.log(error);
    }
    const data = await res.json();
    if (data.status === 1) updateCharts(data.data);
    else
      await swal({
        title: "Error",
        text: data.info,
        icon: "error",
      });
    setLoading(false);
  };

  const submitText = () => {
    if (autoCompleteValue === null) {
      setError(true);
    } else {
      fetchEstadisticas();
      setError(false);
    }
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TimelineOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Estadísticas personales</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <AccountBalanceIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Selecciona un cliente
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">Buscar:</label>
              <Autocomplete
                options={autoCompleteOptions}
                getOptionLabel={(option) => option.username}
                value={autoCompleteValue}
                onChange={autoCompleteUpdate}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    helperText={error ? "Incorrect entry." : null}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <div className="mt-3"></div>
              <label
                className="admin-label-text mt-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por día:
              </label>
              <IOSSwitch checked={switchesState.sw1} onClick={setSwitch1} />
              <label
                className="admin-label-text mt-2 ml-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por semana:
              </label>
              <IOSSwitch checked={switchesState.sw2} onClick={setSwitch2} />
              <label
                className="admin-label-text mt-2 ml-md-2 d-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                Por mes:
              </label>
              <IOSSwitch checked={switchesState.sw3} onClick={setSwitch3} />

              <label className="admin-label-text mt-3 d-block">
                Entre las fechas:
              </label>
              <div className="d-flex">
                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                  value={dates.from}
                  onChange={(e) => setDates({ ...dates, from: e.target.value })}
                />
                <CustomGreenButton
                  variant="contained"
                  style={{
                    height: "40px",
                    pointerEvents: "none",
                  }}
                  className="d-block d-md-inline"
                  disableTouchRipple
                  disableRipple
                  disableElevation
                  disableFocusRipple
                >
                  Hasta
                </CustomGreenButton>
                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{
                    width: "180px",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                  value={dates.to}
                  onChange={(e) => setDates({ ...dates, to: e.target.value })}
                />
              </div>
              <br />
              <CustomGreenButton
                variant="contained"
                className="mt-4"
                onClick={submitText}
                disabled={
                  loading ||
                  (!switchesState.sw1 &&
                    !switchesState.sw2 &&
                    !switchesState.sw3) ||
                  !autoCompleteValue ||
                  !dates.from ||
                  !dates.to
                }
              >
                Consultar
              </CustomGreenButton>
            </form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <PeopleIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                NÚMERO DE SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.num} />
          </Col>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <AccessTimeIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                DURACIÓN DE LAS SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.duration} />
          </Col>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <AccessTimeIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                DURACIÓN PROMEDIO DE LAS SESIONES POR FECHA
              </span>
            </div>
            <Bar data={chartData.avg} />
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="d-flex align-items-center">
              <TableChartIcon />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                TABLA DE DATOS
              </span>
            </div>
            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <>
                <ButtonGroup size="small" variant="contained" className="my-3">
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "estadisticas-acceso-personales",
                        exportType: exportFromJSON.types.csv,
                      });
                    }}
                  >
                    CSV
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: "estadisticas-acceso-personales",
                        exportType: exportFromJSON.types.json,
                      });
                    }}
                  >
                    JSON
                  </CustomGreenButton>
                  <CustomGreenButton>
                    <ExcelWorkBook
                      columns={columns}
                      rows={rows}
                      filename={"estadisticas-acceso-personales"}
                    />
                  </CustomGreenButton>
                </ButtonGroup>
                <MDBDataTableV5
                  className="mt-3"
                  small
                  responsive
                  searchTop
                  searchBottom={false}
                  barReverse
                  pagingTop
                  striped
                  bordered
                  data={{
                    columns: columns.filter((obj) => obj.isVisible),
                    rows: rows,
                  }}
                  noBottomColumns
                  order={["name", "asc"]}
                  entriesOptions={[5, 10, 20, 50]}
                  entriesLabel="Numero de filas"
                  searchLabel="Buscar"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={["Mostrando filas", "a", "de", "filas"]}
                  fullPagination
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EstadiscticasAccesoPersonales;
