import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Creates a new NOTIBUZON with provided information.
 *
 * @async
 * @param {Object} notibuzon - The information of the notibuzon.
 * @param {string} notibuzon.tipoNotificacion - The type of the notibuzon.
 * @param {string} notibuzon.tipoVisibilidad - The visibility of the notibuzon.
 * @param {string} notibuzon.metadatos - The metadata of the notibuzon.
 * @param {string} notibuzon.fechaInicio - The start date of the notibuzon.
 * @param {string} notibuzon.fechaFin - The end date of the notibuzon.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
export async function crearNotibuzon(notibuzon) {
  const fd = new FormData();

  Object.entries(notibuzon).forEach(([key, value]) => {
    if (value) {
      fd.append(key, value);
    }
  });

  const { data } = await axios.post(
    `${GENERAL_URL_API}/notificaciones/notibuzon/crearNotibuzon`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return data;
}
