import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Edits a new NOTIBANNER with provided information.
 *
 * @async
 * @param {Object} notibanner - The information of the notibanner.
 * @param {string} notibanner.idNotibanner - The id of the notibanner.
 * @param {string} notibanner.tipoNotificacion - The type of the notibanner.
 * @param {string} notibanner.tipoVisibilidad - The visibility of the notibanner.
 * @param {string} notibanner.subtitulo - The subtitle of the notibanner.
 * @param {string} notibanner.metadatos - The metadata of the notibanner.
 * @param {string} notibanner.colorTexto- The Notibanner's text color.
 * @param {string} notibanner.colorTextoBoton - The Notibanner's button text color.
 * @param {string} notibanner.colorFondoBoton - The Notibanner's button background color.
 * @param {string} notibanner.colorPrimario - The primary color of the notibanner.
 * @param {string} notibanner.colorSecundario - The secondary color of the notibanner.
 * @param {string} notibanner.fechaInicio - The start date of the notibanner.
 * @param {string} notibanner.fechaFin - The end date of the notibanner.
 * @param {string} notibanner.imagen - The image of the notibanner.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
export const editarNotibanner = async (notibanner) => {
  const fd = new FormData();

  Object.entries(notibanner).forEach(([key, value]) => {
    if (value) {
      fd.append(key, value);
    }
  });

  const { data } = await axios.post(
    `${GENERAL_URL_API}/notificaciones/notibanner/editarNotibanner`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return data;
};
