import React, { useEffect, useState } from "react";

import { GENERAL_URL_API } from "../../../../shared/urls";
// m-ui components
import AgregarNuevoLibroForm from "../../adicionar-elementos/agregar-nuevo-libro-form";
import { ButtonGroup } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditarArchivosLibro from "./editar-archivos-libro";
import { useLocation } from "react-router-dom";

const EditarLibroForm = () => {
  const query = new URLSearchParams(useLocation().search);

  const [books, setBooks] = useState([]);
  const [book, setBook] = useState({
    value: null,
    error: false,
  });
  const [type, setType] = useState("files");

  useEffect(() => {
    getBooks();
    getBookFromQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBookFromQuery = async () => {
    if (query.get("idLibro")) {
      const b = await getBook(query.get("idLibro"));
      setBook({
        ...book,
        value: b,
      });
    }
  };

  const getBook = async (id) => {
    const fd = new FormData();
     
    fd.append("idLibro", id);

    const res = await fetch(`${GENERAL_URL_API}/libros/verLibro.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    return data.data;
  };

  const changeBook = (newBook) => {
    setBook({ ...book, value: newBook });
  };

  const getBooks = async () => {
    const fd = new FormData();
    setBooks([]);
     
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verLibros.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setBooks(data.data);
  };

  // const handleSubmit = () => {};
  const handleCancel = () => {
    setBook({
      ...book,
      value: null,
    });
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div
          className="d-flex"
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <label className="admin-label-text mt-3 d-block">Buscar:</label>
          <ButtonGroup variant="text" aria-label="text button group">
            <Button disabled={type === "info"} onClick={() => setType("info")}>
              INFO
            </Button>
            <Button
              disabled={type === "files"}
              onClick={() => setType("files")}
            >
              aRCHIVOS
            </Button>
          </ButtonGroup>
        </div>
        {books.length === 0 ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Autocomplete
            options={books}
            getOptionLabel={(option) => option.title}
            value={book.value}
            onChange={(e, value) => changeBook(value)}
            noOptionsText="Sin resultados"
            disabled={book.value}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                fullWidth
                style={{
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                error={book.error}
                helperText={book.error ? "Campo no válido" : null}
              />
            )}
          />
        )}
      </form>
      {/* Si hay libro y el tipo es archivo */}
      {book.value && type === "info" && (
        <AgregarNuevoLibroForm
          book={book.value}
          edit
          cancelFn={handleCancel}
          refreshBooks={getBooks}
        />
      )}
      {/* Si hay libro y el tipo es archivos */}
      {book.value && type === "files" && (
        <EditarArchivosLibro
          book={book.value}
          setBook={setBook}
          cancelFn={handleCancel}
        />
      )}
    </>
  );
};

export default EditarLibroForm;
