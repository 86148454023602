import React, { useState } from "react";
// components
import { MDBDataTableV5 } from "mdbreact";
import { Skeleton } from "@material-ui/lab";
// data
import { cursosUsuariosCols } from "../../../../shared/data/columnas-formacion";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import ExcelWorkBook from "../../../../components/shared/ExcelWorkBook";
import exportFromJSON from "export-from-json";
import swal from "sweetalert";
import { ButtonGroup } from "@material-ui/core";

const CursoUsuarioTable = ({ rows }) => {
  const [columns, setColumns] = useState(cursosUsuariosCols);

  return (
    <>
      {rows.length === 0 || columns.length === 0 ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <>
          <ButtonGroup size="small" variant="contained" className="my-3">
            <CustomGreenButton
              onClick={() => {
                const dat = [...rows];
                navigator.clipboard
                  .writeText(JSON.stringify(dat))
                  .then(async () => {
                    await swal({
                      title: "Se ha copiado la tabla!",
                      icon: "success",
                    });
                  });
              }}
            >
              Copy
            </CustomGreenButton>
            <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-cursos-usuarios-academia",
                  exportType: exportFromJSON.types.csv,
                });
              }}
            >
              CSV
            </CustomGreenButton>
            <CustomGreenButton
              onClick={() => {
                exportFromJSON({
                  data: rows,
                  fileName: "lista-cursos-usuarios-academia",
                  exportType: exportFromJSON.types.json,
                });
              }}
            >
              JSON
            </CustomGreenButton>
            <CustomGreenButton>
              <ExcelWorkBook
                columns={columns}
                rows={rows}
                filename={"lista-cursos-usuarios-academia"}
              />
            </CustomGreenButton>
          </ButtonGroup>
          <MDBDataTableV5
            small
            responsive
            striped
            bordered
            searchTop
            searchBottom={false}
            barReverse
            pagingTop
            data={{
              columns: columns.filter((obj) => obj.isVisible),
              rows: rows,
            }}
            noBottomColumns
            order={["name", "asc"]}
            entriesOptions={[5, 10, 20, 50]}
            entriesLabel="Numero de filas"
            searchLabel="Buscar"
            paginationLabel={["Anterior", "Siguiente"]}
            infoLabel={["Mostrando filas", "a", "de", "filas"]}
            fullPagination
          />
        </>
      )}
    </>
  );
};

export default CursoUsuarioTable;
