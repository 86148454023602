import React from "react";
import { Container, Row } from "react-bootstrap";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CrearFormularioIndividual from "./crear-formulario-individual";

const CrearFormulario = () => {
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Agregar un nuevo Formulario</div>
      </div>
      <Container fluid>
        <Row>
          <CrearFormularioIndividual />
        </Row>
      </Container>
    </>
  );
};

export default CrearFormulario;
