import { GENERAL_URL_API } from "../urls";

export const fetchColecciones = async () => {
  const fd = new FormData();
   
   
  const res = await fetch(`${GENERAL_URL_API}/coleccion/verColecciones.php`, {
    method: "POST", credentials: 'include',
    body: fd,
  });
  return res.json();
};

export const verArteColeccion = async (collectionName) => {
  const fd = new FormData();
   
   
  fd.append("coleccion", collectionName);
  const res = await fetch(`${GENERAL_URL_API}/coleccion/verArte.php`, {
    method: "POST", credentials: 'include',
    credentials: "include",
    body: fd,
  });
  return res.json();
};

export const editarArteColeccion = async ({
  collectionName,
  nuevoNombre,
  collectionNameENG,
  subtitulo,
  subtituloENG,
  descripcion,
  descripcionENG,
  color1,
  color2,
  colorLetras,
  coleccionDeAutor,
  coleccionDePais,
  colart,
  colartinterna,
}) => {
  const fd = new FormData();
   
  fd.append("collectionName", collectionName);
  fd.append("nuevoNombre", nuevoNombre);
  fd.append("collectionNameENG", collectionNameENG);
  fd.append("subtitulo", subtitulo);
  fd.append("subtituloENG", subtituloENG);
  fd.append("descripcion", descripcion);
  fd.append("descripcionENG", descripcionENG);
  fd.append("color1", color1);
  fd.append("color2", color2);
  fd.append("colorLetras", colorLetras);
  fd.append("coleccionDeAutor", coleccionDeAutor);
  fd.append("coleccionDePais", coleccionDePais);
  if (colart !== undefined) fd.append("colart", colart);

  if (colartinterna !== undefined) fd.append("colartinterna", colartinterna);

  const res = await fetch(`${GENERAL_URL_API}/coleccion/editarArte.php`, {
    method: "POST", credentials: 'include',
    credentials: "include",
    body: fd,
  });

  return res.json();
};
