import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import { DropzoneArea } from "material-ui-dropzone";
import { GENERAL_URL_API } from "../../../../shared/urls";
import swal from "sweetalert";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const EditarArchivosLibro = ({ book, cancelFn }) => {
  // const [book, setBook] = useState({});
  const [loading, setLoading] = useState(false);

  const [artState, setArtState] = useState({
    portada: {
      images: [],
      error: false,
    },
    screen1: {
      images: [],
      error: false,
    },
    screen2: {
      images: [],
      error: false,
    },
    screen3: {
      images: [],
      error: false,
    },
  });

  const [contentState, setContentState] = useState({
    images: [],
    error: false,
  });

  const handleDropFileChange = (images, type, artName) => {
    if (type === "art")
      setArtState({
        ...artState,
        [artName]: {
          ...artState[artName],
          images,
        },
      });

    if (type === "content")
      setContentState({
        ...contentState,
        images,
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await sendFiles();
    cancelFn();
    setLoading(false);
  };

  const sendFiles = async () => {
    const fd = new FormData();
     
    fd.append("idLibro", book.idLibro);
    if (contentState.images.length)
      fd.append("content", contentState.images[0]);
    if (artState.portada.images.length)
      fd.append("portada", artState.portada.images[0]);
    if (artState.screen1.images.length)
      fd.append("screen1", artState.screen1.images[0]);
    if (artState.screen2.images.length)
      fd.append("screen2", artState.screen2.images[0]);
    if (artState.screen3.images.length)
      fd.append("screen3", artState.screen3.images[0]);

    try {
      const res = await fetch(
        `${GENERAL_URL_API}/libros/subirLibroContenido.php`,
        {
          method: "POST", credentials: 'include',
          credentials: "include",
          body: fd,
        }
      );
      const data = await res.json();
      if (data.status === 1) {
        await swal({
          title: "Rutas modificadas.",
          text: "Los archivos se modificaron satisfactoriamente!",
          icon: "success",
        });
      } else {
        await swal({
          title: "Sorry.",
          text: "No se pudieron cambiar los archivos, comunicate con soporte.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <label className="admin-label-text mt-3 d-block">
        Identificador Makina
      </label>
      <TextField
        variant="outlined"
        size="small"
        disabled
        value={book.idLibro}
        style={{
          width: "100%",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      />
      {/* ---------------------------ART files--------------------------- */}
      <label className="admin-label-text mt-3 d-block">Archivos de arte</label>
      <div
        className="admin-muted-text-small"
        style={artState.portada.error ? { color: "red" } : { color: "auto" }}
      >
        Portada*
      </div>
      <DropzoneArea
        id="dropzone"
        dropzoneText="Arrastra un archivo o haz click aquí!"
        showFileNames={true}
        // maxFileSize={3000000} //n in bytes
        showAlerts={true}
        filesLimit={1}
        acceptedFiles={[
          "image/*, application/zip, application/rar,	application/vnd.rar",
        ]}
        onChange={(files) => handleDropFileChange(files, "art", "portada")}
      />

      <div
        className="admin-muted-text-small"
        style={artState.screen1.error ? { color: "red" } : null}
      >
        Screen 1
      </div>
      <DropzoneArea
        id="dropzone"
        dropzoneText="Arrastra un archivo o haz click aquí!"
        showFileNames={true}
        // maxFileSize={3000000} //n in bytes
        showAlerts={true}
        filesLimit={1}
        acceptedFiles={[
          "image/*, application/zip, application/rar,	application/vnd.rar",
        ]}
        onChange={(files) => handleDropFileChange(files, "art", "screen1")}
      />

      <div
        className="admin-muted-text-small"
        style={artState.screen2.error ? { color: "red" } : null}
      >
        Screen 2
      </div>
      <DropzoneArea
        id="dropzone"
        dropzoneText="Arrastra un archivo o haz click aquí!"
        showFileNames={true}
        // maxFileSize={3000000} //n in bytes
        showAlerts={true}
        filesLimit={1}
        acceptedFiles={[
          "image/*, application/zip, application/rar,	application/vnd.rar",
        ]}
        onChange={(files) => handleDropFileChange(files, "art", "screen2")}
      />

      <div
        className="admin-muted-text-small"
        style={artState.screen3.error ? { color: "red" } : null}
      >
        Screen 3
      </div>
      <DropzoneArea
        id="dropzone"
        dropzoneText="Arrastra un archivo o haz click aquí!"
        showFileNames={true}
        // maxFileSize={3000000} //n in bytes
        showAlerts={true}
        filesLimit={1}
        acceptedFiles={[
          "image/*, application/zip, application/rar,	application/vnd.rar",
        ]}
        onChange={(files) => handleDropFileChange(files, "art", "screen3")}
      />
      {/* ---------------------------content files--------------------------- */}
      <label className="admin-label-text mt-3 d-block">
        Archivo de contenido
      </label>
      <div
        className="admin-muted-text-small"
        style={contentState.error ? { color: "red" } : null}
      >
        1 Archivo requerido ["content.epub" o "content.zip" o "content.pdf"] No
        file chosen
      </div>
      <DropzoneArea
        id="dropzone"
        dropzoneText="Arrastra un archivo o haz click aquí!"
        showFileNames={true}
        maxFileSize={5000000000} //n in bytes
        showAlerts={true}
        filesLimit={1}
        acceptedFiles={[
          "application/*, application/x-zip-compressed,application/epub+zip, application/pdf, application/zip, application/rar, application/vnd.rar",
        ]}
        onChange={(files) => {
          handleDropFileChange(files, "content");
        }}
      />

      <div className="mt-3 w-100 d-flex justify-content-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckRounded />}
          style={{ background: "#81c868" }}
          onClick={handleSubmit}
          disabled={loading}
        >
          Editar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="ml-2"
          startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
          style={{ background: "#f05050" }}
          onClick={cancelFn}
          disabled={loading}
        >
          Cancelar
        </Button>
      </div>
    </>
  );
};

export default EditarArchivosLibro;
