import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import swal from "sweetalert";

import TvIcon from "@material-ui/icons/Tv";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import { GENERAL_URL_API } from "../../../shared/urls";
import { columnasActividades } from "../../../shared/data/columnas";
import exportFromJSON from "export-from-json";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";

const ListaActividades = () => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verActividades.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    // ajustar field en columnas para que concuerden con los que llegan de la req
    let tempColumns = columnasActividades;
    setRows(resJSON.data);
    setColumns(tempColumns);
    setLoading(false);
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Lista de actividades</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de actividades
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Consultas de libros.
            </div>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />

            <ButtonGroup size="small" variant="contained" className="my-3">
              <CustomGreenButton
                disabled={loading}
                onClick={() => {
                  const dat = [...rows];
                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>
              <CustomGreenButton
                disabled={loading}
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-actividades",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
              <CustomGreenButton
                disabled={loading}
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-actividades",
                    exportType: exportFromJSON.types.json,
                  });
                }}
              >
                JSON
              </CustomGreenButton>
              <CustomGreenButton disabled={loading}>
                <ExcelWorkBook
                  columns={columns}
                  rows={rows}
                  filename={"Lista-actividades"}
                />
              </CustomGreenButton>
              {/* <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-libros",
                    fields: {
                      idLibro: "090$a",
                      title: "245$a",
                      subject: "084$a",
                      subject2: "65300$a",
                      // 'subject2ENG': '65300$a',
                      description: "520$a",
                      source: "534$a",
                      relation: "544$n",
                      coverage: "?",
                      author: "100$a",
                      // 'Autor (entidad)': '110$a',
                      // 'Autor' : '245$c',
                      // 'Lugar de publicación/distribución del libro': '264#2$a',
                      publisher: "264#2$b",
                      // 'Autor secundario (entidad)': '710$a',
                      // 'autor secundario-traductor': '70010$a',
                      // 'función del autor secundario': '70010$e',
                      rights: "540$f",
                      isbn: "020$a",
                      date: "264#2$c",
                      bookType: "300$a",
                      language: "041$a",
                      nivelUsuarioLector: "52110#$a",
                      edad: "5211#$a",
                      curso: "5212#$a",
                      disponibilidadRegional: "522$a",
                      genero: "655#4$a",
                      portada: "85640$a",
                      recomendado1: "544$n",
                      webUrl: "85642$a",
                    },
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                MARC
              </CustomGreenButton> */}
            </ButtonGroup>
            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <MDBDataTableV5
                small
                responsive
                striped
                bordered
                searchTop
                searchBottom={false}
                barReverse
                pagingTop
                data={{
                  columns: columns.filter((obj) => obj.isVisible),
                  rows: rows,
                }}
                noBottomColumns
                order={["name", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
                fullPagination
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaActividades;
