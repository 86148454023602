import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const CustomGreenButton = withStyles(() => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#5fbeaa",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5fbeaa",
    },
  },
}))(Button);

export default CustomGreenButton;
