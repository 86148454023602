import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

const ColorIconButton = withStyles(() => ({
    root: {
        color: '#ffffff',
        backgroundColor: '#5fbeaa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#5fbeaa',
        }
    }
}))(IconButton);

export default ColorIconButton