import React from 'react'

import { Dropdown } from 'antd'
import DropdownOverlay from './dropdown-overlay'
import CustomGreenButton from '../custom-green-button'

/*
Este componete es el dropdown de la seleccion de columnas
Los elementos del dropdown son el componente DropdownOverlay
*/
const TableColumnDropdown = (props) => {
    return (
        <Dropdown
            overlay={
                <DropdownOverlay
                    columns={props.columns}
                    changeColumnStatus={props.changeColumnStatus}
                />
            }
            trigger={['click']}>
            <CustomGreenButton
                size="small"
                variant="contained"
            >
                Mostrar Columnas
            </CustomGreenButton>
        </Dropdown>
    )
}

export default TableColumnDropdown