import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

/**
 * Asigna o desasigna un curso a un usuario de la academia mediante una llamada a la API.
 * @param {string} usuario - El usernameAcademia del usuario al que se le asignará o desasignará el curso.
 * @param {number} idCurso - El ID del curso que se asignará o desasignará.
 * @param {string} operacion - La operación a realizar: "asignarCurso" o "quitarCurso".
 * @returns {Promise<any>} - Una promesa que se resuelve en los datos de la asignación o desasignación del curso.
 */
export const asignarDesasignarCursoAcademia = async (
  usuario,
  idCurso,
  operacion
) => {
  const fd = new FormData();
  fd.append("asignar_curso_academia", operacion);
  fd.append("usuario", usuario);
  fd.append("idCurso", idCurso);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/academia/asignarCurso`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

/**
 * Asynchronously assigns or deassigns multiple courses in an academy using a file upload.
 *
 * @param {File} file - The file containing the list of courses to be assigned or deassigned.
 * @returns {Promise<Object>} A Promise that resolves to the response data from the API.
 */
export async function asignarDesasignarCursosAcademia(file) {
  const fd = new FormData();
  fd.append("courses", file);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/academia/asignarCursoMasivo`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
}
