import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Fetches a list of active institutes.
 *
 * @async
 * @returns {Promise<Array>} A promise that resolves to an array of institute objects.
 */
export async function fetchInstitutosActivos() {
  const fd = new FormData();
  fd.append("verActivos", 1);

  const { data } = await axios.post(`${GENERAL_URL_API}/app/verInstitutos`, fd, {
    withCredentials: true,
  });

  return data?.institutos ?? [];
}
