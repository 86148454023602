export const columnasProgresosCursosAcademia = [
  {
    label: "idCursoAcademia",
    field: "idCursoAcademia",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Username",
    field: "usernameAcademia",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Porcentaje de avance",
    field: "porcentajeTotal",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "País",
    field: "pais",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
