import React from "react";
// components
import { Container, Row, Col } from "react-bootstrap";
import AgregarEliminarForm from "./agregar-eliminar-paquete-cliente-form";
// icons
import ClientsPacketsTable from "../clients-packets-table";
import { TvOutlined } from "@material-ui/icons";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";

const AgregarEliminarPaqueteCliente = () => {
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o eliminar un paquete a un cliente
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <ShoppingBasketRoundedIcon style={{ fontSize: "20px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar/Eliminar un paquete a un cliente
              </div>
            </div>
            <AgregarEliminarForm />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            <div className="admin-main-title-dark-gray  ml-2 mt-1">
              <i>
                <TvOutlined />
              </i>
              <span className="ml-2">TABLA DE CLIENTES Y SUS PAQUETES</span>
            </div>
            <ClientsPacketsTable type="autorizado" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarEliminarPaqueteCliente;
