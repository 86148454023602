export const librosData = [
  { data: "MM0001", value: "¡No, no fui yo! // Ivar Da Coll" },
  { data: "MM0002", value: "¿Cuántos globos? // Menena Cottin" },
  {
    data: "MM0003",
    value: "1, 2, 3, ¿Cuántos ves? // Ana María Pavez y Constanza Recart",
  },
  { data: "MM0004", value: "A simple vista // Yael Frankel" },
  {
    data: "MM0005",
    value: "A un hombre de gran nariz // Francisco de Quevedo",
  },
  { data: "MM0006", value: "Acumulando el tesoro // Truchafrita" },
  { data: "MM0007", value: "Ana con A, Otto con O // Menena Cottin" },
  { data: "MM0008", value: "Animaletras // Francisca Palacios" },
  {
    data: "MM0010",
    value: "Blanca Nieve en la casa de los enanos // Gabriela Mistral",
  },
  { data: "MM0011", value: "Bola de agua // Pilar Gutiérrez Llano" },
  { data: "MM0012", value: "Cacería // Fernando Vilela" },
  { data: "MM0013", value: "Cándida // Elvia Silvera" },
  { data: "MM0014", value: "Caperucita Roja // Gabriela Mistral" },
  { data: "MM0015", value: "Chigüiro Abo y Ata // Ivar Da Coll" },
  { data: "MM0016", value: "Chigüiro chistoso // Ivar Da Coll" },
  { data: "MM0017", value: "Chigüiro encuentra ayuda // Ivar Da Coll" },
  { data: "MM0018", value: "Chigüiro Rana Ratón // Ivar Da Coll" },
  { data: "MM0019", value: "Chigüiro se va… // Ivar Da Coll" },
  { data: "MM0020", value: "Chigüiro viaja en chiva // Ivar Da Coll" },
  { data: "MM0021", value: "Chigüiro y el baño // Ivar Da Coll" },
  { data: "MM0022", value: "Chigüiro y el lápiz // Ivar Da Coll" },
  { data: "MM0023", value: "Chigüiro y el palo // Ivar Da Coll" },
  { data: "MM0024", value: "Chumba la cachumba // Carlos Cotte" },
  { data: "MM0025", value: "Circo Piccadilly // Ink Robin" },
  { data: "MM0026", value: "Cometas // Truchafrita" },
  { data: "MM0028", value: "Cuac // Menena Cottin" },
  { data: "MM0029", value: "Cuentos de Diego y Daniela // Verónica Uribe" },
  { data: "MM0030", value: "Cuentos pintados // Rafael Pombo" },
  { data: "MM0031", value: "De paseo // Estrella Ortiz" },
  { data: "MM0032", value: "Despegue // Javier Moreno" },
  { data: "MM0033", value: "Días de rock de garaje // Jairo Buitrago" },
  { data: "MM0034", value: "Disparate // Eugenio Montejo" },
  { data: "MM0035", value: "Doble doble // Menena Cottin" },
  { data: "MM0036", value: "Duerme niño, duerme // Laura Herrera" },
  {
    data: "MM0037",
    value: "Ecos verdes // Mónica Martin y María de los Ángeles Pavez",
  },
  { data: "MM0038", value: "El canto errante // Rubén Darío" },
  { data: "MM0039", value: "El cetro del niño rey // Albeiro Echavarría" },
  { data: "MM0040", value: "El cocuyo y la mora // Fray Cesáreo de Armellada" },
  { data: "MM0041", value: "El conde Olinos // Anónimo" },
  { data: "MM0042", value: "El espejo mágico // Laura Herrera" },
  {
    data: "MM0043",
    value: "El fantástico aviario de Sir William McCrow // Lizardo Carvajal",
  },
  { data: "MM0044", value: "El hipo, el rino, el elefante y yo // Ink Robin" },
  { data: "MM0045", value: "El infiltrado // María Inés McCormick" },
  {
    data: "MM0046",
    value:
      "El insólito viaje de Jemmy Button // Ana María Pavez y Olaya Sanfuentes",
  },
  {
    data: "MM0047",
    value: "El mordisco de la medianoche // Francisco Leal Quevedo",
  },
  {
    data: "MM0048",
    value:
      "El niño del plomo: una momia inka // Ana María Pavez y Constanza Recart",
  },
  {
    data: "MM0049",
    value: "El niño en el hotel al borde de la carretera // Jairo Buitrago",
  },
  {
    data: "MM0050",
    value: "El niño que pasaba desapercibido // Óscar Rodríguez Nieto",
  },
  { data: "MM0051", value: "El pájaro de los mil cantos // Lizardo Carvajal" },
  { data: "MM0053", value: "El Plan Termita // María Fernanda Heredia" },
  { data: "MM0054", value: "El rabanito que volvió // Anónimo" },
  { data: "MM0055", value: "El señor L. Fante // Jairo Buitrago" },
  { data: "MM0056", value: "El sol de los venados // Gloria Cecilia Díaz" },
  {
    data: "MM0057",
    value: "El sol, la luna y el agua. Un cuento nigeriano // Laura Herrera",
  },
  { data: "MM0058", value: "El valle de los Cocuyos // Gloria Cecilia Díaz" },
  { data: "MM0059", value: "El vendedor de sandías // Lizardo Carvajal" },
  {
    data: "MM0060",
    value: "El viaje de Colón // Olaya Sanfuentes y Alejandra Vega",
  },
  { data: "MM0061", value: "Eloísa y los bichos // Jairo Buitrago" },
  { data: "MM0062", value: "Emiliano // Jairo Buitrago" },
  { data: "MM0063", value: "En mis sueños // Stéphane Kiehl" },
  {
    data: "MM0064",
    value: "Érase una mujer // Lizardo Carvajal y Vera Carvajal",
  },
  { data: "MM0065", value: "Érase una vez un espacio // Marta Carrasco" },
  { data: "MM0066", value: "Ese cambio // Philippe Dumas" },
  { data: "MM0067", value: "Estaba la rana // Paloma Valdivia" },
  { data: "MM0069", value: "Fiebre // Truchafrita" },
  {
    data: "MM0070",
    value: "Flores blancas para papá // Beatriz Helena Robledo",
  },
  { data: "MM0071", value: "Francis Drake // Francisco Jiménez" },
  {
    data: "MM0072",
    value:
      "Francisco de Orellana y la expedición al Amazonas // Elizabeth Mejías",
  },
  {
    data: "MM0073",
    value: "Gabriela Mistral, poemas ilustrados // Gabriela Mistral",
  },
  { data: "MM0074", value: "Gabriela, la poeta viajera // Alejandra Toro" },
  {
    data: "MM0075",
    value: "Gonzalo Rojas, poemas ilustrados // Gonzalo Rojas",
  },
  { data: "MM0076", value: "Hamamelis y el secreto // Ivar Da Coll" },
  {
    data: "MM0077",
    value: "Hamamelis, Miosotis y el señor Sorpresa // Ivar Da Coll",
  },
  { data: "MM0078", value: "Historias de Eusebio // Ivar Da Coll" },
  { data: "MM0079", value: "Inocencia perdida // Truchafrita" },
  { data: "MM0080", value: "Jaime en la ciudad // Ink Robin" },
  {
    data: "MM0081",
    value: "James no está en casa // Constanza Martínez Camacho",
  },
  { data: "MM0082", value: "Juegos tradicionales // María Angélica Ovalle" },
  {
    data: "MM0083",
    value: "Kiwala conoce el mar // Ana María Pavez y Constanza Recart",
  },
  {
    data: "MM0084",
    value: "Kiwala va a la selva // Ana María Pavez y Constanza Recart",
  },
  {
    data: "MM0085",
    value: "Kiwala, la llama de cobre // Ana María Pavez y Constanza Recart",
  },
  { data: "MM0086", value: "La ardilla traviesa // Beatrix Potter" },
  {
    data: "MM0087",
    value: "La Bella Durmiente del bosque // Gabriela Mistral",
  },
  {
    data: "MM0088",
    value: "La casa de los espejos humeantes // Albeiro Echavarría",
  },
  { data: "MM0089", value: "La Cenicienta // Gabriela Mistral" },
  { data: "MM0090", value: "La danta blanca // Rafael Rivero Oramas" },
  {
    data: "MM0091",
    value:
      "La historia de Julia, la niña que tenía sombra de niño // Christian Bruel",
  },
  {
    data: "MM0092",
    value: "La luna en los almendros // Gerardo Meneses Claros",
  },
  { data: "MM0094", value: "La niña del pelo corto // Mariangela Venutolo" },
  { data: "MM0095", value: "La niña Violeta // Francisco Jiménez" },
  {
    data: "MM0097",
    value:
      "La venganza de Nicolás Bravo y otros relatos de independencia // Gonzalo España",
  },
  {
    data: "MM0098",
    value: "Las aventuras de Benjamín Conejo // Beatrix Potter",
  },
  { data: "MM0099", value: "Las aventuras de Pedro Conejo // Beatrix Potter" },
  { data: "MM0101", value: "Leopoldo // Ink Robin" },
  { data: "MM0104", value: "Los restos del mundo // Magdalena Salazar" },
  {
    data: "MM0105",
    value: "Los secretos de Hafiz Mustafá // Francisco Leal Quevedo",
  },
  { data: "MM0106", value: "Malaika, la princesa // Lizardo Carvajal" },
  { data: "MM0107", value: "Marilú ¿quién eres tú? // Trinidad Castro" },
  { data: "MM0108", value: "Matador // Wander Piroli" },
  { data: "MM0109", value: "Melero, detective de insectos // Felipe Munita" },
  {
    data: "MM0110",
    value: "Mi abuela no es la de antes // María José Orobitg i Della",
  },
  { data: "MM0111", value: "Mi mascota // Yolanda Reyes" },
  { data: "MM0112", value: "Mil orejas // Pilar Gutiérrez Llano" },
  { data: "MM0114", value: "Mis animales // Carmen Cardemil" },
  { data: "MM0115", value: "Niños de América // Francisca Palacios" },
  { data: "MM0116", value: "Onza, Tigre y León // Pilar Almoina de Carrera" },
  { data: "MM0119", value: "Parque del Poblado // Joni B" },
  {
    data: "MM0121",
    value:
      "Patricio Pico y Pluma en la extraña desaparición del doctor Bonett // María Inés McCormick",
  },
  { data: "MM0122", value: "Pelea en el parque // Evelio Rosero Diago" },
  { data: "MM0123", value: "PJ Hormiguero // Ink Robin" },
  { data: "MM0124", value: "PJ Jirafa // Ink Robin" },
  { data: "MM0125", value: "PJ Koala // Ink Robin" },
  { data: "MM0126", value: "Popol Vuh // Ana María Pavez" },
  {
    data: "MM0127",
    value: "Por favor, ¡no leas este libro! // John Fitzgerald Torres",
  },
  { data: "MM0128", value: "Puercoespinoso // Ala Leszynska" },
  { data: "MM0129", value: "Rana // María Paula Bolaños" },
  { data: "MM0130", value: "Raquel y el fin del mundo // Mariana Gil Rios" },
  { data: "MM0131", value: "Rescate en el Jurásico // Rodrigo Cea" },
  { data: "MM0132", value: "Revoltoso // Ala Leszynska" },
  { data: "MM0133", value: "Rumbo al Planeta J // Daniel Nesquens" },
  { data: "MM0134", value: "Sencillamente está ahí // Truchafrita" },
  { data: "MM0135", value: "Somos igualitos // Juan Carlos Restrepo Rivas" },
  { data: "MM0136", value: "Sueños intactos // Julia Mercedes Castilla" },
  { data: "MM0137", value: "Supongamos // Ivar Da Coll" },
  { data: "MM0138", value: "Tío Tigre y Tío Conejo // Rafael Rivero Oramas" },
  {
    data: "MM0139",
    value: "Todo por sobrevivir // Josefina Hepp y Pamela Torres",
  },
  { data: "MM0140", value: "Un conejo de ciudad // Ink Robin" },
  { data: "MM0141", value: "Un gato de verdad // Lizardo Carvajal" },
  {
    data: "MM0142",
    value: "Una carta para Luciana // Adriana Carreño Castillo",
  },
  { data: "MM0143", value: "Vaivén // Juan Carlos Restrepo Rivas" },
  {
    data: "MM0144",
    value: "Viaje al corazón de Neruda // Marilú Ortiz de Rozas",
  },
  {
    data: "MM0145",
    value: "Vicente Huidobro, poemas ilustrados // Vicente Huidobro",
  },
  {
    data: "MM0146",
    value:
      "Vivir un terremoto // Olivier Balez, Trinidad Castro, Isidora Meier y Carlos Reyes",
  },
  {
    data: "MM0147",
    value: "Y surgió en el vuelo de las mariposas // Edna Iturralde",
  },
  {
    data: "MM0148",
    value: "Yacay en las tierras del buen viento // Luz María del Valle",
  },
  {
    data: "MM0149",
    value: "Yacay rumbo a las Llanuras Kaibas // Luz María del Valle",
  },
  { data: "MM0150", value: "Yo tenía 10 perritos // Paloma Valdivia" },
  { data: "MM0152", value: "Zú // Érika Lancheros" },
];

export const paqueteData = [
  { data: "", value: "" },
  { data: "Adriana Rivera", value: "Adriana Rivera" },
  { data: "Alianza Edu 2019", value: "Alianza Edu 2019" },
  { data: "Alianza educativa_2018", value: "Alianza educativa_2018" },
  { data: "AlianzaEducativa2020-21", value: "AlianzaEducativa2020-21" },
  { data: "AlianzaEducativa_mar2021-22", value: "AlianzaEducativa_mar2021-22" },
  { data: "American School jun 2020-1", value: "American School jun 2020-1" },
  { data: "AnaAvila", value: "AnaAvila" },
  { data: "Andrea Barrera", value: "Andrea Barrera" },
  { data: "Antonia_Araya", value: "Antonia_Araya" },
  { data: "Apoya tus tareas", value: "Apoya tus tareas" },
  { data: "AppBreteau2021", value: "AppBreteau2021" },
  { data: "Austin Library", value: "Austin Library" },
  { data: "AustinLibrary_Abri2021-22", value: "AustinLibrary_Abri2021-22" },
  { data: "Banrep", value: "Banrep" },
  { data: "BanRepDic2020-21", value: "BanRepDic2020-21" },
  { data: "Banrep_2019-2020", value: "Banrep_2019-2020" },
  { data: "Banrep_renov 2019", value: "Banrep_renov 2019" },
  {
    data: "Biblioteca Ramón Correa Mejía",
    value: "Biblioteca Ramón Correa Mejía",
  },
  { data: "Biblioteca Sopo sept20-21", value: "Biblioteca Sopo sept20-21" },
  {
    data: "BibliotecaPilotoMed_dic2020-21",
    value: "BibliotecaPilotoMed_dic2020-21",
  },
  {
    data: "BibliotecasManizales2020-2021dic",
    value: "BibliotecasManizales2020-2021dic",
  },
  { data: "Biblo Madrid", value: "Biblo Madrid" },
  { data: "Biblored", value: "Biblored" },
  { data: "Biblored_2018-9", value: "Biblored_2018-9" },
  { data: "Biblored_2019-2020", value: "Biblored_2019-2020" },
  { data: "Biblored_cajita_2020", value: "Biblored_cajita_2020" },
  { data: "Biblored_dic2020-21", value: "Biblored_dic2020-21" },
  { data: "Blanca de Oro", value: "Blanca de Oro" },
  { data: "Blanca Nieves", value: "Blanca Nieves" },
  { data: "Boston_Library_sept20-21", value: "Boston_Library_sept20-21" },
  { data: "Cajacopi-jul21-22", value: "Cajacopi-jul21-22" },
  { data: "Cajacopi_2020", value: "Cajacopi_2020" },
  { data: "Cajita Casanare", value: "Cajita Casanare" },
  { data: "Cajita Colsubsidio 2020", value: "Cajita Colsubsidio 2020" },
  {
    data: "Cajita Comfenalco Antioquia 1",
    value: "Cajita Comfenalco Antioquia 1",
  },
  {
    data: "Cajita Comfenalco Antioquia 2",
    value: "Cajita Comfenalco Antioquia 2",
  },
  { data: "Cajita Cucuta", value: "Cajita Cucuta" },
  { data: "Cajita Funza 2019", value: "Cajita Funza 2019" },
  { data: "CajitaComfaHuila_dic2020", value: "CajitaComfaHuila_dic2020" },
  { data: "Cajitas BNC 2020", value: "Cajitas BNC 2020" },
  {
    data: "CajitasFundaBNC_extra7_dic2020",
    value: "CajitasFundaBNC_extra7_dic2020",
  },
  { data: "Cajita_BNC_Fundalectura", value: "Cajita_BNC_Fundalectura" },
  { data: "Cajota Comfama", value: "Cajota Comfama" },
  { data: "CajotasBanrep2020", value: "CajotasBanrep2020" },
  { data: "Campestre", value: "Campestre" },
  { data: "Campestre -200-2021", value: "Campestre -200-2021" },
  { data: "Campestre 2019", value: "Campestre 2019" },
  { data: "Carolina de la Cruz", value: "Carolina de la Cruz" },
  { data: "Carolina De la Cruz abr", value: "Carolina De la Cruz abr" },
  { data: "Carolina de la cruz vd.", value: "Carolina de la cruz vd." },
  {
    data: "Casa de la Literatura Peru_sep 20-21",
    value: "Casa de la Literatura Peru_sep 20-21",
  },
  { data: "cataplumpaquete1", value: "cataplumpaquete1" },
  { data: "cataplumpaquete2", value: "cataplumpaquete2" },
  { data: "cataplumpaquete3", value: "cataplumpaquete3" },
  { data: "cataplumpaquete4", value: "cataplumpaquete4" },
  { data: "cataplumpaquete5", value: "cataplumpaquete5" },
  { data: "cataplumpaquete6", value: "cataplumpaquete6" },
  { data: "cataplumpaquete7", value: "cataplumpaquete7" },
  { data: "cataplumpaquete8", value: "cataplumpaquete8" },
  { data: "cataplumpaquete9", value: "cataplumpaquete9" },
  { data: "CEILAEducacionIntegral", value: "CEILAEducacionIntegral" },
  { data: "CentroCrecemos", value: "CentroCrecemos" },
  { data: "circo picadilly", value: "circo picadilly" },
  { data: "Circulo Artes Bquilla 2020", value: "Circulo Artes Bquilla 2020" },
  { data: "CirculoAbierto_marzo2021-22", value: "CirculoAbierto_marzo2021-22" },
  { data: "Club MakeMake 1", value: "Club MakeMake 1" },
  { data: "CNG Elemental", value: "CNG Elemental" },
  { data: "CNG High School", value: "CNG High School" },
  { data: "CNG Middle School", value: "CNG Middle School" },
  { data: "CNG Primary School", value: "CNG Primary School" },
  { data: "Colección Funza", value: "Colección Funza" },
  { data: "Colegio Canapro 2020", value: "Colegio Canapro 2020" },
  { data: "Colegio Canapro 2021", value: "Colegio Canapro 2021" },
  { data: "Colegio Cañaverales", value: "Colegio Cañaverales" },
  { data: "Colegio Cumbres Cuarto", value: "Colegio Cumbres Cuarto" },
  { data: "Colegio Cumbres Cuarto B", value: "Colegio Cumbres Cuarto B" },
  { data: "Colegio Cumbres Decimo", value: "Colegio Cumbres Decimo" },
  { data: "Colegio Cumbres Doce", value: "Colegio Cumbres Doce" },
  { data: "Colegio Cumbres Doce B", value: "Colegio Cumbres Doce B" },
  { data: "Colegio Cumbres Noveno", value: "Colegio Cumbres Noveno" },
  { data: "Colegio Cumbres Noveno B", value: "Colegio Cumbres Noveno B" },
  { data: "Colegio Cumbres Octavo", value: "Colegio Cumbres Octavo" },
  { data: "Colegio Cumbres Octavo B", value: "Colegio Cumbres Octavo B" },
  { data: "Colegio Cumbres Once", value: "Colegio Cumbres Once" },
  { data: "Colegio Cumbres Primero ", value: "Colegio Cumbres Primero " },
  { data: "Colegio Cumbres Primero B", value: "Colegio Cumbres Primero B" },
  { data: "Colegio Cumbres Quinto", value: "Colegio Cumbres Quinto" },
  { data: "Colegio Cumbres Quinto B", value: "Colegio Cumbres Quinto B" },
  { data: "Colegio Cumbres Segundo", value: "Colegio Cumbres Segundo" },
  { data: "Colegio Cumbres Segundo B", value: "Colegio Cumbres Segundo B" },
  { data: "Colegio Cumbres Septimo", value: "Colegio Cumbres Septimo" },
  { data: "Colegio Cumbres Septimo B", value: "Colegio Cumbres Septimo B" },
  { data: "Colegio Cumbres Sexto", value: "Colegio Cumbres Sexto" },
  { data: "Colegio Cumbres Sexto B", value: "Colegio Cumbres Sexto B" },
  { data: "Colegio Cumbres Tercero", value: "Colegio Cumbres Tercero" },
  { data: "Colegio Cumbres Tercero B", value: "Colegio Cumbres Tercero B" },
  { data: "Colegio Jefferson", value: "Colegio Jefferson" },
  { data: "Colegio Tilatá Beatriz", value: "Colegio Tilatá Beatriz" },
  { data: "Colegio Tilatá Marcela", value: "Colegio Tilatá Marcela" },
  { data: "Colegio Tilatá Tulia ", value: "Colegio Tilatá Tulia " },
  { data: "ColegioBolivarCali", value: "ColegioBolivarCali" },
  { data: "ColegioFaceMarzo21-Marzo22", value: "ColegioFaceMarzo21-Marzo22" },
  { data: "ColegioTeresiana2021-2022", value: "ColegioTeresiana2021-2022" },
  {
    data: "Colegio_Cumbres_unificado_2020-21",
    value: "Colegio_Cumbres_unificado_2020-21",
  },
  { data: "Colsubsidio", value: "Colsubsidio" },
  { data: "Colsubsidio_General_2018", value: "Colsubsidio_General_2018" },
  { data: "ColumbusSchool_regalias2020", value: "ColumbusSchool_regalias2020" },
  { data: "Comfama_2019-20", value: "Comfama_2019-20" },
  { data: "Comfama_Dic2020-2021", value: "Comfama_Dic2020-2021" },
  { data: "Comfama_renov 2018-9", value: "Comfama_renov 2018-9" },
  {
    data: "Comfamiliar Atlantico 2020-21",
    value: "Comfamiliar Atlantico 2020-21",
  },
  { data: "Comfamiliar_atlantico_2019", value: "Comfamiliar_atlantico_2019" },
  { data: "Comfe Quindio Cajita", value: "Comfe Quindio Cajita" },
  { data: "Comfe Quindio Web 2019", value: "Comfe Quindio Web 2019" },
  { data: "Comfenalco", value: "Comfenalco" },
  { data: "Comfenalco Provisional 2018", value: "Comfenalco Provisional 2018" },
  {
    data: "ComfenalcoAnt-dic2020-dic2021",
    value: "ComfenalcoAnt-dic2020-dic2021",
  },
  {
    data: "ComfenalcoCartagenafeb2021-22",
    value: "ComfenalcoCartagenafeb2021-22",
  },
  { data: "ComfenalcoQuindioJul20-21", value: "ComfenalcoQuindioJul20-21" },
  { data: "Comfenalco_Cgena_2018", value: "Comfenalco_Cgena_2018" },
  { data: "Compensar", value: "Compensar" },
  { data: "Compensar 2020-21", value: "Compensar 2020-21" },
  { data: "Compensar Julio 2019", value: "Compensar Julio 2019" },
  { data: "Compensar_nueva_2018", value: "Compensar_nueva_2018" },
  { data: "CorazonitaMede2021", value: "CorazonitaMede2021" },
  { data: "Corcumvi (Villabo)", value: "Corcumvi (Villabo)" },
  { data: "Corcumvi 2019", value: "Corcumvi 2019" },
  {
    data: "Daniela Patricia Mitchell Carrasco",
    value: "Daniela Patricia Mitchell Carrasco",
  },
  { data: "Dekalb 6th Godsil-Garcia", value: "Dekalb 6th Godsil-Garcia" },
  { data: "Dekalb 6th Lule", value: "Dekalb 6th Lule" },
  { data: "Dekalb 7th Carrasco", value: "Dekalb 7th Carrasco" },
  { data: "Dekalb 7th Valenzuela", value: "Dekalb 7th Valenzuela" },
  { data: "Dekalb 8th Torres", value: "Dekalb 8th Torres" },
  {
    data: "Dekalb Biblioteca Digital Huntley",
    value: "Dekalb Biblioteca Digital Huntley",
  },
  {
    data: "Dekalb Bilioteca Digital Clinton Rossette",
    value: "Dekalb Bilioteca Digital Clinton Rossette",
  },
  { data: "Dekalb Nov 2020-21", value: "Dekalb Nov 2020-21" },
  { data: "DeKalb regalías 2020", value: "DeKalb regalías 2020" },
  { data: "demo Javeriana", value: "demo Javeriana" },
  { data: "DemoPlanLector", value: "DemoPlanLector" },
  {
    data: "DenverPublicLibrary_Abr-Dic2021",
    value: "DenverPublicLibrary_Abr-Dic2021",
  },
  { data: "desde las aguas", value: "desde las aguas" },
  { data: "Desde los origenes", value: "Desde los origenes" },
  { data: "Diwokids", value: "Diwokids" },
  { data: "dmarcelapt", value: "dmarcelapt" },
  { data: "dmarcelapt_2020", value: "dmarcelapt_2020" },
  { data: "DSMedellin", value: "DSMedellin" },
  { data: "Edison", value: "Edison" },
  { data: "EdisonPL", value: "EdisonPL" },
  { data: "EdisonPL_1", value: "EdisonPL_1" },
  { data: "EdisonPL_2", value: "EdisonPL_2" },
  { data: "EdisonPL_3", value: "EdisonPL_3" },
  { data: "EdisonPL_KF", value: "EdisonPL_KF" },
  { data: "Elegidos Familiar", value: "Elegidos Familiar" },
  { data: "Elegidos Infantil", value: "Elegidos Infantil" },
  {
    data: "Elegidos Infantil Intermedio",
    value: "Elegidos Infantil Intermedio",
  },
  {
    data: "Elegidos Infantil Intermedio Educativo",
    value: "Elegidos Infantil Intermedio Educativo",
  },
  { data: "Elegidos Jóvenes", value: "Elegidos Jóvenes" },
];

export const blogExamples = [
  {
    title: "Historias para enamorados",
    author: "MakeMake",
    description: "Un cuento para dedicarle al amor",
    date: "2018-07-03",
    tag1: "Amistad",
    tag2: "Amor",
    tag3: "Karaoke",
    tag4: "",
    tag5: "",
    recomendado1: "MM0411",
    recomendado2: "MM0272",
    html: '<h1><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;">Hola muy buenas</span></h1><h4><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;font-family: Georgia;"><div><del><strong><em><ins>texto</ins></em></strong></del></div></span></h4><p></p><img src="https://culturadigital.cosasweb.co/wp-content/uploads/2019/11/makemake-800x540.x27417.jpg" alt="undefined" style="height: auto;width: auto"/><p></p><p></p><p></p><p></p>',
  },
  {
    title: "Un clásico de la poesía colombiana",
    author: "MakeMake",
    description: "Un cuento para dedicarle al amor",
    date: "2018-07-03",
    tag1: "Amistad",
    tag2: "Amor",
    tag3: "Karaoke",
    tag4: "",
    tag5: "",
    recomendado1: "MM0411",
    recomendado2: "MM0272",
    html: '<h1><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;">Hola muy buenas</span></h1><h4><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;font-family: Georgia;"><div><del><strong><em><ins>texto</ins></em></strong></del></div></span></h4><p></p><img src="https://culturadigital.cosasweb.co/wp-content/uploads/2019/11/makemake-800x540.x27417.jpg" alt="undefined" style="height: auto;width: auto"/><p></p><p></p><p></p><p></p>',
  },
  {
    title: "Soy el que soy ",
    author: "MakeMake",
    description: "Un cuento para dedicarle al amor",
    date: "2018-07-03",
    tag1: "Amistad",
    tag2: "Amor",
    tag3: "Karaoke",
    tag4: "",
    tag5: "",
    recomendado1: "MM0411",
    recomendado2: "MM0272",
    html: '<h1><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;">Hola muy buenas</span></h1><h4><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;font-family: Georgia;"><div><del><strong><em><ins>texto</ins></em></strong></del></div></span></h4><p></p><img src="https://culturadigital.cosasweb.co/wp-content/uploads/2019/11/makemake-800x540.x27417.jpg" alt="undefined" style="height: auto;width: auto"/><p></p><p></p><p></p><p></p>',
  },
  {
    title: "Latinoamérica a través de los libros",
    author: "MakeMake",
    description: "Un cuento para dedicarle al amor",
    date: "2018-07-03",
    tag1: "Amistad",
    tag2: "Amor",
    tag3: "Karaoke",
    tag4: "",
    tag5: "",
    recomendado1: "MM0411",
    recomendado2: "MM0272",
    html: '<h1><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;">Hola muy buenas</span></h1><h4><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;font-family: Georgia;"><div><del><strong><em><ins>texto</ins></em></strong></del></div></span></h4><p></p><img src="https://culturadigital.cosasweb.co/wp-content/uploads/2019/11/makemake-800x540.x27417.jpg" alt="undefined" style="height: auto;width: auto"/><p></p><p></p><p></p><p></p>',
  },
  {
    title: "Gabriel García Márquez",
    author: "MakeMake",
    description: "Un cuento para dedicarle al amor",
    date: "2018-07-03",
    tag1: "Amistad",
    tag2: "Amor",
    tag3: "Karaoke",
    tag4: "",
    tag5: "",
    recomendado1: "MM0411",
    recomendado2: "MM0272",
    html: '<h1><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;">Hola muy buenas</span></h1><h4><span style="color: rgb(44,130,201);background-color: rgb(243,121,52);font-size: 30px;font-family: Georgia;"><div><del><strong><em><ins>texto</ins></em></strong></del></div></span></h4><p></p><img src="https://culturadigital.cosasweb.co/wp-content/uploads/2019/11/makemake-800x540.x27417.jpg" alt="undefined" style="height: auto;width: auto"/><p></p><p></p><p></p><p></p>',
  },
];

export const testRegalias = [
  { id: "RE0001", name: "// MakeMake EEUU // 2020-12-14 // reallabsPruebaUSA" },
  { id: "RE0002", name: "// MakeMake EEUU // 2020-12-14 // reallabsPruebaUSA" },
  { id: "RE0003", name: "// Cajacopi // 2020-12-15 // GenCajacopi" },
  { id: "RE0004", name: "// BibloRed // 2020-12-15 // BibloRed" },
  { id: "RE0005", name: "// Nogales // 2020-12-20 // Nogales" },
  { id: "RE0006", name: "// Alianza Educativa // 2020-12-20 // AlianzaEAdmin" },
  {
    id: "RE0007",
    name: "// Jardin de la Amistad - Perú // 2020-12-20 // jardin_amistad_admin",
  },
  { id: "RE0008", name: "// Austin // 2020-12-20 // austin_extra_books" },
  { id: "RE0009", name: "// Banco de la República // 2020-12-20 // BanRep" },
  {
    id: "RE0010",
    name: "// Whittier Public Library - CA // 2020-12-20 // whittier_library",
  },
  {
    id: "RE0011",
    name: "// City of Inglewood Library // 2020-12-20 // inglewood_library_users",
  },
  { id: "RE0012", name: "// MakeMake EEUU // 2020-12-14 // reallabsPruebaUSA" },
  { id: "RE0013", name: "// Cajacopi // 2020-12-15 // GenCajacopi" },
  { id: "RE0014", name: "// BibloRed // 2020-12-15 // BibloRed" },
  { id: "RE0015", name: "// Nogales // 2020-12-20 // Nogales" },
  { id: "RE0016", name: "// Alianza Educativa // 2020-12-20 // AlianzaEAdmin" },
  {
    id: "RE0017",
    name: "// Jardin de la Amistad - Perú // 2020-12-20 // jardin_amistad_admin",
  },
  { id: "RE0018", name: "// Austin // 2020-12-20 // austin_extra_books" },
  { id: "RE0019", name: "// Banco de la República // 2020-12-20 // BanRep" },
  {
    id: "RE0020",
    name: "// Whittier Public Library - CA // 2020-12-20 // whittier_library",
  },
  {
    id: "RE0021",
    name: "// City of Inglewood Library // 2020-12-20 // inglewood_library_users",
  },
];

export const testColumns = [
  {
    label: "Usuario",
    field: "usernameClient",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Cliente",
    field: "nombreCliente",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Tipo de suscripción",
    field: "tipoSuscripcion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Renovación",
    field: "renovacion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Recompra dentro del periodo",
    field: "recompraDentroSuscripcion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nuevo cliente",
    field: "nuevoCliente",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Divisa",
    field: "moneda",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Número libros",
    field: "numeroLibros",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nombre del paquete",
    field: "packageName",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. Usuarios",
    field: "numeroUsuarios",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha inicio suscripción",
    field: "fechaIniSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha fin suscripción",
    field: "fechaFinSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "# Factura",
    field: "numeroFactura",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio de venta",
    field: "precioTotalVenta",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "ReteFuente",
    field: "reteFuente",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "ReteICA",
    field: "reteICA",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Descuentos Bancarios",
    field: "descuentosBancarios",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Subtotal",
    field: "subtotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Proporción de ocupación total",
    field: "proporcionDeOcupacionTotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Transmisión de datos",
    field: "costoTransmisionDatos",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Configuración colegios (SAML o Similar)",
    field: "costoConfiguracion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Hardware cajitas",
    field: "hardwareCajitas",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Talleres",
    field: "talleres",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Valor neto",
    field: "valorNeto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio ePub básico",
    field: "precioEpubBasico",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. ePub básicos",
    field: "numeroEpubBasico",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio ePub premium",
    field: "precioEpubPremium",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. ePub premium",
    field: "numeroEpubPremium",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio inter básico",
    field: "precioInterBasico",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. inter básicos",
    field: "numeroInterBasico",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio inter premium",
    field: "precioInterPremium",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. inter premium",
    field: "numeroInterPremium",
    sort: "asc",
    minimal: "lg",
  },
];

export const testRows = [
  {
    usernameClient: "Ashton Cox",
    position: "El fantástico aviario de Sir William McCrow",
    office: "JUV002040 JUVENILE FICTION / Animals / Birds",
    age: "Narrativa",
    date: "¿Serías capaz de buscar algo durante 60 años? ¿Abandonarías todo para encontrar algo que siempre anhelaste? El fantástico aviario de Sir William McCrow nos lleva de la mano en una aventura alucinante en busca del Pájaro de los Mil Cantos. Esta novela juvenil está escrita a través de las anotaciones del diario de Sir William McCrow, el ornitólogo inglés que buscó su vida entera al misterioso Pájaro de los Mil Cantos.",
    salary: "Lizardo Carvajal",
    salary2: "Integration Specialist",
  },
  {
    name: "Ashton Cox",
    position: "El fantástico aviario de Sir William McCrow",
    office: "JUV002040 JUVENILE FICTION / Animals / Birds",
    age: "Narrativa",
    date: "¿Serías capaz de buscar algo durante 60 años? ¿Abandonarías todo para encontrar algo que siempre anhelaste? El fantástico aviario de Sir William McCrow nos lleva de la mano en una aventura alucinante en busca del Pájaro de los Mil Cantos. Esta novela juvenil está escrita a través de las anotaciones del diario de Sir William McCrow, el ornitólogo inglés que buscó su vida entera al misterioso Pájaro de los Mil Cantos.",
    salary: "Lizardo Carvajal",
    salary2: "Integration Specialist",
  },
  {
    name: "Ashton Cox",
    position: "Junior Technical Author",
    office: "San Francisco",
    age: "66",
    date: "2009/01/12",
    salary: "$86",
    salary2: "Integration Specialist",
  },
  {
    name: "Ashton Cox",
    position: "Senior Javascript Developer",
    office: "Edinburgh",
    age: "22",
    date: "2012/03/29",
    salary: "$433",
    salary2: "Integration Specialist",
  },
  {
    name: "Airi Satou",
    position: "Accountant",
    office: "Tokyo",
    age: "33",
    date: "2008/11/28",
    salary: "$162",
    salary2: "Integration Specialist",
  },
  {
    name: "Brielle Williamson",
    position: "Integration Specialist",
    office: "New York",
    age: "61",
    date: "2012/12/02",
    salary: "$372",
  },
  {
    name: "Herrod Chandler",
    position: "Sales Assistant",
    office: "San Francisco",
    age: "59",
    date: "2012/08/06",
    salary: "$137",
  },
  {
    name: "Rhona Davidson",
    position: "Integration Specialist",
    office: "Tokyo",
    age: "55",
    date: "2010/10/14",
    salary: "$327",
  },
  {
    name: "Colleen Hurst",
    position: "Javascript Developer",
    office: "San Francisco",
    age: "39",
    date: "2009/09/15",
    salary: "$205",
  },
  {
    name: "Sonya Frost",
    position: "Software Engineer",
    office: "Edinburgh",
    age: "23",
    date: "2008/12/13",
    salary: "$103",
  },
  {
    name: "Jena Gaines",
    position: "Office Manager",
    office: "London",
    age: "30",
    date: "2008/12/19",
    salary: "$90",
  },
  {
    name: "Quinn Flynn",
    position: "Support Lead",
    office: "Edinburgh",
    age: "22",
    date: "2013/03/03",
    salary: "$342",
  },
  {
    name: "Charde Marshall",
    position: "Regional Director",
    office: "San Francisco",
    age: "36",
    date: "2008/10/16",
    salary: "$470",
  },
  {
    name: "Haley Kennedy",
    position: "Senior Marketing Designer",
    office: "London",
    age: "43",
    date: "2012/12/18",
    salary: "$313",
  },
  {
    name: "Tatyana Fitzpatrick",
    position: "Regional Director",
    office: "London",
    age: "19",
    date: "2010/03/17",
    salary: "$385",
  },
  {
    name: "Michael Silva",
    position: "Marketing Designer",
    office: "London",
    age: "66",
    date: "2012/11/27",
    salary: "$198",
  },
  {
    name: "Paul Byrd",
    position: "Chief Financial Officer (CFO)",
    office: "New York",
    age: "64",
    date: "2010/06/09",
    salary: "$725",
  },
  {
    name: "Gloria Little",
    position: "Systems Administrator",
    office: "New York",
    age: "59",
    date: "2009/04/10",
    salary: "$237",
  },
  {
    name: "Bradley Greer",
    position: "Software Engineer",
    office: "London",
    age: "41",
    date: "2012/10/13",
    salary: "$132",
  },
  {
    name: "Dai Rios",
    position: "Personnel Lead",
    office: "Edinburgh",
    age: "35",
    date: "2012/09/26",
    salary: "$217",
  },
  {
    name: "Jenette Caldwell",
    position: "Development Lead",
    office: "New York",
    age: "30",
    date: "2011/09/03",
    salary: "$345",
  },
  {
    name: "Yuri Berry",
    position: "Chief Marketing Officer (CMO)",
    office: "New York",
    age: "40",
    date: "2009/06/25",
    salary: "$675",
  },
  {
    name: "Caesar Vance",
    position: "Pre-Sales Support",
    office: "New York",
    age: "21",
    date: "2011/12/12",
    salary: "$106",
  },
  {
    name: "Doris Wilder",
    position: "Sales Assistant",
    office: "Sidney",
    age: "23",
    date: "2010/09/20",
    salary: "$85",
  },
  {
    name: "Angelica Ramos",
    position: "Chief Executive Officer (CEO)",
    office: "London",
    age: "47",
    date: "2009/10/09",
    salary: "$1",
  },
  {
    name: "Gavin Joyce",
    position: "Developer",
    office: "Edinburgh",
    age: "42",
    date: "2010/12/22",
    salary: "$92",
  },
  {
    name: "Jennifer Chang",
    position: "Regional Director",
    office: "Singapore",
    age: "28",
    date: "2010/11/14",
    salary: "$357",
  },
  {
    name: "Brenden Wagner",
    position: "Software Engineer",
    office: "San Francisco",
    age: "28",
    date: "2011/06/07",
    salary: "$206",
  },
  {
    name: "Fiona Green",
    position: "Chief Operating Officer (COO)",
    office: "San Francisco",
    age: "48",
    date: "2010/03/11",
    salary: "$850",
  },
  {
    name: "Shou Itou",
    position: "Regional Marketing",
    office: "Tokyo",
    age: "20",
    date: "2011/08/14",
    salary: "$163",
  },
  {
    name: "Michelle House",
    position: "Integration Specialist",
    office: "Sidney",
    age: "37",
    date: "2011/06/02",
    salary: "$95",
  },
  {
    name: "Suki Burks",
    position: "Developer",
    office: "London",
    age: "53",
    date: "2009/10/22",
    salary: "$114",
  },
  {
    name: "Prescott Bartlett",
    position: "Technical Author",
    office: "London",
    age: "27",
    date: "2011/05/07",
    salary: "$145",
  },
  {
    name: "Gavin Cortez",
    position: "Team Leader",
    office: "San Francisco",
    age: "22",
    date: "2008/10/26",
    salary: "$235",
  },
  {
    name: "Martena Mccray",
    position: "Post-Sales support",
    office: "Edinburgh",
    age: "46",
    date: "2011/03/09",
    salary: "$324",
  },
  {
    name: "Unity Butler",
    position: "Marketing Designer",
    office: "San Francisco",
    age: "47",
    date: "2009/12/09",
    salary: "$85",
  },
  {
    name: "Howard Hatfield",
    position: "Office Manager",
    office: "San Francisco",
    age: "51",
    date: "2008/12/16",
    salary: "$164",
  },
  {
    name: "Hope Fuentes",
    position: "Secretary",
    office: "San Francisco",
    age: "41",
    date: "2010/02/12",
    salary: "$109",
  },
  {
    name: "Vivian Harrell",
    position: "Financial Controller",
    office: "San Francisco",
    age: "62",
    date: "2009/02/14",
    salary: "$452",
  },
  {
    name: "Timothy Mooney",
    position: "Office Manager",
    office: "London",
    age: "37",
    date: "2008/12/11",
    salary: "$136",
  },
  {
    name: "Jackson Bradshaw",
    position: "Director",
    office: "New York",
    age: "65",
    date: "2008/09/26",
    salary: "$645",
  },
  {
    name: "Olivia Liang",
    position: "Support Engineer",
    office: "Singapore",
    age: "64",
    date: "2011/02/03",
    salary: "$234",
  },
  {
    name: "Bruno Nash",
    position: "Software Engineer",
    office: "London",
    age: "38",
    date: "2011/05/03",
    salary: "$163",
  },
  {
    name: "Sakura Yamamoto",
    position: "Support Engineer",
    office: "Tokyo",
    age: "37",
    date: "2009/08/19",
    salary: "$139",
  },
  {
    name: "Thor Walton",
    position: "Developer",
    office: "New York",
    age: "61",
    date: "2013/08/11",
    salary: "$98",
  },
  {
    name: "Finn Camacho",
    position: "Support Engineer",
    office: "San Francisco",
    age: "47",
    date: "2009/07/07",
    salary: "$87",
  },
  {
    name: "Serge Baldwin",
    position: "Data Coordinator",
    office: "Singapore",
    age: "64",
    date: "2012/04/09",
    salary: "$138",
  },
  {
    name: "Zenaida Frank",
    position: "Software Engineer",
    office: "New York",
    age: "63",
    date: "2010/01/04",
    salary: "$125",
  },
  {
    name: "Zorita Serrano",
    position: "Software Engineer",
    office: "San Francisco",
    age: "56",
    date: "2012/06/01",
    salary: "$115",
  },
  {
    name: "Jennifer Acosta",
    position: "Junior Javascript Developer",
    office: "Edinburgh",
    age: "43",
    date: "2013/02/01",
    salary: "$75",
  },
  {
    name: "Cara Stevens",
    position: "Sales Assistant",
    office: "New York",
    age: "46",
    date: "2011/12/06",
    salary: "$145",
  },
  {
    name: "Hermione Butler",
    position: "Regional Director",
    office: "London",
    age: "47",
    date: "2011/03/21",
    salary: "$356",
  },
  {
    name: "Lael Greer",
    position: "Systems Administrator",
    office: "London",
    age: "21",
    date: "2009/02/27",
    salary: "$103",
  },
  {
    name: "Jonas Alexander",
    position: "Developer",
    office: "San Francisco",
    age: "30",
    date: "2010/07/14",
    salary: "$86",
  },
  {
    name: "Shad Decker",
    position: "Regional Director",
    office: "Edinburgh",
    age: "51",
    date: "2008/11/13",
    salary: "$183",
  },
  {
    name: "Michael Bruce",
    position: "Javascript Developer",
    office: "Singapore",
    age: "29",
    date: "2011/06/27",
    salary: "$183",
  },
  {
    name: "Donna Snider",
    position: "Customer Support",
    office: "New York",
    age: "27",
    date: "2011/01/25",
    salary: "$112",
  },
];
