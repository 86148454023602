import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EntradaForm from "./entrada-form";

import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";

//Datos de prueba
import { GENERAL_URL_API } from "../../../../shared/urls";
import { MenuBookRounded } from "@material-ui/icons";

const EditarClienteExistente = () => {
  //Definimos un estado para guardar la informacion del autocomplete
  const [blog, setBlog] = useState(null);

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = (newValue) => {
    fetchBlog(newValue?.idBlogEntries);
  };

  const fetchBlogs = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/blog/verBlogs.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setBlogs(data.data);
  };

  const fetchBlog = async (id) => {
    const fd = new FormData();
     
    fd.append("idBlog", id);
    const res = await fetch(`${GENERAL_URL_API}/blog/verBlog.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setBlog({ ...data.data, html: data.data.content });
  };

  //Funcion para editar el automcomplete
  const clearSelection = () => {
    setBlog(null);
  };

  /*
      Esta pagina cambia dependiendo si un usuario ha seleccionado un elemento del autocomplete
      si el valor del blog es null se retorna el autocomplete
      si ese valor no es null se retorna un componente el cual permite editar el blog seleccionado
    */
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <ForumOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Editar una entrada en el blog</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            {!blog ? (
              <>
                <div className="d-flex align-items-center">
                  <MenuBookRounded />
                  <div className="admin-main-title-dark-gray ml-2 mt-1">
                    BUSCAR Y EDITAR UNA ENTRADA DE BLOG
                  </div>
                </div>
                <label className="admin-label-text mt-3 d-block">Buscar:</label>
                <Autocomplete
                  options={blogs}
                  getOptionLabel={(option) => option.title}
                  value={blog}
                  onChange={(e, value) => autoCompleteUpdate(value)}
                  noOptionsText="Sin resultados"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  )}
                />
              </>
            ) : (
              <EntradaForm
                clearSelection={clearSelection}
                selectedBlog={blog}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditarClienteExistente;
