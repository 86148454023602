import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { GENERAL_URL_API } from "../../../../shared/urls";
import { TextField } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
import AgregarEstudianteIndividualForm from "../../adicionar-elementos/agregar-estudiante-pl/agregar-estudiante-individual-form";

const EditarEstudiantePL = () => {
  const [estudiantes, setEstudiantes] = useState([]);
  const [estudiante, setEstudiante] = useState("");

  useEffect(() => {
    fetchEstudiantes();
  }, []);

  const fetchEstudiantes = async () => {
    setEstudiantes([]);
    const fd = new FormData();
     

    const res = await fetch(
      `${GENERAL_URL_API}/estudiantes/verEstudiantes.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const data = await res.json();
    setEstudiantes(data.data);

    if (!data.status)
      await swal({
        title: "Error.",
        text: data.info,
        icon: "error",
      });
  };

  const clearEstudiante = async () => {
    await fetchEstudiantes();
    setEstudiante("");
  };

  return (
    <>
      <div>
        <div className="mb-4 d-flex align-items-center">
          <div className="admin-page-tilte">(</div>
          <CreateOutlinedIcon />
          <div className="admin-page-tilte mr-2">)</div>
          <div className="admin-page-tilte">Editar un estudiante</div>
        </div>
        <Container fluid>
          <Row>
            <Col xs={12} className="content-white-box px-4 py-4">
              <div className="d-flex align-items-center">
                <PersonIcon style={{ fontSize: "20px" }} />
                <span className="admin-main-title-dark-gray ml-2 mt-1">
                  BUSCAR Y EDITAR UNA ESTUDIANTE EXISTENTE
                </span>
              </div>
              {!estudiantes.length ? (
                <>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </>
              ) : !estudiante ? (
                <>
                  <label className="admin-label-text mt-3 d-block">
                    Selecciona un estudiante para editar:
                  </label>
                  <Autocomplete
                    options={estudiantes}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.nombreInstituto} - ${option.nombre}`
                        : ""
                    }
                    value={estudiante}
                    onChange={(e, newValue) => {
                      setEstudiante(newValue);
                    }}
                    noOptionsText="Sin resultados"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        style={{
                          width: "100%",
                          fontFamily: "'Noto Sans JP', sans-serif",
                        }}
                      />
                    )}
                  />
                </>
              ) : (
                <AgregarEstudianteIndividualForm
                  estudiante={estudiante}
                  clearEstudiante={clearEstudiante}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EditarEstudiantePL;
