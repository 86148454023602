import { GENERAL_URL_API } from "../urls";

export const fetchProfesores = async () => {
  const fd = new FormData();
   
   

  const res = await fetch(`${GENERAL_URL_API}/profesores/verProfesores.php`, {
    method: "POST", credentials: 'include',
    body: fd,
  });
  return res.json();
};
