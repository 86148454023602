import axios from "axios";
import { GENERAL_URL_API } from "../../urls";

/**
 * Crea un nuevo usuario en la academia mediante una llamada a la API.
 * @param {object} user - Objeto que contiene los datos del usuario a crear.
 * @param {string} user.username - Nombre de usuario del nuevo usuario.
 * @param {string} user.password - Contraseña del nuevo usuario.
 * @param {string} user.email - Correo electrónico del nuevo usuario.
 * @param {string} user.nombre - Nombre completo del nuevo usuario.
 * @param {string} user.celular - Número de celular del nuevo usuario.
 * @param {{inicio: string, fin: string}} user.fechas - Fechas de inicio y fin de la suscripción del nuevo usuario.
 * @returns {Promise<any>} - Una promesa que se resuelve en los datos del nuevo usuario.
 */
export const crearUsuarioAcademia = async (user) => {
  const { username, password, email, nombre, celular, fechas } = user;

  const fd = new FormData();
  fd.append("createClientAcademia", 1);
  fd.append("usernameAcademia", username);
  fd.append("password", password);
  fd.append("email", email);
  fd.append("nombreCompleto", nombre);
  fd.append("celular", celular);
  fd.append("fechaIniSub", fechas.inicio);
  fd.append("fechaFinSub", fechas.fin);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/academia/crearUsuario`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const crearUsuariosAcademia = async (file) => {
  const fd = new FormData();

  fd.append("users", file);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/academia/crearUsuarioMasivo`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};
