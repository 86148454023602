import { GENERAL_URL_API } from "../../../shared/urls";
export const crearCorte = async (data) => {
  const factura = getFactura(data);
  const fd = new FormData();
   
   
  fd.append("factura", JSON.stringify(factura));

  let url = `${GENERAL_URL_API}/regalias/generarCorte.php`;
  let res;
  let resJSON;
  try {
    res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    resJSON = await res.json();
  } catch (e) {
    console.log(e);
  }
  console.log("res", resJSON);
  return res;
};

const getFactura = (data) => {
  return {
    clienteUsername: data.user.username,
    nombreCliente: data.clientName.value,
    tipoSuscripcion: data.suscriptionType,
    renovacion: data.renewal.value,
    recompraDentroSuscripcion: data.repurchase.value,
    nuevoCliente: data.newClient.value,
    moneda: data.currency.value,
    numeroLibros: data.totalBooks.value,
    packageName: data.packet,
    numeroUsuarios: data.numConcurrences.value,
    fechaIniSub: data.subscriptionDateInit.value,
    fechaFinSub: data.subscriptionDateEnd.value,
    numeroFactura: data.invoiceNumber.value,
    fechaFactura: data.invoiceDate.value,
    precioTotalVenta: data.sellPrice.value,
    reteFuente: data.reteFuente.value,
    reteICA: data.reteIca.value,
    descuentosBancarios: data.bankDiscounts.value,
    subtotal: data.subtotal.value,
    proporcionDeOcupacionTotal: data.SOR.value,
    costoTransmisionDatos: data.DTC.value,
    costoConfiguracion: data.configurationCost.value,
    hardwareCajitas: data.cajitasHardware.value,
    talleres: data.atelier.value,
    valorNeto: data.netWorth.value,
    precioEpubBasico: data.priceEpubBasic.value,
    numeroEpubBasico: data.totalEpubBasicBooks.value,
    precioEpubPremium: data.priceEpubPremium.value,
    numeroEpubPremium: data.totalEpubPremiumBooks.value,
    precioInterBasico: data.priceInterBasic.value,
    numeroInterBasico: data.totalInterBasicBooks.value,
    precioInterPremium: data.priceInterPremium.value,
    numeroInterPremium: data.totalInterPremiumBooks.value,
  };
};
