import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import AgregarNuevaSedeForm from "./agregar-nueva-sede-form";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";

const AgregarNuevaSede = () => {
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Agregar una nueva sede lectora</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "17px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                CREAR UNA SEDE NUEVO
              </div>
            </div>
            <div className="admin-muted-text-small mt-2">
              Por favor ingresar la siguiente info:
            </div>
            <AgregarNuevaSedeForm />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarNuevaSede;
