import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import EditarLibroForm from "./editar-libro-form";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";

const editarLibro = () => {
  return (
    <div>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Editar un libro</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "20px" }} />
              <span className="admin-main-title-dark-gray ml-2 mt-1">
                BUSCAR Y EDITAR UN LIBRO EXISTENTE
              </span>
            </div>
            <EditarLibroForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default editarLibro;
