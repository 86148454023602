export const listaInstitutosC = [
  {
    label: "Id Sede",
    field: "idSuperinstitutoPlanLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nombre",
    field: "nombre",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Sedes",
    field: "sedes",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Estudiantes por sede",
    field: "maxEstudiantes",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Correo",
    field: "correo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Tipo de ingreso",
    field: "tipoIngreso",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Tipo de servicio",
    field: "tipoServicio",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Link acceso App",
    field: "linkAccesoApp",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Link acceso Web",
    field: "linkAccesoWeb",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "LinkTutorial",
    field: "linkTutorial",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha de inicio Sub",
    field: "fechaIniSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha de fin Sub",
    field: "fechaFinSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Dirección",
    field: "direccion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Teléfono",
    field: "telefono",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Vendedor",
    field: "vendedor",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Estado Activación",
    field: "estadoActivacion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Estado de subscripción",
    field: "estadoSubscripcion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Ingreso App",
    field: "ingresoApp",
    sort: "asc",
    minimal: "lg",
  },
];

export const listaSedesC2 = [
  {
    label: "Instituto",
    field: "nombre",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Teléfono",
    field: "telefono",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Correo",
    field: "correo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha de inicio Sub",
    field: "fechaIniSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha de fin Sub",
    field: "fechaFinSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Número de profesores",
    field: "numProfesores",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "País",
    field: "pais",
    sort: "asc",
    minimal: "lg",
  },
];

export const HabilitarPaqueteCursoC = [
  {
    label: "Usuario del cliente",
    field: "Cliente",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Paquete",
    field: "Paquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Max Libros",
    field: "maxLibros",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const HabilitarPaqueteInstitutoC = [
  {
    label: "Instituto",
    field: "superinstituto",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Paquete",
    field: "nombrePaquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Max Libros",
    field: "maxLibros",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const HabilitarPaqueteSedeC = [
  {
    label: "Sede",
    field: "instituto",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Paquete",
    field: "Paquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Max Libros",
    field: "maxLibros",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const listaSedesC = [
  {
    label: "Id Sede",
    field: "idInstitutoPlanLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nombre",
    field: "instituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Instituto",
    field: "superInstituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Estudiantes",
    field: "estudiantes",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Máximo de estudiantes",
    field: "maxEstudiantesInstituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Correo",
    field: "correo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Teléfono",
    field: "telefono",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Dirección",
    field: "direccionSede",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Ciudad",
    field: "ciudadSede",
    sort: "asc",
    minimal: "lg",
  },
];

export const listaCursosC = [
  {
    label: "Id Curso",
    field: "idCursoPlanLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Curso",
    field: "curso",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Instituto",
    field: "instituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Cantidad estudiantes",
    field: "estudiantes",
    sort: "asc",
    minimal: "lg",
  },
];

export const listaProfesoresC = [
  {
    label: "Username",
    field: "username",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nombre Completo",
    field: "nombreCompleto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Instituto",
    field: "institutoPlanLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Correo",
    field: "email",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Rector",
    field: "rector",
    sort: "asc",
    minimal: "lg",
  },
];

export const listaEstudiantesC = [
  {
    label: "Id Estudiante",
    field: "idEstudiantePlanLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nombre Completo",
    field: "nombre",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Código Estudiante",
    field: "codigo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Sede",
    field: "nombreInstituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Id Curso",
    field: "idCursoPlanLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nombre Curso",
    field: "curso",
    sort: "asc",
    minimal: "lg",
  },
];

export const reporteLecturasC = [
  {
    label: "Sede",
    field: "Instituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha Ingreso",
    field: "Fecha_Ingreso",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "idLibro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Título",
    field: "Titulo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Autor",
    field: "Autor",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Editorial",
    field: "Editorial",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Genero",
    field: "Genero",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nivel",
    field: "Nivel",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Isbn",
    field: "Isbn",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Idioma",
    field: "Idioma",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Formato",
    field: "Formato",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Codigo",
    field: "Codigo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Codigo Colegio",
    field: "CodigoColegio",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Tiempo de lectura",
    field: "Tiempo_Sesion",
    sort: "asc",
    minimal: "lg",
  },
];

export const reporteAccesoPL = [
  {
    label: "Instituto",
    field: "instituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Sede",
    field: "sede",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha Ingreso",
    field: "sessionDate",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Duracion Sesion",
    field: "sessionDuration",
    sort: "asc",
    minimal: "lg",
  },
];

export const reporteAccesosEstudiantesC = [
  {
    label: "Fecha de registro",
    field: "fechaRegistro",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Usuario",
    field: "usuario",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Duración de la sesión en segundos",
    field: "sessionDuration",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Instituto",
    field: "instituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "País",
    field: "pais",
    sort: "asc",
    minimal: "lg",
  },
];
