import React, { useState, useEffect } from "react";
// Components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
import { GENERAL_URL_API } from "../../../../shared/urls";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const AgregarEliminarForm = () => {
  const [loading, setLoading] = useState(false);

  const [packets, setPackets] = useState([]);
  const [packet, setPacket] = useState({
    value: null,
    error: false,
    required: true,
  });

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({
    value: null,
    error: false,
    required: true,
  });

  useEffect(() => {
    fetchPackets();
    fetchClients();
  }, []);

  const fetchPackets = async () => {
    const fd = new FormData();
     
    fd.append("estado", "autorizado");

    const res = await fetch(`${GENERAL_URL_API}/paquetes/verPaquetes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo clientes.",
        text: data.info,
        icon: "error",
      });
    setPackets(data.data);
  };

  const fetchClients = async () => {
    const fd = new FormData();
     
    fd.append("show", "active");
    fd.append("tipoUsuario", "Todos");

    const res = await fetch(`${GENERAL_URL_API}/verDatos/verClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    console.log(data.data.filter((el) => el.username === "BanRep"));
    if (data.status !== 1)
      await swal({
        title: "Error obteniendo clientes.",
        text: data.info,
        icon: "error",
      });
    setClients(data.data);
  };

  // action:  "asociar"|"disociar"
  const sendReq = async (action) => {
    setLoading(true);
    const fd = new FormData();
     
    fd.append("nombrePaquete", packet.value);
    fd.append("cliente", client.value.username);
    fd.append("accion", action);

    const url = `${GENERAL_URL_API}/paquetes/asociarPaqueteCliente.php`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const resJSON = await res.json();
    if (resJSON.status === 0)
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    else
      await swal({
        title: `Paquete asociado/disociado`,
        text: resJSON.info,
        icon: "success",
      });
    setLoading(false);
  };

  const changePacket = (value) =>
    setPacket({
      ...packet,
      value,
    });

  const changeCliente = (value) =>
    setClient({
      ...client,
      value,
    });

  const clearForm = () => {
    setPacket({
      ...packet,
      value: null,
      error: false,
    });
    setClient({
      ...client,
      value: null,
      error: false,
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (!packet.value) {
      setPacket({
        ...packet,
        error: true,
      });
      isValid = false;
    }

    if (!client.value) {
      setClient({
        ...client,
        error: true,
      });
      isValid = false;
    }

    return isValid;
  };

  // route es la ruta a la que debe hacer la req ej: /paquetes/eliminarLibroPaquete.php
  const handleSubmit = async (e, route) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      await sendReq(route);
      // clearForm();
    }
  };

  return (
    <>
      {!packets.length || !clients.length ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <form>
          <label className="admin-label-text mt-3 d-block">
            Agregar o eliminar del paquete:{" "}
          </label>
          <Autocomplete
            options={packets}
            getOptionLabel={(option) => option}
            value={packet.value}
            onChange={(e, value) => changePacket(value)}
            noOptionsText="Sin resultados"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                error={packet.error}
                helperText={packet.error ? "Campo no válido" : null}
              />
            )}
          />

          <label className="admin-label-text mt-3 d-block">El cliente:</label>
          <Autocomplete
            options={clients}
            getOptionLabel={(option) => option.username}
            value={client.value}
            onChange={(e, value) => changeCliente(value)}
            noOptionsText="Sin resultados"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                style={{
                  width: "100%",
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                error={client.error}
                helperText={client.error ? "Campo no válido" : null}
              />
            )}
          />
          <div className="mt-3">
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e, "asociar")}
              startIcon={<CheckRounded />}
              disabled={loading}
              style={{ background: "#81c868" }}
            >
              Agregar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e, "disociar")}
              className="ml-2"
              startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
              disabled={loading}
              style={{ background: "#f05050" }}
            >
              Eliminar
            </Button>
          </div>
          <div className="mt-3 admin-simple-text ">
            Nota: Asignar un paquete a un cliente generará regalías asociadas a
            cada libro en el paquete.
          </div>
        </form>
      )}
    </>
  );
};

export default AgregarEliminarForm;
