import React from "react";
// Components
import { useQuery } from "@tanstack/react-query";
import TvIcon from "@material-ui/icons/Tv";
// Fetchers
import { fetchFilasHome } from "../../../../shared/fetchers/filasHome";
import { fetchLibros } from "../../../../shared/fetchers/libros";
// Subsection
import AgregarFilasHome from "./agregarFila";
import EditarFilasHome from "./editarFila";
import LibrosFila from "./librosFila";
import ListaFilasHome from "./listaFilas";

const FilasHome = () => {
  const {
    data: filasHome,
    isLoading: loadingFilas,
    isError,
  } = useQuery(["filasHome"], fetchFilasHome);

  const {
    data: libros,
    isLoading: loadingLibros,
    isError: errorLibros,
  } = useQuery(["libros"], fetchLibros);

  if (loadingFilas || loadingLibros) return <div>Loading...</div>;

  if (isError || errorLibros) return <div>Error...</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Filas Home</div>
      </div>

      <AgregarFilasHome />

      <EditarFilasHome filasHome={filasHome} />

      <LibrosFila filasHome={filasHome} libros={libros} />

      <ListaFilasHome filasHome={filasHome} />
    </>
  );
};

export default FilasHome;
