import { useState } from "react";
// components
import TextField from "@material-ui/core/TextField";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { DropzoneArea } from "material-ui-dropzone";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
// icons
import { CheckRounded } from "@material-ui/icons";
// data
import { GENERAL_URL_API } from "../../../../shared/urls";
// css
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TinyEditorComponent from "../../tinyMCEEditor";

//Componente que permite editar un blog
const EntradaForm = (props) => {
  const [loading, setLoading] = useState(false);

  //Definimos los estaos para almacenar los datos del formulario y sus errores
  //La informacion por defecto es la del blog seleccionado
  const [formData, setFormData] = useState({
    titulo: { value: props.selectedBlog.title, error: false },
    autor: { value: props.selectedBlog.author, error: false },
    descripcion: { value: props.selectedBlog.description, error: false },
    tag1: { value: props.selectedBlog.tag1, error: false },
    tag2: { value: props.selectedBlog.tag2, error: false },
    tag3: { value: props.selectedBlog.tag3, error: false },
    tag4: { value: props.selectedBlog.tag4, error: false },
    tag5: { value: props.selectedBlog.tag5, error: false },
    libro1: { value: props.selectedBlog.recomendado1, error: false },
    libro2: { value: props.selectedBlog.recomendado2, error: false },
  });

  //Estado para guardar lo que hay en el editor de texto
  const [editorState, setEditorState] = useState(props.selectedBlog.html);

  //Estado para guardar todo lo relacionado con las imagenes
  const [imageState, setImageState] = useState({
    thumb: { images: [], error: false },
    cover: { images: [], error: false },
  });

  const handleDropFileChange = (images, type) => {
    setImageState({
      ...imageState,
      [type]: {
        ...imageState[type],
        images,
      },
    });
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: { value: value, error: false } });
  };

  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { value: "", error: false };
    }
    setFormData(temp);
    setImageState({
      ...imageState,
      thumb: {
        ...imageState.thumb,
        error: false,
      },
      cover: {
        ...imageState.cover,
        error: false,
      },
    });
  };

  const validateForm = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };
    //Recorremos cada valor del formulario y en el caso de que sea '' activamos su error
    for (const formElement in temp) {
      //No todos los tags son obligatorios
      if (
        formElement !== "tag2" &&
        formElement !== "tag3" &&
        formElement !== "tag4" &&
        formElement !== "tag5"
      ) {
        if (temp[formElement].value === "") {
          temp[formElement].error = true;
          isValid = false;
        }
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(temp);

    // No se validan imagenes por petición de Mákina

    return isValid;
  };

  //Funcion para validar el formulario y subirlo
  const SubmitForm = async () => {
    setLoading(true);
    if (validateForm()) {
      try {
        await editBlog();
      } catch (error) {
        console.log(error);
        await swal({
          title: "Error.",
          text: error.message,
          icon: "error",
        });
        setLoading(false);
        clearForm();
        props.clearSelection();
      }
      setLoading(false);
      clearForm();
      props.clearSelection();
    }
    setLoading(false);
  };

  const editBlog = async () => {
    const fd = new FormData();
     
    fd.append("idBlog", props.selectedBlog.idBlogEntries);
    fd.append("contenidoBlog", editorState);
    fd.append("tituloBlog", formData.titulo.value);
    fd.append("autorBlog", formData.autor.value);
    fd.append("descBlog", formData.descripcion.value);
    fd.append("tag1", formData.tag1.value);
    fd.append("tag2", formData.tag2.value);
    fd.append("tag3", formData.tag3.value);
    fd.append("tag4", formData.tag4.value);
    fd.append("tag5", formData.tag5.value);
    fd.append("recom1", formData.libro1.value);
    fd.append("recom2", formData.libro2.value);
    if (imageState.thumb.images.length) {
      console.log("inThumb");
      fd.append("thumbBlog", imageState.thumb.images[0]);
    }
    if (imageState.cover.images.length) {
      console.log("inCover");
      fd.append("coverBlog", imageState.cover.images[0]);
    }
    console.log(imageState);

    const res = await fetch(`${GENERAL_URL_API}/blog/editarEntrada.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (data.status !== 1)
      await swal({
        title: "Error.",
        text: data.info,
        icon: "error",
      });
    else
      await swal({
        title: "La entrada ha sido editada.",
        text: data.info,
        icon: "success",
      });
  };

  return (
    <>
      <CustomGreenButton className="mb-4 mr-3" onClick={props.clearSelection}>
        Seleccionar otro blog
      </CustomGreenButton>
      <form>
        <TinyEditorComponent
          value={editorState}
          onChange={(nv) => {
            setEditorState(nv);
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Título del post*
        </label>
        <TextField
          name="titulo"
          value={formData.titulo.value}
          onChange={updateForm}
          error={formData.titulo.error}
          helperText={
            formData.titulo.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Título"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">Autor*</label>
        <TextField
          name="autor"
          value={formData.autor.value}
          onChange={updateForm}
          error={formData.autor.error}
          helperText={
            formData.autor.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Autor"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">
          Descripción corta*
        </label>
        <TextField
          name="descripcion"
          value={formData.descripcion.value}
          onChange={updateForm}
          error={formData.descripcion.error}
          helperText={
            formData.descripcion.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Descripción corta"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">
          Tag 1* (solo 1 es requerido)
        </label>
        <TextField
          name="tag1"
          value={formData.tag1.value}
          onChange={updateForm}
          error={formData.tag1.error}
          helperText={
            formData.tag1.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">Tag 2</label>
        <TextField
          name="tag2"
          value={formData.tag2.value}
          onChange={updateForm}
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">Tag 3</label>
        <TextField
          name="tag3"
          value={formData.tag3.value}
          onChange={updateForm}
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">Tag 4</label>
        <TextField
          name="tag4"
          value={formData.tag4.value}
          onChange={updateForm}
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">Tag 5</label>
        <TextField
          name="tag5"
          value={formData.tag5.value}
          onChange={updateForm}
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">
          Libro recomendado 1*
        </label>
        <TextField
          name="libro1"
          value={formData.libro1.value}
          onChange={updateForm}
          error={formData.libro1.error}
          helperText={
            formData.libro1.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="MM0001"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">
          Libro recomendado 2*
        </label>
        <TextField
          name="libro2"
          value={formData.libro2.value}
          onChange={updateForm}
          error={formData.libro2.error}
          helperText={
            formData.libro2.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="MM0002"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />
        <label className="admin-label-text mt-3 d-block">
          Archivos de arte*
        </label>
        <div
          className="admin-muted-text-small"
          style={imageState.thumb.error ? { color: "red" } : null}
        >
          Miniatura (Thumb)
        </div>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          // maxFileSize={3000000} //n in bytes
          showAlerts={true}
          filesLimit={1}
          acceptedFiles={["image/*"]}
          onChange={(files) => handleDropFileChange(files, "thumb")}
        />

        <div
          className="admin-muted-text-small"
          style={imageState.cover.error ? { color: "red" } : null}
        >
          Cover
        </div>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          // maxFileSize={3000000} //n in bytes
          showAlerts={true}
          filesLimit={1}
          acceptedFiles={["image/*"]}
          onChange={(files) => handleDropFileChange(files, "cover")}
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            onClick={SubmitForm}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
            disabled={loading}
          >
            Editar
          </Button>
        </div>
      </form>
    </>
  );
};

export default EntradaForm;
