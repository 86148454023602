import React from "react";
// Components
import { useQuery } from "@tanstack/react-query";
import TvIcon from "@material-ui/icons/Tv";
// Fetchers
import { fetchLibros } from "../../../../shared/fetchers/libros";
// Sections
import PaquetesLibroTable from "./paquetesLibroTable";

const FilasHome = () => {
  const {
    data: libros,
    isLoading: loadingLibros,
    isError: errorLibros,
  } = useQuery(["libros"], fetchLibros);

  if (loadingLibros) return <div>Loading...</div>;

  if (errorLibros) return <div>Error...</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Paquetes relacionados a un libro</div>
      </div>

      <PaquetesLibroTable libros={libros} />
    </>
  );
};

export default FilasHome;
