import Checkbox from '@material-ui/core/Checkbox'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
/*
Este componente son los items del dropdown
Cada elemento llama una funcion para cambiar la visivilidad de la columna y pasa como parametro el id de la columna
Si la columna de un elemento es visible el check aparece seleccionado
*/
const DropdownOverlay = (props) => {
    return (
        <div className='dropdown-overlay-background'>
            {
                props.columns.map((column, index) => (
                    <ListItem 
                    key={index} 
                    onClick={() => { props.changeColumnStatus(column.field) }} 
                    button
                    className='py-0'
                    >
                        <Checkbox checked={column.isVisible} className='pl-0'/>
                        <ListItemText primary={column.label} className='pl-3' />
                    </ListItem>
                ))
            }
        </div>
    )
}

export default DropdownOverlay