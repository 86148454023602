export const columnasUsuariosAcademia = [
  {
    label: "Nombre Completo",
    field: "nombre",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Username",
    field: "usernameAcademia",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Correo",
    field: "email",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Celular",
    field: "celular",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha de Creación",
    field: "tsCreacion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Cursos Comprados",
    field: "cursos",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha de Inicio",
    field: "fechaIniSub",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha de Fin",
    field: "fechaFinSub",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  }
];
