import React from 'react'

import useWindowSize from '../../../shared/hooks/use-window-size'

import NavigationElements from '../navigation-elements/navigation-elements'

import './sidebar.css'

//Este componente corresponde a la barra de navegacion del admin
//Este solo se muestra si se esta en una pantalla con ancho mayor a 992px
const Sidebar = () => {

    const size = useWindowSize();

    return (
        size.width > 992 &&
        <div className='sticky-top'>
            <div className='navbar-replacement'></div>
            <div className='navigation-sidebar pt-4 pl-1 custom-scrollbar'>
                <NavigationElements />
                <div className='navbar-replacement'></div>
            </div>
        </div>
    )
}

export default Sidebar
