import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { Button, TextField, makeStyles } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import { useQueryClient } from "@tanstack/react-query";
import { createEditDeleteTag } from "../../../../shared/fetchers/tags";
import { toast } from "react-hot-toast";

const AgregarTags = () => {
  const [tagEsp, setTagEsp] = useState("");
  const [tagEng, setTagEng] = useState("");
  const [tagDescription, setTagDescription] = useState("");

  const queryClient = useQueryClient();

  const classes = useStyles();

  const addTag = async (e) => {
    e.preventDefault();
    console.log("Agregando tag...");

    try {
      const response = await createEditDeleteTag(
        tagEsp,
        "añadir",
        tagEng,
        tagDescription
      );

      if (!response.status) throw new Error("Error al agregar tag");

      queryClient.invalidateQueries({
        queryKey: ["tags"],
      });

      clearForm();

      toast.success("Tag agregado");
    } catch (error) {
      toast.error("Error al agregar tag");
    }
  };

  const clearForm = () => {
    setTagEsp("");
    setTagEng("");
    setTagDescription("");
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="content-white-box px-4 py-4">
          <div className="d-flex align-items-center">
            <MenuBookIcon />
            <div className="admin-main-title-dark-gray ml-2 mt-1">
              Agregar Tag
            </div>
          </div>
          <hr />
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <TextField
              name="tagEsp"
              value={tagEsp}
              onChange={(e) => setTagEsp(e.target.value)}
              variant="outlined"
              placeholder="Nombre del tag"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />

            <TextField
              name="tagEng"
              value={tagEng}
              onChange={(e) => setTagEng(e.target.value)}
              variant="outlined"
              placeholder="Traducción del tag"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />

            <TextField
              name="tagDescription"
              value={tagDescription}
              onChange={(e) => setTagDescription(e.target.value)}
              variant="outlined"
              placeholder="Descripción"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />

            <div className="mt-3">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!tagEsp}
                startIcon={<CheckRounded />}
                onClick={addTag}
                classes={{ root: classes.agregar }}
              >
                Agregar
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default AgregarTags;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
