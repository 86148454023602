import React from "react";

import { Container } from "react-bootstrap";
import CargaMasiva from "./carga-masiva";
import CargaIndividual from "./asignar-curso-individual";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DesasignarIndividual from "./desasignar-curso-individual";

const AsignarCursos = () => {
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Asignar cursos a profesores plan lector
        </div>
      </div>
      <Container fluid>
        <CargaIndividual />
        <DesasignarIndividual />
        <CargaMasiva />
      </Container>
    </>
  );
};

export default AsignarCursos;
