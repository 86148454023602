import React, { useState } from "react";

import { Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { paisesAutocomplete } from "../../../../shared/data/paises";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button, Checkbox } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { Autocomplete } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useQueryClient } from "@tanstack/react-query";
import { crearRecurso } from "../../../../shared/fetchers/classics/recursos/crearRecurso";
import { editarRecurso } from "../../../../shared/fetchers/classics/recursos/editarRecurso";

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const CrearRecursoIndividual = ({ recurso, clearEdit }) => {
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    titulo: { value: recurso?.titulo ?? "", error: false },
    tituloEng: {
      value: recurso?.tituloEng ?? "",
      error: false,
      optional: true,
    },
    tipoRecurso: { value: recurso?.tipoDeRecurso ?? "", error: false },
    descripcion: {
      value: recurso?.descripcion ?? "",
      error: false,
      optional: true,
    },
    descripcionEng: {
      value: recurso?.descripcionEng ?? "",
      error: false,
      optional: true,
    },
    enlace: { value: recurso?.enlace ?? "", error: false, optional: true },
    territorio: {
      value: recurso?.territorio.split(",") ?? [],
      error: false,
    },
  });

  //Definimos un estado para guardar la imagen y la preview
  const [imagenRecurso, setImagenRecurso] = useState({
    imageFile: null,
    imageUrl: null,
    error: false,
  });

  const [fileRecurso, setFileRecurso] = useState({
    imageFile: null,
    imageUrl: null,
    error: false,
  });

  //Funcion para guardar una imagen seleccionada
  const handleImage = (files) => {
    if (files.length === 1) {
      const pickedFile = files[0];
      setImagenRecurso({
        imageFile: pickedFile,
        imageUrl: URL.createObjectURL(pickedFile),
        error: false,
      });
    }
  };

  //Funcion para guardar una archivo seleccionada
  const handleFile = (files) => {
    if (files.length === 1) {
      const pickedFile = files[0];
      setFileRecurso({
        imageFile: pickedFile,
        imageUrl: URL.createObjectURL(pickedFile),
        error: false,
      });
    }
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: { ...formData[name], value: value, error: false },
    });

    if (name === "enlace" && value && fileRecurso.error) {
      setFileRecurso({ ...fileRecurso, error: false });
    }
  };

  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], value: "", error: false };
      if (formElement === "territorio")
        temp[formElement] = { ...temp[formElement], value: [], error: false };
    }
    setFormData(temp);
  };

  const validate = () => {
    let isValid = true;

    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };

    //console.log(imagenRecurso);

    //Recorremos todos los campos del formulario y validamos que no esten vacios si no son opcionales
    for (const formElement of Object.entries(temp)) {
      const [key, value] = formElement;

      if (
        (key !== "territorio" && (value.optional || Boolean(value.value))) ||
        (key === "territorio" && value.value.length)
      )
        continue;

      value.error = true;
      isValid = false;
      toast.error(`El campo ${key} no puede estar vacío`);
    }

    if (!Boolean(recurso) && !imagenRecurso.imageFile) {
      setImagenRecurso({ ...imagenRecurso, error: true });
      isValid = false;
      toast.error("Debes subir una imagen para el recurso");
    }

    if (!Boolean(recurso) && !formData.enlace.value && !fileRecurso.imageFile) {
      setFileRecurso({ ...fileRecurso, error: true });
      temp.enlace.error = true;
      isValid = false;
      toast.error("Debes subir un archivo y/o un enlace para el recurso");
    }

    setFormData(temp);
    return isValid;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    let datosRecurso = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (key === "territorio") return [key, value.value.join(",")];

        return [key, value.value];
      }),
    );

    datosRecurso = {
      ...datosRecurso,
      imagenRecurso: imagenRecurso.imageFile,
      fileRecurso: fileRecurso.imageFile,
    };

    if (recurso)
      datosRecurso = { ...datosRecurso, idRecurso: recurso.idRecurso };

    //console.log(imagenRecurso);

    const { status } = !recurso
      ? await crearRecurso(datosRecurso)
      : await editarRecurso({ ...datosRecurso, idRecurso: recurso.idRecursos });

    if (recurso) clearEdit();

    queryClient.invalidateQueries({
      queryKey: ["recursos"],
    });

    if (!status)
      return swal({
        title: "Error",
        text: `Hubo un error ${
          recurso ? "editando" : "creando"
        } el recurso, inténtalo de nuevo.`,
        icon: "error",
      });

    !recurso
      ? toast.success("Recurso creado correctamente")
      : toast.success("Recurso editado correctamente");
  };

  return (
    <Col xs={12} className="content-white-box px-4 py-4">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          {recurso ? "Editar" : "Crear"} un Recurso
        </div>
      </div>
      <div className="admin-muted-text-small mt-2">
        Los campos opcionales están marcados con (Opcional), los demás son
        obligatorios
      </div>
      <div className="admin-muted-text-small mt-2">
        Por favor ingresar la siguiente info:
      </div>
      <form>
        <label className="admin-label-text mt-3 d-block">
          Título del recurso
        </label>
        <TextField
          name="titulo"
          value={formData.titulo.value}
          onChange={updateForm}
          error={formData.titulo.error}
          hllpertext={
            formData.titulo.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Título del recurso"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Título del recurso en inglés (Opcional)
        </label>
        <TextField
          name="tituloEng"
          value={formData.tituloEng.value}
          onChange={updateForm}
          error={formData.tituloEng.error}
          helpertext={
            formData.tituloEng.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Título del recurso en inglés"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">Tipo de recurso</label>
        <Autocomplete
          options={[
            "Anuncia",
            "Publica",
            "Imprime",
            "Descarga app",
            "Tutoriales",
          ]}
          freeSolo
          name="tipoRecurso"
          getOptionLabel={(option) => option}
          value={formData.tipoRecurso.value}
          inputValue={formData.tipoRecurso.value}
          onInputChange={(_, value) => {
            updateForm({
              target: {
                name: "tipoRecurso",
                value,
              },
            });
          }}
          noOptionsText="Sin resultados"
          error={formData.tipoRecurso.error}
          helpertext={formData.tipoRecurso.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Tipo de recurso"
              variant="outlined"
              size="small"
              error={formData.tipoRecurso.error}
              helpertext={formData.tipoRecurso.error ? "Campo no válido" : null}
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">
          Descripcion (Opcional)
        </label>
        <TextField
          name="descripcion"
          value={formData.descripcion.value}
          onChange={updateForm}
          type="text"
          error={formData.descripcion.error}
          helpertext={
            formData.descripcion.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          multiline
          minRows={3}
          placeholder="Descripcion del recurso"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Descripcion en inglés (Opcional)
        </label>
        <TextField
          name="descripcionEng"
          value={formData.descripcionEng.value}
          onChange={updateForm}
          type="text"
          error={formData.descripcionEng.error}
          helpertext={
            formData.descripcionEng.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          multiline
          minRows={3}
          placeholder="Descripcion del recurso en inglés"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label htmlFor="territorio" className="admin-label-text mt-3 d-block">
          Territorio
        </label>
        <Autocomplete
          multiple
          name="territorio"
          disableCloseOnSelect
          options={paisesAutocomplete}
          getOptionLabel={(option) => option}
          value={formData.territorio.value}
          placeholder="Disponibilidad regional"
          noOptionsText="Sin resultados"
          onChange={(_, value) =>
            updateForm({
              target: {
                name: "territorio",
                value,
              },
            })
          }
          error={formData.territorio.error}
          helpertext={
            formData.territorio.error
              ? "El campo territorio no puede estar vacío"
              : null
          }
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                color="primary"
              />
              {option}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              error={formData.territorio.error}
              helpertext={formData.territorio.error ? "Campo no válido" : null}
              placeholder={
                formData.territorio.value.length
                  ? ""
                  : "Disponibilidad regional"
              }
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">
          Enlace del recurso (Opcional*)
        </label>
        <div
          className="admin-muted-text-small"
          style={fileRecurso.error ? { color: "red" } : null}
        >
          Enlace al que redireciona el botón "Editar".
        </div>
        <TextField
          name="enlace"
          value={formData.enlace.value}
          onChange={updateForm}
          type="text"
          error={formData.enlace.error}
          helpertext={
            formData.enlace.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Enlace del recurso, al hacer click en el botón"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">
          Archivo del recurso (Opcional*)
        </label>
        <div
          className="admin-muted-text-small"
          style={fileRecurso.error ? { color: "red" } : null}
        >
          Archivo que se descargará con el botón "Descargar".
        </div>
        <div
          className="admin-muted-text-small"
          style={fileRecurso.error ? { color: "red" } : null}
        >
          1 archivo requerido con extensión .zip o .rar
        </div>

        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          showAlerts={true}
          maxFileSize={30000000}
          filesLimit={1}
          acceptedFiles={[
            "application/zip",
            "application/x-zip-compressed",
            "application/x-zip",
            "application/x-compressed",
            "multipart/x-zip",
            "application/x-rar-compressed",
          ]}
          onChange={(files) => handleFile(files)}
        />

        <label className="admin-label-text mt-3 d-block">
          Imagen del recurso
        </label>
        <div
          className="admin-muted-text-small"
          style={imagenRecurso.error ? { color: "red" } : null}
        >
          1 archivo requerido con extensión png, jpeg o jpg
        </div>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          showAlerts={true}
          filesLimit={1}
          acceptedFiles={["image/png", "image/jpeg", "image/jpg"]}
          onChange={(files) => handleImage(files)}
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => SubmitForm(e)}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
          >
            Finalizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              clearForm();
              if (recurso) clearEdit();
            }}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearRecursoIndividual;
