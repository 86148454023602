import React, { useEffect, useState } from "react";

import { Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { paisesAutocomplete } from "../../../../shared/data/paises";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { fetchInstitutos } from "../../../../shared/fetchers/classics/institutos/verInstitutos";
import { crearSedeClassics } from "../../../../shared/fetchers/classics/institutos/crearSedeClassics";
import { editarSedeClassics } from "../../../../shared/fetchers/classics/institutos/editarSedeClassics";

const CrearSedeClassicsIndividual = ({ sede, clearEdit }) => {
  const queryClient = useQueryClient();

  const {
    data: institutos,
    isLoading,
    isError,
  } = useQuery(["institutosClassics"], fetchInstitutos);

  const [formData, setFormData] = useState({
    nombreSede: { value: sede?.nombreSede ?? "", error: false },
    nombreInstituto: { value: sede?.instituto ?? "", error: false },
    direccionSede: { value: sede?.direccionSede ?? "", error: false },
    pais: { value: sede?.pais ?? "", error: false },
    ciudad: { value: sede?.ciudad ?? "", error: false, optional: true },
  });

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: { ...formData[name], value: value, error: false },
    });
  };

  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { value: "", error: false };
    }
    setFormData(temp);
  };

  const validate = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };

    //Recorremos todos los campos del formulario y validamos que no esten vacios si no son opcionales
    for (const formElement of Object.entries(temp)) {
      const [key, value] = formElement;

      if (value.optional || Boolean(value.value)) continue;

      console.log(key, value);

      value.error = true;
      isValid = false;
      toast.error(`El campo ${key} no puede estar vacío`);
    }

    setFormData(temp);
    return isValid;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const datosSede = Object.fromEntries(
      Object.entries(formData).map(([key, value]) =>
        key === "nombreInstituto"
          ? [key, value?.value?.nombre ?? ""]
          : [key, value?.value ?? ""]
      )
    );

    const { info, status } = !sede
      ? await crearSedeClassics(datosSede)
      : await editarSedeClassics({
        ...datosSede,
        nombreSedeNuevo: datosSede.nombreSede,
        nombreSede: sede.nombreSede,
      });

    if (sede) clearEdit();

    if (!status)
      return swal({
        title: "Error",
        text: info,
        icon: "error",
      });

    queryClient.invalidateQueries({
      queryKey: ["sedesClassics", sede?.instituto?.nombre ?? ""],
    });

    !sede
      ? toast.success("Sede creada correctamente")
      : toast.success("Sede editada correctamente");
  };

  if (isLoading)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isError) return <div>Error</div>;

  return (
    <Col xs={12} className="content-white-box px-4 py-4">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          {sede ? "Editar" : "Crear"} una sede
        </div>
      </div>
      <div className="admin-muted-text-small mt-2">
        Por favor ingresar la siguiente info:
      </div>
      <form>
        <label className="admin-label-text mt-3 d-block">Instituto</label>
        <Autocomplete
          options={institutos}
          name="nombreInstituto"
          disabled={Boolean(sede)}
          getOptionLabel={(option) => option.nombre}
          value={formData.nombreInstituto.value}
          onChange={(_, value) =>
            updateForm({
              target: { name: "nombreInstituto", value },
            })
          }
          noOptionsText="Sin resultados"
          error={formData.nombreInstituto.error}
          helperText={formData.nombreInstituto.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecciona un instituto"
              variant="outlined"
              size="small"
              error={formData.nombreInstituto.error}
              helperText={
                formData.nombreInstituto.error ? "Campo no válido" : null
              }
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />
        <label className="admin-label-text mt-3 d-block">Nombre Sede</label>
        <TextField
          name="nombreSede"
          value={formData.nombreSede.value}
          onChange={updateForm}
          error={formData.nombreSede.error}
          helperText={
            formData.nombreSede.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Nombre de la sede"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">Dirección</label>
        <TextField
          name="direccionSede"
          value={formData.direccionSede.value}
          onChange={updateForm}
          error={formData.direccionSede.error}
          helperText={
            formData.direccionSede.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Dirección"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">País</label>
        <Autocomplete
          options={paisesAutocomplete}
          freeSolo
          name="pais"
          getOptionLabel={(option) => option}
          value={formData.pais.value}
          inputValue={formData.pais.value}
          onInputChange={(_, value) =>
            updateForm({
              target: { name: "pais", value },
            })
          }
          noOptionsText="Sin resultados"
          error={formData.pais.error}
          helperText={formData.pais.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecciona un país o escribe uno nuevo"
              variant="outlined"
              size="small"
              error={formData.pais.error}
              helperText={formData.pais.error ? "Campo no válido" : null}
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">Ciudad</label>
        <TextField
          name="ciudad"
          value={formData.ciudad.value}
          onChange={updateForm}
          error={formData.ciudad.error}
          helperText={
            formData.ciudad.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Ciudad"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => SubmitForm(e)}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
          >
            Finalizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              clearForm();
              if (sede) clearEdit();
            }}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearSedeClassicsIndividual;
