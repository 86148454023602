import React, { useState, useEffect } from "react";
// material ui components
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { CloseRounded } from "@material-ui/icons";
import CheckRounded from "@material-ui/icons/CheckRounded";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import pdf1 from "../../../documents/crear-saml.pdf";
import pdf2 from "../../../documents/guia-azure.pdf";
import pdf3 from "../../../documents/guia-gsuite.pdf";

import { GENERAL_URL_API } from "../../../shared/urls";
import swal from "sweetalert";

const AgregarNuevoClienteSAMLForm = () => {
  const [formData, setFormData] = useState(fData);
  const [loading, setLoading] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const fd = new FormData();
     
    fd.append("show", "all");
    fd.append("tipoUsuario", "Instituto");
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verClientes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setAutoCompleteOptions(data.data);
  };

  const autoCompleteUpdate = (e, newValue) => {
    //Si efectivametne se seleccion un nuevo valor actualizamos el estado
    if (newValue) {
      setAutoCompleteValue(newValue);
    }
  };

  /*maneja los cambios en el form actualizando el estado
  name es el name del element (e.target.name) ...similar para value
  si no hay condición debe enviarse codition = true
  si hay condición, condition debe realizar las evaluaciones correspondientes y retornar true|false */
  const handleChange = (name, value, condition) => {
    if (condition === true || condition(value)) {
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value,
        },
      });
    }
  };

  const clearForm = () => setFormData(fData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { ...formData };
    let isValid = true;

    for (const formElement in data) {
      if (data[formElement].required && data[formElement].value === "") {
        data[formElement].error = true;
        isValid = false;
      }
    }
    //Actualizamos el estado con todos los cambios
    setFormData(data);
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      setLoading(true);
      const fd = new FormData();
       
       
      fd.append("idInstitutoSAML", formData.samlId.value);
      fd.append(
        "cliente",
        autoCompleteValue === null ? "" : autoCompleteValue.username
      );
      fd.append("entityID", formData.entityID.value);
      fd.append("SingleLogoutServiceUrl", formData.singleLogoutService.value);
      fd.append("SingleSignOnServiceUrl", formData.SingleSignOnService.value);
      fd.append("x509cert", formData.x509Cert.value);
      fd.append("vendedor", localStorage.getItem("userAdminMake"));
      let res = {};
      try {
        res = await fetch(`${GENERAL_URL_API}/clientes/crearConexionSAML.php`, {
          method: "POST", credentials: 'include',
          credentials: "include",
          body: fd,
        });
      } catch (error) {
        console.log(error);
      }
      const data = await res.json();
      if (data.status === 1) {
        await swal({
          title: "IdP Agregado",
          text: "El IdP se edito satisfactoriamente!",
          icon: "success",
        });
      } else {
        await swal({
          title: "Error",
          text: data.info,
          icon: "error",
        });
      }
      setLoading(false);
    }
  };

  // solo funciona para textfields, si hay autocomplete components no
  const textFields = Object.keys(formData).map((key, index) => {
    const formElement = formData[key];
    let onChangeCondition = true;
    if (formElement.condition !== undefined) {
      onChangeCondition = formElement.condition;
    }
    return (
      <React.Fragment key={index}>
        <label className="admin-label-text mt-3 d-block">
          {formElement.label}
        </label>
        <TextField
          fullWidth
          multiline={formElement.multiline ? formElement.multiline : false}
          rows={formElement.multiline ? 4 : 1}
          maxrows={formElement.multiline ? 4 : 1}
          variant="outlined"
          size="small"
          type="text"
          value={formElement.value}
          name={key}
          placeholder={formElement.placeholder && formElement.placeholder}
          onChange={(e) => {
            const name = e.target.name;
            let value = e.target.value;
            if (formElement.uppercase) value = e.target.value.toUpperCase();
            handleChange(name, value, onChangeCondition);
          }}
          error={formElement.error ? formElement.error : false}
          helperText={formElement.error ? "Campo no válido" : null}
        />
      </React.Fragment>
    );
  });

  return (
    <form onSubmit={handleSubmit}>
      <ButtonGroup size="small" className="my-3">
        <a className="mr-2" href={pdf1} target="_blank" rel="noreferrer">
          <CustomGreenButton>Manual General</CustomGreenButton>
        </a>
        <a className="mr-2" href={pdf2} target="_blank" rel="noreferrer">
          <CustomGreenButton>Manual Azure</CustomGreenButton>
        </a>
        <a href={pdf3} target="_blank" rel="noreferrer">
          <CustomGreenButton>Manual Gsuite</CustomGreenButton>
        </a>
      </ButtonGroup>
      <label className="admin-label-text mt-3 d-block">
        Username que ingresará a traves de SAML (Dejar vacio si es Plan Lector)
      </label>
      <Autocomplete
        options={autoCompleteOptions}
        getOptionLabel={(option) => option.username}
        value={autoCompleteValue}
        onChange={autoCompleteUpdate}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />
      {textFields}
      <div className="mt-3 w-100 d-flex justify-content-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckRounded />}
          style={{ background: "#81c868" }}
          onClick={handleSubmit}
          disabled={loading}
        >
          Agregar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="ml-2"
          startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
          style={{ background: "#f05050" }}
          onClick={clearForm}
          disabled={loading}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default AgregarNuevoClienteSAMLForm;

const fData = {
  samlId: {
    label: "identificador SAML Instituto (sin espacios)*",
    placeholder: "idSAML (min 4 caracteres y sin espacios)",
    value: "",
    error: false,
    required: true,
  },
  xml: {
    label: "XML de metadatos",
    placeholder: "XML",
    multiline: true,
    value: "",
    error: false,
    required: false,
  },
  entityID: {
    label: "entityID",
    placeholder: "Usualmente una URL que se encuentra en el XML del IdP",
    value: "",
    error: false,
    required: false,
  },
  singleLogoutService: {
    label: "SingleLogoutService",
    placeholder:
      "Usualmente una URL que se encuentra en el XML Location de SingleLogoutService",
    value: "",
    error: false,
    required: false,
  },
  SingleSignOnService: {
    label: "SingleSignOnService",
    placeholder:
      "Usualmente una URL que se encuentra en el XML Location de SingleSignOnService",
    value: "",
    error: false,
    required: false,
  },
  x509Cert: {
    label: "x509Cert",
    placeholder: "Usualmente una cadena larga de caracteres",
    value: "",
    error: false,
    required: false,
  },
};
