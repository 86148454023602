import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import swal from "sweetalert";
import { asignarDesasignarCursoAcademia } from "../../../../shared/fetchers/academia/asignarCurso";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

const CursoUsuarioForm = ({ cursos, usuarios }) => {
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    curso: "",
    usuario: "",
  });

  const updateForm = (e, n, v) => {
    const name = n || e.target.name;
    const value = v || e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const clearForm = () => {
    setFormData({
      curso: "",
      usuario: "",
    });
  };

  const asignarDesasignarCurso = async (e, asignar = true) => {
    e.preventDefault();
    const { curso, usuario } = formData;
    const response = await asignarDesasignarCursoAcademia(
      usuario.usernameAcademia,
      curso.idCursoAcademia,
      asignar ? "asignarCurso" : "quitarCurso"
    );

    if (!response.status)
      return swal({
        title: "Error",
        text: "Puede que el usuario ya esté asignado al curso",
        icon: "error",
      });

    queryClient.invalidateQueries({
      queryKey: ["usuariosCursosAcademia"],
    });

    queryClient.invalidateQueries({
      queryKey: ["usuariosAcademia"],
    });

    toast.success(`Usuario ${asignar ? "agregado al" : "eliminado del"} curso`);
  };

  return (
    <form>
      <label className="admin-label-text mt-3 d-block">
        Agregar o eliminar del curso:
      </label>
      <Autocomplete
        options={cursos}
        getOptionLabel={(option) =>
          option.titulo ? `${option?.titulo} - ${option?.idCursoAcademia}` : ""
        }
        value={formData.curso}
        onChange={(e, newValue) => updateForm(e, "curso", newValue)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <label className="admin-label-text mt-3 d-block">El usuario:</label>
      <Autocomplete
        options={usuarios}
        getOptionLabel={(option) =>
          option
            ? `${option.usernameAcademia} - ${option.nombre}`
            : ""
        }
        value={formData.usuario}
        onChange={(e, newValue) => updateForm(e, "usuario", newValue)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            style={{
              width: "100%",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        )}
      />

      <div className="d-flex mt-4">
        <Tooltip title="Agregar usuario a curso">
          <span>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!(formData.curso && formData.usuario)}
              onClick={(e) => asignarDesasignarCurso(e, true)}
              startIcon={<CheckRounded />}
              style={{ background: "#81c868" }}
            >
              Agregar
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Eliminar usuario de curso">
          <span>
            <Button
              variant="contained"
              color="primary"
              disabled={!(formData.curso && formData.usuario)}
              onClick={(e) => asignarDesasignarCurso(e, false)}
              className="ml-2"
              startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
              style={{ background: "#f05050" }}
            >
              Eliminar
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Limpiar formulario">
          <span>
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
              onClick={clearForm}
              style={{ background: "#5d9cec" }}
            >
              Cancelar
            </Button>
          </span>
        </Tooltip>
      </div>
    </form>
  );
};

export default CursoUsuarioForm;
