import React from "react";
import { Container, Row } from "react-bootstrap";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CrearNotibuzonIndividual from "./crear-notibuzon-individual";

const CrearNotibuzon = () => {
  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Agregar un nuevo Notibuzón</div>
      </div>
      <Container fluid>
        <Row>
          <CrearNotibuzonIndividual />
        </Row>
      </Container>
    </>
  );
};

export default CrearNotibuzon;
