import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * @returns {Promise<Array<Recurso>>}
 */
export async function fetchRecursos() {
  const { data } = await axios.get(`${GENERAL_URL_API}/recursos/verRecursos`, {
    withCredentials: true,
  });

  return data.data;
}
