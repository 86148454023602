import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Fetches a list of institute campuses (sedes) based on the given institute name.
 *
 * @async
 * @param {string} nombreInstituto - The name of the institute for which to fetch campuses.
 * @returns {Promise<Array>} A promise that resolves to an array of campus objects.
 */
export async function fetchSedes(nombreInstituto) {
  if (!nombreInstituto) return Promise.resolve([]);

  const fd = new FormData();
  fd.append("nombreInstituto", nombreInstituto);

  const { data } = await axios.post(
    `${GENERAL_URL_API}/app/verSedesInstituto`,
    fd,
    {
      withCredentials: true,
    }
  );

  return data?.sedes ?? [];
}
