import axios from "axios";
import { GENERAL_URL_API } from "../../../urls";

/**
 * Creates a new institute with provided information.
 *
 * @async
 * @param {Object} instituto - The object containing institute information.
 * @param {string} instituto.nombreInstituto - The name of the institute.
 * @param {string} instituto.nombreMostrar - The display name of the institute.
 * @param {string} instituto.correo - The email address of the institute.
 * @param {string} instituto.telefono - The phone number of the institute.
 * @param {string} instituto.direccion - The address of the institute.
 * @param {string} instituto.pais - The country where the institute is located.
 * @param {string} instituto.tipoIngreso - The type of admission for the institute.
 * @param {string} instituto.tipoServicio - The type of service provided by the institute.
 * @param {string} instituto.linkAcceso - The access link for the institute.
 * @param {string} instituto.linkTutorial - The tutorial link for the institute.
 * @param {string} instituto.colorHeader - The header color for the institute.
 * @param {string} instituto.colorLetra - The font color for the institute.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 */
export const crearInstitutoClassics = async (instituto) => {
  const {
    nombreInstituto,
    correo,
    pais,
    tipoIngreso,
    tipoServicio,
    linkAcceso,
    linkTutorial,
    colorHeader,
    colorLetra,
    logoInstituto,
    lecturasDiarias,
    estadoActivacion,
    estadoSubscripcion,
    ingresoApp
  } = instituto;

  const fd = new FormData();
  fd.append("nombreInstituto", nombreInstituto);
  fd.append("nombreMostrar", nombreInstituto);
  fd.append("correo", correo);
  fd.append("pais", pais);
  fd.append("tipoIngreso", tipoIngreso);
  fd.append("tipoServicio", tipoServicio);
  fd.append("linkAcceso", linkAcceso);
  fd.append("linkTutorial", linkTutorial);
  fd.append("colorHeader", colorHeader);
  fd.append("colorLetra", colorLetra);
  fd.append("logoInstituto", logoInstituto);
  fd.append("lecturasDiarias", lecturasDiarias);
  fd.append("estadoActivacion", Number(estadoActivacion));
  fd.append("estadoSubscripcion", Number(estadoSubscripcion));
  fd.append("ingresoApp", Number(ingresoApp));

  const { data } = await axios.post(
    `${GENERAL_URL_API}/app/crearInstitutos`,
    fd,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};
