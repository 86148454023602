export const columnasAccesosAcademia = [
  {
    label: "Username",
    field: "usernameAcademia",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Duración de la sesión (segundos)",
    field: "sessionDuration",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Fecha",
    field: "sessionDate",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "País",
    field: "pais",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
