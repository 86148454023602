import React, { useState, useEffect } from "react";
// components
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IOSSwitch from "../../components/shared/ios-switch";
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import swal from "sweetalert";
// icons
import FormatPaintOutlinedIcon from "@material-ui/icons/FormatPaintOutlined";
import OpacityIcon from "@material-ui/icons/Opacity";
// data
import { GENERAL_URL_API } from "../../shared/urls";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";

const CambiarLosBanners = () => {
  //Estado para guardar todo lo relacionado con las imagenes
  const [banners, setBanners] = useState({
    b1: [],
    b2: [],
    b3: [],
    b4: [],
  });

  const [formData, setFormData] = useState(fData);
  const [overWrite, setOverWrite] = useState(false);
  const [institute, setInstitute] = useState({
    value: null,
    error: false,
    required: true,
  });
  const [institutes, setInstitutes] = useState([]);

  useEffect(() => {
    fetchInstitutes();
  }, []);

  const fetchInstitutes = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setInstitutes(data.data);
  };

  const sendFiles = async () => {
    const fd = new FormData();
     
    fd.append("rutaBanner1", formData.route1.value);
    fd.append("rutaBanner2", formData.route2.value);
    fd.append("rutaBanner3", formData.route3.value);
    fd.append("rutaBanner4", formData.route4.value);
    fd.append("instituto", institute.value.nombre);
    if (banners.b1.length) fd.append("banner1", banners.b1[0]);
    if (banners.b2.length) fd.append("banner2", banners.b2[0]);
    if (banners.b3.length) fd.append("banner3", banners.b3[0]);
    if (banners.b4.length) fd.append("banner4", banners.b4[0]);
    if (institute.value.nombre.toLowerCase() === "manuvo" && overWrite)
      fd.append("rewriteAllBanners", 1);
    try {
      const res = await fetch(`${GENERAL_URL_API}/arte/banners.php`, {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      });
      const data = await res.json();
      if (data.status === 1) {
        await swal({
          title: "Rutas modificadas.",
          text: "Las rutas de los banners se modificaron satisfactoriamente!",
          icon: "success",
        });
      } else {
        await swal({
          title: "Sorry.",
          text: `No se pudieron cambiar los banners:\n ${data.info}`,
          icon: "error",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropFileChange = (files, banner) => {
    setBanners({
      ...banners,
      [banner]: files,
    });
  };

  const changeAutocomplete = (e, value) => {
    setInstitute({
      ...institute,
      value,
    });
    if (value) {
      setFormData({
        route1: {
          ...formData.route1,
          value: value.rutaBanner1 || "",
        },
        route2: {
          ...formData.route2,
          value: value.rutaBanner2 || "",
        },
        route3: {
          ...formData.route3,
          value: value.rutaBanner3 || "",
        },
        route4: {
          ...formData.route4,
          value: value.rutaBanner4 || "",
        },
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendFiles();
    clearForm();
  };

  const clearForm = () => {
    fetchInstitutes();
    setInstitute({
      ...institute,
      value: null,
    });
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <FormatPaintOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o editar archivos y rutas de los banners
        </div>
      </div>

      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <OpacityIcon style={{ fontSize: "22px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar o editar archivos y rutas de los banners
              </div>
            </div>
            <span className="admin-muted-text-small mt-2">
              Para cambiar banners de Uberhome ir al instituto ManuvoExterno,
              para banners generales internos ir al instituto Manuvo.
            </span>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Instituto*
              </label>
              <Autocomplete
                fullWidth
                options={institutes}
                getOptionLabel={(option) => option.nombre}
                value={institute.value}
                onChange={(e, value) => {
                  changeAutocomplete(e, value);
                }}
                noOptionsText="Sin resultados"
                disabled={institute.value ? true : false}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                      error={institute.error}
                      helperText={institute.error ? "Campo no válido" : null}
                    />
                  </>
                )}
              />

              {institute.value && (
                <>
                  <label className="admin-label-text mt-3 d-block">
                    Ruta banner 1
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="/rutaDelBanner"
                    name="route1"
                    value={formData.route1.value}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                    error={
                      formData.route1.error ? formData.route1.error : false
                    }
                    helperText={
                      formData.route1.error ? "Campo no válido" : null
                    }
                  />
                  <label className="admin-label-text mt-3 d-block">
                    Ruta banner 2
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="/rutaDelBanner"
                    name="route2"
                    value={formData.route2.value}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                    error={
                      formData.route2.error ? formData.route2.error : false
                    }
                    helperText={
                      formData.route2.error ? "Campo no válido" : null
                    }
                  />
                  <label className="admin-label-text mt-3 d-block">
                    Ruta banner 3
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="/rutaDelBanner"
                    name="route3"
                    value={formData.route3.value}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                    error={
                      formData.route3.error ? formData.route3.error : false
                    }
                    helperText={
                      formData.route3.error ? "Campo no válido" : null
                    }
                  />
                  <label className="admin-label-text mt-3 d-block">
                    Ruta banner 4
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="/rutaDelBanner"
                    name="route4"
                    value={formData.route4.value}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                    error={
                      formData.route4.error ? formData.route4.error : false
                    }
                    helperText={
                      formData.route4.error ? "Campo no válido" : null
                    }
                  />

                  <div
                    className="align-center mt-2"
                    style={{
                      visibility:
                        institute.value.nombre.toLowerCase() === "manuvo"
                          ? "auto"
                          : "hidden",
                      position:
                        institute.value.nombre.toLowerCase() === "manuvo"
                          ? "auto"
                          : "absolute",
                    }}
                  >
                    <label className="admin-label-text mt-2 d-inline">
                      Sobreescribir:
                    </label>
                    <IOSSwitch
                      checked={overWrite}
                      onClick={() => setOverWrite(!overWrite)}
                      name="active"
                    />
                  </div>

                  <label className="admin-label-text mt-3 d-block">
                    Banners
                  </label>
                  <div className="admin-muted-text-small">
                    Banner 1 (Visible a todos)
                  </div>
                  <DropzoneArea
                    id="dropzone"
                    dropzoneText="Arrastra un archivo o haz click aquí!"
                    showFileNames={true}
                    // maxFileSize={3000000} //n in bytes
                    showAlerts={true}
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    onChange={(files) => handleDropFileChange(files, "b1")}
                  />
                  <div className="admin-muted-text-small">
                    Banner 2 (Visible a todos)
                  </div>
                  <DropzoneArea
                    id="dropzone"
                    dropzoneText="Arrastra un archivo o haz click aquí!"
                    showFileNames={true}
                    // maxFileSize={3000000} //n in bytes
                    showAlerts={true}
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    onChange={(files) => handleDropFileChange(files, "b2")}
                  />
                  <div className="admin-muted-text-small">
                    Banner 3 (Visible al cliente)
                  </div>
                  <DropzoneArea
                    id="dropzone"
                    dropzoneText="Arrastra un archivo o haz click aquí!"
                    showFileNames={true}
                    // maxFileSize={3000000} //n in bytes
                    showAlerts={true}
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    onChange={(files) => handleDropFileChange(files, "b3")}
                  />
                  <div className="admin-muted-text-small">
                    Banner 4 (Visible al cliente)
                  </div>
                  <DropzoneArea
                    style={{ width: "50%" }}
                    id="dropzone"
                    dropzoneText="Arrastra un archivo o haz click aquí!"
                    showFileNames={true}
                    // maxFileSize={3000000} //n in bytes
                    showAlerts={true}
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    onChange={(files) => handleDropFileChange(files, "b4")}
                  />

                  <div className="d-flex justify-content-end mt-4">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CheckRounded />}
                      onClick={handleSubmit}
                      style={{ background: "#81c868" }}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="ml-2"
                      startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
                      onClick={clearForm}
                      style={{ background: "#f05050" }}
                    >
                      Cancelar
                    </Button>
                  </div>
                </>
              )}
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CambiarLosBanners;

const fData = {
  route1: {
    value: "",
    error: false,
  },
  route2: {
    value: "",
    error: false,
  },
  route3: {
    value: "",
    error: false,
  },
  route4: {
    value: "",
    error: false,
  },
};
