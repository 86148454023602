import axios from "axios";
import { GENERAL_URL_API } from "../urls";

/**
 * Recupera las etiquetas de los libros mediante una llamada a la API.
 * @returns {Promise<any>} - Una promesa que se resuelve en la respuesta JSON de la llamada a la API.
 */
async function fetchTags() {
  const { data } = await axios.get(`${GENERAL_URL_API}/libros/tags.php`, {
    withCredentials: true,
  });

  return data;
}

/**
 * Crea, edita o elimina una etiqueta con opciones adicionales mediante una llamada a la API.
 * @param {string} tag - El nombre de la etiqueta.
 * @param {string} action - La acción a realizar: "añadir", "editar" o "eliminar".
 * @param {string} [translation] - La traducción de la etiqueta (opcional, solo para "añadir" y "editar").
 * @param {string} [description] - La descripción de la etiqueta (opcional, solo para "añadir" y "editar").
 * @returns {Promise<any>} - Una promesa que se resuelve en los datos de la operación de la etiqueta.
 */
async function createEditDeleteTag(tag, action, translation, description) {
  const fd = new FormData();
  fd.append("nombreTag", tag);
  fd.append("editionType", action);

  if (translation) fd.append("nombreTagEng", translation);
  if (description) fd.append("descripcionTag", description);

  const { data } = await axios.post(`${GENERAL_URL_API}/tags/editarTags`, fd, {
    withCredentials: true,
  });

  return data;
}

export { fetchTags, createEditDeleteTag };
