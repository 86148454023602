import React from "react";
// Components
import { Container } from "mdbreact";
import { Row, Col } from "react-bootstrap";
import CursoUsuarioForm from "./curso-usuario-form";
// icons
import CursoUsuarioTable from "./curso-usuario-table";
import { Tv, MenuBook, CreateOutlined } from "@material-ui/icons";
import { fetchUsuariosCursosAcademia } from "../../../../shared/fetchers/academia/verUsuariosCursos";
import { useQuery } from "@tanstack/react-query";
import { fetchCursosAcademia } from "../../../../shared/fetchers/academia/verCursos";
import { fetchUsuariosAcademia } from "../../../../shared/fetchers/academia/verUsuarios";
import CargaMasiva from "../../../../components/shared/carga-masiva";
import { asignarDesasignarCursosAcademia } from "../../../../shared/fetchers/academia/asignarCurso";

const AsignarCursoUsuario = () => {
  const {
    data: usuariosCursos,
    isLoading,
    isError,
  } = useQuery(["usuariosCursosAcademia"], fetchUsuariosCursosAcademia);

  const {
    data: cursos,
    isLoading: cursosIsLoading,
    isError: cursosIsError,
  } = useQuery(["cursosAcademia"], fetchCursosAcademia);

  const {
    data: usuarios,
    isLoading: usuariosIsLoading,
    isError: usuariosIsError,
  } = useQuery(["usuariosAcademia"], fetchUsuariosAcademia);

  if (isLoading || cursosIsLoading || usuariosIsLoading)
    return <div>Cargando...</div>;

  if (isError || cursosIsError || usuariosIsError)
    return <div>Ha ocurrido un error</div>;

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlined />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o eliminar un curso a un usuario
        </div>
      </div>

      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBook />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                AGREGAR/ELIMINAR UN USUARIO A UN CURSO
              </div>
            </div>
            <CursoUsuarioForm cursos={cursos} usuarios={usuarios} />
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4">
            <CargaMasiva
              load={asignarDesasignarCursosAcademia}
              title="AGREGAR/ELIMINAR USUARIOS A CURSOS (Masivo)"
              template="/templates/formacion/asignar-curso.xlsx"
            />
          </Col>

          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <Tv />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                TABLA DE USUARIOS Y SUS CURSOS
              </div>
            </div>
            <CursoUsuarioTable rows={usuariosCursos} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AsignarCursoUsuario;
