import React, { useState, useEffect } from "react";
// Components
import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";
import swal from "sweetalert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
// icons
import TvIcon from "@material-ui/icons/Tv";
import PersonIcon from "@material-ui/icons/Person";
//Datos
import { listaEstudiantesC } from "../../../shared/data/columnas-plan-lector";
// Datos de prueba
import exportFromJSON from "export-from-json";
import { GENERAL_URL_API } from "../../../shared/urls";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";

const ListaEstudiantes = () => {
  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [sedes, setSedes] = useState([]);
  const [institutos, setInstitutos] = useState([]);
  const [cursos, setCursos] = useState([]);

  const [formData, setFormData] = useState({
    instituto: null,
    sede: null,
    curso: null,
  });

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    let temp = listaEstudiantesC;
    //Añadimos un atributo de visible a todas las columnas
    temp.map((_, index) => (temp[index].isVisible = true));
    setColumns(temp);
    fetchInstitutos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchEstudiantes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const fetchInstitutos = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/institutos/verInstitutos.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setInstitutos(data.data);
  };

  const fetchSedes = async (instituto) => {
    const fd = new FormData();
     
    fd.append("instituto", instituto);
    const res = await fetch(`${GENERAL_URL_API}/sedes/verSedes.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (!data.status)
      await swal({
        title: "Error.",
        text: data.info,
        icon: "error",
      });
    setSedes(data.data);
  };

  const fetchCursos = async (instituto, sede) => {
    const fd = new FormData();
     
    fd.append("instituto", instituto);
    fd.append("sede", sede);
    const url = `${GENERAL_URL_API}/Cursos/verCursos.php`;
    const res = await fetch(url, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    if (!data.status)
      await swal({
        title: "Error.",
        text: data.info,
        icon: "error",
      });
    setCursos(data.data);
  };

  const fetchEstudiantes = async () => {
    setRows([]);
    const fd = new FormData();
     
    if (formData.instituto) fd.append("instituto", formData.instituto.nombre);
    if (formData.sede) fd.append("sede", formData.sede.instituto);
    if (formData.curso) fd.append("idCurso", formData.curso.idCursoPlanLector);
    const res = await fetch(
      `${GENERAL_URL_API}/estudiantes/verEstudiantes.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const data = await res.json();
    setRows(data.data);

    if (!data.status)
      await swal({
        title: "Error.",
        text: data.info,
        icon: "error",
      });
  };

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  //Funcion para actualizar un campo de un formulario
  const updateForm = async (e, n, v) => {
    const value = v || e.target.value;
    const name = n || e.target.name;
    if (name === "instituto" && !value)
      setFormData({
        instituto: value,
        sede: value,
        curso: value,
      });
    else if (name === "sede" && !value)
      setFormData({
        ...formData,
        sede: value,
        curso: value,
      });
    else setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <TvIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">Lista de estudiantes plan lector</div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Tabla de estudiantes plan lector
              </div>
            </div>
            <div className="admin-muted-text-small mt-1 mb-4">
              Lista de todos los estudiantes.
            </div>

            <form className="mb-3">
              <label className="admin-label-text mt-3 d-block">
                Instituto*
              </label>
              <Autocomplete
                fullWidth
                options={institutos}
                getOptionLabel={(option) => option.nombre}
                value={formData.instituto}
                onChange={(e, value) => {
                  updateForm(e, "instituto", value);
                  if (value) fetchSedes(value.nombre);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Selecciona un instituto"
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  </>
                )}
              />

              <label className="admin-label-text mt-3 d-block">Sede</label>
              <Autocomplete
                fullWidth
                options={sedes}
                getOptionLabel={(option) => option.instituto}
                value={formData.sede}
                onChange={(e, value) => {
                  updateForm(e, "sede", value);
                  if (formData.instituto && value)
                    fetchCursos(formData.instituto.nombre, value.instituto);
                }}
                disabled={!formData.instituto || !sedes.length}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder={
                        formData.instituto
                          ? "Selecciona una sede"
                          : "Debes seleccionar un instituto para ver sus sedes"
                      }
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  </>
                )}
              />
              <label className="admin-label-text mt-3 d-block">Curso</label>
              <Autocomplete
                fullWidth
                options={cursos}
                getOptionLabel={(option) =>
                  option ? `${option.idCursoPlanLector} - ${option.curso}` : ""
                }
                value={formData.curso}
                onChange={(e, value) => {
                  updateForm(e, "curso", value);
                }}
                disabled={!formData.sede || !cursos.length}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder={
                        formData.sede
                          ? "Selecciona un curso"
                          : "Debes seleccionar una sede para ver sus cursos"
                      }
                      style={{
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  </>
                )}
              />
            </form>

            {/* Este componente es el dropdown que permite ocultar columnas*/}
            <TableColumnDropdown
              columns={columns}
              changeColumnStatus={changeColumnStatus}
            />
            <br />

            <ButtonGroup size="small" variant="contained" className="my-3">
              {/* <CustomGreenButton>Copiar</CustomGreenButton>
              <CustomGreenButton>Archivo csv</CustomGreenButton>
              <CustomGreenButton>Excel</CustomGreenButton>
              <CustomGreenButton>PDF</CustomGreenButton>
              <CustomGreenButton>Imprimir</CustomGreenButton> */}

              <CustomGreenButton
                onClick={() => {
                  const dat = [...rows];
                  console.log(dat);
                  // console.log(dat);
                  navigator.clipboard
                    .writeText(JSON.stringify(dat))
                    .then(async () => {
                      await swal({
                        title: "Se ha copiado la tabla!",
                        icon: "success",
                      });
                    });
                }}
              >
                Copy
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-estudiantes",
                    exportType: exportFromJSON.types.csv,
                  });
                }}
              >
                CSV
              </CustomGreenButton>
              <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-estudiantes",
                    exportType: exportFromJSON.types.json,
                  });
                }}
              >
                JSON
              </CustomGreenButton>
              {/* <CustomGreenButton
                onClick={() => {
                  exportFromJSON({
                    data: rows,
                    fileName: "lista-estudiantes",
                    exportType: exportFromJSON.types.xls,
                  });
                }}
              >
                EXCEL
              </CustomGreenButton> */}
              <CustomGreenButton>
                <ExcelWorkBook
                  columns={columns}
                  rows={rows}
                  filename={"lista-estudiantes"}
                />
              </CustomGreenButton>
            </ButtonGroup>
            {rows.length <= 0 || columns.length <= 0 ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <MDBDataTable
                small
                responsive
                striped
                searchTop
                searchBottom={false}
                barReverse
                pagingTop
                bordered
                data={{
                  columns: columns.filter((obj) => obj.isVisible),
                  rows: rows,
                }}
                noBottomColumns
                order={["name", "asc"]}
                entriesOptions={[5, 10, 20, 50]}
                entriesLabel="Numero de filas"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando filas", "a", "de", "filas"]}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaEstudiantes;
