import React, { useEffect, useState } from "react";

import { Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { Autocomplete, Skeleton } from "@material-ui/lab";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DropzoneArea } from "material-ui-dropzone";
import { fetchEventos } from "../../../../shared/fetchers/classics/eventos/verEventos";
import TinyEditorComponent from "../../../blog/tinyMCEEditor";
import { crearFormulario } from "../../../../shared/fetchers/classics/formularios/crearFormulario";
import { editarFormulario } from "../../../../shared/fetchers/classics/formularios/editarFormulario";

const CrearFormularioIndividual = ({ formulario, clearEdit }) => {
  const queryClient = useQueryClient();

  const {
    data: eventos,
    isLoading: isLoadingEventos,
    isError: isErrorEventos,
  } = useQuery(["eventos"], fetchEventos);

  const [imagen, setImagen] = useState({
    imageFile: null,
    imageUrl: null,
    error: false,
  });

  const [formData, setFormData] = useState({
    evento: { value: null, error: false },
    limiteInscritos: {
      value: formulario?.limiteInscritos ?? 30,
      error: false,
      optional: true,
    },
    colorFondo: {
      value: formulario?.colorFondo ?? "#00ffff",
      error: false,
    },
    colorFuente: {
      value: formulario?.colorFuente ?? "#008000",
      error: false,
    },
    mensajeInscripcion: {
      value: formulario?.mensajeInscripcion ?? "",
      error: false,
    },
    mensajeRecordatorio: {
      value: formulario?.mensajeRecordatorio ?? "",
      error: false,
    },
    estado: { value: +formulario?.estado ? "ACTIVO" : "INACTIVO" , error: false, optional: true },
    linkEvento: {
      value: formulario?.linkEvento ?? "",
      error: false,
      optional: true,
    },
  });

  useEffect(() => {
    if (eventos?.length && formulario) {
      updateForm({
        target: {
          name: "evento",
          value: eventos.find(
            (evento) => evento.idEvento === formulario.idEvento,
          ),
        },
      });
    }

    // eslint-disable-next-line
  }, [eventos]);

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: { ...formData[name], value: value, error: false },
    });
  };

  const handleImage = (files) => {
    if (files.length === 1) {
      const pickedFile = files[0];
      setImagen({
        imageFile: pickedFile,
        imageUrl: URL.createObjectURL(pickedFile),
        error: false,
      });
    }
  };

  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], value: "", error: false };
    }

    temp = {
      ...temp,
      colorFondo: { value: "#00ffff", error: false },
      colorFuente: { value: "#008000", error: false },
      evento: { value: null, error: false },
      limiteInscritos: { value: 30, error: false },
    };

    setFormData(temp);
  };

  const validate = () => {
    let isValid = true;

    const temp = { ...formData };

    if (!formulario && !imagen.imageFile) {
      setImagen({ ...imagen, error: true });
      isValid = false;
      toast.error("Debes seleccionar una imagen");
    }

    for (const formElement of Object.entries(temp)) {
      const [key, value] = formElement;

      if (!value.optional && !value.value) {
        value.error = true;
        isValid = false;
        console.log(key, value);
        toast.error(`El campo ${key} no puede estar vacío`);
      }
    }

    setFormData(temp);
    return isValid;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    let datosFormulario = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        return [key, value.value];
      }),
    );

    const { status } = !formulario
      ? await crearFormulario({
        ...datosFormulario,
        idEvento: datosFormulario.evento.idEvento,
        imagenFormulario: imagen.imageFile,
      })
      : await editarFormulario({
        ...datosFormulario,
        estado: datosFormulario.estado === "ACTIVO" ? 1 : 0,
        idEvento: datosFormulario.evento.idEvento,
        imagenFormulario: imagen.imageFile,
        idFormularioEvento: formulario.idFormularioEvento,
        idNotibanner: formulario.idNotibanner,
      });

    if (formulario) clearEdit();

    queryClient.invalidateQueries({
      queryKey: ["formularios"],
    });

    if (!status)
      return swal({
        title: "Error",
        text: `Hubo un error ${formulario ? "editando" : "creando"
          } el formulario, inténtalo de nuevo.`,
        icon: "error",
      });

    !formulario
      ? toast.success("Formulario creado correctamente")
      : toast.success("Formulario editado correctamente");
  };

  if (isLoadingEventos)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  if (isErrorEventos)
    return <div>Ha ocurrido un error, prueba recargando la página</div>;

  return (
    <Col xs={12} className="content-white-box px-4 py-4">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          {formulario ? "Editar" : "Crear"} un Formulario
        </div>
      </div>
      <div className="admin-muted-text-small mt-2">
        Los campos opcionales están marcados con (Opcional), los demás son
        obligatorios
      </div>
      <div className="admin-muted-text-small mt-2">
        Por favor ingresar la siguiente info:
      </div>
      <form>
        <label htmlFor="idEvento" className="admin-label-text mt-3 d-block">
          Evento
        </label>
        <Autocomplete
          options={eventos}
          value={formData.evento.value}
          getOptionLabel={(option) =>
            `${option.idEvento} - ${option["Nombre del evento"]?.es ?? "SIN NOMBRE"
            } - ${option.tipoEvento}`
          }
          onChange={(_, value) => {
            updateForm({
              target: {
                name: "evento",
                value,
              },
            });
          }}
          noOptionsText="Sin resultados"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        {formulario && (
          <>
            <label className="admin-label-text mt-3 d-block">
              Límite de inscritos
            </label>
            <TextField
              name="limiteInscritos"
              type="number"
              value={formData.limiteInscritos.value}
              onChange={updateForm}
              error={formData.limiteInscritos.error}
              helperText={
                formData.limiteInscritos.error
                  ? "Este campo no puede estar vacío"
                  : null
              }
              variant="outlined"
              size="small"
              placeholder="Límite de inscritos"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          </>
        )}

        <label className="admin-label-text mt-3 d-block">Color Fondo</label>
        <TextField
          name="colorFondo"
          value={formData.colorFondo.value}
          onChange={updateForm}
          error={formData.colorFondo.error}
          helperText={
            formData.colorFondo.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label className="admin-label-text mt-3 d-block">Color Fuente</label>
        <TextField
          name="colorFuente"
          value={formData.colorFuente.value}
          onChange={updateForm}
          error={formData.colorFuente.error}
          helperText={
            formData.colorFuente.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label
          htmlFor="mensajeInscripcion"
          className="admin-label-text mt-3 d-block"
        >
          Mensaje de Inscripción
        </label>
        <TinyEditorComponent
          value={formData.mensajeInscripcion.value}
          onChange={(value) => {
            updateForm({
              target: {
                name: "mensajeInscripcion",
                value,
              },
            });
          }}
        />

        <label htmlFor="textoBoton" className="admin-label-text mt-3 d-block">
          Mensaje de Recordatorio
        </label>
        <TinyEditorComponent
          value={formData.mensajeRecordatorio.value}
          onChange={(value) => {
            updateForm({
              target: {
                name: "mensajeRecordatorio",
                value,
              },
            });
          }}
        />

        {formulario && (
          <>
            <label
              htmlFor="tipoVisibilidad"
              className="admin-label-text mt-3 d-block"
            >
              Estado
            </label>
            <Autocomplete
              options={["ACTIVO", "INACTIVO"]}
              value={formData.estado.value}
              getOptionLabel={(option) => option}
              onChange={(_, value) => {
                updateForm({
                  target: {
                    name: "estado",
                    value,
                  },
                });
              }}
              noOptionsText="Sin resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    fontFamily: "'Noto Sans JP', sans-serif",
                  }}
                />
              )}
            />
          </>
        )}

        {formulario && (
          <>
            <label
              htmlFor="linkEvento"
              className="admin-label-text mt-3 d-block"
            >
              Enlace del evento
            </label>
            <TextField
              name="linkEvento"
              value={formData.linkEvento.value}
              onChange={updateForm}
              error={formData.linkEvento.error}
              helpertext={
                formData.linkEvento.error
                  ? "Este campo no puede estar vacío"
                  : null
              }
              variant="outlined"
              size="small"
              placeholder="Enlace del evento"
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          </>
        )}

        <label className="admin-label-text mt-3 d-block">Imagen</label>
        <div
          className="admin-muted-text-small"
          style={imagen.error ? { color: "red" } : null}
        >
          1 archivo requerido con extensión png, jpeg o jpg
        </div>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          showAlerts={true}
          filesLimit={1}
          acceptedFiles={["image/png", "image/jpeg", "image/jpg"]}
          onChange={(files) => handleImage(files)}
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => SubmitForm(e)}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
          >
            Finalizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              clearForm();
              if (formulario) clearEdit();
            }}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearFormularioIndividual;
