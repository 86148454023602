import { GENERAL_URL_API } from "../../../shared/urls";
import { fData } from "./agregar-nuevo-libro-data";
import swal from "sweetalert";

export const addBook = async (formData, edit) => {
  const fd = new FormData();

  fd.append("idManuvoR", formData?.manuvoId.value);
  fd.append("titleR", formData?.title.value);
  fd.append("subjectR", formData?.bisac.value);
  fd.append("subject2R", formData?.subject?.value);
  fd.append("subject2ENG", formData?.subjectEng?.value);
  fd.append("descriptionR", formData?.description.value);
  fd.append("descENG", formData?.descriptionEng.value ?? "");
  fd.append("sourceR", formData?.source.value ?? "");
  fd.append("dateManualR", formData?.date.value ?? "");
  fd.append("relationR", formData?.relation.value ?? "");
  fd.append("coverageR", formData?.coverage.value ?? "");
  fd.append("authorR", formData?.author.value ?? "");
  fd.append("publisherR", formData?.publisher.value ?? "");
  fd.append("contributorR", formData?.contributor.value ?? "");
  fd.append("rightsR", formData?.rights.value ?? "");
  fd.append("isbnR", formData?.isbn.value ?? "");
  fd.append("formatR", formData?.format?.value?.value ?? "");
  fd.append("bookTypeR", formData?.type.value ?? "");
  fd.append("royaltiesR", formData?.royalties.value ?? "");
  fd.append("languageR", formData?.lang.value ?? "");
  fd.append("tag1R", formData?.topic1.value?.tag ?? null);
  fd.append("tag2R", formData?.topic2.value?.tag ?? null);
  fd.append("tag3R", formData?.topic3.value?.tag ?? null);
  fd.append("tag4R", formData?.value?.value?.tag ?? null);
  fd.append("tag5R", formData?.tag5.value?.tag ?? null);
  fd.append("dba1R", formData?.dba?.value?.code ?? "");
  fd.append("dba2R", formData?.dbaKeyword1.value ?? "");
  fd.append("dba3R", formData?.dbaKeyword2.value ?? "");
  const userLevel = formData?.userLevel?.value?.value;
  const formatedUserLevel =
    userLevel.charAt(0).toUpperCase() + userLevel.slice(1).toLowerCase();
  fd.append("nivelUsuarioLectorR", formatedUserLevel ?? "");
  fd.append("tiempoLecturaR", formData?.readTime.value ?? "");
  fd.append("edadR", formData?.age?.value?.value.replaceAll("+", "") ?? "");
  fd.append("cursoR", formData?.course?.value?.value ?? "");
  fd.append("valoresR", formData?.values.value ?? "");
  fd.append("levelling", formData.levelling.value ?? "");
  fd.append("levellingENG", formData.levellingENG.value ?? "");
  fd.append("compatibilidadR", formData?.compatibility?.value?.value ?? "");
  fd.append("generoR", formData?.genre?.value?.value ?? "");
  fd.append("recomendado1R", formData?.recommended1.value ?? "");
  fd.append("recomendado2R", formData?.recommended2.value ?? "");
  fd.append("link1R", formData?.link1.value ?? "");
  fd.append("link2R", formData?.link2.value ?? "");
  fd.append("link3R", formData?.link3.value ?? "");
  fd.append("linkExternoR", formData?.linkExterno.value ?? "");
  fd.append("textLink1R", formData?.link1Text.value ?? "");
  fd.append("textLink2R", formData?.link2Text.value ?? "");
  fd.append("textLink3R", formData?.link3Text.value ?? "");
  fd.append("tipoDeLibro", formData?.tipoDeLibro?.value ?? "");

  // paises es un arreglo del tipo ["CO","CL"] entonces lo paso a un string del tipo "CO,CL"
  fd.append(
    "disponibilidadRegional",
    formData?.countries?.value?.join(",") ?? ""
  );
  fd.append("sonido", formData?.sound.value ? 1 : 0);
  fd.append("interactivo", formData?.interactive.value ? 1 : 0);
  fd.append("gratis", formData?.free.value ? 1 : 0);
  fd.append("libroPremiado", formData?.libroPremiado.value ? 1 : 0);
  fd.append("libroAccesible", formData?.libroAccesible.value ? 1 : 0);
  fd.append("libroBilingue", formData?.libroBilingue.value ? 1 : 0);
  fd.append("linkBlog", formData?.blogLink.value ?? "");
  fd.append("authorAlt", formData?.authorAlt.value ?? "");
  fd.append("publicationPlace", formData?.publicationPlace.value ?? "");
  fd.append(
    "authorSecundaryFunction",
    formData?.authorSecundaryFunction.value ?? ""
  );
  fd.append("language2", formData?.lang2.value ?? "");
  fd.append("language3", formData?.lang3.value ?? "");
  fd.append("tag1ENG", formData?.topic1Eng.value ?? "");
  fd.append("tag2ENG", formData?.topic2Eng.value ?? "");
  fd.append("tag3ENG", formData?.topic3Eng.value ?? "");
  fd.append("tag4ENG", formData?.valueEng.value ?? "");
  fd.append("tag5ENG", formData?.tag5Eng.value ?? "");
  fd.append("nivelENG", formData?.englishLevel.value ?? "");
  fd.append("valoresENG", formData?.valuesEng.value ?? "");
  fd.append("generoENG", formData?.genreEng?.value?.value ?? "");
  fd.append("activo", formData.active.value.data ?? "");

  try {
    let url = edit
      ? `${GENERAL_URL_API}/libros/editarLibroMetadatos.php`
      : `${GENERAL_URL_API}/libros/crearLibroMetadatos.php`;

    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    return res;
  } catch (e) {
    console.log(e);
    swal({
      title: "Error agregando libro.",
      text: e.message,
      icon: "error",
    });
  }
};

export const getDataFromBook = (b) => {
  const bookData = { ...fData };
  bookData.active.value = {
    data: parseInt(b.activo),
    value: `${b.activo}` === "0" ? "Inactivo" : "Activo",
  };
  bookData.author.value = b.author || "";
  bookData.authorAlt.value = b.authorAlt || "";
  bookData.authorSecundaryFunction.value = b.authorSecundaryFunction || "";
  bookData.type.value = b.bookType || "";
  bookData.compatibility.value = {
    data: b.compatibilidad || "",
    value: b.compatibilidad || "",
  };
  // bookData..value = b.contenidoExtra1||"";
  // bookData..value = b.contenidoExtra2||"";
  bookData.contributor.value = b.contributor || "";
  bookData.coverage.value = b.coverage || "";
  bookData.course.value = { data: b.curso || "", value: b.curso || "" };
  // bookData.date.value = b.date||"";
  bookData.date.value = b.dateManual || "";
  bookData.dba.value = { code: b.dba1 } || "";
  bookData.dbaKeyword1.value = b.dba2 || "";
  bookData.dbaKeyword2.value = b.dba3 || "";
  bookData.description.value = b.description || "";
  bookData.descriptionEng.value = b.descriptionENG || "";
  bookData.countries.value = b.disponibilidadRegional.split(",") || "";
  bookData.levelling.value = b.levelling || "";
  bookData.levellingENG.value = b.levellingENG || "";
  bookData.age.value = {
    data: b.edad ? `${b.edad.replaceAll("+", "")}+` : "",
    value: b.edad ? `${b.edad.replaceAll("+", "")}+` : "",
  };
  bookData.format.value = { data: b.format || "", value: b.format || "" };
  bookData.genre.value = { data: b.genero || "", value: b.genero || "" };
  bookData.genreEng.value = {
    data: b.generoENG || "",
    value: b.generoENG || "",
  };
  bookData.free.value = `${b.gratis}` === "0" ? false : true;
  bookData.manuvoId.value = b.idLibro || "";
  // bookData..value = b.identifier||"";
  bookData.interactive.value = `${b.interactivo}` === "0" ? false : true;
  bookData.isbn.value = b.isbn || "";
  bookData.lang.value = b.language || "";
  bookData.lang2.value = b.language2 || "";
  bookData.lang3.value = b.language3 || "";
  // bookData..value = b.libroInterno||"";
  bookData.link1.value = b.link1 || "";
  bookData.link2.value = b.link2 || "";
  bookData.link3.value = b.link3 || "";
  bookData.blogLink.value = b.linkBlog || "";
  bookData.linkExterno.value = b.linkExterno || "";
  bookData.englishLevel.value = b.nivelENG || "";
  bookData.userLevel.value = {
    data: b.nivelUsuarioLector,
    value: b.nivelUsuarioLector,
  };
  // bookData..value = b.numeroPalabras||"";
  // bookData..value = b.portada||"";
  // bookData..value = b.portadaThumb||"";
  bookData.publicationPlace.value = b.publicationPlace || "";
  bookData.publisher.value = b.publisher || "";
  bookData.recommended1.value = b.recomendado1 || "";
  bookData.recommended2.value = b.recomendado2 || "";
  bookData.relation.value = b.relation || "";
  // bookData..value = b.restriccionRegional||"";
  bookData.rights.value = b.rights || "";
  bookData.royalties.value = b.royalties || "";
  // bookData..value = b.screenshot1||"";
  // bookData..value = b.screenshot2||"";
  // bookData..value = b.screenshot3||"";
  bookData.sound.value = `${b.sonido}` === "0" ? false : true;
  bookData.source.value = b.source || "";
  bookData.bisac.value = b.subject || "";
  bookData.subject.value = b.subject2 || null;
  bookData.subjectEng.value = b.subject2ENG || null;
  bookData.topic1.value = {'tag': b.tag1} || "";
  bookData.topic1Eng.value = b.tag1ENG || "";
  bookData.topic2.value = {'tag': b.tag2} || "";
  bookData.topic2Eng.value = b.tag2ENG || "";
  bookData.topic3.value = {'tag': b.tag3} || "";
  bookData.topic3Eng.value = b.tag3ENG || "";
  bookData.value.value = {'tag': b.tag4} || "";
  bookData.valueEng.value = b.tag4ENG || "";
  bookData.tag5.value = {'tag': b.tag5} || "";
  bookData.tag5Eng.value = b.tag5ENG || "";
  bookData.link1Text.value = b.textLink1 || "";
  bookData.link2Text.value = b.textLink2 || "";
  bookData.link3Text.value = b.textLink3 || "";
  bookData.readTime.value = b.tiempoLectura || "";
  bookData.title.value = b.title || "";
  bookData.values.value = b.valores || "";
  bookData.valuesEng.value = b.valoresENG || "";
  // bookData..value = b.webUrl;
  // formData.manuvoId.value
  bookData.libroPremiado.value = `${b.libroPremiado}` === "0" ? false : true;
  bookData.libroAccesible.value = `${b.libroAccesible}` === "0" ? false : true;
  bookData.libroBilingue.value = `${b.libroBilingue}` === "0" ? false : true;
  bookData.tipoDeLibro.value = b.tipoDeLibro || null;

  Object.keys(bookData).forEach((key) => {
    bookData[key].error = false;
  });

  return bookData;
};
