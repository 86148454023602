import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MDBDataTable } from "mdbreact";
import { Col, Container, Row } from "react-bootstrap";
import CustomGreenButton from "../../../components/shared/custom-green-button";
import ColorIconButton from "../../../components/shared/custom-green-icon-button";
import TableColumnDropdown from "../../../components/shared/table-column-dropdown/table-column-dropdown";

import CachedIcon from "@material-ui/icons/Cached";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import exportFromJSON from "export-from-json";
import swal from "sweetalert";

import { DropzoneArea } from "material-ui-dropzone";
import ExcelWorkBook from "../../../components/shared/ExcelWorkBook";
import { columnasColeccion } from "../../../shared/data/columnas";
import { GENERAL_URL_API } from "../../../shared/urls";

const AgregarEliminarLibrosColeccion = () => {
  const classes = useStyles();

  //Definimos los estados para almacenar la informacion de la tabla
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [libros, setLibros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [librosFile, setLibrosFile] = useState(null);

  //Definimos un estados para guardar la informacion del  los autocomplete
  const [packageSelected, setPackageSelected] = useState(null);
  const [bookSelected, setBookSelected] = useState(null);

  //Funcion para traer los datos de la tabla
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    let temp = columnasColeccion;
    //Añadimos un atributo de visible a todas las columnas
    temp.map((_, index) => (temp[index].isVisible = true));
    setColumns(temp);

    getColecciones();
    getLibros();
  };

  const getColecciones = async () => {
    const fd = new FormData();

    const res = await fetch(`${GENERAL_URL_API}/coleccion/verColecciones.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const resJSON = await res.json();
    setColecciones(resJSON.data.map((c) => c.collectionName));
  };

  const getLibros = async () => {
    const fd2 = new FormData();

    const res2 = await fetch(`${GENERAL_URL_API}/verDatos/verLibros.php`, {
      method: "POST",
      credentials: "include",
      body: fd2,
    });
    const resJSON2 = await res2.json();
    setLibros(resJSON2.data);
  };

  const handleDropFileChange = (files) => setLibrosFile(files);

  //Funciones para actualizar el valor seleccionado por los autocomplete
  const packageSelectedUpdate = async (e, newValue) => {
    setPackageSelected(newValue);
    const fd = new FormData();

    fd.append("coleccion", newValue);
    const res = await fetch(`${GENERAL_URL_API}/coleccion/verColeccion.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const resJSON = await res.json();
    setRows(resJSON.data);
  };

  const updateTable = async () => {
    const fd = new FormData();

    fd.append("coleccion", packageSelected);
    const res = await fetch(`${GENERAL_URL_API}/coleccion/verColeccion.php`, {
      method: "POST",
      credentials: "include",
      body: fd,
    });
    const resJSON = await res.json();
    setRows(resJSON.data);
  };

  const bookSelectedUpdate = (e, newValue) => setBookSelected(newValue);

  //Funcion para cambiar el estado de visibilidad de una columna
  const changeColumnStatus = (columnField) => {
    const temp = [...columns];
    const index = temp.findIndex((column) => column.field === columnField);
    temp[index].isVisible = !temp[index].isVisible;
    setColumns(temp);
  };

  const addBook = async () => {
    setLoading(true);
    const fd = new FormData();

    fd.append("coleccion", packageSelected);
    fd.append("idLibro", bookSelected.idLibro);
    const res = await fetch(
      `${GENERAL_URL_API}/coleccion/agregarLibroColeccion.php`,
      {
        method: "POST",
        credentials: "include",
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 1) {
      await swal({
        title: "Libro agregado",
        text: "El libro se agrego satisfactoriamente!",
        icon: "success",
      });
      if (!colecciones.includes(packageSelected)) await getColecciones();
      await updateTable();
    } else {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    }
    setLoading(false);
  };

  const addBooks = async () => {
    setLoading(true);
    const fd = new FormData();

    fd.append("nombreColeccion", packageSelected);
    if (librosFile?.length > 0) fd.append("books", librosFile[0]);

    const res = await fetch(
      `${GENERAL_URL_API}/coleccion/agregarLibrosColeccion.php`,
      {
        method: "POST",
        credentials: "include",
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 1) {
      await swal({
        title: "Libro agregado",
        text: "El libro se agrego satisfactoriamente!",
        icon: "success",
      });
      if (!colecciones.includes(packageSelected)) await getColecciones();
      await updateTable();
    } else {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    }
    setLoading(false);
  };

  const handleSubmit = () => (librosFile?.length > 0 ? addBooks() : addBook());

  const deleteBook = async () => {
    setLoading(true);
    const fd = new FormData();

    fd.append("coleccion", packageSelected);
    fd.append("idLibro", bookSelected.idLibro);
    const res = await fetch(
      `${GENERAL_URL_API}/coleccion/eliminarLibroColeccion.php`,
      {
        method: "POST",
        credentials: "include",
        credentials: "include",
        body: fd,
      }
    );
    const resJSON = await res.json();
    if (resJSON.status === 1) {
      await swal({
        title: "Libro eliminado",
        text: "El libro se elimino satisfactoriamente!",
        icon: "success",
      });
      if (!colecciones.includes(packageSelected)) await getColecciones();
      await updateTable();
    } else {
      await swal({
        title: "Error",
        text: resJSON.info,
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <CreateOutlinedIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Agregar o eliminar libros de una coleccion
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <MenuBookIcon style={{ fontSize: "20px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Agregar/eliminar un libro a una coleccion
              </div>
            </div>
            <form>
              <label className="admin-label-text mt-3 d-block">
                Agregar o eliminar de la coleccion:
              </label>
              <Autocomplete
                freeSolo
                options={colecciones}
                getOptionLabel={(option) => option}
                value={packageSelected}
                onChange={packageSelectedUpdate}
                onBlurCapture={(e) => {
                  setPackageSelected(e.target.value);
                }}
                noOptionsText="Sin resultados"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />
              <label className="admin-label-text mt-3 d-block">El libro:</label>
              <Autocomplete
                options={libros}
                getOptionLabel={(option) =>
                  `${option.title} - ${option.author}`
                }
                value={bookSelected}
                onChange={bookSelectedUpdate}
                noOptionsText="Sin resultados"
                disabled={librosFile?.length > 0}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                      width: "100%",
                      fontFamily: "'Noto Sans JP', sans-serif",
                    }}
                  />
                )}
              />

              <label className="admin-label-text mt-3 d-block">
                Los libros:
              </label>
              <DropzoneArea
                id="dropzone"
                dropzoneText="Arrastra un archivo o haz click aquí!"
                showFileNames={true}
                // maxFileSize={} //n in bytes
                showAlerts={true}
                disabled={true}
                filesLimit={1}
                acceptedFiles={[
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "application/vnd.ms-excel",
                ]}
                onChange={(files) => handleDropFileChange(files)}
              />

              <div className="mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !packageSelected ||
                    (!bookSelected && librosFile?.length === 0) ||
                    loading
                  }
                  startIcon={<CheckRoundedIcon />}
                  classes={{ root: classes.agregar }}
                  onClick={handleSubmit}
                >
                  Agregar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2"
                  disabled={
                    !(packageSelected !== null && bookSelected !== null) ||
                    loading
                  }
                  startIcon={<CloseRoundedIcon style={{ fontSize: "25px" }} />}
                  classes={{ root: classes.eliminar }}
                  onClick={deleteBook}
                >
                  Eliminar
                </Button>
              </div>
            </form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4 mt-3">
            {packageSelected === null ? (
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Selecciona una coleccion para poder ver sus libros
              </div>
            ) : (
              <>
                <div className="admin-main-title-dark-gray  mt-1">
                  Tabla de colecciones de libros
                </div>
                <div className="d-flex align-items-center my-3">
                  <ColorIconButton size="medium" onClick={updateTable}>
                    <CachedIcon style={{ fontSize: "20px" }} />
                  </ColorIconButton>
                  <div className="pl-3 admin-muted-text">
                    Refrescar la tabla.
                  </div>
                </div>
                {/* Este componente es el dropdown que permite ocultar columnas*/}
                <TableColumnDropdown
                  columns={columns}
                  changeColumnStatus={changeColumnStatus}
                />
                <br />

                <ButtonGroup size="small" variant="contained" className="my-3">
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: packageSelected + "-books",
                        exportType: exportFromJSON.types.csv,
                      });
                    }}
                  >
                    CSV
                  </CustomGreenButton>
                  <CustomGreenButton
                    onClick={() => {
                      exportFromJSON({
                        data: rows,
                        fileName: packageSelected + "-books",
                        exportType: exportFromJSON.types.json,
                      });
                    }}
                  >
                    JSON
                  </CustomGreenButton>
                  <CustomGreenButton>
                    <ExcelWorkBook
                      columns={columns}
                      rows={rows}
                      filename={packageSelected + "-books"}
                    />
                  </CustomGreenButton>
                </ButtonGroup>
                <MDBDataTable
                  small
                  responsive
                  striped
                  searchTop
                  searchBottom={false}
                  barReverse
                  pagingTop
                  bordered
                  data={{
                    columns: columns.filter((obj) => obj.isVisible),
                    rows: rows,
                  }}
                  noBottomColumns
                  order={["name", "asc"]}
                  entriesOptions={[5, 10, 20, 50]}
                  entriesLabel="Numero de filas"
                  searchLabel="Buscar"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={["Mostrando filas", "a", "de", "filas"]}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgregarEliminarLibrosColeccion;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
