import React, { useEffect, useState } from "react";

import { Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { CloseRounded, EventTwoTone } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button, Checkbox, makeStyles } from "@material-ui/core";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { Autocomplete } from "@material-ui/lab";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { crearNotibuzon } from "../../../../shared/fetchers/classics/notibuzon/crearNotibuzon";
import { editarNotibuzon } from "../../../../shared/fetchers/classics/notibuzon/editarNotibuzon";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { fetchInstitutosActivos } from "../../../../shared/fetchers/classics/institutos/fetchInstitutosActivos";
import axios from "axios";

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const optionsTipoNotificacion = [
  "Evento",
  "Recomendado",
  "Oferta",
  "MakeBlog",
  "Noticia",
];

const CrearNotibuzonIndividual = ({ notibuzon, clearEdit }) => {
  const classes = useStyles();

  const queryClient = useQueryClient();
  const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD

  const {
    data: institutos,
    isLoading: isLoadingInstitutos,
    isError: isErrorInstitutos,
  } = useQuery(["institutosClassics"], fetchInstitutosActivos);

  const [formData, setFormData] = useState({
    tipoNotificacion: {
      value: notibuzon?.tipoNotificacion ?? "",
      error: false,
    },
    tipoVisibilidad: {
      value: [],
      error: false,
    },
    metadatos: { value: JSON.stringify(notibuzon?.metadatos) ?? "{}", error: false },
    fechaInicio: { value: notibuzon?.fechaInicio ?? today, error: false },
    fechaFin: { value: notibuzon?.fechaFin ?? "", error: false },
  });

  useEffect(() => {
    // this second use effect is needed so the initial
    // tipoVisibilidad of a notibuzón while editing it is shown
    // Otherwise the previous useEffect'll overwrite tipoVisibilidad to [] (empty array)

    if (notibuzon && institutos?.length) {
      let { tipoVisibilidad } = notibuzon;

      let value =
        tipoVisibilidad.toLocaleLowerCase() === "all"
          ? institutos
          : institutos?.filter(
              (instituto) =>
                instituto?.nombre.length &&
                tipoVisibilidad.includes(instituto?.nombre),
            );

      updateForm({
        target: {
          name: "tipoVisibilidad",
          value,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutos]);

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: { ...formData[name], value: value, error: false },
    });
  };

  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { ...temp[formElement], value: "", error: false };
    }

    temp = {
      ...temp,
      fechaInicio: { value: today, error: false },
      tipoVisibilidad: { value: [], error: false },
      metadatos: { value: "{}", error: false },
    };

    setFormData(temp);
  };

  const validate = () => {
    let isValid = true;

    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };

    //Recorremos todos los campos del formulario y validamos que no esten vacios si no son opcionales
    for (const formElement of Object.entries(temp)) {
      const [key, value] = formElement;

      if (!value.value) {
        value.error = true;
        isValid = false;
        toast.error(`El campo ${key} no puede estar vacío`);
      }

      if (key === "tipoVisibilidad" && !value.value.length) {
        value.error = true;
        isValid = false;
        toast.error(`El campo ${key} no puede estar vacío`);
      }
    }

    setFormData(temp);
    return isValid;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    let datosNotibuzon = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (key === "tipoVisibilidad")
          return [
            key,
            value.value.map((instituto) => instituto.nombre).join(";"),
          ];
        return [key, value.value];
      }),
    );

    datosNotibuzon = {
      ...datosNotibuzon,
      tipoVisibilidad:
        formData.tipoVisibilidad.value.length === institutos.length
          ? "ALL"
          : datosNotibuzon.tipoVisibilidad,
    };

    const { status } = !notibuzon
      ? await crearNotibuzon({
          ...datosNotibuzon,
        })
      : await editarNotibuzon({
          ...datosNotibuzon,
          idNotibuzon: notibuzon.idNotibuzon,
        });

    if (notibuzon) clearEdit();

    queryClient.invalidateQueries({
      queryKey: ["notibuzones"],
    });

    if (!status)
      return swal({
        title: "Error",
        text: `Hubo un error ${
          notibuzon ? "editando" : "creando"
        } el notibuzon, inténtalo de nuevo.`,
        icon: "error",
      });

    !notibuzon
      ? toast.success("Notibuzón creado correctamente")
      : toast.success("Notibuzón editado correctamente");
  };

  if (isLoadingInstitutos) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  }

  if (isErrorInstitutos)
    return <div>Ha ocurrido un error, prueba recargando la página</div>;

  return (
    <Col xs={12} className="content-white-box px-4 py-4">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          {notibuzon ? "Editar" : "Crear"} un Notibuzón
        </div>
      </div>
      <div className="admin-muted-text-small mt-2">
        Los campos opcionales están marcados con (Opcional), los demás son
        obligatorios
      </div>
      <div className="admin-muted-text-small mt-2">
        Por favor ingresar la siguiente info:
      </div>
      <form>
        <label className="admin-label-text mt-3 d-block">
          Tipo de notificación
        </label>
        <Autocomplete
          options={optionsTipoNotificacion}
          value={formData.tipoNotificacion.value}
          name="tipoNotificacionAutocomplete"
          getOptionLabel={(option) => option}
          inputValue={formData.tipoNotificacion.value}
          onInputChange={(_, value) => {
            updateForm({
              target: {
                name: "tipoNotificacion",
                value,
              },
            });
          }}
          noOptionsText="Sin resultados"
          error={formData.tipoNotificacion.error}
          helpertext={
            formData.tipoNotificacion.error ? "Campo no válido" : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Tipo de Notificación"
              name="tipoNotificacion"
              variant="outlined"
              size="small"
              error={formData.tipoNotificacion.error}
              helpertext={
                formData.tipoNotificacion.error ? "Campo no válido" : null
              }
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <div className="d-flex justify-content-between flex-wrap">
          <label className="admin-label-text mt-3 d-block">
            Tipo de Visibilidad:
          </label>
          <div className="d-flex" style={{ gap: "4px" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                formData.tipoVisibilidad.value.length === institutos.length ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              classes={{ root: classes.agregar }}
              style={{
                height: "1.5em",
                alignSelf: "self-end",
                marginBottom: "0.3em",
              }}
              onClick={() => {
                updateForm({
                  target: {
                    name: "tipoVisibilidad",
                    value: institutos,
                  },
                });
              }}
            >
              {"Agregar todos"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                !formData.tipoVisibilidad.value.length ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              classes={{ root: classes.eliminar }}
              style={{
                height: "1.5em",
                alignSelf: "self-end",
                marginBottom: "0.3em",
              }}
              onClick={() => {
                updateForm({
                  target: {
                    name: "tipoVisibilidad",
                    value: [],
                  },
                });
              }}
            >
              {"Eliminar todos"}
            </Button>
          </div>
        </div>
        <Autocomplete
          multiple
          name="tipoVisibilidad"
          disableCloseOnSelect
          options={institutos}
          getOptionLabel={(option) => option?.nombre}
          value={formData.tipoVisibilidad.value}
          placeholder="Tipo de visibilidad"
          noOptionsText="Sin resultados"
          onChange={(_, value) => {
            updateForm({
              target: {
                name: "tipoVisibilidad",
                value,
              },
            });
          }}
          error={formData.tipoVisibilidad.error}
          helpertext={
            formData.tipoVisibilidad.error
              ? "El campo tipoVisibilidad no puede estar vacío"
              : null
          }
          renderOption={(option, { selected }) => {
            return (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color="primary"
                />
                {option?.nombre}
              </>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              error={formData.tipoVisibilidad.error}
              helpertext={
                formData.tipoVisibilidad.error ? "Campo no válido" : null
              }
              placeholder={
                formData.tipoVisibilidad.value.length
                  ? ""
                  : "Tipo de visibilidad"
              }
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">
          Entre las fechas:
        </label>
        <div className="d-flex">
          <TextField
            name="fechaInicio"
            value={formData.fechaInicio.value}
            onChange={updateForm}
            variant="outlined"
            size="small"
            type="date"
            error={formData.fechaInicio.error}
            style={{
              width: "180px",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
          <CustomGreenButton
            variant="contained"
            style={{
              height: "40px",
              pointerEvents: "none",
            }}
            className="d-block d-md-inline"
            disableTouchRipple
            disableRipple
            disableElevation
            disableFocusRipple
          >
            Hasta
          </CustomGreenButton>
          <TextField
            name="fechaFin"
            value={formData.fechaFin.value}
            onChange={updateForm}
            variant="outlined"
            size="small"
            type="date"
            error={formData.fechaFin.error}
            style={{
              width: "180px",
              fontFamily: "'Noto Sans JP', sans-serif",
            }}
          />
        </div>

        <label htmlFor="metadata" className="admin-label-text mt-3 d-block">
          Metadatos
        </label>
        <div className="admin-muted-text-small mt-2">
          Para habilitar los botones debes seleccionar un tipo de notificación.
        </div>
        <div className="my-3 d-flex" style={{ gap: "0.15rem" }}>
          <Link
            to={`/templates/classics/notibuzon/${
              optionsTipoNotificacion.includes(formData.tipoNotificacion.value)
                ? formData.tipoNotificacion.value.toLocaleLowerCase() + ".json"
                : "template.json"
            }`}
            target="_blank"
            download
          >
            <CustomGreenButton
              size="small"
              variant="contained"
              disabled={!formData.tipoNotificacion.value}
            >
              Descargar plantilla{" "}
              {formData.tipoNotificacion.value
                ? `- ${formData.tipoNotificacion.value}`
                : ""}
            </CustomGreenButton>
          </Link>

          <CustomGreenButton
            size="small"
            variant="contained"
            disabled={!formData.tipoNotificacion.value}
            onClick={async () => {
              const { data: metadata } = await axios.get(
                `/templates/classics/notibuzon/${
                  optionsTipoNotificacion.includes(
                    formData.tipoNotificacion.value,
                  )
                    ? formData.tipoNotificacion.value.toLocaleLowerCase() +
                      ".json"
                    : "template.json"
                }`,
              );

              navigator.clipboard
                .writeText(JSON.stringify(metadata))
                .then(async () => {
                  toast.success("Metadatos copiados al portapapeles");
                });
            }}
          >
            Copiar{" "}
            {formData.tipoNotificacion.value
              ? `- ${formData.tipoNotificacion.value}`
              : ""}
          </CustomGreenButton>
        </div>
        <TextField
          name="metadatos"
          value={formData.metadatos.value}
          onChange={updateForm}
          type="text"
          error={formData.metadatos.error}
          helpertext={
            formData.metadatos.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          multiline
          minRows={4}
          placeholder="Metadatos de la notificación"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => SubmitForm(e)}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
          >
            Finalizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              clearForm();
              if (notibuzon) clearEdit();
            }}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearNotibuzonIndividual;

const useStyles = makeStyles({
  agregar: {
    background: "#81c868",
    "&:hover": {
      backgroundColor: "#81c868",
    },
  },
  eliminar: {
    background: "#f05050",
    "&:hover": {
      backgroundColor: "#f05050",
    },
  },
});
