import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
// import { GENERAL_URL_API } from "../../../shared/urls";
import { GENERAL_URL_API } from "../../../shared/urls";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PersonIcon from "@material-ui/icons/Person";
import { toast } from "react-hot-toast";

const EliminarUnEstudiante = () => {
  //Estados para gestionar todo lo relacionado con los datos
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //Definimos un estado para guardar la informacion del autocomplete
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);

  //Funcion para traer los datos de la api
  useEffect(() => {
    setLoading(true);
    fetchEstudiantes();
    setLoading(false);
  }, []);

  const fetchEstudiantes = async () => {
    setData([]);
    const fd = new FormData();


    const res = await fetch(
      `${GENERAL_URL_API}/estudiantes/verEstudiantes.php`,
      {
        method: "POST", credentials: 'include',
        credentials: "include",
        body: fd,
      }
    );
    const data = await res.json();
    setData(data.data);

    if (!data.status)
      await swal({
        title: "Error.",
        text: data.info,
        icon: "error",
      });
  };

  //Funcion para actualizar el valor seleccionado por el autocomplete
  const autoCompleteUpdate = async (e, newValue) => {
    if (newValue) {
      setAutoCompleteValue(newValue);
      //Si se selecciono un valor creamos un modal para confirmar si la persona desea borrar un elemento
      const res = await swal({
        title: "Está seguro?",
        text: "Esta acción no se puede revertir!",
        icon: "warning",
        buttons: ["Cancelar", "Borrar"],
      });
      //Si se quiere borrar un elemento se llama a la api y se actualiza localmente el estado
      if (res) {
        try {
          const fd = new FormData();


          fd.append("idEstudiante", newValue.idEstudiantePlanLector);
          //Llamamos la api
          const res = await fetch(
            `${GENERAL_URL_API}/estudiantes/eliminarEstudiante.php`,
            {
              method: "POST", credentials: 'include',
              credentials: "include",
              body: fd,
            }
          );
          const resJSON = await res.json();
          console.log(resJSON);
          //Actualizamos localmente
          setAutoCompleteValue(null);

          setLoading(true);
          await fetchEstudiantes();
          setLoading(false);
          toast.success("Estudiante eliminado con éxito");
        } catch (e) {
          swal({
            title: e.message,
            icon: "error",
          });
        }
      }
      setAutoCompleteValue(null);
    }
  };

  return (
    <>
      <div className="mb-4 d-flex align-items-center">
        <div className="admin-page-tilte">(</div>
        <DeleteOutlineIcon />
        <div className="admin-page-tilte mr-2">)</div>
        <div className="admin-page-tilte">
          Eliminar un estudiante plan lector
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} className="content-white-box px-4 py-4">
            <div className="d-flex align-items-center">
              <PersonIcon style={{ fontSize: "22px" }} />
              <div className="admin-main-title-dark-gray ml-2 mt-1">
                Borrar un estudiante plan lector existente
              </div>
            </div>
            {loading ? (
              <div className="mt-2">
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </div>
            ) : (
              <>
                <label className="admin-label-text mt-3 d-block">Buscar:</label>
                <Autocomplete
                  options={data}
                  getOptionLabel={(option) =>
                    `${option.nombreInstituto} - ${option.nombre}`
                  }
                  value={autoCompleteValue}
                  onChange={autoCompleteUpdate}
                  noOptionsText="Sin resultados"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "100%",
                        fontFamily: "'Noto Sans JP', sans-serif",
                      }}
                    />
                  )}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EliminarUnEstudiante;
